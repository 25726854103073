import React, { useState, useEffect } from "react";
import { httpClient, apiUrl } from "../../utils/utilsFetch";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const NotePreview = (props) => {
  const [data, setData] = useState(props.data);

  useEffect(() => {
    if (!data) {
      httpClient(`${apiUrl}/notes/${props.id}`, {
        method: "GET",
      }).then(({ json }) => {
        setData(json);
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderDelete = props.clear ? (
    <div
      style={{
        marginLeft: "10px",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-start",
        flex: 1,
      }}
    >
      <IconButton
        edge="start"
        color="inherit"
        onClick={props.clear}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
    </div>
  ) : null;

  return data ? (
    <li>
      <article>
        <figure>
          <img src={data.featured_image} alt="" />
        </figure>
        <div className={"content"}>
          <h2 className={"title"}>{data.title_home || data.title}</h2>
        </div>
        {renderDelete}
      </article>
    </li>
  ) : null;
};

export default NotePreview;
