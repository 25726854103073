import dataProviderApi from './dataProviderApi'; //handle ruby dataProvider
import dataProviderPlanA from './dataProviderPlanA';// handle java dataProvider
import {
  GET_LIST,
  GET_ONE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
  GET_MANY,
  GET_MANY_REFERENCE,
} from 'react-admin';

const isPlanAResource = resource => {
  const planAReource = [
    'benefits',
    'shop'
  ];
  return planAReource.some(ele => ele === resource);
};

const mappingType = {
  [GET_LIST]: 'getList',
  [GET_ONE]: 'getOne',
  [GET_MANY]: 'getMany',
  [GET_MANY_REFERENCE]: 'getManyReference',
  [CREATE]: 'create',
  [UPDATE]: 'update',
  [UPDATE_MANY]: 'updateMany',
  [DELETE]: 'delete',
  [DELETE_MANY]: 'deleteMany',
  'locked': 'locked',
  'unlocked': 'unlocked',
  'getAbilities': 'getAbilities',
};

const superDataProvider = (type, resource, params) => {
  if (isPlanAResource(resource)) {
    return dataProviderPlanA[mappingType[type]](resource, params);
  }

  return dataProviderApi[mappingType[type]](resource, params);
};

export default superDataProvider;