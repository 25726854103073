import * as React from "react";
import MyList from "../../components/listSettings/listSettings"

export const SettingsList = (props) => {

    return (
        <MyList {...props} title=" ">
            
        </MyList>
    );
}
