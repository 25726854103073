import React, { useState } from "react";
import { useTranslate } from "react-admin";
import {
  Input,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
} from "@material-ui/core";
import { colorsalert } from "Static/blocks/ColorOption";
import { makeStyles, Chip } from "@material-ui/core";
const useStyles = makeStyles({
  chip: {
    marginLeft: "10px",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
  },
});

const SelectColor = ({ value, zoneIndex, handleChangeField }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const [selected, setSelected] = useState(
    colorsalert.find((item) => item.name === value)?.value || "light"
  );
  return (
    <Box sx={{ width: { xs: "160px", md: "250px" } }}>
      <FormControl fullWidth>
        <InputLabel id="InputLabel-position-hero">
          {translate("resources.pages.fields.color")}
        </InputLabel>
        <Select
          id={"Select-component-" + zoneIndex}
          input={<Input />}
          variant="filled"
          onChange={(e) => {
            handleChangeField(e.target.value, "color", zoneIndex);
            setSelected(
              colorsalert.find((item) => item.name === e.target.value)
            );
          }}
          style={{ minWidth: "250px" }}
          value={value}
          renderValue={(item) => (
            <div className={classes.chips}>
              <Chip
                variant="outlined"
                label={selected?.title || "Default"}
                icon={
                  <span
                    className={classes.chip}
                    style={{
                      background: selected?.value
                        ? selected.value
                        : colorsalert.find((item) => item.name === "light")
                            ?.value,
                    }}
                  ></span>
                }
              />
            </div>
          )}
        >
          {colorsalert.map((color, index) => (
            <MenuItem key={index} value={color.name}>
              {color.title}{" "}
              <span
                className={classes.chip}
                style={{ background: color.value }}
              ></span>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default React.memo(
  SelectColor,
  (prev, next) => prev.value === next.value && prev.zoneIndex === next.zoneIndex
);
