import { Fragment, useState, useEffect } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";
import { Typography, Box, makeStyles } from "@material-ui/core";
import { useField } from "react-final-form";
import { useTranslate } from "ra-core";

const useStyles = makeStyles({
  input: {
    maxWidth: "220px",
  },
  inputDescription: {
    width: "520px",
  },
});

const DescriptionField = ({ source, label, record = {}, className }) => {
  const [valuesArea, setValuesArea] = useState(record[source]);
  const { input } = useField(source);
  const CHARACTER_LIMIT = 120;
  const translate = useTranslate();

  const handleChangeTextArea = (e) => {
    setValuesArea(e.target.value);
    input.onChange(e.target.value);
  };

  useEffect(() => {
    setValuesArea(record[source]);
  }, [record[source]]); // eslint-disable-line react-hooks/exhaustive-deps

  const maxLength =
    (max, message = `${translate("components.description_too_long")}`) =>
      (value) =>
        value && value.length > max
          ? `${translate("components.chars")}: ${valuesArea ? valuesArea.length : 0
          }/${CHARACTER_LIMIT} - ${message}`
          : undefined;

  return (
    <TextInput
      source={source}
      label={label}
      multiline
      rows={4}
      initialvalue={valuesArea ? valuesArea : undefined}
      onChange={(e) => handleChangeTextArea(e)}
      helperText={`${translate("components.chars")}: ${valuesArea ? valuesArea.length : 0
        }/${CHARACTER_LIMIT}`}
      validate={[required(), maxLength(CHARACTER_LIMIT)]}
      className={className}
    />
  );
};

export const AdMediaFields = ({ label, ...props }) => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <Fragment>
      <Typography variant='h5' style={{ marginBottom: "16px" }}>
        {label ? label : `${translate("resources.amedia.new")}`}
      </Typography>
      <Box display='flex' flexDirection='column'>
        <TextInput
          source='name'
          reference='amedia'
          label={translate("resources.amedia.fields.name")}
          className={classes.input}
          validate={required()}
        />
        <DescriptionField
          {...props}
          className={classes.inputDescription}
          source='description'
          label={translate("resources.amedia.fields.description")}
          reference='amedia'
        />
      </Box>
    </Fragment>
  );
};

export const AdMediaCreate = ({ ...props }) => {
  return (
    <Create {...props} title=' '>
      <SimpleForm>
        <AdMediaFields {...props} />
      </SimpleForm>
    </Create>
  );
};
