import * as React from "react";
import { Create, SimpleForm, TextInput, required } from 'react-admin';
import { Box, Typography, Grid } from '@material-ui/core';
import { AvatarInput } from './AvatarInput';
import { useTranslate } from "ra-core";

const FormCreateAuthor = (props) => {
    const translate = useTranslate();
    return (
        <Grid style={{ width: "100%" }} sm={12} md={6} display={{ md: 'block', lg: 'flex' }}>
            <Box flex={3} mr={{ md: 0, lg: '1em' }}>
                <Typography variant="h6" gutterBottom>
                    {translate("components.add_author")}
                </Typography>
                <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} style={{ alignSelf: "center" }}>
                        <AvatarInput resource="authors" source="avatar_file" {...props} fullWidth />
                    </Box>
                </Box>
                <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1}>
                        <TextInput
                            source="first_name"
                            resource="authors"
                            validate={[required()]}
                            fullWidth
                        />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }} >
                        <TextInput
                            source="last_name"
                            resource="authors"
                            validate={[required()]}
                            fullWidth
                        />
                    </Box>
                </Box>
                <TextInput
                    source="description"
                    resource="authors"
                    multiline
                    rows={3}
                    fullWidth
                />
            </Box>
        </Grid>
    );
}

export const AuthorCreate = props => {
    return (
        <Create {...props} title=" ">
            <SimpleForm>
                <FormCreateAuthor />
            </SimpleForm>
        </Create>
    );
};