import React, { useState, useEffect } from 'react';
import CardWithIcon from '../../components/CardWithIcon/CardWithIcon';
import BarChart from '@material-ui/icons/BarChart';
import { useTranslate } from 'ra-core';
import PieChartCard from '../../components/PieChartCard/PieChartCard';
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import MetricForm from './MetricForm';
import { DataGrid } from '@material-ui/data-grid';


const styles = {
    container: { display: 'flex', padding: "0 0 0 16px" },
    flex: { display: 'flex', marginBottom: '1em' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
    header: { display: 'flex', justifyContent: "space-between", alignItems: 'center' },
    toolbar: { display: 'flex', alignItems: 'flex-start', gap: "1em", minWidth: "100%" },
    centered: { display: 'flex', justifyContent: 'center' }
};

const useStyles = makeStyles({
    formEmpty: {
        '& div': {
            display: 'flex',
            marginRight: '8px',
        }
    },
    formTop: {
        '& div': {
            display: 'flex',
            marginRight: '4px',
            maxWidth: "180px"
        }
    }
});

const Spacer = () => <span style={{ width: '1em' }} />;

// ========================================================================
// Componente principal
// ========================================================================
const ViewSubscriptions = (props) => {
    const translate = useTranslate();
    const [data, setData] = useState(null);
    const [formData, setFormData] = useState({});
    const [serverData, setServerData] = useState([]);
    const [subUrlData, setSubUrlData] = useState([]);

    const classes = useStyles();

    // ========================================================================
    // Inicializa la tabla de sub-url
    // ========================================================================
    const dataColumns = [
        { field: 'url', headerName: translate('resources.metrics.titles.sub_url'), flex: 4 },
        { field: 'bounced', headerName: translate('resources.metrics.titles.bounced'), flex: 1, description: translate('resources.metrics.descriptions.bounced') },
        { field: 'unfulfilled', headerName: translate('resources.metrics.titles.unfulfilled'), flex: 1, description: translate('resources.metrics.descriptions.unfulfilled') },
        { field: 'fulfilled', headerName: translate('resources.metrics.titles.fulfilled'), flex: 1, description: translate('resources.metrics.descriptions.fulfilled') },
        {
            field: 'successful', headerName: translate('resources.metrics.titles.successful'), flex: 1, description: translate('resources.metrics.descriptions.successful'),
        }
    ];

    // Debug
    // useEffect(() => {
    //     setData(subscriptionMetrics(mockData));
    // }, []);

    useEffect(() => {
        if (data && data.subByUrl) setSubUrlData(data.subByUrl)
    }, [data])

    // ========================================================================
    // Component body
    // ========================================================================
    return <>
        {data ? <>
            <div style={styles.header}>
                <MetricForm endpoint={"subscriptions"} data={data} jss={classes.form + " " + classes.formTop} setData={setData} serverdata={serverData} setserverdata={setServerData} formdata={formData} setformdata={setFormData} />
                <div></div>
            </div>
            <div style={styles.container}>
                <div style={styles.leftCol}>
                    {data.subByPlan.length > 0 && <PieChartCard title={translate('resources.metrics.titles.sub_by_plan')} data={data.subByPlan} />}
                </div>
                <div style={styles.rightCol}>
                    <div style={styles.flex}>
                        <CardWithIcon icon={BarChart}
                            title={translate('resources.metrics.titles.singups')}
                            subtitle={data.singUps} />
                        <Spacer />
                        <CardWithIcon icon={BarChart}
                            title={translate('resources.metrics.titles.signrenews')}
                            subtitle={data.singRenews} />
                    </div>
                    <div style={styles.flex}>
                        <CardWithIcon icon={BarChart}
                            title={translate('resources.metrics.titles.optouts')}
                            subtitle={data.optOuts} />
                        <Spacer />
                        <CardWithIcon icon={BarChart}
                            title={translate('resources.metrics.titles.churn_rate')}
                            subtitle={data.churnRate + "%"} />
                    </div>
                </div>
            </div>
            <div style={{ height: 640, width: '100%', marginBottom: '50px' }}>
                <DataGrid
                    style={{ marginLeft: "1em", marginTop: "1em" }}
                    rows={subUrlData}
                    columns={dataColumns}
                    pageSize={10}
                    disableColumnMenu
                    disableSelectionOnClick
                />
            </div>
        </> :
            <Box textAlign="center" m={1} maxWidth={1200}>
                <BarChart style={{ fontSize: "150px" }} color="disabled" />
                <Typography variant="h6">
                    {translate("resources.metrics.validation.empty_message")}
                </Typography>
                <Box style={styles.centered} m={1}>
                    <MetricForm endpoint={"subscriptions"} serverdata={serverData} data={data} setserverdata={setServerData} jss={classes.form + " " + classes.formEmpty} setData={setData} formdata={formData} setformdata={setFormData} />
                </Box>
            </Box>
        }
    </>
};

export default ViewSubscriptions;