import { Button, Card, CardActions, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { useState } from "react";
import {
  Link,
  Notification,
  PasswordInput,
  TextInput,
  useLogin,
  useNotify,
  useTranslate,
} from "react-admin";
import { Form } from "react-final-form";
import { useLocation } from "react-router-dom";
import loginBg from "../../assets/img/login-bg.jpg";
import Logo from "../../Logo";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    background: `url(${loginBg})`,
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "75%",
    backgroundSize: "cover",
  },
  card: {
    maxWidth: 320,
    marginTop: "6em",
  },
  link: {
    alignSelf: "flex-end",
    position: "relative",
    top: "-26px",
    zIndex: 2,
    cursor: "pointer",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "space-around",
  },
  icon: {
    backgroundColor: "#14A9D4",
    "&:hover": {
      backgroundColor: "#1F3A4F",
    },
  },
  hint: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.grey[500],
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  actions: {
    padding: "1.5em 1em 1em 1em",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
}));

const Login = () => {
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const login = useLogin();
  const location = useLocation();

  const handleSubmit = (auth) => {
    setLoading(true);
    login(auth, location.state ? location.state.nextPathname : "/").catch(
      (error) => {
        setLoading(false);
        notify(
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message,
          "warning",
          {
            _:
              typeof error === "string"
                ? error
                : error && error.message
                ? error.message
                : undefined,
          }
        );
      }
    );
  };

  const validate = (values) => {
    const errors = {};
    if (!values.username) {
      errors.username = translate("ra.validation.required");
    }
    if (!values.password) {
      errors.password = translate("ra.validation.required");
    }
    return errors;
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.main}>
            <Card className={classes.card}>
              <div className={classes.avatar}>
                <Logo height="48px" />
              </div>
              <div className={classes.hint}>{translate("ra.auth.title")}</div>
              <div className={classes.form}>
                <TextInput
                  name="username"
                  source="username"
                  label={translate("ra.auth.email")}
                  disabled={loading}
                  fullWidth
                />
                <PasswordInput
                  name="password"
                  source="password"
                  label={translate("ra.auth.password")}
                  disabled={loading}
                  fullWidth
                />
              </div>
              <CardActions className={classes.actions}>
                <Link to="/recovery-password" className={classes.link}>
                  {translate("ra.auth.forgot")}
                </Link>
                <Button
                  className={classes.icon}
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading}
                  fullWidth
                >
                  {loading && <CircularProgress size={25} thickness={2} />}
                  {translate("ra.auth.sign_in")}
                </Button>
              </CardActions>
            </Card>
            <Notification />
          </div>
        </form>
      )}
    />
  );
};

const LoginWithTheme = (props) => <Login {...props} />;

export default LoginWithTheme;
