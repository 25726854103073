import React from "react";
import {
  Input,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
} from "@material-ui/core";

const SelectGeneral = ({
  value,
  zoneIndex,
  handleChangeField,
  list,
  field,
}) => {
  return (
    <Box sx={{ width: { xs: "160px", md: "250px" } }} marginRight="1em">
      <FormControl fullWidth>
        <InputLabel id="InputLabel-position-hero">
          {list.name}
        </InputLabel>
        <Select
          id={"Select-component-" + zoneIndex}
          input={<Input />}
          variant="filled"
          onChange={(e) => handleChangeField(e.target.value, field, zoneIndex)}
          style={{ minWidth: "250px" }}
          value={value || ""}
        >
          {list.list.map((item, index) => (
            <MenuItem value={item.value} key={index}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default React.memo(
  SelectGeneral,
  (prev, next) => prev.value === next.value && prev.zoneIndex === next.zoneIndex
);
