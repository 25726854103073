import Tooltip from "@material-ui/core/Tooltip";
import LockIcon from "@material-ui/icons/Lock";
import { useGetIdentity, useTranslate } from "ra-core";
import * as React from "react";
import {
  Datagrid,
  DateField,
  EditButton,
  List,
  ReferenceField,
  TextField,
  TopToolbar,
} from "react-admin";
import { PAGES_LOCK_LEAVE } from "./edit";

const PagesEditActions = () => {
  return <TopToolbar />;
};

const EditButtonOrLocked = (props) => {
  const translate = useTranslate();

  if (!props.record || !props.permissions) return null;
  if (
    props.permissions.some(
      (permission) =>
        permission.name === "*" || permission.name === "edit-pages"
    )
  ) {
    if (
      props.record.locked_at &&
      Math.abs((new Date() - new Date(props.record.locked_at)) / 1000) <
        PAGES_LOCK_LEAVE
    ) {
      if (props.identity) {
        if (props.identity.id === props.record.locked_by) {
          return (
            <>
              <EditButton {...props} />
              <span
                style={{
                  display: "inline-flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "#a4a19f",
                }}
              >
                <LockIcon />
                <span>{translate("components.possession")}</span>
              </span>
            </>
          );
        } else if (props.identity.email === "info@tuxdi.com") {
          return (
            <>
              <EditButton {...props} />
              <span
                style={{
                  display: "inline-flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "#a4a19f",
                }}
              >
                <span>{translate("components.editing")}</span>
                <span
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  {props.record.locked_user &&
                    `(${props.record.locked_user.username})`}
                </span>
              </span>
            </>
          );
        }
      }
      return (
        <span
          style={{
            display: "inline-flex",
            flexDirection: "column",
            alignItems: "center",
            color: "#a4a19f",
          }}
        >
          <LockIcon />
          <span>{translate("components.locked")}</span>
          <span
            style={{
              whiteSpace: "nowrap",
            }}
          >
            {props.record.locked_user &&
              `(${props.record.locked_user.username})`}
          </span>
        </span>
      );
    }
    return (
      <Tooltip title={`${translate("ra.action.edit")}`}>
        <div>
          <EditButton {...props} />
        </div>
      </Tooltip>
    );
  }
};

export const PagesList = ({ permissions, ...props }) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();

  return (
    <List
      {...props}
      actions={<PagesEditActions />}
      bulkActionButtons={false}
      sort={{ field: "name", order: "ASC" }}
      title=" "
    >
      <Datagrid rowClick="">
        <TextField source="id" />
        <TextField source="name" />
        <DateField
          source="updated_at"
          showTime
          locales={translate("components.locale_language")}
          options={{
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          }}
        />
        <ReferenceField source="locked_by" reference="journalists">
          <TextField source="username" />
        </ReferenceField>
        {permissions &&
          permissions.some(
            (permission) =>
              permission.name === "*" || permission.name === "edit-pages"
          ) && (
            <EditButtonOrLocked
              label=""
              identity={identity}
              permissions={permissions}
            />
          )}
      </Datagrid>
    </List>
  );
};
