import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  useTranslate,
  ImageInput,
  ImageField,
  useRecordContext,
  maxLength,
  useQueryWithStore,
  Loading
} from "react-admin";
import { apiCODPlanA } from "../../utils/utilsFetch";
import { validateFilesize, bytesToSize } from "../../utils/validations";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";

export const CHOICES = [
  { id: 1, name: "CUIDADO PERSONAL" },
  { id: 2, name: "SERVICIOS" },
  { id: 9, name: "GASTRONOMIA" },
  { id: 10, name: "MODA" },
  { id: 11, name: "HOME & DECO" },
  { id: 12, name: "MAS" },
  { id: 13, name: "ENTRETENIMIENTO" },
];

const maxSizePhoto = 51200;
const validatePhoto = [
  validateFilesize(maxSizePhoto, "File size cannot exceed %{max_size}"),
];

const useStyles = makeStyles({
  file: {
    width: "500px",
  },
});

export const Form = () => {
  const translate = useTranslate();
  const classes = useStyles();
  const record = useRecordContext();
  const img = record?.image
    ? record?.image + `?${Math.floor(Math.random() * 10)}`
    : null;

  const { loaded, data: shops } = useQueryWithStore({
    type: 'getList',
    resource: 'shop',
    payload: {
      filter: {
      },
      sort: { field: 'name', order: 'ASC' },
      pagination: { page: 1, perPage: 2000 },
    },
  });

  return (
    <div style={{ display: "flex", gap: "16px", width: "100%" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <TextInput
          source="title"
          label="resources.benefits.fields.title"
          validate={[required()]}
        />
        <SelectInput
          source="category"
          label="resources.benefits.fields.category"
          choices={CHOICES}
          validate={[required()]}
        />
        <TextInput
          source="description"
          label="resources.benefits.fields.description"
          validate={[required()]}
          style={{ width: "450px" }}
          multiline
        />
        <TextInput
          source="short_desc"
          label="resources.benefits.fields.short_description"
          validate={[required(), maxLength(50)]}
          multiline
          inputProps={{ maxLength: 50 }}
        />
        {shops && loaded ? <SelectInput
          source="id_shop"
          reference="shop"
          label="resources.benefits.fields.shop"
          choices={shops}
        ></SelectInput> : <Loading />}
      </div>

      <div style={{ width: "500px", display: "flex", flexDirection: "column" }}>
        <ImageInput
          source="file"
          label={record?.image ? "Imagen nueva" : "Imagen"}
          accept="image/png, image/jpg, image/gif, image/jpeg"
          maxSize={maxSizePhoto}
          validate={
            record?.image ? [validatePhoto] : [validatePhoto, required()]
          }
          helperText={translate("resources.media.image_maxFilesize", {
            maxSize: bytesToSize(maxSizePhoto),
          })}
          className={classes.input}
        >
          <ImageField source="picture_prev" title="Upload Preview" />
        </ImageInput>
        {record?.image && img ? (
          <>
            <Typography
              variant="caption"
              component="span"
              style={{ color: "rgba(0, 0, 0, 0.54)", marginTop: "24px" }}
            >
              {`${translate("resources.benefits.fields.current_image")}`}
            </Typography>
            <ImageField
              source="image"
              record={{ image: img }}
              title="Current File"
            />
          </>
        ) : null}
      </div>
    </div>
  );
};

export const BenefitsCreate = ({ ...props }) => {
  const transform = (values) => {
    let copy = { ...values };
    copy.club = apiCODPlanA;
    return copy;
  };
  return (
    <Create title=" " transform={transform} redirect="list" {...props}>
      <SimpleForm>
        <Form />
      </SimpleForm>
    </Create>
  );
};
