import React from 'react';
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import { Loading, useDataProvider, useNotify } from 'react-admin';
import withWidth from '@material-ui/core/withWidth';
import { ImageList } from '@material-ui/core';
import { ImageListItem } from '@material-ui/core';
import { ImageListItemBar } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import audioImg from '../../assets/img/audio.png';
import { useTranslate} from "ra-core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '100%',
  },
  icon: {
    color: 'rgba(255, 255, 255)',
  },
  pagination: {
    width: '100%',
  },
  image: {
    cursor: 'pointer'
  },
  selected: {
    border: '4px solid #1a72e8',
  }
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(0),
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      maxWidth: 350,
      width: '100%',
    },
  },
  svgColor: {
    color: 'rgba(0, 0, 0, 0.26)',
  },
  title: {
    flex: '1 1 100%',
  },
}));

const getColsForWidth = (width) => {
  if (width === 'xs') return 2;
  if (width === 'sm') return 3;
  if (width === 'md') return 3;
  if (width === 'lg') return 5;
  return 6;
};

const GridToolbar = (props) => {
  const classes = useToolbarStyles();
  const { handlerOnChangeSearch, search } = props;
  const translate = useTranslate();
  return (
    <Toolbar className={classes.root} >
      <TextField id="search" label={translate("ra.action.search")}
        value={search}
        variant="filled"
        onChange={event => {
          const { value } = event.target;
          handlerOnChangeSearch(value);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon className={classes.svgColor} />
            </InputAdornment>
          )
        }}
      />
    </Toolbar>
  );
};

const GridListMedia = ({ modeMultiSelect = false, media, handleListItemDoubleClick, handleListItemClick,max, width, ...props }) => {
  const classes = useStyles();
  const [selectedImage, setSelectedImage] = useState([]);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const dataProvider = useDataProvider();
  const [listImage, setListImage] = useState([]);
  const [totalImage, setTotalImage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [info, setInfo] = React.useState([]);
  const notify = useNotify();
  const translate = useTranslate();


  useEffect(() => {
    getListImage();
  }, [page, rowsPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const timeOutId = setTimeout(() => getListImage(), 1000);
    return () => clearTimeout(timeOutId);
  }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

  const getListImage = () => {
    const filter = search?.length > 2 ? { search: search, media_type: media } : { media_type: media };

    dataProvider.getList('media', { pagination: { page: page + 1, perPage: rowsPerPage }, sort: { field: "created_at", order: "DESC" }, filter: filter })
      .then((data) => {
        setListImage(data.data);
        setTotalImage(data.total);
      })
      .catch(error => {
        setListImage([]);
      })
      .finally(() => setLoading(false));
  }

  const isSelected = (images, image) => {
    const findImages = images.filter(item => item.id === image.id);
    return findImages.length > 0 ? images.indexOf(findImages[0]) : null;
  };

  const handleClick = (image) => {
    let newImages = [...selectedImage];
    const clickedImage = isSelected(newImages, image);

    if (clickedImage !== null) {
      newImages.splice(clickedImage, 1);
    } else {
      if (modeMultiSelect) {
        if (!max || max > newImages.length){
          newImages.push(image);
        }else{
          notify('ra.message.max_select',{type:'info',messageArgs:{max:max}});
        }
      } else {
        newImages = [image]
      }
    }
    handleListItemClick(newImages);
    setSelectedImage(newImages);
  };

  const handleDoubleClick = (media) => {
    if (!modeMultiSelect) {
      handleListItemDoubleClick([media]);
    }
  };

  const handlerOnChangeSearch = (value) => {
    setSearch(value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const onImgLoad = ({ target: img }, id) => {
    let value = {
      id: id,
      dimension: `${translate('components.size')}: ${img.naturalWidth} x ${img.naturalHeight} ${translate('components.height')}`,
    };
    setInfo(prev => [...prev, value]);
  }

  const getValue = (id, data) => {
    return info.find(elem => elem.id === id)?.dimension || data.alt_text || "";
  }

  return (
    loading ? <Loading /> :
      <div className={classes.root}>
        <GridToolbar search={search} handlerOnChangeSearch={handlerOnChangeSearch} />
        <ImageList rowHeight={180} cols={getColsForWidth(width)} className={classes.gridList}>
          {listImage.map((media, index) => (
            <ImageListItem
              key={index}
              className={`${classes.image} ${selectedImage.find((item) => item.id === media.id) ? classes.selected : ''}`}
              onClick={(e) => {
                handleClick(media)
              }}
              onDoubleClick={(e) => {
                handleDoubleClick(media)
              }}>

              {media.media_type === 'image' ?
                <img src={media.preview_url} alt={media.alt_text || ""} onLoad={(e) => { onImgLoad(e, media.id) }} />
                :
                <img src={audioImg} className={classes.audioImg} alt="audio" />
              }

              <ImageListItemBar
                title={media.title}
                subtitle={getValue(media.id, media)}
                actionIcon={
                  selectedImage.find((item) => item.id === media.id) ? (
                    <IconButton aria-label={translate("components.selected")} className={classes.icon}>
                      <CheckCircleIcon />
                    </IconButton>)
                    : <></>
                }
              />
            </ImageListItem>
          ))}
        </ImageList>

        <TablePagination
          className={classes.pagination}
          labelRowsPerPage={media === "image" ? translate("components.image_per_page") : translate("components.audio_per_page")}
          rowsPerPageOptions={[20, 40, 60]}
          component="div"
          count={totalImage}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
  );
}
export default withWidth()(GridListMedia);