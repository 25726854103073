import * as React from 'react';
import { Fragment } from 'react';
import MuiToolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import AcceptButton from './AcceptButton';
import RejectButton from './RejectButton';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        justifyContent: 'space-between',
    },
}));

const CommentsEditToolbar = ({
    basePath,
    handleSubmitWithRedirect,
    invalid,
    record,
    resource,
    saving,
}) => {
    const classes = useStyles();

    if (!record) return null;
    return (
        <MuiToolbar className={classes.root}>
            <Fragment>
                <AcceptButton record={record} />
                <RejectButton record={record} />
            </Fragment>
        </MuiToolbar>
    );
};

export default CommentsEditToolbar;
