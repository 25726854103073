import React, { useEffect, useState } from "react";
import { useTranslate } from "react-admin";
import { FormControl, TextField, Box } from "@material-ui/core";

const TitleSection = ({ value, zoneIndex, handleChangeField, helperTextTitle, fullWidth = false }) => {
  const translate = useTranslate();
  const [state, setState] = useState(value);

  useEffect(() => {
    const timeOutId = setTimeout(
      () => state !== value && handleChangeField(state, "title", zoneIndex),
      1000
    );
    return () => clearTimeout(timeOutId);
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <FormControl fullWidth>
        <TextField
          id={"TextField-title-" + zoneIndex}
          label={translate("resources.pages.fields.title_section")}
          margin="dense"
          variant="filled"
          value={state}
          multiline
          minRows={fullWidth ? 3 : 1}
          style={{ width: fullWidth ? '100%':"400px", marginBottom: 0 }}
          helperText={helperTextTitle}
          onChange={(e) => setState(e.target.value)}
        />
      </FormControl>
    </Box>
  );
};

export default React.memo(
  TitleSection,
  (prev, next) => prev.value === next.value && prev.zoneIndex === next.zoneIndex
);
