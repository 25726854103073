import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/styles";
const useStyles = makeStyles({
    red:{
        color:"darkred",
    },
    orange:{
        color:"orange",
    },
    green:{
        color: "green"
    },
    line:{
        borderRadius: "5px",
    }

})

export const CircularProgressPercentage = ({ percentage, variant = "" }) => {
    const classes = useStyles();
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" size={90} thickness={3} value={percentage} className={`${classes.line} ${variant ? classes[variant] :''}`}/>
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          style={{ fontSize: "18px" }}
          color="textSecondary"
        >{`${String(percentage).padStart(2, "0")}/100`}</Typography>
      </Box>
    </Box>
  );
};
