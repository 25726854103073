import React from "react";
import { FieldTitle } from "react-admin";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { useTranslate} from "ra-core";

export default function SelectionDialog({
  selection,
  columns,
  onClose,
  onColumnClicked,
}) {
  const t = useTranslate();
  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="ra-columns-dialog-title"
      onClose={onClose}
      fullWidth
      open
    >
      <DialogTitle id="ra-columns-dialog-title">{t("resources.settings.singular_name")}</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map(({ source, label }) => (
            <FormControlLabel
              key={source}
              control={
                <Checkbox
                  color="primary"
                  checked={!!selection[source]}
                  onChange={(e) => onColumnClicked(e.target.value)}
                  value={source}
                />
              }
              label={<FieldTitle label={label} source={source} />}
            />
          ))}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t("ra.action.accept")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}