import * as React from 'react';
import MuiGridList from '@material-ui/core/ImageList';
import GridListTile from '@material-ui/core/ImageListItem';
import GridListTileBar from '@material-ui/core/ImageListItemBar';
import { makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import {
    linkToRecord,
    useListContext,
} from 'react-admin';
import { Link } from 'react-router-dom';
import audioImg from '../../assets/img/audio.png';
import { useTranslate} from "ra-core";

const useStyles = makeStyles(theme => ({
    gridList: {
        margin: 0,
    },
    tileBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%)',
    },
    placeholder: {
        height: 'auto',
    },
    audioImg: {
        objectFit: 'none'
    }
}));

const getColsForWidth = (width) => {
    if (width === 'xs') return 2;
    if (width === 'sm') return 3;
    if (width === 'md') return 3;
    if (width === 'lg') return 5;
    return 6;
};

const LoadedGridList = ({ width }) => {
    const { ids, data, basePath } = useListContext();
    const classes = useStyles();
    const [info, setInfo] = React.useState([]);
    const translate = useTranslate();
    if (!ids || !data) return null;

    const onImgLoad = ({ target: img }, id) => {
        let value = {
            id: id,
            dimension: `${translate("components.size")}: ${img.naturalWidth} x ${img.naturalHeight} ${translate("components.height")}`,
        };
        setInfo(prev => [...prev, value]);
    }

    const getValue = (id, data) => {
        return info.find(elem => elem.id === id)?.dimension || data[id].alt_text;
    }

    return (
        <MuiGridList
            rowHeight={180}
            cols={getColsForWidth(width)}
            className={classes.gridList}
        >
            {ids.map((id) => {

                return (data[id] &&
                    <GridListTile
                        component={Link}
                        key={id}
                        to={linkToRecord(basePath, data[id].id, 'show')}
                    >
                        {data[id].media_type === 'image' ?
                            <img src={data[id].preview_url} className={classes.placeholder} onLoad={(e) => { onImgLoad(e, id) }} alt="preview_url" />
                            :
                            <img src={audioImg} className={classes.audioImg} alt="audio" />
                        }
                        <GridListTileBar
                            className={classes.tileBar}
                            title={data[id].title}
                            subtitle={getValue(id, data)}
                        />
                    </GridListTile>
                )
            })}
        </MuiGridList>
    );
};

const GridList = ({ width }) => (
    <LoadedGridList width={width} />
);

export default withWidth()(GridList);
