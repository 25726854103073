import * as React from 'react';
import { Typography, Table, TableBody, TableCell, TableRow, TableHead, Chip } from "@material-ui/core";
import { STATE_TRANSLATIONS } from "../../utils/cardStatus";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useTranslate, useGetList, Loading } from 'react-admin';

const useStyles = makeStyles({
  root: { width: 600, margin: 'auto' },
  spacer: { height: 20 },
  cards: { margin: '10px 0' },
});

const useStylesClubCards = makeStyles({
  rightAlignedCell: { textAlign: 'right' },
  centerAlignedCell: { textAlign: 'center' },
});

const ClubCards = (props) => {
  const { record } = props;
  const classes = useStylesClubCards();
  const translate = useTranslate();

  const { data: cards, ids, loading, error } = useGetList(
    'club_cards',
    { page: 1, perPage: 10 },
    { field: 'created_at', order: 'DESC' },
    { user_id: record.id },
  );

  if (loading) { return <Loading />; }
  if (error) { return <p>ERROR</p>; }

  return (
    <Table>
      <TableHead display="flex">
        <TableRow component="th">
          <TableCell>
            {translate(
              'resources.club_cards.fields.username'
            )}
          </TableCell>
          <TableCell className={classes.rightAlignedCell}>
            {translate(
              'resources.club_cards.fields.dni'
            )}
          </TableCell>
          <TableCell className={classes.centerAlignedCell}>
            {translate(
              'resources.club_cards.fields.state'
            )}
          </TableCell>
          <TableCell className={classes.rightAlignedCell}>
            {translate(
              'resources.club_cards.fields.printed'
            )}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {ids.map((item) => (
          <TableRow key={cards[item].id}>
            <TableCell>
              <Link to={`/club_cards/${cards[item].id}/show`}>
                {cards[item].username}
              </Link>
            </TableCell>
            <TableCell className={classes.rightAlignedCell}>
              {cards[item].dni}
            </TableCell>
            <TableCell className={classes.centerAlignedCell}>
              {STATE_TRANSLATIONS[cards[item].state] ?
                <Chip
                  size="small"
                  style={STATE_TRANSLATIONS[cards[item].state].chip}
                  label={STATE_TRANSLATIONS[cards[item].state].name}
                />
                :
                <Chip
                  size="small"
                  label={cards[item].state}
                />
              }
            </TableCell>
            <TableCell className={classes.rightAlignedCell}>
              {cards[item].printed ? 'Si' : 'No'}
            </TableCell>
          </TableRow>
        )
        )}
      </TableBody>
    </Table>
  );
};


const AddressField = ({ record }) =>{
  const translate  = useTranslate();
  if (!record) return null;
  return(
    <Typography>
      {translate("components.address")}
      <br />
      {record.address} {record.addr_floor} {record.addr_door}
      <br />
      {record.city && `${record.city},`} {record.province}
    </Typography>
  );
}

export const ClubCardPanel = ({ record, ...props }) => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              {translate("components.cards")} {record.username}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} container alignContent="flex-end">
            <AddressField record={record} />
          </Grid>
        </Grid>
        <div className={classes.spacer}>&nbsp;</div>
        {record.clubcards_count > 0 ?
          <div className={classes.cards}>
            <ClubCards record={record} />
          </div>
          :
          <div className={classes.cards}>
            <Typography variant="h6" gutterBottom>
            {translate("components.no_card")}
            </Typography>
          </div>
        }
      </CardContent>
    </Card>
  );
};
