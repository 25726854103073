import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import { MultiTableNotes } from "../fields/MultiTableNotes";
import NoteList from "../SuggestedPick/NoteList";
import { translate, useTranslate} from "ra-core";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  gridContainer: {
    height: "100%",
  },
  list: {
    width: "100%",
  },
  itemContainer: {
    padding: "20px",
    overflowY: "auto",
    height: "100%",
    transition: "all 0.6s ease",
  },
  preview: {
    border: " 1px solid grey",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "60vh",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenDialog = ({
  onClose,
  onSave,
  open,
  excludeId,
  selectNotes,
  ...props
}) => {
  // ========================================================================
  // Parametros
  // ========================================================================
  const maxNotes = 3;
  const classes = useStyles();
  const [selectedNotes, setSelectedNotes] = useState(selectNotes);
  const translate = useTranslate();
  const [title, setTitle] = useState(translate("components.interested"));
  const handleClose = () => {
    setSelectedNotes([]);
    onClose();
  };

  const handleSave = () => {
    setSelectedNotes([]);
    onSave(selectedNotes, title);
  };

  const handleListItemClick = (value) => {
    setSelectedNotes(value);
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {translate("components.select_news")}
          </Typography>
        </Toolbar>
      </AppBar>
      <Grid container className={classes.gridContainer}>
        <Grid
          item
          xs={12}
          md={selectedNotes && selectedNotes.length > 0 ? 6 : 12}
          className={classes.itemContainer}
        >
          <MultiTableNotes
            handleListItemClick={handleListItemClick}
            open={open}
            excludeId={excludeId}
            currentNotes={selectedNotes}
            maxNotes={maxNotes}
          />
        </Grid>
        {selectedNotes && selectedNotes.length > 0 && (
          <Grid item xs={12} md={6} className={classes.itemContainer}>
            <NoteList setTitle={setTitle} title={title} data={selectedNotes} clearAction={handleListItemClick} maxNotes={maxNotes} />
          </Grid>
        )}
      </Grid>
      <Divider />
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
        {translate("ra.action.cancel")}

        </Button>
        <Button variant="contained" color="primary" onClick={handleSave}>
          {translate("ra.action.accept")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default function MultiNoticiaPicker(props) {
  const { currentNote, selectedNotes } = props;
  const [open, setOpen] = useState(false);
  const [currentEditor, setCurrentEditor] = useState("");
  const translate = useTranslate();

  const handleSave = (value = null, title = `${translate("components.interested")}`) => {
    const customEvent = new CustomEvent("selected-notes-suggested", {
      detail: {
        editorId: currentEditor,
        notes: value,
        title: title,
      },
      bubbles: false,
      cancelable: true,
      composed: false,
    });
    // Disparar event.
    window.dispatchEvent(customEvent);
    handleClose();
  };

  useEffect(() => {
    const handleWindowClick = (event) => {
      const editorId = event.detail.editor;
      setCurrentEditor(editorId);
      setOpen(true);
    };
    window.addEventListener("open-multinoticia-picker", handleWindowClick);
    return () => {
      window.removeEventListener("open-multinoticia-picker", handleWindowClick);
    };
  }, [currentEditor]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <FullScreenDialog
      {...props}
      open={open}
      onSave={handleSave}
      onClose={handleClose}
      selectNotes={selectedNotes}
      excludeId={currentNote}
    />
  );
}
