import * as React from "react";
import {
  BulkDeleteWithConfirmButton,
  Datagrid,
  EditButton,
  Filter,
  List,
  TextField,
  TextInput,
  useTranslate,
} from "react-admin";
import { ActionsCreate } from "../../components/Toolbar/ActionsCreate";

const TagsFilter = (props) => (
  <Filter {...props}>
    <TextInput source="search" label="ra.action.search" alwaysOn />
  </Filter>
);

export const TagsList = (props) => {
  const translate = useTranslate();
  return (
    <List
      filters={<TagsFilter />}
      actions={<ActionsCreate label={`${translate("resources.tags.new")}`} />}
      bulkActionButtons={
        <BulkDeleteWithConfirmButton
          confirmTitle={`${translate("resources.tags.confirm_title_bulk")}`}
          confirmContent={""}
        />
      }
      {...props}
      sort={{ field: "name", order: "ASC" }}
      title=" "
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="description" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
