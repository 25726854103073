import React from "react";
import { useTranslate } from "react-admin";
import { Switch, FormControlLabel } from "@material-ui/core";

const DisableItem = ({ value, zoneIndex, handleChangeField }) => {
  const translate = useTranslate();
  return (
    <FormControlLabel
      control={
        <Switch
          id={"Switch-component-" + zoneIndex}
          checked={value === undefined ? true : value}
          onChange={(e) =>
            handleChangeField(e.target.checked, "show_section", zoneIndex)
          }
          color="primary"
        />
      }
      label={translate("resources.pages.fields.show_section")}
      labelPlacement="start"
    />
  );
};

export default React.memo(
  DisableItem,
  (prev, next) => prev.value === next.value && prev.zoneIndex === next.zoneIndex
);
