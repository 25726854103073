import * as React from "react";
import {
  Datagrid,
  EditButton,
  Filter,
  List,
  TextField,
  TextInput,
  useTranslate
} from "react-admin";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { ActionsCreate } from "../../components/Toolbar/ActionsCreate";

const CategoriesFilter = (props) => (
  <Filter {...props}>
    <TextInput source="search" label="ra.action.search" alwaysOn />
  </Filter>
);

const ButtonFilter = ({ record, source }) => {
  if (!record && !record.notes_count) return null;
  return (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: `/notes`,
        search: `filter=${JSON.stringify({ category_id: record.id })}`,
      }}
      onClick={(e) => e.stopPropagation()}
    >
      {record[`${source}`]}
    </Button>
  );
};

export const CategoriesList = ({ permissions, ...props }) => {
  const translate = useTranslate();
  return (
    <List
      filters={<CategoriesFilter />}
      sort={{ field: "name", order: "ASC" }}
      bulkActionButtons={false}
      title=" "
      actions={<ActionsCreate label={`${translate("resources.categories.new")}`}/>}
      {...props}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="description" />
        <ButtonFilter source="notes_count" />
        {permissions &&
          permissions.some(
            (permission) =>
              permission.name === "*" || permission.name === "edit-categories"
          ) && <EditButton />}
      </Datagrid>
    </List>
  );
};
