import { useCallback, useState } from "react";
import {
  Button,
  Dialog,
  Box,
  DialogTitle,
  DialogContent,
  makeStyles,
} from "@material-ui/core";
import {
  useTranslate,
  useRefresh,
  SimpleForm,
  useUpdateMany,
  useUnselectAll,
  showNotification,
  SaveButton,
  Toolbar,
  SelectInput,
  DateTimeInput,
  required,
  ReferenceInput,
} from "react-admin";
import IconCancel from "@material-ui/icons/Cancel";
import Edit from "@material-ui/icons/Edit";
import { useDispatch } from "react-redux";

const useStyles = makeStyles({
  button: {
    margin: "10px 24px",
    position: "relative",
  },
  iconPaddingStyle: {
    paddingRight: "0.5em",
  },
});

const StatusEditToolbar = ({ onCancel, ...props }) => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <Toolbar {...props} style={{ justifyContent: "space-between" }}>
      <Button className={classes.button} onClick={onCancel}>
        <IconCancel className={classes.iconPaddingStyle} />
        {translate("ra.action.cancel")}
      </Button>
      <SaveButton />
    </Toolbar>
  );
};

export default function BulkSelectButton({
  selectedIds,
  source,
  resource,
  options = {},
  type = null,
  reference = null,
  sort = "name",
  name = "name",
  ...props
}) {
  const translate = useTranslate();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll();
  const dispatch = useDispatch();
  const [showDialog, setShowDialog] = useState(false);
  const [inputValue, setInputValue] = useState(null);
  const [updateMany, { loading }] = useUpdateMany();

  const handleOpen = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      setShowDialog(true);
    },
    [setShowDialog]
  );

  const handleClose = useCallback(() => {
    setShowDialog(false);
  }, [setShowDialog]);

  const handleSave = (values) => {
    updateMany("notes", selectedIds, values, {
      onSuccess: () => {
        refresh();
        setShowDialog(false);
        dispatch(
          showNotification(
            "resources." + resource + ".notification.success",
            "success"
          ),
          unselectAll("notes")
        );
      },
      onFailure: (error) => {
        dispatch(
          showNotification(
            "resources." + resource + ".notification.error",
            "error"
          )
        );
      },
    });
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  // Seleccion de tipo de campo según variable $type
  const BulkInput = () => {
    switch (type) {
      case "reference":
        return (
          <ReferenceInput
            label={translate(
              "resources." + resource + ".bulk_actions." + source + "_label"
            )}
            source={source}
            reference={reference}
            sort={{ field: sort, order: "ASC" }}
            perPage={1000}
            fullWidth
          >
            <SelectInput optionText={name} validate={required()} />
          </ReferenceInput>
        );

      default:
        return (
          <SelectInput
            source={source}
            resource={resource}
            choices={options}
            validate={required()}
            onChange={handleChange}
            fullWidth
          />
        );
    }
  };

  return (
    <Box display='flex'>
      <Button
        style={{ marginRight: ".5em" }}
        size='small'
        color='primary'
        onClick={handleOpen}
        startIcon={<Edit />}
      >
        {translate(
          "resources." + resource + ".bulk_actions." + source + "_label"
        )}
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
        aria-label='crear'
      >
        <DialogTitle>
          {translate(
            "resources." +
              resource +
              ".bulk_actions." +
              source +
              "_confirm_title"
          )}
        </DialogTitle>
        <DialogContent style={{ padding: 0 }}>
          <SimpleForm
            save={handleSave}
            saving={loading}
            redirect={false}
            toolbar={<StatusEditToolbar onCancel={handleClose} />}
            {...props}
          >
            <BulkInput />
            {source === "status" && inputValue === "scheduled" ? (
              <DateTimeInput
                source='date_publish'
                label={translate("resources.notes.fields.date_publish")}
                initialvalue={new Date().toJSON()}
                validate={required()}
                fullWidth
              />
            ) : null}
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
