import { Box, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import NoticiaField from "../../../../components/fields/NoticiaField";
import SelectCategory from "../../SelectCategory";
export const Opinion = ({
  component,
  index,
  settings,
  handleChange,
  listCategories,
}) => {
  const [state, setState] = useState(settings || {});

  useEffect(() => {
    if (Object.values(state)?.length > 0) {
      const copyComponent = { ...component };
      copyComponent.settings = { ...copyComponent.settings, ...state };
      setTimeout(handleChange(copyComponent, index), 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const handleChangeTitle = (value) => {
    setState({ ...state, title: value });
  };

  const handleCallback = (zoneIndex, values) => {
    setState({ ...state, notes: values });
  };

  const handleChangeField = (value, field, zoneIndex) => {
    setState({ ...state, category: value });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      maxWidth="450px"
      padding="1em"
      border="1px solid gray"
      boxSizing="border-box"
      borderRadius="5px"
      style={{ gap: "8px" }}
    >
      <Typography variant="h6" style={{ marginBottom: "4px" }}>
        {component?.name}
      </Typography>
      <TextField
        value={state.title}
        label="Titulo"
        onChange={(e) => handleChangeTitle(e.target.value)}
      />
      <SelectCategory
        value={state.category || [""]}
        zoneIndex={1}
        handleChangeField={handleChangeField}
        listCategories={listCategories}
      />
      <NoticiaField
        handleCallback={handleCallback}
        zoneIndex={index}
        notes={state.notes || []}
      />
    </Box>
  );
};
export default Opinion;
