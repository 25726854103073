import { useState } from "react";
import { Button, Dialog, DialogContent, Box } from "@material-ui/core";
import { AutocompleteInput, ReferenceInput, required } from "react-admin";
import { RelationTypeCreate } from "../relation_type";
import { useField } from "react-final-form";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  container: {
    "& .MuiTextField-root.MuiFormControl-marginDense": {
      marginTop: "0!important",
      marginBottom: "0!important",
    },
  },
});

export const RelationType = ({
  source,
  record,
  onChange,
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);
  const { input: input_relation } = useField(source);
  const classes = useStyles();
  const onSave = (data) => {
    setOpen(false);
    onChange(data?.data?.id);
    input_relation.onChange(data?.data?.id);
  };

  return (
    <Box
      style={{ display: "flex", gap: "10px", alignItems: "flex-start" }}
      className={classes.container}
    >
      <ReferenceInput
        source={source}
        record={record}
        onChange={onChange}
        disabled={disabled}
        reference="relation_types"
        label="Tipo de relación"
        filterToQuery={(searchText) => ({
          search: searchText,
        })}
        validate={[required()]}
      >
        <AutocompleteInput
          source="name"
          optionValue="id"
          optionText="name"
          size="small"
          margin="dense"
        />
      </ReferenceInput>

      {!disabled ? (
        <Box>
          <Button
            onClick={() => {
              setOpen(true);
            }}
            size="small"
            variant="outlined"
            disabled={disabled}
          >
            Nueva Relación
          </Button>
        </Box>
      ) : null}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent style={{ padding: 0 }}>
          <RelationTypeCreate
            onSave={onSave}
            handleCancel={() => setOpen(false)}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};
