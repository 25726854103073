import { stringify } from "query-string";
import {
  httpClientPlanA,
  apiUrlPlanA,
  apiCODPlanA,
  sendAsFormData,
} from "../utils/utilsFetchPlanA";
import { httpClient } from "../utils/utilsFetch";

const transformData = (data) => {
  const replacements = {
    ad_category: "category",
    ad_city: "city",
    ad_desc: "desc",
    ad_id: "id",
    ad_images: "images",
    ad_text: "text",
    ad_title: "title",
  };

  const replacedItems = Object.keys(data).map((key) => {
    const newKey = replacements[key] || key;
    return { [newKey]: data[key] };
  });

  return replacedItems?.reduce((a, b) => Object.assign({}, a, b));
};

const transformDataShow = (data) => {
  const replacements = {
    content: "description",
  };

  const replacedItems = Object.keys(data).map((key) => {
    const newKey = replacements[key] || key;
    if (key == "id") {
      const id = data[key] ? Number(data[key]) : null;
      return { id: id };
    }
    if (key == "image") {
      return {
        image: `https://plana.com.ar/images/com_adsmanager/contents/${data[key]}`,
      };
    }
    return { [newKey]: data[key] };
  });

  return replacedItems?.reduce((a, b) => Object.assign({}, a, b));
};

const transformDataShop = (data) => {
  const replacements = {
    comercio: "name",
    direccion: "address",
    nombre_contacto: "owner_name",
    email_contacto: "email",
    telefono_contacto: "phone",
    beneficio: "benefit_desc",
    descripcion: "short_desc",
    fecha_creacion: "created_at",
    dni_contacto: "dni",
  };
  // id
  // password_app
  // username_app

  const replacedItems = Object.keys(data).map((key) => {
    const newKey = replacements[key] || key;
    if (key == "id") {
      return { id: Number(data[key]) };
    }
    return { [newKey]: data[key] };
  });

  return replacedItems?.reduce((a, b) => Object.assign({}, a, b));
};

const Enpoints = [
  {
    resource: "benefits",
    urls: {
      create: { url: "createAdd", method: "POST" },
      edit: { url: "updateAdd", method: "POST" },
      delete: { url: "deleteAdd", method: "POST" },
      list: { url: "getAdsByClub", method: "GET" , notauth : true},
      show: { url: "getAdsDetailById", method: "GET" },
      search: { url: "searchAds", method: "GET" },
    },
  },
  {
    resource: "shop",
    urls: {
      create: { url: "createShopWithBody", method: "POST" },
      edit: { url: "updateShop", method: "POST" },
      delete: { url: "deleteShop", method: "POST" },
      list: { url: "getShopsList", method: "GET" },
      show: { url: "getShop", method: "GET" },
    },
  },
];
const getResource = (resource, field = "list") => {
  return Enpoints.find((i) => i?.resource == resource)?.urls[`${field}`];
};

const dataProvider = {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    /* const { field, order } = params.sort; */

    const query = {
      club: apiCODPlanA,
      offset: (page - 1) * perPage,
      ads_number: perPage,
    };
    const resourceUrl = getResource(resource, "list");
    const url = `${apiUrlPlanA}/${
      resourceUrl?.url || "getAdsByClub"
    }?${stringify(query)}`;
    const option = {
      method: resourceUrl?.method,
      notauth: resourceUrl?.notauth || false
    };
    const queryGetTotal = {
      club: apiCODPlanA,
      offset: 0,
      ads_number: perPage,
    };
    if (option?.method == "POST") {
      const data = params.data;
      option.body = JSON.stringify({ club: apiCODPlanA });
      return httpClientPlanA(url + `?${stringify({ ...data })}`, option).then(
        ({ json }) => {
          if (Array.isArray(json)) {
            return {
              data: json,
              total: Number(json?.lenght || 0),
            };
          }
          return {
            data: data?.data || data,
            total: Number(json?.total || 0),
          };
        }
      );
    } else
      return httpClientPlanA(url, option).then(({ json }) => {
        let data = [];
        if (json?.ads) {
          data = json.ads?.reduce(
            (a, b) => ({ data: [...a.data, transformData(b)] }),
            { data: [] }
          );
        } else {
          if (json.success != 0) {
            data = json?.reduce(
              (a, b) => ({ data: [...a.data, transformDataShop(b)] }),
              { data: [] }
            );
            return {
              data: data.data,
              total: Number(data.data.length || 0),
            };
          } else {
            return {
              data: [],
              total: 0,
            };
          }
        }
        if (json.total) {
          return {
            data: data?.data || data,
            total: Number(json.total || 0),
          };
        }
        // En caso de que no tenga el total, pero haya datos, vamos a buscar el total a offset=0

        return httpClientPlanA(`${url}?${stringify(queryGetTotal)}`).then(
          (json) => {
            return {
              data: data.data,
              total: Number(json.total || 0),
            };
          }
        );
      });
  },

  getOne: (resource, params) => {
    const resourceUrl = getResource(resource, "show");

    return httpClientPlanA(
      `${apiUrlPlanA}/${resourceUrl?.url}?id=${params.id}`,
      { method: resourceUrl?.method }
    ).then(({ json }) => {
      if (resource == "shop") {
        return { data: transformDataShop(json) };
      } else {
        if (resource == "benefits") {
          return { data: transformDataShow(json) };
        }
      }
      return {
        data: json,
      };
    });
  },
  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrlPlanA}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({ data: json.data }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sortBy: field,
      sortType: order,
      page: page,
      size: perPage,
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrlPlanA}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json.data,
      total: json.total,
    }));
  },
  update: (resource, params) => {
      return sendAsFormData(
        "POST",
        `${getResource(resource, "edit")?.url}`,
        params
      );
  },
  create: (resource, params) => {
    return sendAsFormData(
      "POST",
      getResource(resource, "create")?.url,
      params
    );
  },
  delete: (resource, params) =>
    httpClientPlanA(
      `${apiUrlPlanA}/${getResource(resource, "delete")?.url}?id=${
        params.id
      }&club=${apiCODPlanA}`,
      {
        method: "POST",
      }
    ).then(({ json }) => ({ data: json })),
};
export default dataProvider;
