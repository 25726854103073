import React, { useState, useEffect } from "react";
import {
  Create,
  FormWithRedirect,
  TextInput,
  ImageInput,
  ImageField,
  FileField,
  FileInput,
  required,
  SaveButton,
  DateInput,
  DeleteButton,
  ReferenceInput,
  SelectInput,
  useDataProvider,
  LoadingPage,
} from "react-admin";
import { Box, Toolbar, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CreateCategory } from "./CreateCategoryDialog";
import { EditCategory } from "./EditCategoryDialog";
import EditIcon from "@material-ui/icons/Edit";
import { useFormState } from "react-final-form";
import { useTranslate } from "ra-core";

const useStyles = makeStyles(
  (theme) => ({
    toolbar: {
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[900],
    },
    desktopToolbar: {
      marginTop: theme.spacing(2),
    },
    mobileToolbar: {
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      padding: "16px",
      width: "100%",
      boxSizing: "border-box",
      flexShrink: 0,
      zIndex: 2,
    },
    defaultToolbar: {
      flex: 1,
      display: "flex",
      justifyContent: "space-between",
    },
    spacer: {
      [theme.breakpoints.down("xs")]: {
        height: "5em",
      },
    },
  }),
  { name: "RaToolbar" }
);

const BtnEditCategory = ({ categories }) => {
  const { values } = useFormState();
  const [open, setOpen] = useState(false);
  const translate = useTranslate();
  if (!(values?.category_id) || !categories) return null;
  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<EditIcon />}
        onClick={() => setOpen(true)}
      >
        {translate("components.edit_category")}
      </Button>
      <EditCategory
        open={open}
        setOpen={setOpen}
        data={categories?.find((i) => i.id === values.category_id)}
      />
    </>
  );
};

export const NewspapersCreate = (props) => {
  const classes = useStyles(props);
  const dataProvider = useDataProvider();
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  const getCategories = () => {
    dataProvider
      .getList("newspapers_categories", {
        pagination: { page: 1, perPage: 10000 },
        sort: { field: "name", order: "ASC" },
        filter: {},
      })
      .then((data) => {
        setCategories(data.data);
        setLoading(false);
      })
      .catch((error) => {
        setCategories([]);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <LoadingPage></LoadingPage>;
  return (
    <Create title=" " {...props}>
      <FormWithRedirect
        {...props}
        render={(formProps) => (
          <form>
            <Box display="flex" m="1em">
              <Box flex={1} mr="1em">
                <DateInput
                  source="date_publish"
                  label="resources.newspapers.fields.date_publish"
                  validate={required()}
                />
                <TextInput
                  source="title"
                  label="resources.newspapers.fields.title"
                  fullWidth
                />
                <Box display="flex" alignItems="center" style={{ gap: "1em" }}>
                  <ReferenceInput
                    source="category_id"
                    reference="newspapers_categories"
                    label="resources.newspapers.fields.category"
                  >
                    <SelectInput
                      create={<CreateCategory />}
                      choices={categories}
                      styles={{ width: "300px" }}
                      validate={required()}
                    />
                  </ReferenceInput>
                  <Box mb="8px">
                    <BtnEditCategory categories={categories} />
                  </Box>
                </Box>
              </Box>
              <Box flex={1} ml="1em">
                <FileInput
                  source="file"
                  label="Archivo"
                  helperText={"Subir el archivo en formato pdf"}
                  validate={required()}
                >
                  <FileField source="file_prev" title="Upload Preview" />
                </FileInput>
                <Box mt="3em" />
                <ImageInput
                  source="thumbnail"
                  label="Imagen"
                  accept="image/*"
                  helperText={"Subir una imágen de la tapa del día"}
                  validate={required()}
                >
                  <ImageField source="thumbnail_prev" title="Upload Preview" />
                </ImageInput>
              </Box>
            </Box>
            <Toolbar className={classes.toolbar} role="toolbar">
              <Box display="flex" justifyContent="space-between" width="100%">
                <SaveButton
                  saving={formProps.saving}
                  disabled={formProps.pristine}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                />
                <DeleteButton record={formProps.record} />
              </Box>
            </Toolbar>
          </form>
        )}
      />
    </Create>
  );
};
