import * as React from "react";
import {
  DeleteWithConfirmButton,
  Toolbar,
  Edit,
  SimpleForm,
  SaveButton,
  useRecordContext,
  TextInput,
  required,
  maxLength
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import { useTranslate } from "ra-core";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  }
});

const EditToolbar = (props) => {
  const record = useRecordContext();
  const translate = useTranslate();
  return (
    <Toolbar classes={useStyles()} {...props}>
      <SaveButton />
      {record && record.notes_count === 0 ? (
        <DeleteWithConfirmButton
          confirmTitle={`${translate("resources.categories.confirm_title")}`}
          confirmContent={`${translate("resources.categories.confirm_content")}`}
          translateOptions={{ name: record.name }}
        />
      ) : (
        <Tooltip title={`${translate("resources.categories.disable_delete")}`}>
          <span>
            <Button
              disabled
              startIcon={<DeleteIcon />}
            >
              {translate("ra.action.delete")}
            </Button>
          </span>
        </Tooltip>
      )}
    </Toolbar>
  );
};

export const CategoriesEdit = (props) => (
  <Edit title=" " {...props}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput disabled source="id" />
      <TextInput source="name" validate={[required()]} />
      <TextInput
        validate={[maxLength(1000)]}
        source="description"
        multiline
        style={{ width: '600px' }}
      />
    </SimpleForm>
  </Edit>
);
