import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Paper from "@material-ui/core/Paper";
import Switch from "@material-ui/core/Switch";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { stringify } from "query-string";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Loading, ReferenceInput, SelectInput } from "react-admin";
import { useField } from "react-final-form";
import { apiUrl, httpClient } from "../../utils/utilsFetch";
import { useTranslate } from "ra-core";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}



function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const translate = useTranslate();
  const headCells = [
    {
      id: "date_publish",
      numeric: false,
      disablePadding: true,
      label: `${translate("resources.notes.fields.date_publish")}`,
    },
    { id: "title", numeric: false, disablePadding: false, label: `${translate("resources.notes.fields.title")}` },
  ];

  return (
    <TableHead>
      <TableRow>
        <TableCell />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "desc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      maxWidth: 350,
      width: "100%",
    },
  },
  title: {
    flex: "1 1 100%",
  },
  box: {
    display: "flex",
    flexDirection: "column",
  },
  select: {
    background: "none",
  },
  boxSearch: {
    justifyContent: "center",
  },
  boxSearchFull: {
    justifyContent: "flex-end",
  },
  formcontrol: {
    paddingLeft: "8px",
    boxSizing: "border-box",
    justifyContent: "flex-end",
    flex: "1 1 100%",
  },
  labelswitch: {
    fontSize: "0.8em",
    margin: "8px",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const translate = useTranslate();
  const {
    handlerOnChangeSearch,
    search,
    setUpdated_At,
    updated_at,
    category,
    setCategory,
    open,
    selectedValue,
  } = props;
  const { input: categoryInput } = useField("id");
  useEffect(() => {
    if (open) {
      //reset reference input
      categoryInput.onChange(null);
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Toolbar className={classes.root}>
      <Box width="100%" m={1} className={classes.box}>
        <Box display="flex">
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {translate("components.news")}
          </Typography>
          <FormControlLabel
            className={classes.formcontrol}
            control={
              <Switch
                checked={updated_at}
                onChange={() => setUpdated_At(!updated_at)}
                color={"primary"}
              />
            }
            label={
              <span className={classes.labelswitch}>
                {translate("components.update_last_days")}
              </span>
            }
          />
        </Box>
        <Box
          display="flex"
          className={selectedValue ? classes.boxSearch : classes.boxSearchFull}
        >
          <ReferenceInput
            variant="outlined"
            label={translate("components.category")}
            source="id"
            reference="categories"
            allowEmpty
            onChange={(e) => setCategory(e.target.value)}
            sort={{ field: "name", order: "ASC" }}
            perPage={200}
          >
            <SelectInput
              value={category}
              optionText="name"
              resettable
              className={classes.select}
            />
          </ReferenceInput>
          <Tooltip title={translate("components.search_news")}>
            <TextField
              id="search"
              label={translate("ra.action.search")}
              value={search}
              variant="outlined"
              size="small"
              InputProps={{
                endAdornment: <SearchIcon fontSize="small" />,
              }}
              onChange={(event) => {
                handlerOnChangeSearch(event.target.value);
              }}
            />
          </Tooltip>
        </Box>
      </Box>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export const MultiTableNotes = ({
  handleListItemClick,
  open,
  currentNotes,
  maxNotes,
  excludeId,
}) => {
  const classes = useStyles();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("date_publish");
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [listNotes, setListNotes] = useState([]);
  const [totalNotes, setTotalNotes] = useState(0);
  const [loading, setLoading] = useState(true);
  const [updated_at, setUpdated_At] = useState(true);
  const [category, setCategory] = useState(null);
  const isMountedRef = useRef(true);
  const translate = useTranslate();
  useEffect(() => {
    isMountedRef.current = true;
    return () => (isMountedRef.current = false);
  }, []);

  useEffect(() => {
    getListNotes();
  }, [page, rowsPerPage, updated_at, category]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const timeOutId = setTimeout(
      () => search?.length > 4 && getListNotes(),
      1000
    );
    return () => {
      clearTimeout(timeOutId);
    };
  }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

  const getListNotes = () => {
    const filter =
      search?.length > 4
        ? { status: "publish", search: search, updated_last_days: updated_at }
        : { status: "publish", updated_last_days: updated_at };
    if (category) {
      filter["category_id"] = category;
    }
    if (excludeId) {
      filter["exclude"] = excludeId;
    }

    const query = {
      sortBy: "updated_at",
      sortType: "DESC",
      page: page + 1,
      size: rowsPerPage,
      filter: JSON.stringify(filter),
    };
    const url = `${apiUrl}/notes?${stringify(query)}`;
    httpClient(url, {
      method: "GET",
    })
      .then(({ json }) => {
        if (isMountedRef.current) {
          setListNotes(json.data);
          setTotalNotes(json.total);
        }
      })
      .catch((error) => {
        if (isMountedRef.current) {
          setListNotes([]);
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          setLoading(false);
        }
      });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const isSelected = (notes, note) => {
    const findNote = notes.filter((item) => item.id === note.id);
    return findNote.length > 0 ? notes.indexOf(findNote[0]) : null;
  };

  const handleClick = (event, note) => {
    let newNotes = [...currentNotes];
    const clickedNote = isSelected(newNotes, note);

    if (clickedNote !== null) {
      newNotes.splice(clickedNote, 1);
    } else {
      if (currentNotes.length < maxNotes) {
        newNotes.push(note);
      }
    }

    handleListItemClick(newNotes);
  };

  const handlerOnChangeSearch = (value) => {
    setSearch(value);
    setPage(0);
  };

  const handlerOnChangeCategory = (value) => {
    setCategory(value);
    setPage(0);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, totalNotes - page * rowsPerPage);

  // ========================================================================
  // Component Body
  // ========================================================================
  return loading ? (
    <Loading />
  ) : (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          search={search}
          handlerOnChangeSearch={handlerOnChangeSearch}
          updated_at={updated_at}
          setUpdated_At={setUpdated_At}
          category={category}
          setCategory={handlerOnChangeCategory}
          open={open}
          selectedValue={currentNotes}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(listNotes, getComparator(order, orderBy)).map(
                (row) => {
                  const isItemSelected =
                    isSelected(currentNotes, row) !== null ? true : false;
                  const labelId = `enhanced-table-checkbox-${row.id}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell align="left">
                        {new Date(row.date_publish).toLocaleString(translate("components.locale_language"), {
                          timeZone: "UTC",
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                        })}
                      </TableCell>
                      <TableCell align="left">{row.title}</TableCell>
                    </TableRow>
                  );
                }
              )}
              {totalNotes === 0 ? (
                <Fragment>
                  <TableRow>
                    <TableCell colSpan={6} style={{ textAlign: "center" }}>
                      {translate("components.news_not_found")}
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ height: 40 * (rowsPerPage - 1) }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                </Fragment>
              ) : (
                emptyRows > 0 && (
                  <TableRow style={{ height: 40 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage={translate("components.news_per_page")}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalNotes}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, newPage) => setPage(newPage)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};
