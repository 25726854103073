import * as React from "react";
import {Create, SimpleForm, TextInput, required} from 'react-admin';

export const PhotographersCreate = props => (
    <Create title=" " {...props}>
        <SimpleForm>
            <TextInput source="name" validate={[required()]} />
            <TextInput source="description"/>
        </SimpleForm>
    </Create>
);