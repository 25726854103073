import * as React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { useGetList } from 'react-admin';
import { Typography, Card, CardContent, Box, Link, Stepper, Step, StepLabel, StepContent } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from "ra-core";

const useAsideStyles = makeStyles(theme => ({
    root: {
        width: 400,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));

const Aside = ({ record, basePath }) => {
    const classes = useAsideStyles();
    return (
        <div className={classes.root}>
            {record && <EventList record={record} basePath={basePath} />}
        </div>
    );
};

const useEventStyles = makeStyles({
    stepper: {
        background: 'none',
        border: 'none',
        marginLeft: '0.3em',
    },
});

const EventList = ({ record, basePath }) => {
    const classes = useEventStyles();
    const translate = useTranslate();

    const { data: notes, ids: noteIds, total } = useGetList(
        'notes',
        { page: 1, perPage: 10 },
        { field: 'id', order: 'DESC' },
        { author_id: record && record.id }
    );

    const events = getArrayNotes(notes, noteIds);

    return (
        <>
            <Box m="0 0 1em 1em">
                <Card>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            {translate("components.history")}
                        </Typography>
                        <Box display="flex">
                            <Box flexGrow={1}>
                                {total && total > 0 ? (
                                    <Box display="flex">
                                        <Box flexGrow={1}>
                                            <Typography>
                                                {total} {translate("components.entries")}
                                            </Typography>
                                        </Box>
                                    </Box>
                                ) : <Typography>{translate("components.without_entries")}</Typography>}
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
            <Stepper orientation="vertical" classes={{ root: classes.stepper }}>
                {events.map(event => (
                    <Step
                        key={`${event.type}-${event.data.id}`}
                        expanded
                        active
                        completed
                    >
                        <StepLabel
                            StepIconComponent={() => {
                                const Component = EditIcon;
                                return (
                                    <Component
                                        fontSize="small"
                                        color="disabled"
                                        style={{ paddingLeft: 3 }}
                                    />
                                );
                            }}
                        >
                            {new Date(event.data.created_at).toLocaleString(translate("components.locale_language"), {
                                weekday: 'long',
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                            })}
                        </StepLabel>
                        <StepContent>
                            <Note
                                record={event.data}
                                key={`event_${event.data.id}`}
                                basePath={basePath}
                            />
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
        </>
    );
};

const getArrayNotes = (notes, noteIds) => {
    const events =
        notes ? noteIds.map(id => ({
            data: notes[id],
        }))
            : [];

    return events;
};

const useNoteStyles = makeStyles({
    clamp: {
        display: '-webkit-box',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
    },
});

const Note = ({ record, basePath }) => {
    const classes = useNoteStyles();
    return record ? (
        <>
            <Typography variant="body2" gutterBottom>
                <Link to={`/notes/${record.id}`} component={RouterLink}>
                    {record.title}
                </Link>
            </Typography>
            <Typography variant="body2" color="textSecondary" className={classes.clamp}>
                {record.copete}
            </Typography>
        </>
    ) : null;
};

export default Aside;
