import {
  Datagrid,
  List,
  NumberField,
  EditButton,
  TextField,
  ReferenceField,
  Filter,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  Button,
  useRecordContext,
} from "react-admin";
import { cloneElement } from "react";
import { TopToolbar, CreateButton } from "react-admin";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
import { Assignment } from '@material-ui/icons/';
import { Button as MuiButton, Box } from "@material-ui/core";
import { useTranslate } from "ra-core";

const useStyles = makeStyles({
  buttonOrder: {
    margin: "0 1em",
    "& span": {
      paddingLeft: 0
    }
  },
  buttonDuplicate: {
    marginRight: '1em',
  }
})

const CustomFilters = (props) => (
  <Filter {...props}>
    <TextInput source="search" label="ra.action.search" alwaysOn />
    <ReferenceInput source="admedia_id" reference="amedia" link="show">
      <AutocompleteInput source="name" />
    </ReferenceInput>
  </Filter>
);
// eslint-disable-next-line
const OrderButton = () => {
  const classes = useStyles();
  const translate = useTranslate();
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(push("/combosorder/create"));
  };
  return (
    <Button
      label={`${translate("resources.combos.order")}`}
      onClick={() => handleClick()}
      variant="outlined"
      size="medium"
      className={classes.buttonOrder}
    />
  );
};

const DuplicateButton = () => {
  const record = useRecordContext();
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <MuiButton
      label=""
      onClick={(e) => e.stopPropagation()}
      component={Link}
      color="primary"
      startIcon={<Assignment color="primary" />}
      to={{
        pathname: 'combos/create',
        state: { record: { ...record, id: null, created_at: null, updated_at: null } },
      }}
      className={classes.buttonDuplicate}
    >
      {translate('resources.combos.duplicate')}
    </MuiButton>
  )
}

export const Actions = ({ label, ...props }) => (
  <TopToolbar>
    {props?.filters && cloneElement(props?.filters, { context: "button" })}
    <OrderButton />
    <CreateButton
      variant="contained"
      size="medium"
      label={label}
      translate={label}
    />
  </TopToolbar>
);

const RowActions = ({ permissions, ...props }) => {
  return (
    <Box display="flex" justifyContent="flex-end">
      <DuplicateButton />
      {permissions &&
        permissions?.some(
          (permission) =>
            permission.name === "*" || permission.name === "edit-adcombos"
        ) && <EditButton  {...props} />}
    </Box>
  )
}


export const ComboList = ({ permissions, ...props }) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      title=" "
      bulkActionButtons={false}
      filters={<CustomFilters />}
      actions={<Actions label={`${translate("resources.combos.new")}`} />}
    >
      <Datagrid>
        <TextField source="name" />
        <ReferenceField source="admedia_id" reference="amedia" link="show">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="slug" />
        <NumberField
          source="price"
          locales={translate("components.locale_language")}
          options={{ style: "currency", currency: translate("components.currency") }}
        />
        <NumberField
          source="price_discount"
          locales={translate("components.locale_language")}
          options={{ style: "currency", currency: translate("components.currency") }}
        />
        <RowActions permissions={permissions} />
      </Datagrid>
    </List>
  );
};
