import React from "react";
import Divider from "@material-ui/core/Divider";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { ListItem } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import { ImageSelect } from "../fields/ImageSelect";
import { Select, MenuItem, InputLabel, Tooltip } from "@material-ui/core";
import { useTranslate } from "ra-core";

const useStyles = makeStyles((theme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightBold,
    },
    textField: {
      width: "auto",
      minWidth: "250px",
      marginRight: "30px",
    },
    selectNotes: {
      flex: 1,
      marginBottom: "10px",
    },
    img: {
      width: "75px",
      marginRight: "15px",
    },
    numberOrder: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightBold,
      marginRight: "8px",
    },
    ul: {
      listStyleType: "none !important",
    },
    select: {
      minWidth: "200px",
    },
  })
);

const TYPES_ADS = [
  { id: "large-h100", name: "1300 x 100" },
  { id: "large-h150", name: "1300 x 150" },
  { id: "large-h300", name: "1300 x 300" },
  { id: "large-f-h300", name: "fullwidth x 300" },
];

// { id: "box-300", name: "Caja 300px * 250px" },
// { id: "box-240", name: "Caja 240px * 250px" },

const PublicidadForm = ({
  handleCallback,
  componentData,
  listCategories,
  ...props
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const handleSelected = (value) => {
    componentData.image = value;
    handleCallback(componentData);
  };

  const handleChange = (event) => {
    componentData.url = event.target.value;
    handleCallback(componentData);
  };

  const handleChangeType = (event) => {
    componentData.type = event.target.value;
    handleCallback(componentData);
  };
  const handleChangeSize = (event) => {
    componentData.size = event.target.value;
    handleCallback(componentData);
  };
  const handleChangePosition = (event) => {
    componentData.position = event.target.value;
    handleCallback(componentData);
  };
  const SIZE = [
    {
      id: "cover",
      name: `${translate("components.sizes.cover_name")}`,
      tip: `${translate("components.sizes.cover_tip")}`,
    },
    {
      id: "contain",
      name: `${translate("components.sizes.contain_name")}`,
      tip: `${translate("components.sizes.contain_tip")}`,
    },
    { id: "none", name: `${translate("components.none")}` },
  ];
  
  const POSITION = [
    { id: "top", name: `${translate("components.positions.top")}` },
    { id: "bottom", name: `${translate("components.positions.bottom")}` },
    { id: "center", name: `${translate("components.positions.center")}` },
  ];
  return (
    <Box display='flex' justifyContent='space-between'>
      <div className={classes.selectNotes}>
        <Typography className={classes.heading}>{translate("components.advertising")}</Typography>
        <ListItem className={classes.ul} ContainerComponent='div'>
          <Box display='flex'>
            <ImageSelect
              handleSelected={handleSelected}
              componentData={componentData}
            />
            <Box
              display='flex'
              flexDirection='column'
              style={{ marginLeft: "1em" }}
            >
              <Box>
                <InputLabel id='demo-simple-select-label'>{translate("components.type")}</InputLabel>
                <Select
                  value={componentData.type || "large-h100"}
                  initialvalue={componentData.type}
                  className={classes.select}
                  onChange={handleChangeType}
                >
                  {TYPES_ADS.map((item, index) => (
                    <MenuItem value={item.id} key={index}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box mt='0.5em'>
                <InputLabel id='demo-simple-select-label'>{translate("components.position")}</InputLabel>
                <Select
                  value={componentData.position}
                  initialvalue={componentData.position}
                  className={classes.select}
                  onChange={handleChangePosition}
                >
                  {POSITION.map((item, index) => (
                    <MenuItem value={item.id} key={index}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box mt='0.5em'>
                <InputLabel id='demo-simple-select-label'>
                  {translate("components.image_style")}
                </InputLabel>
                <Select
                  value={componentData.size}
                  initialvalue={componentData.size}
                  className={classes.select}
                  onChange={handleChangeSize}
                >
                  {SIZE.map((item, index) => (
                    <MenuItem value={item.id} key={index}>
                      {item.tip ? (
                        <Tooltip title={item.tip} key={`${index}_tip`}>
                          <div>{item.name}</div>
                        </Tooltip>
                      ) : (
                        <div>{item.name}</div>
                      )}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <TextField
                value={componentData.url}
                onChange={(e) => {
                  handleChange(e);
                }}
                label='URL'
                style={{ margin: "0.5em 0" }}
              />
            </Box>
          </Box>
        </ListItem>
        <Divider variant='middle' />
      </div>
    </Box>
  );
};

export default PublicidadForm;
