import * as React from "react";
import {
  useRecordContext,
  useNotify,
  useRefresh,
  translate,
} from "react-admin";
import {
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import RestoreIcon from "@material-ui/icons/Restore";
import { apiUrl, httpClient } from "../utils/utilsFetch";

export const RestoreButton = ({ resource, ...props }) => {
  const record = useRecordContext();
  const notification = useNotify();
  const refresh = useRefresh();
  const [show, setShow] = React.useState(false);

  if (record?.deleted_at == null) return null;
  const handleAccept = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const resp = await httpClient(
      apiUrl + "/" + resource + "/" + record.id + "/restore",
      { method: "POST" }
    );
    if (resp.status == 200 || resp.status == 204) {
      notification("ra.notification.element_restored", { type: "success" });
      setTimeout(refresh(), 500);
    } else {
      notification("ra.notification.element_restored_fail", { type: "error" });
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShow(true);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShow(false);
  };

  return (
    <>
      <Button
        startIcon={<RestoreIcon />}
        variant="outlined"
        color="primary"
        size="small"
        onClick={(e) => handleClick(e)}
        style={{ marginLeft: "10px" }}
      >
        {translate("ra.action.restore")}
      </Button>
      <Dialog open={show}>
        <DialogContent>
          <Typography>{translate("ra.message.restore")}</Typography>
        </DialogContent>
        <DialogActions
          style={{
            marginTop: "16px",
            padding: "16px 24px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            onClick={(e) => handleCancel(e)}
            variant="outlined"
            color="secondary"
            size="large"
          >
            {translate("ra.message.no")}
          </Button>
          <Button
            onClick={(e) => handleAccept(e)}
            variant="contained"
            color="primary"
            size="large"
          >
            {translate("ra.message.yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
