import * as React from "react";
import {Edit, SimpleForm, TextInput, required} from 'react-admin';

export const PhotographersEdit = props => (
    <Edit title=" " {...props}>
        <SimpleForm>
            <TextInput disabled source="id"/>
            <TextInput source="name" validate={[required()]} />
            <TextInput source="description"/>
        </SimpleForm>
    </Edit>
);