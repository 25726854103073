export const checkPermissions = (permissions, name) => {
  if (permissions !== undefined && Array.isArray(permissions)) {
    if (
      permissions &&
      permissions?.some(
        (permission) => permission.name === "*" || permission.name === name
      )
    ) {
      return true;
    }
  }
  return false;
};

export const bytesToSize = (bytes) => {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
};

export const validateFilesize = (maxSize, message) => {
  if (message === void 0) {
    message = "ra.validation.maxFileSize";
  }
  return (value, allValues) => {
    const fileID = Object.keys(allValues)[0],
      file = document.getElementById(fileID);

    let size = 0;
    if (file && file.files) {
      size = file.files[0].size;
    }

    if (size && size > maxSize) {
      return {
        message,
        args: { maxSize: bytesToSize(maxSize) },
      };
    }
    return undefined;
  };
};

export const validateUrl = (message) => {
  if (message === void 0) {
    message = "ra.validation.invalidUrl";
  }
  return (value, allValues) => {
    if (
      value &&
      !/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
        value
      )
    ) {
      return {
        message,
      };
    }
    return undefined;
  };
};

export const validateDatePublish = (message) => {
  if (message === void 0) {
    message = "resources.notes.validation.date_publish";
  }
  return (value, allValues) => {
    //value is a string of "YYYY-MM-DDT00:00" format
    if (
      allValues &&
      allValues.status &&
      allValues.status === "scheduled" &&
      value instanceof Date &&
      value.getMinutes() !== 30 &&
      value.getMinutes() !== 0
    ) {
      return { message };
    }
    return undefined;
  };
};
