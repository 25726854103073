import * as React from "react";
import { useState, useEffect } from "react";
import { TextInput, required, useQueryWithStore } from "react-admin";
import { useField } from "react-final-form";
import { useTranslate} from "ra-core";
const TitleHomeField = ({ source, label, record = {} }) => {
  const [valuesArea, setValuesArea] = useState(record[source]);
  const { input } = useField(source);
  const translate = useTranslate();
  const { data } = useQueryWithStore({
    type: "getOne",
    resource: "settings",
    payload: { id: 1 },
  });
  const CHARACTER_LIMIT = data ? data.val : 0;

  const handleChangeTextArea = (e) => {
    setValuesArea(e.target.value);
    input.onChange(e.target.value);
  };

  useEffect(() => {
    setValuesArea(record[source]);
  }, [record[source]]); // eslint-disable-line react-hooks/exhaustive-deps

  const maxLength =
    (max, message = `${translate("components.long_title")}`) =>
    (value) =>
      value && value.length > max
        ? `${translate("components.chars")}: ${
            valuesArea ? valuesArea.length : 0
          }/${CHARACTER_LIMIT} - ${message}`
        : undefined;

  return (
    <TextInput
      source={source}
      label={label}
      multiline
      initialvalue={valuesArea ? valuesArea : undefined}
      onChange={(e) => handleChangeTextArea(e)}
      helperText={`${translate("components.chars")}: ${
        valuesArea ? valuesArea.length : 0
      }/${CHARACTER_LIMIT}`}
      fullWidth
      validate={[required(), maxLength(CHARACTER_LIMIT)]}
    />
  );
};

export default TitleHomeField;
