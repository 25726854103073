import React, { useEffect, useState, forwardRef } from "react";
import {
  Button,
  TextField,
  Typography,
  Box,
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  List as ListMUI,
  ListItem,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslate } from "ra-core";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  attributes: {
    "& .MuiButton-outlinedSizeSmall": {
      marginTop: "17px",
      marginBottom: "15px",
    },
  },
}));

export const ObjectCreator = ({
  record,
  onSave,
  labelButton = "Establecer Objeto",
  title = "Opciones",
  label = "Opción",
}) => {
  const [state, setState] = useState({});
  const [newValue, setNewValue] = useState("");
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const translate = useTranslate();

  useEffect(() => {
    if (record) {
      setState(record || {});
    }
  }, [record, show]);

  const handleClick = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setState({});
    setNewValue("");
  };

  const handleAccept = () => {
    onSave(state);
    setShow(false);
  };

  const handleAddItem = () => {
    let copy = { ...state };
    copy[newValue] = "";
    setState(copy);
    setNewValue("");
  };

  const handleChange = (field, value) => {
    if (state.hasOwnProperty(field)) {
      let nuevo = {};
      const keys = Object.keys(state);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (key === field) {
          nuevo[value] = "";
        } else {
          nuevo[key] = state[key];
        }
      }
      setState(nuevo);
    }
  };

  const handleRemoveItem = (field) => {
    let newState = { ...state };
    delete newState[field];
    setState(newState);
  };

  return (
    <Box display={"flex"} style={{ alignItems: "center" }}>
      <Button onClick={() => handleClick()} variant="outlined" size="small">
        {labelButton}
      </Button>
      <Dialog
        open={show}
        onClose={() => handleClose()}
        fullScreen
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
            <Button autoFocus variant="contained" onClick={handleAccept}>
              {translate("ra.action.save")}
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <TextField
            value={newValue}
            label="Clave Nueva"
            onChange={(e) => setNewValue(e.target.value || "")}
          />
          <Button
            variant="outlined"
            color="primary"
            onClick={handleAddItem}
            disabled={newValue?.length === 0}
          >
            {translate("components.add")}
          </Button>
          <ListMUI>
            {Object.keys(state)?.map((i, key) => (
              <ListItem
                key={key}
                style={{ marginBottom: "10px", display: "flex", gap: "10px" }}
              >
                <TextField
                  value={i}
                  label={label}
                  variant="filled"
                  size="small"
                  onChange={(e) => handleChange(i, e.target.value)}
                />
                <Box display="flex" alignItems="center" alignSelf="flex-start">
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={() => handleRemoveItem(i)}
                    startIcon={<DeleteIcon />}
                  >
                    {translate("ra.action.delete")}
                  </Button>
                </Box>
              </ListItem>
            ))}
          </ListMUI>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
