import React, { useState, useEffect } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import { FormControlLabel, Switch } from "@material-ui/core";
import { translate } from "ra-core";

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      width: "auto",
      minWidth: "280px",
      maxWidth: "500px",
      marginRight: "30px",
      marginBottom: "24px",
      "&.full": {
        width: "100%",
      },
    },
    numberField: {
      width: "auto",
      minWidth: "250px",
      marginRight: "30px",
      marginBottom: "24px",

      /* Chrome, Safari, Edge, Opera */
      "& input::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: "0",
      },
      "& input::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: "0",
      },

      /* Firefox */
      "& input[type=number]": {
        "-moz-appearance": "textfield",
      },
    },
  })
);

const SubscribeCTA = ({ handleCallback, componentData }) => {
  const classes = useStyles();
  const [state, setState] = useState(componentData);

  const handleChange = () => {
    componentData = state;
    handleCallback(componentData);
  };

  const handleChangeSwitch = (value) => {
    const newstate = { ...state };
    newstate.showIcon = value;
    handleCallback(newstate);
    setState(newstate);
  };

  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        (state.title !== componentData.title ||
          componentData.text !== state.text) &&
        handleChange(),
      1000
    );
    return () => clearTimeout(timeOutId);
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box display='flex' flexDirection='row' flexWrap='wrap'>
      <TextField
        id={"TextField-title"}
        label={translate("components.title_banner")}
        margin='dense'
        variant='filled'
        autoComplete='off'
        value={state?.title || ""}
        className={classes.textField}
        onChange={(e) =>
          setState((prev) => ({ ...prev, title: e.target.value }))
        }
      />
      {state && state.text !== undefined && (
        <TextField
          id={"TextField-text"}
          label={translate("components.text")}
          margin='dense'
          variant='filled'
          autoComplete='off'
          value={state?.text || ""}
          className={classes.textField}
          onChange={(e) =>
            setState((prev) => ({ ...prev, text: e.target.value }))
          }
        />
      )}
      <FormControlLabel
        control={
          <Switch
            checked={state.showIcon === undefined ? false : state.showIcon}
            onChange={(e) => handleChangeSwitch(e.target.checked)}
            color='primary'
          />
        }
        label={translate("components.show_icon_premim")}
        labelPlacement='start'
      />
    </Box>
  );
};

export default SubscribeCTA;
