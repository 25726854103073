import { Button } from "react-admin";
import { Fragment, useState } from "react";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslate } from "ra-core";

export const CopyToPaper = ({ record, permissions }) => {
  const [showCopyDialog, setShowCopyDialog] = useState(false);
  const translate = useTranslate();

  const handleNewClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setShowCopyDialog(true);
  };

  const handleClose = () => {
    setShowCopyDialog(false);
  };

  if (!record || !permissions) return null;
  if (permissions.some(permission => permission.name === '*' || permission.name === 'download-edition-paper')) {
    return (
      <Fragment>
        <Button size="small" onClick={handleNewClick}>
          <Tooltip title={translate("components.copy_paper")}>
            <FileCopyIcon />
          </Tooltip>
        </Button>
        <Dialog
          maxWidth={"md"}
          open={showCopyDialog}
          onClose={handleClose}
          onClick={(e) => e.stopPropagation()}
          aria-label={translate("components.copy_to_assemble")}
        >
          <DialogTitle>{translate("components.copy_to_assemble")}</DialogTitle>
          <DialogContent>
            {record.page_paper ? <div dangerouslySetInnerHTML={{ __html: `<strong>${translate("resources.notes.fields.page_paper")}: </strong> ${record.page_paper}` }}></div> : <div dangerouslySetInnerHTML={{ __html: `<strong>Página papel: </strong>` }}></div>}<br />
            {record.priority_paper ? <div dangerouslySetInnerHTML={{ __html: `<strong>${translate("resources.notes.fields.priority_paper")}: </strong> ${record.priority_paper}` }}></div> : <div dangerouslySetInnerHTML={{ __html: `<strong>Prioridad: </strong>` }}></div>}<br />
            {record.observation ? <div dangerouslySetInnerHTML={{ __html: `<strong>${translate("resources.notes.fields.observation")}: </strong> ${record.observation}` }}></div> : <div dangerouslySetInnerHTML={{ __html: `<strong>Comentarios: </strong>` }}></div>}<br /><br />
            {record.volanta_paper && <><div dangerouslySetInnerHTML={{ __html: `${record.volanta_paper}` }}></div><br /></>}
            {record.title_paper && <><div dangerouslySetInnerHTML={{ __html: `${record.title_paper}` }}></div><br /></>}
            {record.copete_paper && <><div dangerouslySetInnerHTML={{ __html: `${record.copete_paper}` }}></div><br /></>}
            {record.content_paper && <><div dangerouslySetInnerHTML={{ __html: `${record.content_paper}` }}></div><br /></>}
            {record.epigrafe_paper && <><div dangerouslySetInnerHTML={{ __html: `${record.epigrafe_paper}` }}></div><br /></>}
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
};
