import {
  Box,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useTranslate } from "ra-core";
import { useState } from "react";
import {
  Create,
  fetchEnd,
  fetchStart,
  ImageField,
  ImageInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  Toolbar,
  useNotify,
  useRefresh,
} from "react-admin";
import { useDispatch } from "react-redux";
import { apiUrl, httpClient } from "../../utils/utilsFetch";
import DescriptionField from "./DescriptionField";

const useStyles = makeStyles({
  width500: {
    width: "500px",
  },
  dropZone: {
    backgroundColor: "#e8e8e8",
    color: "rgba(0, 0, 0, 0.64)",
  },
});

export const ContactFormCreate = ({ ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();
  const translate = useTranslate();

  const tipos = [
    { id: "problema", name: `${translate("components.problem")}` },
    {
      id: "sugerencia",
      name: `${translate("components.improvement_suggestion")}`,
    },
  ];

  const prioridad = [
    { id: "urgente", name: `${translate("components.priorities.urgent")}` },
    { id: "alta", name: `${translate("components.priorities.high")}` },
    { id: "media", name: `${translate("components.priorities.medium")}` },
    { id: "baja", name: `${translate("components.priorities.low")}` },
  ];

  const CustomToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton
        label={translate("ra.action.send")}
        disabled={loading}
        icon={loading ? <CircularProgress size={18} thickness={2} /> : <></>}
        onSave={sendForm}
      />
    </Toolbar>
  );

  const sendForm = async (data) => {
    setLoading(true);
    dispatch(fetchStart()); // start the global loading indicator

    const formData = new FormData();
    formData.append("type", data.type);
    formData.append("priority", data.priority);
    formData.append("description", data.description);

    if (data.screenshot?.rawFile) {
      formData.append("screenshot", data.screenshot.rawFile);
    }

    httpClient(apiUrl + "/technical-assistance", {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (res.status === 200) {
          notify("ra.notification.ticket_created");
          refresh();
        } else {
          notify("ra.notification.http_error", { type: "warning" });
        }
      })
      .catch((e) => {
        notify("ra.notification.http_error", { type: "warning" });
      })
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd()); // stop the global loading indicator
      });
  };

  return (
    <Create {...props} title=" ">
      <SimpleForm toolbar={<CustomToolbar />}>
        <Typography variant="h6">
          {translate("components.technical_support")}
        </Typography>
        <SelectInput
          choices={tipos}
          source="type"
          label={translate("components.type")}
          validate={requiredValidate}
        ></SelectInput>
        <SelectInput
          choices={prioridad}
          source="priority"
          label={translate("components.priority")}
          validate={requiredValidate}
        ></SelectInput>
        <Box className={classes.width500}>
          <Typography
            className={classes.width500}
            style={{ marginBottom: ".5em" }}
          >
            {translate("components.description_problem_text")}
          </Typography>
          <Typography
            className={classes.width500}
            style={{ marginBottom: ".5em" }}
          >
            {translate("components.create_ticket_text")}
          </Typography>
          <DescriptionField
            {...props}
            className={classes.width500}
            source="description"
            label={translate("components.description")}
            reference="contact_form"
          />
          <Typography
            className={classes.width500}
            style={{ marginTop: "22px" }}
          >
            {translate("components.attachScreenshot")}{" "}
            <small style={{ color: "gray", fontSize: "11px" }}>
              ({translate("components.optional")})
            </small>
          </Typography>
          <ImageInput
            className={classes.width500}
            classes={{
              dropZone: classes.dropZone,
            }}
            source="screenshot"
            label=""
            accept="image/*"
          >
            <ImageField source="picture_prev" title="Upload Preview" />
          </ImageInput>
        </Box>
      </SimpleForm>
    </Create>
  );
};

const requiredValidate = [required()];
