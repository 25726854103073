import React from "react";
import { useTranslate } from "ra-core";
import {
  Input,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
} from "@material-ui/core";

const SelectFontColor = ({ value, zoneIndex, handleChangeField }) => {
  const translate = useTranslate();

  return (
    <Box sx={{ width: { xs: "160px", md: "250px" } }} marginRight="1em">
      <FormControl fullWidth>
        <InputLabel id="InputLabel-position-hero">
          {translate("resources.pages.fields.fontcolor")}
        </InputLabel>
        <Select
          id={"Select-component-" + zoneIndex}
          input={<Input />}
          variant="filled"
          onChange={(e) =>
            handleChangeField(e.target.value, "fontcolor", zoneIndex)
          }
          style={{ minWidth: "250px" }}
          value={value || ""}
        >
          <MenuItem value="">Default</MenuItem>
          <MenuItem value="white">{translate("components.white")} </MenuItem>
          <MenuItem value="black">{translate("components.black")}</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default React.memo(
  SelectFontColor,
  (prev, next) => prev.value === next.value && prev.zoneIndex === next.zoneIndex
);
