import { Box, Card, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LockIcon from "@material-ui/icons/Lock";
import { useTranslate } from "ra-core";
import React from "react";
import { Title } from "react-admin";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#fff",
    padding: 20,
    marginTop: theme.spacing(2),
    marginBottom: "16px",
    textAlign: "center",
  },
  icon: {
    width: "5em",
    height: "5em",
  },
}));

export const Locked = (props) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Card elevation={0} className={classes.root}>
      <Title title={translate("components.locked_no_permissions")} />
      <Box display="flex">
        <Box flex="1">
          <LockIcon className={classes.icon} />
          <Typography variant="h5" component="p" gutterBottom>
            {translate("components.locked_title", {
              name: props.record.locked_user.username,
            })}
          </Typography>
          <Box>
            <Typography variant="body1" component="p" gutterBottom>
              {translate("components.locked_text")}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};
