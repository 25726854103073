import React, { useEffect, useState } from 'react';
import { TextField, Chip, InputAdornment } from '@material-ui/core';
import { useField } from "react-final-form";
import { useRecordContext,useTranslate } from 'ra-core';

export const CustomAutocompleteArrayInput = ({ source, label }) => {
  const [inputValue, setInputValue] = useState('');
  const [chips, setChips] = useState([]);
  const { input: input_array } = useField(source);
  const record = useRecordContext();
  const translate = useTranslate();
  useEffect(() => {
    if (record) {
      setChips(record[source] || []);
    }
  }, [])

  useEffect(() => {
    if (chips) {
      input_array.onChange(chips);
    }
  }, [chips])

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyPress = (event) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      setChips([...chips, inputValue.trim()]);
      setInputValue('');
    }
  };

  const handleDeleteChip = (chipToDelete) => () => {
    setChips((prevChips) => prevChips.filter((chip) => chip !== chipToDelete));
  };
  const properties = label == " " ? {paddingTop: "10px"} : undefined;
  return (
    <TextField
      label={label}

      variant="filled"
      value={inputValue}
      onChange={handleInputChange}
      onKeyPress={handleInputKeyPress}
      style={{ flex: 1, minHeight: "65px", height: "auto", width: "100%" }}
      multiline
      helperText={translate("components.press_enter")}
      InputProps={{
        startAdornment: (<InputAdornment position="start" style={{ height: "auto", maxHeight: "none", marginTop: "5px", display: "flex", flexWrap: "wrap", maxWidth: "300px" }}>
          {chips?.map((chip, index) => (
            <Chip
              key={index}
              label={chip}
              variant="outlined"
              size="small"
              onDelete={handleDeleteChip(chip)}
              style={{ margin: '5px' }}
            />
          ))}
        </InputAdornment>)
      ,style: properties
    }} />
  );
};
