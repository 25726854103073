import * as React from "react";
import { DateField, Show, SimpleShowLayout, TextField } from 'react-admin';

// import HtmlEditor from "../../components/fields/HtmlEditor";

export const NotesShow = (props) => (
    <Show {...props} title=" ">
        <SimpleShowLayout>
            <TextField source="volanta" />
            <TextField source="title" />
            <TextField source="title_home" />
            <TextField source="copete" />
            <TextField source="epigrafe" />
            {/*<HtmlEditor source="content"/>
                <HtmlEditor source="content_premium"/>
                <HtmlEditor source="content_paper"/>*/}
            <DateField source="created_at" />
        </SimpleShowLayout>
    </Show>
);