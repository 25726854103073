import * as React from "react";
import { Datagrid, EditButton, Filter, List, TextField, TextInput, useTranslate, SelectField } from 'react-admin';
import { ActionsCreate } from "../../components/Toolbar/ActionsCreate";

const RoleFilter = (props) => (
    <Filter {...props}>
        <TextInput source="search" label="ra.action.search" alwaysOn />
    </Filter>
);

export const RoleList = props => {
    const translate = useTranslate();
    
    const ROLE = [
        { id: "subscriber", name: `${translate("components.subscriber")}` },
        { id: "admin", name: `${translate("components.admin")}` }
    ];
    return (
        <List filters={<RoleFilter />} actions={<ActionsCreate label={`${translate("resources.roles.new")}`} />} {...props} title=" " sort={{ field: 'name', order: 'ASC' }} bulkActionButtons={false}>
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="title" />
                <TextField source="users_count" />
                <SelectField source="type" choices={ROLE} />
                <EditButton />
            </Datagrid>
        </List>
    );
}
