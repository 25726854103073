import * as React from 'react';
import { Fragment, useCallback } from 'react';
import classnames from 'classnames';
import { List } from 'react-admin';
import { Route, useHistory } from 'react-router-dom';
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CommentsListDesktop from './CommentsListDesktop';
import commentsFilters from './commentsFilters';
import CommentsEdit from './CommentsEdit';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        marginRight: 400,
        [theme.breakpoints.down('xs')]: {
            marginRight: 0,
        },
    },
    drawerPaper: {
        zIndex: 100,
    },
}));

export const CommentsList = ({ permissions, ...props }) => {
    const classes = useStyles();
    const history = useHistory();

    const handleClose = useCallback(() => {
        history.push('/comments');
    }, [history]);
 
    return (
        <div className={classes.root}>
            <Route path="/comments/:id">
                {({ match }) => {
                    const isMatch = !!(
                        match &&
                        match.params &&
                        match.params.id !== 'create' && 
                        permissions && permissions.some(permission => permission.name === '*' || permission.name === 'edit-comments')
                    );

                    return (
                        <Fragment>
                            <List
                                {...props}
                                className={classnames(classes.list, {
                                    [classes.listWithDrawer]: isMatch,
                                })}
                                //bulkActionButtons={<CommentsBulkActionButtons />}
                                bulkActionButtons={false}
                                filters={commentsFilters}
                                perPage={25}
                                title=" "
                                sort={{ field: 'created_at', order: 'DESC' }}
                            >
                                <CommentsListDesktop
                                    selectedRow={
                                        isMatch
                                            ? parseInt(
                                                (match).params.id,
                                                10
                                            )
                                            : undefined
                                    }
                                />
                            </List>
                            {permissions && permissions.some(permission => permission.name === '*' || permission.name === 'edit-comments') &&
                                <Drawer
                                    variant="persistent"
                                    open={isMatch}
                                    anchor="right"
                                    onClose={handleClose}
                                    classes={{
                                        paper: classes.drawerPaper,
                                    }}
                                >
                                    {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
                                    {isMatch ? (
                                        <CommentsEdit
                                            id={(match).params.id}
                                            onCancel={handleClose}
                                            resource={props.resource}
                                            basePath={props.basePath}
                                        />
                                    ) : null}
                                </Drawer>
                            }
                        </Fragment>
                    );
                }}
            </Route>
        </div>
    );
};
