export const validateTitleHome = (values) => {
  if (values?.title?.length >= 20 && values?.title?.length <= 70) {
    return true;
  }
  return false;
};

export const validateImage = (values) => {
  if (values?.featured_image) return true;
  return false;
};
export const validateImageEpigrafe = (values) => {
  if (values?.epigrafe) return true;
  return false;
};
export const validateVolanta = (values) => {
  if (values?.volanta) return true;
  return false;
};
export const validateCopete = (values) => {
  if (values?.copete) return true;
  return false;
};
export const validateCopeteExtension = (values) => {
  if (values?.copete?.length >= 50 && values?.copete?.length <= 120)
    return true;
  return false;
};
export const validateContentExtension = (values) => {
  let text = values?.content ? values.content : values?.content_premium;
  if (text != undefined) {
    const cleanTextTags = /<\/?[^>]+(>|$)/g;
    const lineBreak = /\r?\n/g;
    const firstSpace = /^ /;
    const lastSpace = / $/;
    const multipleSpaces = /[ ]+/g;
    //Limpiamos los tag html
    text = text.replace(cleanTextTags, " ");
    //Reemplazamos los saltos de linea por espacios
    text = text.replace(lineBreak, " ");
    //Removemos espacios multiple
    text = text.replace(multipleSpaces, " ");
    //Removemos primer espacio
    text = text.replace(firstSpace, "");
    //Removemos ultimo espacio
    text = text.replace(lastSpace, "");
    //Dividimos en palabras
    const splitText = text.split(" ");
    const words = splitText.length;
    if (words >= 500) return true;
  }
  return false;
};
export const validateContentStrong = (values) => {
  const text = values?.content ? values.content : values?.content_premium;
  if (text != undefined) {
    let parser = new DOMParser();
    const doc = parser.parseFromString(text, "text/html");
    const strong = doc.getElementsByTagName("strong");
    if (strong.length >= 1) return true;
  }
  return false;
};
export const validateContentTitle = (values) => {};

export const validateContentLink = (values) => {
  const text = values?.content ? values.content : values?.content_premium;
  if (text != undefined) {
    let parser = new DOMParser();
    const doc = parser.parseFromString(text, "text/html");
    const links = doc.getElementsByTagName("a");
    const sugested = doc.querySelector("section.noteSuggested");
    if (sugested) {
      return true;
    }
    if (links?.length >= 1) {
      for (let i of links) {
        const ref = i.getAttribute("href");
        if (ref.indexOf(process.env.REACT_APP_URL_CLIENT, 0) != -1) {
          return true;
        }
      }
      return true;
    }
  }
  return false;
};
export const validateTags = (values) => {
  if (values?.tags?.length > 0) return true;
  return false;
};
export const validateAuthor = (values) => {
  if (values?.author_id) return true;
  return false;
};
export const validateKeywords = (values) => {
  if (
    values?.keywords &&
    Array.isArray(values?.keywords) &&
    values?.keywords?.length >= 3
  )
    return true;
  return false;
};
export const validateMetaDescription = (values) => {
  if (values?.meta_description && values?.meta_description?.length > 0)
    return true;
  return false;
};
export const validateMetaDescriptionIncludesKeywords = (values) => {
  if (values?.meta_description && values?.keywords?.length > 0) {
    const texto = values.meta_description.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return values.keywords.some(function (palabra) {
      palabra = palabra.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      return texto.includes(palabra);
    });
  }
  return false;
};
export const validateTitleIncludesKeywords = (values) => {
  if (values?.title && values?.keywords?.length > 0) {
    const texto = values.title.toLowerCase();
    return values.keywords.some(function (palabra) {
      palabra = palabra.toLowerCase();
      return texto.includes(palabra);
    });
  }
  return false;
};