import * as React from 'react';
import {
    Datagrid,
    DateField,
    TextField,
    SelectField,
    ReferenceField,
    FunctionField
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import SubscriberFullNameField from '../subscribers/FullNameField';
import JournalistFullNameField from '../journalists/FullNameField';
import rowStyle from './rowStyle';

export const CustomerReferenceField = (props) => {
    if (!props.record) return null;
    if (props.record.user?.type === 'journalist') {
        return (
            <ReferenceField source="user_id" reference="journalists" {...props}>
                <JournalistFullNameField />
            </ReferenceField>
        )
    }
    return (
        <ReferenceField source="user_id" reference="subscribers" {...props}>
            <SubscriberFullNameField />
        </ReferenceField>
    )
}
CustomerReferenceField.defaultProps = {
    addLabel: true,
};

const useListStyles = makeStyles({
    headerRow: {
        borderLeftColor: 'transparent',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
    },
    headerCell: {
        padding: '6px 8px 6px 8px',
    },
    rowCell: {
        padding: '6px 8px 6px 8px',
    },
    comment: {
        maxWidth: '18em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
});

const CommentsListDesktop = ({ selectedRow, ...props }) => {
    const classes = useListStyles();
    return (
        <Datagrid
            rowClick="edit"
            rowStyle={rowStyle(selectedRow)}
            classes={{
                headerRow: classes.headerRow,
                headerCell: classes.headerCell,
                rowCell: classes.rowCell,
            }}
            optimized
            {...props}
        >
            <DateField source="created_at" />
            <CustomerReferenceField link={false} source="user_id" {...props} />
            <TextField source="comment" cellClassName={classes.comment} />
            <SelectField
                source="status"
                choices={[
                    { id: 'publish', name: 'Publicado' },
                    { id: 'rejected', name: 'Rechazado' },
                ]}
            />,
            <FunctionField source="parent_id" render={record => record && record.parent_id ? <CheckIcon/> : ''} />
        </Datagrid>
    );
};

export default CommentsListDesktop;
