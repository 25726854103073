import React, { useState, useEffect } from "react";
import Divider from "@material-ui/core/Divider";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { ListItem } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { Select, MenuItem, InputLabel } from "@material-ui/core";
import ListImgsComponent from "./ListImgs";
import { useTranslate } from "ra-core";
const useStyles = makeStyles((theme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightBold,
    },
    textField: {
      width: "auto",
      minWidth: "250px",
      marginRight: "30px",
    },
    selectNotes: {
      flex: 1,
      marginBottom: "10px",
    },
    img: {
      width: "75px",
      marginRight: "15px",
    },
    numberOrder: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightBold,
      marginRight: "8px",
    },
    ul: {
      listStyleType: "none !important",
    },
    select: {
      minWidth: "250px",
      margin: ".5em 0",
    },
  })
);

const TYPES_ADS = [
  { id: "box-300", name: "300 x 250" },
  { id: "box-240", name: "240 x 250" },
];

const GrillaPublicidadx4 = ({
  handleCallback,
  zone,
  componentData,
  listCategories,
  ...props
}) => {
  const classes = useStyles();
  const [list, setListImgs] = useState(componentData?.list);
  const translate = useTranslate();
  const handleChangeType = (event) => {
    componentData.type = event.target.value;
    handleCallback(componentData);
  };

  const handleChangeListImgs = (list) => {
    if (list && list.length > 0) {
      componentData.list = [];
      list.forEach((elem) => {
        componentData.list.push(elem);
      });
      handleCallback(componentData);
    }
  };
  useEffect(() => {
    const list = [];
    if (componentData.list) {
      componentData.list.forEach((elem) => {
        list.push(elem);
      });
    }
    setListImgs(list);
  }, [componentData.list]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box display='flex' justifyContent='space-between'>
      <div className={classes.selectNotes}>
        <Box>
          <InputLabel id='demo-simple-select-label'>
            {translate("components.advertising_type")}{" "}
          </InputLabel>
          <Select
            value={componentData.type}
            initialvalue={componentData.type}
            className={classes.select}
            onChange={handleChangeType}
          >
            {TYPES_ADS.map((item, index) => (
              <MenuItem value={item.id} key={index}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <ListItem className={classes.ul} ContainerComponent='div'>
          <Box display='flex' flexDirection='column'>
            {list && (
              <ListImgsComponent
                list={list}
                handleChangeListImgs={handleChangeListImgs}
                componentData={componentData}
              />
            )}
          </Box>
        </ListItem>
        <Divider variant='middle' />
      </div>
    </Box>
  );
};

export default GrillaPublicidadx4;
