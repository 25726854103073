import React, { useEffect, useState, forwardRef } from "react";
import {
  Button,
  TextField,
  Typography,
  Box,
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  List as ListMUI,
  ListItem,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslate } from "ra-core";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  attributes: {
    "& .MuiButton-outlinedSizeSmall": {
      marginTop: "17px",
      marginBottom: "15px"
    }
  }
}));

export const List = ({ record, onSave, labelButton = "Establecer Opciones", title = "Opciones", label = "Opción" }) => {
  const [state, setState] = useState([]);
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const translate = useTranslate();
  useEffect(() => {
    if (record) {
      if (Array.isArray(record)) {
        setState(record || []);
      } else {
        let res = JSON.parse(record);
        setState(res || []);
      }
    }
  }, [record, show]);

  const handleClick = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setState([]);
  };

  const handleAccept = () => {
    onSave(state);
    setShow(false);

  };

  const handleAddItem = () => {
    setState([...state, { name: "" }]);
  };

  const handleChange = (index, value) => {
    let newState = [...state];
    newState[index].name = value;
    setState(newState);
  };

  const handleRemoveItem = (index) => {
    const updatedItems = [...state];
    updatedItems.splice(index, 1);
    setState(updatedItems);
  };

  return (
    <Box display={"flex"} style={{ alignItems: "center" }}>
      <Button onClick={() => handleClick()} variant="outlined" size="small">
        {labelButton}
      </Button>
      <Dialog
        open={show}
        onClose={() => handleClose()}
        fullScreen
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
            <Button autoFocus variant="contained" onClick={handleAccept}>
              {translate("ra.action.save")}
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <ListMUI>
            {state && state?.map((i, key) => (
              <ListItem
                key={key}
                style={{ marginBottom: "10px", display: "flex", gap: "10px" }}
              >
                <TextField
                  value={i?.name}
                  label={label}
                  variant="filled"
                  size="small"
                  onChange={(e) => handleChange(key, e.target.value)}
                />
                <Box display="flex" alignItems="center" alignSelf="flex-start">
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={() => handleRemoveItem(key)}
                    startIcon={<DeleteIcon />}

                  >
                    {translate("ra.action.delete")}
                  </Button>
                </Box>
              </ListItem>
            ))}
          </ListMUI>
          <Button variant="outlined" color="primary" onClick={handleAddItem}>
            {translate("components.add")}
          </Button>
        </DialogContent>
      </Dialog>
    </Box>
  );
};