import {
  Box,
  createStyles,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Tooltip,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { useTranslate } from "ra-core";
import React, { useState } from "react";
import { ImageSelect } from "../fields/ImageSelect";

const useStyles = makeStyles((theme) =>
  createStyles({
    img: {
      width: "75px",
      marginRight: "15px",
    },
    numberOrder: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightBold,
      marginRight: "8px",
    },
    items: { marginRight: "12px" },
    select: {
      minWidth: "250px",
    },
  })
);

export const ImageComponent = ({
  handleSelectedImgs,
  index,
  componentData,
}) => {
  const classes = useStyles();
  const [state, setState] = useState(
    componentData ? componentData?.list[index] : {}
  );
  const translate = useTranslate();
  const SIZE = [
    {
      id: "cover",
      name: `${translate("components.sizes.cover_name")}`,
      tip: `${translate("components.sizes.cover_tip")}`,
    },
    {
      id: "contain",
      name: `${translate("components.sizes.contain_name")}`,
      tip: `${translate("components.sizes.contain_tip")}`,
    },
    { id: "none", name: `${translate("components.none")}` },
  ];

  const POSITION = [
    { id: "top", name: `${translate("components.positions.top")}` },
    { id: "bottom", name: `${translate("components.positions.bottom")}` },
    { id: "center", name: `${translate("components.positions.center")}` },
  ];
  const handleSelected = (value) => {
    let newState = { ...state, image: value };
    setState(newState);
    handleSelectedImgs(newState, index);
  };

  const handleChange = (event) => {
    let newState = { ...state, url: event.target.value };
    setState(newState);
    handleSelectedImgs(newState, index);
  };

  const handleChangeSize = (event) => {
    let newState = { ...state, size: event.target.value };
    setState(newState);
    handleSelectedImgs(newState, index);
  };
  const handleChangePosition = (event) => {
    let newState = { ...state, position: event.target.value };
    setState(newState);
    handleSelectedImgs(newState, index);
  };

  return (
    <Box display="flex">
      <ImageSelect
        handleSelected={handleSelected}
        componentData={componentData?.list}
        indexImage={index}
      />
      <Box display="flex" flexDirection="column" style={{ marginLeft: "1em" }}>
        <Box mt="0.5em">
          <InputLabel id="demo-simple-select-label">
            {translate("components.position")}
          </InputLabel>
          <Select
            value={state.position}
            className={classes.select}
            onChange={handleChangePosition}
          >
            {POSITION.map((item, index) => (
              <MenuItem value={item.id} key={index}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box mt="0.5em">
          <InputLabel id="demo-simple-select-label">
            {translate("components.image_style")}
          </InputLabel>
          <Select
            value={state.size}
            className={classes.select}
            onChange={handleChangeSize}
          >
            {SIZE.map((item, index) => (
              <MenuItem value={item.id} key={index}>
                {item.tip ? (
                  <Tooltip title={item.tip} key={`${index}_tip`}>
                    <div>{item.name}</div>
                  </Tooltip>
                ) : (
                  <div>{item.name}</div>
                )}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <TextField
          value={state.url}
          onChange={(e) => {
            handleChange(e);
          }}
          label="URL"
          style={{ margin: "0.5em 0", width: "100%" }}
        />
      </Box>
    </Box>
  );
};
