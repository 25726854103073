import React, { cloneElement } from 'react';
import {
    useListContext,
    TopToolbar,
    sanitizeListRestProps,
    CreateButton, 
} from 'react-admin';
const NotesActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        permissions,
        ...rest
    } = props;
    const {
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            {permissions && permissions.some(permission => permission.name === '*' || permission.name === 'create-notes') &&
                <CreateButton variant="contained" style={{padding: "4px 20px 4px 10px", marginLeft:"20px"}} basePath={basePath} />
            }
        </TopToolbar>
    );
};

export default NotesActions;