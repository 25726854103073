import * as React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
// import { DropResult } from "react-beautiful-dnd";
import List from "./List";

const useStyles = makeStyles({
  flexPaper: {
    flex: 1,
    margin: 16,
    minWidth: 350,
  },
  root: {
    width: "100%",
  },
});

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const DraggableList = ({
  list,
  handleSave,
  zoneIndex,
  zone,
  handleCallbackUpdateComponent,
  listCategories,
  deleteComponent,
}) => {
  const classes = useStyles();
  const [items, setItems] = React.useState(list);

  const onDragEnd = ({ destination, source }) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(items, source.index, destination.index);

    setItems(newItems);
    handleSave(newItems, zoneIndex);
  };

  return (
    <div className={classes.root}>
      <List
        items={items}
        onDragEnd={onDragEnd}
        zone={zone}
        handleCallbackUpdateComponent={handleCallbackUpdateComponent}
        listCategories={listCategories}
        deleteComponent={deleteComponent}
      />
    </div>
  );
};
