import { Chip } from "@material-ui/core";

export default function ColorField({ value, name, classes }) {
    return (
        <Chip
            variant='outlined'
            label={name || "Default"}
            icon={
                <span
                    className={classes}
                    style={{
                        background: value || "white",
                    }}
                ></span>
            }
        />
    );
}