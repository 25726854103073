import * as React from "react";
import MuiGridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import { makeStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { linkToRecord, useListContext } from "react-admin";
import { Link } from "react-router-dom";
import ImgDefault from "../../assets/img/note-default.png";

const useStyles = makeStyles((theme) => ({
  gridList: {
    margin: 0,
  },
  tileBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%)",
  },
  placeholder: {
    backgroundColor: theme.palette.grey[300],
    height: "100%",
  },
  price: {
    display: "inline",
    fontSize: "1em",
  },
  link: {
    color: "#fff",
  },
  ImgDefault: {
    objectFit: "none",
  },
}));

const getColsForWidth = (width) => {
  if (width === "xs") return 2;
  if (width === "sm") return 3;
  if (width === "md") return 3;
  if (width === "lg") return 5;
  return 6;
};

const times = (nbChildren) =>
  Array.from({ length: nbChildren }, (_, key) => key);

const LoadingGridList = ({ width, nbItems = 20 }) => {
  const classes = useStyles();
  return (
    <MuiGridList
      cellHeight={180}
      cols={getColsForWidth(width)}
      className={classes.gridList}
    >
      {" "}
      {times(nbItems, (key) => (
        <GridListTile key={key}>
          <div className={classes.placeholder} />
        </GridListTile>
      ))}
    </MuiGridList>
  );
};

const getDate = (stringToSplit) => {
  if (stringToSplit) {
    let arrayStrings = stringToSplit.split("-");
    return `${arrayStrings[2]}/${arrayStrings[1]}/${arrayStrings[0]}`;
  }
  return "";
};

const LoadedGridList = ({ width }) => {
  const { ids, data, basePath } = useListContext();
  const classes = useStyles();

  if (!ids || !data) return null;

  return (
    <MuiGridList
      cellHeight={180}
      cols={getColsForWidth(width)}
      className={classes.gridList}
    >
      {ids.map(
        (id) =>
          data[id] && (
            <GridListTile
              component={Link}
              key={id}
              to={linkToRecord(basePath, data[id].id, "show")}
            >
              {data[id].thumbnail_url ? (
                <img src={data[id].thumbnail_url} alt="preview_url" />
              ) : (
                <img
                  src={ImgDefault}
                  className={classes.ImgDefault}
                  alt="default"
                />
              )}
              <GridListTileBar
                className={classes.tileBar}
                title={`${getDate(data[id].date_publish)} ${data[id]?.category?.name ? " | " + data[id]?.category?.name : ""}`}
                subtitle={data[id].title ? data[id].title : ""}
              />
            </GridListTile>
          )
      )}
    </MuiGridList>
  );
};

const GridList = ({ width }) => {
  const { loaded } = useListContext();
  return loaded ? (
    <LoadedGridList width={width} />
  ) : (
    <LoadingGridList width={width} />
  );
};

export default withWidth()(GridList);
