import { BooleanInput } from "react-admin";
import { Box, Dialog, DialogContent, DialogActions, Button, Typography } from "@material-ui/core";
import { useField } from "react-final-form";
import { useState } from "react";
import { useTranslate } from "ra-core";

export const CorrectedField = ({ source }) => {
    const [show, setShow] = useState(false);
    const { input } = useField(source);
    const translate = useTranslate();

    const handleChange = (value) => {
        if (value == false) {
            setShow(true);
        }
    }

    const handleCancel = () => {
        input.onChange(true);
        setShow(false);
    }

    const handleAccept = () => {
        setShow(false);
    }

    return (
        <Box >
            <BooleanInput source={source} label={translate("resources.notes.fields.corrected")} defaultValue={false} onChange={(value) => handleChange(value)} />
            <Dialog open={show} >
                <DialogContent>
                    <Typography variant="h6">{translate("components.corrected_status_change")}</Typography>
                    <Typography variant="h6" style={{ textAlign: "center" }}>{translate("ra.message.continue")}</Typography>
                </DialogContent>
                <DialogActions style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button onClick={() => handleCancel()} variant="outlined" color="secondary" style={{ marginRight: "10px" }}>{translate("ra.action.cancel")}</Button>
                    <Button onClick={() => handleAccept()} variant="contained" color="primary">{translate("ra.action.accept")}</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}