import * as React from "react";
import {
  DeleteWithConfirmButton,
  Toolbar,
  Edit,
  SimpleForm,
  SaveButton,
  useRecordContext,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "react-admin";
import { FormType } from "./create";

const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  }
});

const EditToolbar = (props) => {
  const record = useRecordContext();
  const translate = useTranslate();
  return (
    <Toolbar classes={useStyles()} {...props}>
      <SaveButton />
      <DeleteWithConfirmButton
        confirmTitle={`${translate("resources.entities.confirm_title",{ name: record.name })}`}
        confirmContent={`${translate("resources.entities.confirm_content")}`}
      />
    </Toolbar>
  );
};

export const TypeEdit = (props) => {
  return (
    <Edit title=" " {...props}>
      <SimpleForm toolbar={<EditToolbar />}>
        <FormType permissions={props.permissions} />
      </SimpleForm>
    </Edit>
  )
}