import { Fragment } from "react";
import {
  TextInput,
  SelectInput,
  NumberInput,
  CheckboxGroupInput,
} from "react-admin";
import { Box } from "@material-ui/core";
import { ImageSelect } from "../../components/pickers/ImageSelectFromSource";
import { CustomRadio } from "./CustomRadio";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  tachado: {
    "& .Mui-checked + span": {
      textDecoration: "line-through!important",
    },
  },
});

//*** Opción simple  */
export const OptionRender = ({ permissions, source, options = [] }) => {
  const classes = useStyles();
  return (
    <Box mb="1em">
      <Box display="flex" flexDirection="column">
        {options?.map((item, index) => (
          <Fragment key={index}>
            {item.type === "image" && (
              <ImageSelect
                source={source + "." + item.name}
                label={item.label}
                indexStep={Number(
                  source +
                    "." +
                    item.name?.split(".")[0]?.split("[")[1]?.split("]")[0] || 0
                )}
                indexComponent={Number(
                  source +
                    "." +
                    item.name?.split(".")[1]?.split("[")[1]?.split("]")[0] || 0
                )}
                style={{ marginRight: "1em" }}
                permissions={permissions}
              />
            )}
            {item.type === "number" && (
              <NumberInput
                source={source + "." + item.name}
                label={item.label}
                style={{ marginRight: "1em", maxWidth: "260px" }}
                helperText={item?.helperText}
              />
            )}
            {item.type === "text" && (
              <TextInput
                source={source + "." + item.name}
                label={item.label}
                style={{ marginRight: "1em" }}
              />
            )}
            {item.type === "select" && (
              <SelectInput
                source={source + "." + item.name}
                label={item.label}
                choices={item.options}
              />
            )}
            {item.type === "radio" && (
              <CustomRadio
                source={source + "." + item.name}
                label={item?.label}
                choices={item?.options}
              />
            )}
            {item.type === "check" && (
              <CheckboxGroupInput
                source={source + "." + item.name}
                label={item?.label}
                options={{
                  checkedIcon:
                    item.settings?.icon == "cross" ? (
                      <CloseIcon
                        style={{
                          border: "2px solid #3f51b5",
                          borderRadius: "1px",
                          width: "14px",
                          height: "14px",
                          margin: "0 3px",
                        }}
                      />
                    ) : undefined,
                }}
                choices={item?.options}
                className={
                  item.settings?.text == "line-through" ? classes.tachado : ""
                }
              />
            )}
          </Fragment>
        ))}
      </Box>
    </Box>
  );
};
