import React from 'react';
import ReactDOM from 'react-dom';
import { useInput } from 'react-admin';
import CKEditor from '@ckeditor/ckeditor5-react';
// Listas de notas
import NoteList from '../SuggestedPick/NoteList';
//import CKEditorInspector from '@ckeditor/ckeditor5-inspector';
import medioData from "Static/data/medio.json";

const ClassicEditor = window.ClassicEditor
const default_language = medioData?.default_language ? `${medioData?.default_language}` : "es";

const HtmlEditor = (props) => {
    const config = {
        language: default_language,
        toolbar: {
            items: [
                'heading',
                '|',
                'fontColor',
                'fontBackgroundColor',
                'bold',
                'italic',
                'underline',
                'strikethrough',
                '|',
                'alignment',
                '|',
                'numberedList',
                'bulletedList',
                'todoList',
                '|',
                'outdent',
                'indent',
                '|',
                'link',
                'blockQuote',
                'mediaLibrary',
                'audioLibrary',
                'insertTable',
                'mediaEmbed',
                'htmlEmbed',
                '|',
                'fullScreen',
                'undo',
                'redo',
                '|',
                'insertSuggested'
            ],
            shouldNotGroupWhenFull: true
        },
        image: {
            pasteType: 'html',
            styles: [
                'alignLeft', 'alignCenter', 'alignRight'
            ],
            toolbar: [
                'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight',
                '|',
                'imageTextAlternative',
                '|',
                'toggleImageCaption',
            ]
        },
        table: {
            contentToolbar: [
                'tableColumn',
                'tableRow',
                'mergeTableCells',
                'tableCellProperties',
                'tableProperties'
            ]
        },
        heading: {
            options: [
                { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                { model: 'heading1', view: 'h2', title: 'Heading 1', class: 'ck-heading_heading1' },
                { model: 'heading2', view: 'h3', title: 'Heading 2', class: 'ck-heading_heading2' },
                { model: 'heading3', view: 'h4', title: 'Heading 3', class: 'ck-heading_heading3' },
            ]
        },
        mediaEmbed: {
            previewsInData: true
        },
        apiUrl: process.env.REACT_APP_API_HOST,
        notes: {
            // Funcion que renderíza una nota sugerida desde un ID
            noteRenderer: (ids, title, domElement) => {
                ReactDOM.render(
                    // Se renderiza en SuggestedPickEditing.js -> return modelWriter.createElement('notePreview'...
                    <NoteList ids={ids} title={title} />,
                    domElement
                );
            }
        }
    };

    const {
        input: { name, value, onChange, onBlur, ...rest },
    } = useInput(props);

    const handleOnBlur = (event, editor) => {
        onBlur(event)
    }

    const handleOnChange = (event, editor) => {
        setState(prevState => {
            const newValue = editor.getData();
            if (newValue !== prevState) {
                return newValue;
            }
            return prevState; // Si el valor no ha cambiado, conserva el estado actual.
        });
    };

    const initCkEditor = (editor) => {
        const wordCountPlugin = editor.plugins.get('WordCount'),
            wordCountWrapper = document.getElementById(props.source + '-word-count');
        wordCountWrapper.appendChild(wordCountPlugin.wordCountContainer);
        // Habilitar CKEditorInspector para desarrollo 
        //CKEditorInspector.attach(props.source, editor);
    };

    const [state, setState] = React.useState(value);
    React.useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (state !== value) {
                onChange(state);
            }
        }, 1000);
        return () => clearTimeout(timeOutId);
    }, [state, value, onChange]);

    // ========================================================================
    // Component Body
    // ========================================================================
    return (
        <div className='app'>
            <CKEditor
                editor={ClassicEditor}
                config={config}
                data={value}
                onInit={editor => initCkEditor(editor)}
                onBlur={handleOnBlur}
                onChange={handleOnChange}
                {...rest}
            />
            <div id={props.source + '-word-count'}></div>
        </div>
    );
};

export default HtmlEditor;
