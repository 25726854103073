import React, { useState, useEffect, useCallback, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useVersion, useDataProvider } from "react-admin";
import { useField } from "react-final-form";
import {
  Tab,
  Tabs,
  Typography,
  Paper,
  Box,
  ListItemSecondaryAction,
  ListItemText,
  Checkbox,
  ListItem,
  List,
} from "@material-ui/core";
import { ROLE } from "./constants";
import { useFormState } from "react-final-form";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import medio from "Static/data/medio.json";
import { useTranslate } from "ra-core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: ".5em",
  },
  content: {
    padding: ".5em",
    flexGrow: 1,
    maxWidth: 400,
  },
  label: {
    padding: ".25em 0",
    "& .MuiTreeItem-label": {
      fontSize: "1.1em",
      fontWeight: 500,
    },
  },
}));

const TreeAbilities = ({ data, checked, handleClick }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const translate = useTranslate();

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
    if (nodeIds) handleClick(nodeIds);
  };

  return (
    <TreeView
      className={classes.content}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      expanded={expanded}
      selected={selected}
      onNodeToggle={handleToggle}
      onNodeSelect={handleSelect}
    >
      {Object.entries(data).map((category, idx) => (
        <TreeItem
          nodeId={`cat-${idx}`}
          label={translate(`resources.${category[0]}.name`)}
          className={classes.label}
          key={idx}
        >
          <List>
            {Object.values(category[1]).map((item, index) => (
              <ListItem key={index} button onClick={handleClick(item.id)}>
                <ListItemText
                  id={`checkbox-list-label-${item.id}`}
                  primary={item.title}
                // secondary={item.name}
                />
                <ListItemSecondaryAction>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(item.id) !== -1}
                    tabIndex={-1}
                    disableRipple
                    onClick={handleClick(item.id)}
                    inputProps={{
                      "aria-labelledby": `checkbox-list-label-${item.id}`,
                    }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </TreeItem>
      ))}
    </TreeView>
  );
};

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      key={index}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Paper elevation={1} style={{ marginTop: "2px", padding: "1em" }}>
          {children}
        </Paper>
      )}
    </div>
  );
}

export default function Abilities({ record, source }) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [state, setState] = useState({});
  const version = useVersion();
  const dataProvider = useDataProvider();
  const { input } = useField(source);
  const [value, setValue] = React.useState(0);
  const { values } = useFormState();
  const translate = useTranslate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchAbilities = useCallback(async () => {
    const { data: allAbilities } = await dataProvider.getAbilities();
    setState((state) => ({
      ...state,
      allAbilities,
    }));
  }, [dataProvider]);

  useEffect(() => {
    fetchAbilities();
  }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (record && record.abilities) {
      setChecked(record.abilities);
    }
  }, [record]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    input.onChange(newChecked);
  };

  useEffect(() => {
    if (!medio?.only_club) {
      setValue(1);
    }
  }, [values?.type]);

  const condition =
    values?.type == "subscriber" ||
    !values?.type ||
    values?.type != "subscriber";

  if (!state.allAbilities || Object.keys(state.allAbilities)?.length == 0)
    return null;
  return (
    <Fragment>
      <Typography className={classes.heading}>Permisos</Typography>
      <Paper square elevation={2}>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
        >
          {Object.keys(state.allAbilities).map((role, key = 1) => {
            const val = ROLE.find((i) => i.id == role);
            return (
              ((values?.type == "subscriber" && role == "subscriber") ||
                values?.type != "subscriber") && (
                <Tab label={translate("components." + val?.id)} value={key} key={key}></Tab>
              )
            );
          })}
        </Tabs>
      </Paper>
      <Box p="1">
        {Object.entries(state.allAbilities).map((module, index = 1) => {
          return (
            condition && (
              <TabPanel value={value} key={index} index={index}>
                <TreeAbilities
                  data={module[1]}
                  checked={checked}
                  handleClick={handleToggle}
                />
              </TabPanel>
            )
          );
        })}
      </Box>
    </Fragment>
  );
}
