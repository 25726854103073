import Button from "@material-ui/core/Button";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslate } from "ra-core";

export const ShowNote = ({ record, permissions }) => {
  const translate = useTranslate();
  if (!record) return null;
  const link_note = process.env.REACT_APP_URL_CLIENT + record.link_note;
  const url =  link_note.replaceAll("undefined/","");
  return (
    <Tooltip title={translate("components.view_online")}>
      <Button
        size="small"
        color="primary"
        disabled={record.status !== "publish" || false}
        href={`${url}`}
        target="_blank"
      >
        <VisibilityIcon />
      </Button>
    </Tooltip>
  );
};
