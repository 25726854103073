import { useState } from "react";
import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useFormState } from "react-final-form";
import { apiUrl, httpClient } from "../../utils/utilsFetch";
import { useDispatch } from "react-redux";
import { showNotification } from "react-admin";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslate } from "ra-core";

export const PreviewButton = () => {
    const { values, pristine } = useFormState();
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();
    const dispatch = useDispatch();

    const handleSavePreview = async () => {
        setLoading(true);
        const id = values.id;
        if (id) {
            httpClient(`${apiUrl}/preview/${id}/note`, {
                method: "POST",
                body: JSON.stringify(values),
            })
                .then(({ json }) => {
                    if (json && json.hash && json.note && json.note.link_note) {
                        const link_note = json.note.link_note;
                        const url = process.env.REACT_APP_URL_CLIENT + `/api/preview?hash=${json.hash}&slug=${link_note}&secret=d100b126-c2fa-4d37-94b9-a2d11c118a33`;
                        window.open(url, '_blank');
                    } else {
                        dispatch(
                            showNotification(translate("ra.notification.note_preview_error"), "error")
                        );
                    }
                })
                .catch(() => {
                    dispatch(
                        showNotification(translate("ra.notification.note_preview_error"), "error")
                    );
                })
                .finally(() => {
                    setLoading(false);
                })
        }
    }
    return (
        <Box>
            {values.id ?
                <Button
                    variant="outlined"
                    color="primary"
                    disabled={loading || pristine}
                    startIcon={loading ? <CircularProgress size={18} /> : null}
                    onClick={() => handleSavePreview()}
                >
                    {translate("components.preview")}
                </Button>
                :
                null
            }
        </Box>
    )
}
