import React, { useState, useEffect } from "react";
import {
  Datagrid,
  EmailField,
  Filter,
  List,
  TextField,
  TextInput,
  ReferenceArrayInput,
  EditButton,
  useRecordContext,
  SelectInput,
  SelectArrayInput,
  downloadCSV,
  useNotify,
} from "react-admin";
import PlanField from "./PlanField";
import FullNameField from "./FullNameField";
import { Chip } from "@material-ui/core";
import { ClubCardPanel } from "./ClubCardPanel";
import { makeStyles } from "@material-ui/core/styles";
import { ActionsCreate } from "../../components/Toolbar/ActionsCreate";
import jsonExport from "jsonexport/dist";
import { checkPermissions } from "../../utils/validations";
import { useTranslate } from "ra-core";

const useStyles = makeStyles({
  select: {
    minWidth: " 160px",
    maxWidth: "300px",
    marginTop: "40px",
  },
  chip: {
    fontWeight: 500,
  },
  active: {
    border: "2px solid #98bf17",
    background: "white",
    color: "black",
  },
  canceled: {
    border: "2px solid #b9000b",
    background: "white",
    color: "black",
  },
  expired: {
    border: "2px solid #fbbc04",
    background: "white",
    color: "black",
  },
});

const SubscriberFilter = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const renderComponent = (values) => {
    return (
      <span>
        {values?.name}
        <span style={{ fontSize: 10, color: "red" }}>
          {values?.deleted_at ? " Borrado" : ""}
        </span>
      </span>
    );
  };
  return (
    <Filter {...props}>
      <TextInput source="search" label="ra.action.search" alwaysOn />
      <ReferenceArrayInput
        label={translate("resources.plans.name")}
        source="plans_ids"
        reference="plans"
        filter={{ with_trashed: true }}
        sort={{ field: "name", order: "ASC" }}
        perPage={1000}
        allowEmpty
      >
        <SelectArrayInput
          optionText={renderComponent}
          variant="outlined"
          className={classes.select}
        />
      </ReferenceArrayInput>
      <SelectInput
        source="plan_status"
        label={translate("components.plan_status")}
        choices={[
          { id: "active", name: `${translate("resources.subscribers.export.status.active")}` },
          { id: "canceled", name: `${translate("resources.subscribers.export.status.cancelled")}` },
          { id: "expired", name: `${translate("resources.subscribers.export.status.expired")}` },
        ]}
      />
    </Filter>
  );
};

const CardCountField = () => {
  const record = useRecordContext();

  if (record.clubcards_count == 0) return null;
  return <Chip label={record.clubcards_count} color={"primary"} />;
};

const ChipStatusPlan = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const status =
    record.subscriptions && Array.isArray(record.subscriptions)
      ? record.subscriptions[0]?.status
      : null;
  const [chip, setChip] = useState();
  const classes = useStyles();
  useEffect(() => {
    if (status) {
      switch (status) {
        case "active":
          setChip({ label: `${translate("resources.subscribers.export.status.active")}`, class: status });
          break;
        case "expired":
          setChip({ label: `${translate("resources.subscribers.export.status.expired")}`, class: status });
          break;
        case "canceled":
          setChip({ label: `${translate("resources.subscribers.export.status.cancelled")}`, class: status });
          break;
        default:
          break;
      }
    }
  }, [status]);
  if (!status || !chip) return null;
  return (
    <Chip
      size="small"
      label={chip.label}
      className={`${classes.chip} ${classes[chip.class]}`}
    />
  );
};

export const MemberList = ({ permissions, ...props }) => {
  const [havePermissionMoreInfo, setHavePermissionMoreInfo] = useState(false);
  const notify = useNotify();
  useEffect(() => {
    if (checkPermissions(permissions, "subscribers-more-info")) {
      setHavePermissionMoreInfo(true);
    } else {
      setHavePermissionMoreInfo(false);
    }
  }, [permissions]); // eslint-disable-line react-hooks/exhaustive-deps

  const translate = useTranslate();
  const exporter = (values) => {
    let resp = [];
    if (values && values.length > 0) {
      values.forEach((item) => {
        const status = item.subscriptions[0]?.status;
        let obj = {};
        obj[translate("resources.subscribers.export.name")] = item.username;
        obj.Email = item.email;
        obj[translate("resources.subscribers.export.subscription")] =
          item.subscriptions?.length > 0 ? item.subscriptions[0].plan.name : "";
        obj[translate("resources.subscribers.export.status_subscription")] =
          status == "active"
            ? `${translate("resources.subscribers.export.status.active")}`
            : status == "expired"
              ? `${translate("resources.subscribers.export.status.expired")}`
              : `${translate("resources.subscribers.export.status.cancelled")}`;
        obj[`${translate("components.last_update")}`] = new Date(
          item.subscriptions[0].updated_at
        ).toLocaleDateString();
        resp.push(obj);
      });
    }
    if (resp && resp?.length > 0) {
      let respErr = "";
      let respCsv;
      jsonExport(resp, function (err, csv) {
        if (err) respErr = err;
        respCsv = csv;
      });
      if (respCsv && respErr === "") {
        downloadCSV(respCsv, `subscribers_${new Date().toLocaleDateString()}`);
      } else {
        notify("resources.subsribers.errors.export", "warning");
      }
    }
  };
  return (
    <List
      filters={<SubscriberFilter />}
      actions={
        <ActionsCreate
          exportButton={true}
          exporter={exporter}
          filter={{ has_suscription: true }}
          resource="subscribers"
          label={`${translate("resources.subscribers.new")}`}
        />
      }
      {...props}
      filter={{ has_suscription: true }}
      resource="subscribers"
      sort={{ field: "first_name", order: "ASC" }}
      bulkActionButtons={false}
      title=" "
    >
      {
        <Datagrid
          expand={
            havePermissionMoreInfo ? (
              <ClubCardPanel permissions={permissions} />
            ) : null
          }
          rowClick={
            havePermissionMoreInfo
              ? "expand"
              : checkPermissions(permissions, "edit-subscribers")
                ? "edit"
                : ""
          }
        >
          <TextField source="id" />
          <FullNameField
            sortBy="first_name"
            source="username"
            label={translate("resources.subscribers.fields.username")}
          />
          <EmailField source="email" />
          <PlanField label={translate("resources.subscribers.fields.roles")} source="subscriptions" sortable={false} />
          <CardCountField label={translate("resources.subscribers.fields.cards_asoc")} sortable={false} />
          <ChipStatusPlan label={translate("components.plan_status")} sortable={false} />
          {checkPermissions(permissions, "edit-subscribers") && (
            <EditButton record={props.record} />
          )}
        </Datagrid>
      }
    </List>
  );
};
