import React from 'react';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import { useState, useEffect } from 'react'
import { useQueryWithStore, useInput, useTranslate } from 'react-admin';
import ListSubheader from '@material-ui/core/ListSubheader';
import { VariableSizeList } from 'react-window';
import { LinearProgress } from '@material-ui/core';

const CustomAutocomplete = withStyles({
    root: {
        marginBottom: "16px"
    },
    inputRoot: {
        '&[class*="MuiFilledInput-root"]': {
            paddingTop: "24px",
        }
    }
})(Autocomplete);

function renderRow(props) {
    const { data, index, style } = props;
    return React.cloneElement(data[index], {
        style: {
            ...style,
            top: style.top,
        },
    });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});


const TagsField = ({ source, record = {}, ...props }) => {

    const translate = useTranslate();
    const [values, setValues] = useState();
    const {
        input,
        meta: { touched, error },
        isRequired
    } = useInput(props);

    const { loaded, data: listTags } = useQueryWithStore({
        type: 'getList',
        resource: 'tags',
        payload: {
            sort: {},
            pagination: { page: 1, perPage: 100000 },
        },
    });

    useEffect(() => {
        if (listTags) {
            if (record[source]) {
                const arr = listTags.filter(item => record[source].includes(item.id));
                setValues(arr);
            } else {
                setValues([]);
            }
        }
    }, [listTags, record[source]]); // eslint-disable-line react-hooks/exhaustive-deps


    function useResetCache(data) {
        const ref = React.useRef(null);
        React.useEffect(() => {
            if (ref.current != null) {
                ref.current.resetAfterIndex(0, true);
            }
        }, [data]);
        return ref;
    }

    // Adapter for react-window
    const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
        const { children, ...other } = props;
        const itemData = React.Children.toArray(children);
        const itemCount = itemData.length;
        const itemSize = 36;

        const getChildSize = (child) => {
            if (React.isValidElement(child) && child.type === ListSubheader) {
                return 48;
            }

            return itemSize;
        };

        const getHeight = () => {
            if (itemCount > 8) {
                return 8 * itemSize;
            }
            return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
        };

        const gridRef = useResetCache(itemCount);

        return (
            <div ref={ref}>
                <OuterElementContext.Provider value={other}>
                    <VariableSizeList
                        itemData={itemData}
                        height={getHeight() + 10}
                        width="100%"
                        ref={gridRef}
                        outerElementType={OuterElementType}
                        innerElementType="ul"
                        itemSize={(index) => getChildSize(itemData[index])}
                        overscanCount={5}
                        itemCount={itemCount}
                    >
                        {renderRow}
                    </VariableSizeList>
                </OuterElementContext.Provider>
            </div>
        );
    });

    if (!loaded || !values) return <div style={{ marginBottom: "16px", marginTop: "16px" }}>{translate("components.loading_tags")}<LinearProgress /></div>;

    return (
        <CustomAutocomplete
            multiple
            value={values}
            loading={loaded}
            ListboxComponent={ListboxComponent}
            required={isRequired}
            onChange={(event, newValue) => {
                setValues([
                    ...newValue.filter((option) => listTags.indexOf(option) !== -1),
                ]);
                const newvalueinput = newValue.map(function (elem) { return elem.id; });
                input.onChange(newvalueinput);
            }}
            options={listTags}
            getOptionLabel={(option) => option.name}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip variant="outlined" label={option.name} {...getTagProps({ index })} />
                ))
            }
            renderInput={(params) => (
                <TextField {...params}
                    variant="filled"
                    label={translate('resources.tags.name')}
                    error={!!(touched && error)}
                    required={isRequired}
                    helperText={touched && translate(error)} />
            )}
        />
    );
}

export default TagsField;