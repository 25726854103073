import { makeStyles } from '@material-ui/core/styles';
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import { useTranslate } from 'ra-core';

const useTypeContentStyles = makeStyles({
    chipF: { fontSize: "12px", margin: "2px", backgroundColor: "sandybrown" },
    chipPR: { fontSize: "12px", margin: "2px", backgroundColor: "gold" },
    chipPA: { fontSize: "12px", margin: "2px", backgroundColor: "#e0e0e0" },
});

const TypeContentField = ({ record }) => {
    const classes = useTypeContentStyles();
    const translate = useTranslate();
    return record ? (
        <span style={{ whiteSpace: "nowrap" }}>
            {record.content && (
                <Tooltip title={translate("components.free_content")} aria-label={translate("components.free_content")}>
                    <Chip size="small" className={classes.chipF} label={"F"} />
                </Tooltip>
            )}
            {record.content_premium && (
                <Tooltip title={translate("components.premium_content")} aria-label={translate("components.premium_content")}>
                    <Chip size="small" className={classes.chipPR} label={"PR"} />
                </Tooltip>
            )}
            {record.content_paper && (
                <Tooltip title={translate("components.paper_content")} aria-label={translate("components.paper_content")}>
                    <Chip size="small" className={classes.chipPA} label={"PA"} />
                </Tooltip>
            )}
        </span>
    ) : null;
};

export default TypeContentField;
