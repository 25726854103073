import { required, useTranslate } from "ra-core";
import { useEffect, useState } from "react";
import { TextInput } from "react-admin";
import { useField } from "react-final-form";

const DescriptionField = ({ source, label, record = {}, className }) => {
  const [valuesArea, setValuesArea] = useState(record[source]);
  const { input } = useField(source);
  const CHARACTER_LIMIT = 1000;
  const translate = useTranslate();

  const handleChangeTextArea = (e) => {
    setValuesArea(e.target.value);
    input.onChange(e.target.value);
  };

  useEffect(() => {
    setValuesArea(record[source]);
  }, [record[source]]); // eslint-disable-line react-hooks/exhaustive-deps

  const maxLength =
    (max, message = `${translate("components.description_too_long")}`) =>
    (value) =>
      value && value.length > max
        ? `${translate("components.chars")}: ${
            valuesArea ? valuesArea.length : 0
          }/${CHARACTER_LIMIT} - ${message}`
        : undefined;

  return (
    <TextInput
      source={source}
      label={label}
      multiline
      minRows={5}
      initialvalue={valuesArea ? valuesArea : undefined}
      onChange={(e) => handleChangeTextArea(e)}
      helperText={`${translate("components.chars")}: ${
        valuesArea ? valuesArea.length : 0
      }/${CHARACTER_LIMIT}`}
      validate={[required(), maxLength(CHARACTER_LIMIT)]}
      className={className}
    />
  );
};

export default DescriptionField;
