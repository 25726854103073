import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextInput, Button, required } from 'react-admin';
import { Typography } from '@material-ui/core';
import { useField } from 'react-final-form';
import IconContentAdd from '@material-ui/icons/Add';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { useTranslate} from "ra-core";
const useStyles = makeStyles({
    flex: {
        display: 'flex',
        alignItems: 'center'
    },
    hidden: {
        display: 'none'
    },
});

export const AudioNoteField = ({ record }) => {
    const [audioNote, setAudioNote] = useState('');
    const classes = useStyles();
    const [customEvent, setCustomEvent] = useState();
    const { input: input_meta_note } = useField('audio_note');
    const translate = useTranslate();
    useEffect(() => {
        if (input_meta_note.value) {
            setAudioNote(input_meta_note.value);
        } else if (record && record.format === "audio") {
            setAudioNote(record.meta_note);
        }
    }, [record]); // eslint-disable-line react-hooks/exhaustive-deps


    const handleCallback = (event) => {
        const { editorId, audio } = event.detail;
        if (editorId === 'audioNote') {
            setAudioNote(audio.audioUrl);
            input_meta_note.onChange(audio.audioUrl);
        }
    };

    const handleClick = () => {
        window.addEventListener('selected-audio-audiopicker', handleCallback);
        window.dispatchEvent(customEvent);
    };

    useEffect(() => {
        setCustomEvent(new CustomEvent('open-audiopicker', {
            detail: {
                editor: 'audioNote'
            },
            bubbles: false,
            cancelable: true,
            composed: false,
        }));
        return () => {
            setCustomEvent(null);
        }
    }, [])

    return (
        <>
            <div className="d-flex">
                <Typography variant="h6" gutterBottom>
                    {translate("components.audio")}
                </Typography>
            </div>
            <div className={classes.container} style={{ position: 'relative' }}>
                {audioNote && audioNote !== '' ?
                    <Button onClick={handleClick} label={translate("components.update")}
                        style={{ position: 'absolute', right: '12px', top: '-37px', zIndex: 2, color: 'rgb(31, 58, 79)', background: 'rgba(255, 255, 255, 0.8)', boxShadow: '1px 1px 3px 1px #0000002b' }}>
                        <AutorenewIcon />
                    </Button>
                    :
                    <Button onClick={handleClick} label={translate("components.add")}
                        style={{ position: 'absolute', right: '12px', top: '-42px', zIndex: 2, color: 'rgb(31, 58, 79)', background: 'rgba(255, 255, 255, 0.8)', boxShadow: '1px 1px 3px 1px #0000002b' }}>
                        <IconContentAdd />
                    </Button>
                }
                {audioNote ?
                    <audio src={audioNote} controls controlsList="nodownload" style={{ width: '100%', marginTop: '12px', marginBottom: '18px' }}>
                        <source src={audioNote} type="audio/ogg" />
                    </audio>
                    :
                    <div style={{ width: '100%', marginTop: '12px', marginBottom: '18px', height: '54px' }}></div>
                }
            </div>
            <div className={classes.hidden}>
                <TextInput
                    id="audio_note"
                    source="audio_note"
                    label={translate("components.audio")+" "+translate("components.note")}
                    validate={required()}
                    fullWidth
                />
            </div>

        </>
    );
};