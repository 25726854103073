import React from "react";
import { useTranslate } from "react-admin";
import {
  Input,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
} from "@material-ui/core";

const HeroPosition = ({ value, zoneIndex, handleChangeField }) => {
  const translate = useTranslate();
  return (
    <Box sx={{ width: { xs: "160px", md: "250px" }, marginRight: "16px" }}>
      <FormControl fullWidth>
        <InputLabel id="component-position-hero">
          {translate("resources.pages.fields.position")}
        </InputLabel>
        <Select
          labelId="component-position-hero"
          fullWidth
          input={<Input />}
          onChange={(e) =>
            handleChangeField(e.target.value, "position_hero", zoneIndex)
          }
          value={value}
        >
          <MenuItem value={"center"}>
            {translate("resources.pages.fields.position_center")}
          </MenuItem>
          <MenuItem value={"top"}>
            {translate("resources.pages.fields.position_top")}
          </MenuItem>
          <MenuItem value={"bottom"}>
            {translate("resources.pages.fields.position_bottom")}
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};
export default React.memo(HeroPosition, (prev, next) => prev.value === next.value && prev.zoneIndex === next.zoneIndex);
