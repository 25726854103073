import * as React from "react";
import Item from "./Item";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

const List = React.memo(
  ({
    items,
    onDragEnd,
    zone,
    handleCallbackUpdateComponent,
    listCategories,
    deleteComponent,
  }) => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={`zone-draggable`}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {items.map((item, index) => (
                <Item
                  item={item}
                  key={item?.uuid || index}
                  zone={zone}
                  handleCallbackUpdateComponent={(componentData) =>
                    handleCallbackUpdateComponent(index, componentData)
                  }
                  uuid={item?.uuid || index}
                  index={index}
                  listCategories={listCategories}
                  deleteComponent={() => deleteComponent(index)}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
);

export default List;
