import React, { useState, useEffect } from "react";
import { useTranslate } from "react-admin";
import { FormControl, Box } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

const SelectCount = ({ value, zoneIndex, handleChangeField }) => {
  const [error, setError] = useState(false);
  const [state, setState] = useState(value);
  const translate = useTranslate();

  useEffect(() => {
    const timeOutId = setTimeout(
      () => state !== value && handleChangeField(state, "count", zoneIndex),
      1000
    );
    return () => clearTimeout(timeOutId);
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box >
      <FormControl fullWidth>
        <TextField
          id={"TextField-component-" + zoneIndex}
          margin="dense"
          variant="filled"
          helperText={error || ""}
          error={!!error}
          label={translate("resources.pages.fields.count_section")}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          style={{ minWidth: "280px", marginBottom: 0 }}
          value={state}
          type={"number"}
          onChange={(e) => {
            setError(false);
            const value = e.target.value;
            if (!value) {
              setError("Debe ingresar un número");
            } else if (!value || value < 1) {
              setError("El número debe ser mayor a cero");
            } else if (!value || value > 10) {
              setError("El número debe ser menor o igual a díez");
            } else {
              setState(e.target.value);
            }
          }}
        />
      </FormControl>
    </Box>
  );
};

export default React.memo(
  SelectCount,
  (prev, next) => prev.value === next.value && prev.zoneIndex === next.zoneIndex
);
