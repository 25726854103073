import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { colors } from "Static/blocks/ColorOption";
import React, { useEffect, useState } from "react";
import { useTranslate } from "react-admin";
import { ImageSelect } from "../fields/ImageSelect";
import ListDnd from "./ListDnd";
import SelectCategories from "./SelectCategories";

const useStyles = makeStyles((theme) =>
  createStyles({
    chip: {
      marginLeft: "10px",
      width: "20px",
      height: "20px",
      borderRadius: "50%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightBold,
    },
    selectInput: {
      width: "auto",
      minWidth: "250px",
      marginRight: "30px",
      marginBottom: "24px",
    },
    inputLabel: {
      width: "auto",
      minWidth: "250px",
      marginRight: "30px",
    },
    textField: {
      width: "auto",
      minWidth: "250px",
      marginRight: "30px",
      marginBottom: "24px",
    },
    selectNotes: {
      flex: 1,
    },
  })
);

const ColorField = ({ value, name }) => {
  const classes = useStyles();
  return (
    <Chip
      variant="outlined"
      label={name || "Default"}
      icon={
        <span
          className={classes.chip}
          style={{
            background: value || "white",
          }}
        ></span>
      }
    />
  );
};

const GrillaForm = ({ handleCallback, componentData, listCategories }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const [listNotes, setListNotes] = useState();
  const [title, setTitle] = useState(componentData.title ?? "");
  const [title_image_link, setTitleImageLink] = useState(
    componentData.title_image_link ?? ""
  );
  const [localComponent, setLocalComponent] = useState({ ...componentData });

  const FONTCOLOR = [
    { id: "black", name: `${translate("components.black")}` },
    { id: "white", name: `${translate("components.white")}` },
  ];

  const handleChangeField = (value, field) => {
    const newComponent = { ...localComponent };
    newComponent[field] = value;
    setLocalComponent(newComponent);
    handleCallback(newComponent);
  };

  const handleChangeListNote = (list) => {
    if (list && list.length > 0) {
      const newComponent = { ...localComponent };
      newComponent.notes = [];
      list.forEach((elem) => {
        newComponent.notes.push(elem.id ? { ...elem } : "");
      });
      handleCallback(newComponent);
    }
  };

  useEffect(() => {
    const list = [];
    if (localComponent.notes) {
      localComponent.notes.forEach((elem) => {
        list.push(elem ? { ...elem } : {});
      });
    }
    setListNotes(list);
  }, [localComponent.notes]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const timeOutId = setTimeout(
      () => title !== localComponent.title && handleChangeField(title, "title"),
      800
    );
    return () => clearTimeout(timeOutId);
  }, [title]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        title_image_link !== localComponent.title_image_link &&
        handleChangeField(title_image_link, "title_image_link"),
      800
    );
    return () => clearTimeout(timeOutId);
  }, [title_image_link]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    componentData && (
      <Box width="100%">
        <Box display="flex" justifyContent="space-between">
          <Box mr="1em" display="flex" flexDirection="column">
            <TextField
              label={translate("components.title_section")}
              margin="dense"
              variant="filled"
              className={classes.textField}
              value={title || ""}
              onChange={(e) => setTitle(e.target.value)}
            />

            <Box
              mb="1em"
              display="flex"
              flexDirection="column"
              style={{
                border: "1px solid #e6e6e6",
                display: "flex",
                padding: "12px",
                borderRadius: "4px",
                backgroundColor: "#f5f5f5",
              }}
            >
              <Typography variant="caption" style={{ fontWeight: 600 }}>
                {translate("resources.pages.fields.sponsor")}
              </Typography>
              <Box mr="1em" display="flex" flexDirection="column">
                <ImageSelect
                  componentData={localComponent}
                  hiddeLabel={true}
                  imgSize={{ imgWidth: "180px", imgHeight: "60px" }}
                  handleSelected={(value) =>
                    handleChangeField(value, "title_image")
                  }
                  field={"title_image"}
                />
                <Typography variant="caption">
                  {translate("resources.pages.fields.helper_title_image")}
                </Typography>
              </Box>
              <TextField
                margin="dense"
                variant="filled"
                className={classes.textField}
                value={title_image_link || ""}
                onChange={(e) => setTitleImageLink(e.target.value)}
                label={translate("resources.pages.fields.link_sponsor")}
              />
            </Box>

            {listCategories.length > 0 ? (
              <SelectCategories
                listCategories={listCategories}
                value={localComponent.category || ""}
                handleChangeField={handleChangeField}
              />
            ) : (
              <div className={classes.selectInput}>
                <LinearProgress />
              </div>
            )}

            <FormControl variant="filled">
              <InputLabel className={classes.inputLabel}>
                {translate("components.background")}
              </InputLabel>
              <Select
                onChange={(e) => {
                  handleChangeField(e.target.value, "background");
                }}
                fullWidth
                className={classes.selectInput}
                value={localComponent.background || ""}
              >
                <MenuItem value="">Default</MenuItem>
                {colors.map((color, index) => (
                  <MenuItem key={index} value={color.name}>
                    <ColorField value={color.value} name={color.title} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="filled">
              <InputLabel className={classes.inputLabel}>
                {translate("components.font_color")}
              </InputLabel>
              <Select
                onChange={(e) => {
                  handleChangeField(e.target.value, "fontcolor");
                }}
                fullWidth
                className={classes.selectInput}
                value={localComponent.fontcolor || ""}
              >
                <MenuItem value="">Default</MenuItem>
                {FONTCOLOR.map((color, index) => (
                  <MenuItem key={index} value={color.id}>
                    {translate(`components.${color.id}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <div className={classes.selectNotes}>
            <Typography className={classes.heading}>
              {translate("components.notes")}
            </Typography>
            {listNotes && (
              <ListDnd
                listNotes={listNotes}
                handleChangeListNote={handleChangeListNote}
              />
            )}
          </div>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <FormControlLabel
            control={
              <Switch
                checked={
                  localComponent.show_tags === undefined
                    ? false
                    : !!localComponent.show_tags || false
                }
                onChange={(e) =>
                  handleChangeField(e.target.checked, "show_tags")
                }
                color="primary"
              />
            }
            label={translate("resources.pages.fields.show_section_tag")}
            labelPlacement="start"
          />
        </Box>
      </Box>
    )
  );
};

export default GrillaForm;
