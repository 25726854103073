import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useTranslate} from "ra-core";

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      width: "auto",
      minWidth: "280px",
      maxWidth: "500px",
      marginRight: "30px",
      marginBottom: "24px",
      "&.full": {
        width: "100%",
      },
    },
    titleField: {
      minWidth: "380px",
    },
    selectInput: {
      width: "auto",
      minWidth: "160px",
      marginRight: "30px",
      marginBottom: "24px",
    },
    numberField: {
      width: "auto",
      minWidth: "250px",
      marginRight: "30px",
      marginBottom: "24px",

      /* Chrome, Safari, Edge, Opera */
      "& input::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: "0",
      },
      "& input::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: "0",
      },

      /* Firefox */
      "& input[type=number]": {
        "-moz-appearance": "textfield",
      },
    },
  })
);

const WhatsAppForm = ({ handleCallback, componentData }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [state, setState] = useState(
    componentData.mode ? componentData : { ...componentData, mode: "chat" }
  );

  const handleChange = () => {
    componentData = state;
    handleCallback(componentData);
  };

  useEffect(() => {
    const timeOutId = setTimeout(
      () => state !== componentData.phoneNumber && handleChange(),
      1000
    );
    return () => clearTimeout(timeOutId);
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        <TextField
          id={"TextField-title-whatsapp"}
          label={translate("components.title_section")}
          margin="dense"
          variant="filled"
          autoComplete="off"
          value={state?.title || ""}
          className={`${classes.textField} ${classes.titleField}`}
          onChange={(e) =>
            setState((prev) => ({ ...prev, title: e.target.value }))
          }
        />
        {state && state.subtitle !== undefined && (
          <TextField
            id={"TextField-subtitle-whatsapp"}
            label={translate("components.subtitle_section")}
            margin="dense"
            variant="filled"
            autoComplete="off"
            value={state?.subtitle || ""}
            className={`${classes.textField} ${classes.titleField}`}
            onChange={(e) =>
              setState((prev) => ({ ...prev, subtitle: e.target.value }))
            }
          />
        )}
        <TextField
          id={"TextField-btnTxt-whatsapp"}
          label={translate("components.button_text")}
          margin="dense"
          variant="filled"
          autoComplete="off"
          value={state?.btnTxt || ""}
          className={classes.textField}
          onChange={(e) =>
            setState((prev) => ({ ...prev, btnTxt: e.target.value }))
          }
        />
      </Box>
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        <FormControl variant="filled">
          <InputLabel>{translate("components.modality")}</InputLabel>
          <Select
            className={classes.selectInput}
            value={state.mode ?? "chat"}
            onChange={(e) =>
              setState((prev) => ({ ...prev, mode: e.target.value }))
            }
            style={{ minWidth: "160px" }}
          >
            <MenuItem value="chat">{translate("components.modalities.chat")}</MenuItem>
            <MenuItem value="canal">{translate("components.modalities.channel")}</MenuItem>
          </Select>
        </FormControl>

        {state.mode === "chat" ? (
          <>
            <TextField
              id={"component-phoneNumber"}
              type="number"
              label={translate("components.whatsapp_number")}
              margin="dense"
              variant="filled"
              autoComplete="off"
              className={classes.numberField}
              value={state?.phoneNumber || ""}
              onChange={(e) =>
                setState((prev) => ({ ...prev, phoneNumber: e.target.value }))
              }
            />
            <TextField
              id={"TextField-text-whatsapp"}
              label={translate("components.whatsapp_text")}
              margin="dense"
              variant="filled"
              autoComplete="off"
              value={state?.text || ""}
              className={`${classes.textField} full`}
              onChange={(e) =>
                setState((prev) => ({ ...prev, text: e.target.value }))
              }
            />
          </>
        ) : state.mode === "canal" ? (
          <TextField
            id={"component-link-canal-whatsapp"}
            label={translate("components.whatsapp_link_channel")}
            margin="dense"
            variant="filled"
            autoComplete="off"
            value={state.link_canal || ""}
            className={`${classes.textField} full`}
            onChange={(e) =>
              setState((prev) => ({ ...prev, link_canal: e.target.value }))
            }
          />
        ) : null}
      </Box>
    </>
  );
};

export default WhatsAppForm;
