import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import LockIcon from "@material-ui/icons/Lock";
import { useTranslate } from "ra-core";
import React, { Children, cloneElement } from "react";
import {
  BooleanInput,
  BulkDeleteButton,
  DateField,
  DateInput,
  EditButton,
  Filter,
  FunctionField,
  List,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  TextField,
  TextInput,
  useGetIdentity,
} from "react-admin";
import CustomizableDatagrid from "../../components/CustomizableDataGrid/CustomizableDatagrid";
import { showResource } from "../../providers/configProvider";
import BulkSelectButton from "../../utils/BulkSelectButton";
import { CopyToPaper } from "./CopyToPaper";
import NotesActions from "./NotesActions";
import ResetNotesButton from "./ResetNotesButton";
import ShareButton from "./ShareButton";
import { ShowNote } from "./ShowNote";
import TypeContentField from "./TypeContentField";
import { NOTES_LOCK_LEAVE } from "./edit";
import exporter from "./exporter";
import rowStyle from "./rowStyle";

const useStyles = makeStyles({
  select: {
    minWidth: " 160px",
    maxWidth: "300px",
    marginTop: "40px",
  },
});
const NotesFilter = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  return (
    <Filter {...props}>
      <TextInput source="search" alwaysOn />
      <BooleanInput
        alwaysOn
        label="resources.notes.fields.deleted_at"
        source="deleted_at"
      />
      <ReferenceInput
        label="components.category"
        source="category_id"
        sort={{ field: "name", order: "ASC" }}
        perPage={300}
        reference="categories"
        allowEmpty
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        label="resources.notes.fields.creator_id"
        source="creator_id"
        reference="journalists"
        sort={{ field: "first_name", order: "ASC" }}
        perPage={1000}
        allowEmpty
      >
        <SelectInput optionText="username" />
      </ReferenceInput>
      <ReferenceInput
        label="resources.notes.fields.author_id"
        source="author_id"
        reference="authors"
        sort={{ field: "first_name", order: "ASC" }}
        perPage={2000}
        allowEmpty
      >
        <SelectInput optionText="display_name" />
      </ReferenceInput>
      <SelectInput
        label="resources.notes.fields.status"
        source="status"
        defaultValue="draft"
        fullWidth
        choices={[
          {
            id: "draft",
            name: `${translate("resources.notes.status.draft")}`,
          },
          {
            id: "publish",
            name: `${translate("resources.notes.status.published")}`,
          },
          {
            id: "scheduled",
            name: `${translate("resources.notes.status.scheduled")}`,
          },
        ]}
      />
      {showResource("paper") && (
        <NumberInput
          source="page_paper"
          label="resources.notes.fields.page_paper"
          max={20}
          min={0}
          step={1}
          fullWidth
        />
      )}
      <DateInput
        source="date_publish"
        label="resources.notes.fields.date_web"
        fullWidth
      />
      {showResource("paper") && (
        <DateInput
          source="date_publish_paper"
          label="resources.notes.fields.date_publish_paper_short"
          fullWidth
        />
      )}
      {showResource("paper") && (
        <SelectArrayInput
          source="content_type"
          label="resources.notes.fields.type_content"
          variant="outlined"
          className={classes.select}
          choices={[
            { id: "premium", name: `${translate("components.premium")}` },
            { id: "free", name: `${translate("components.free")}` },
            { id: "paper", name: `${translate("components.paper")}` },
          ]}
        />
      )}
      <SelectInput
        source="corrected"
        label="resources.notes.fields.corrected"
        className={classes.select}
        choices={[
          {
            id: "dont-corrected",
            name: `${translate("resources.notes.corrected.dont_corrected")}`,
          },
          {
            id: "corrected",
            name: `${translate("resources.notes.corrected.corrected")}`,
          },
        ]}
      ></SelectInput>
    </Filter>
  );
};

const NoteBulkActionButtons = (props) => {
  const translate = useTranslate();

  if (props.filterValues.deleted_at === true) {
    return (
      <ResetNotesButton
        label={`${translate("ra.action.restore")}`}
        {...props}
      />
    );
  }
  const STATUS = [
    { id: "draft", name: `${translate("resources.notes.status.draft")}` },
    { id: "publish", name: `${translate("resources.notes.status.publish")}` },
    {
      id: "scheduled",
      name: `${translate("resources.notes.status.scheduled")}`,
    },
  ];

  return (
    <>
      <BulkSelectButton
        source={"status"}
        resource={"notes"}
        options={STATUS}
        {...props}
      />
      <BulkSelectButton
        source={"category_id"}
        resource={"notes"}
        type={"reference"}
        reference={"categories"}
        {...props}
      />
      {/* <BulkSelectButton source={"author_id"} resource={"notes"} type={'reference'} reference={"authors"} sort={'first_name'} name={'display_name'} {...props} /> */}
      <BulkDeleteButton {...props} />
    </>
  );
};

const StatusField = ({ record }) => {
  const translate = useTranslate();

  return record ? (
    <span>
      {record.status && record.status === "publish"
        ? `${translate("resources.notes.status.published")}`
        : record.status === "draft"
        ? `${translate("resources.notes.status.draft")}`
        : record.status === "scheduled"
        ? `${translate("resources.notes.status.scheduled")}`
        : record.status === "deleted"
        ? `${translate("resources.notes.status.deleted")}`
        : ""}
    </span>
  ) : null;
};

const EditButtonOrLocked = (props) => {
  const translate = useTranslate();

  if (!props.record || !props.permissions) return null;
  if (
    props.permissions.some(
      (permission) =>
        permission.name === "*" || permission.name === "edit-notes"
    )
  ) {
    if (
      props.record.locked_at &&
      Math.abs((new Date() - new Date(props.record.locked_at)) / 1000) <
        NOTES_LOCK_LEAVE
    ) {
      if (props.identity) {
        if (props.identity.id === props.record.locked_by) {
          return (
            <>
              <EditButton {...props} />
              <span
                style={{
                  display: "inline-flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "#a4a19f",
                }}
              >
                <LockIcon />
                <span>{translate("components.possession")}</span>
              </span>
            </>
          );
        } else if (props.identity.email === "info@tuxdi.com") {
          return (
            <>
              <EditButton {...props} />
              <span
                style={{
                  display: "inline-flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "#a4a19f",
                }}
              >
                <span>{translate("components.editing")}</span>
                <span
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  {props.record.locked_user &&
                    `(${props.record.locked_user.username})`}
                </span>
              </span>
            </>
          );
        }
      }
      return (
        <span
          style={{
            display: "inline-flex",
            flexDirection: "column",
            alignItems: "center",
            color: "#a4a19f",
          }}
        >
          <LockIcon />
          <span>{translate("components.locked")}</span>
          <span
            style={{
              whiteSpace: "nowrap",
            }}
          >
            {props.record.locked_user &&
              `(${props.record.locked_user.username})`}
          </span>
        </span>
      );
    }
    return (
      <Tooltip title={`${translate("ra.action.edit")}`}>
        <div>
          <EditButton {...props} />
        </div>
      </Tooltip>
    );
  }
};

const useListStyles = makeStyles({
  title: {
    "& span": {
      display: "-webkit-box",
      "-webkit-line-clamp": "2",
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    fontWeight: "600",
    textAlign: "right",
  },
  compoundColumn: {
    maxWidth: "600px",
    minWidth: "220px",
  },
  customDatagrid: {
    position: "relative",
    "& .MuiTableCell-sizeSmall": {
      padding: "6px 12px",
    },
    "& .MuiTableCell-head": {
      padding: "6px 12px",
      height: "48px",
    },
    "& .MuiButton-root": {
      minWidth: "40px",
    },
  },
});

const usePostListActionToolbarStyles = makeStyles({
  toolbar: {
    alignItems: "center",
    display: "flex",
  },
});

const NotesListActionToolbar = ({ children, ...props }) => {
  const classes = usePostListActionToolbarStyles();

  return (
    <div className={classes.toolbar}>
      {Children.map(
        children,
        (button) => button && cloneElement(button, props)
      )}
    </div>
  );
};

export const NotesList = ({ permissions, selectedRow, ...props }) => {
  const classes = useListStyles();
  const { identity } = useGetIdentity();
  const translate = useTranslate();

  return (
    <List
      bulkActionButtons={<NoteBulkActionButtons />}
      filters={<NotesFilter />}
      actions={<NotesActions permissions={permissions} />}
      exporter={exporter}
      sort={{ field: "date_publish", order: "DESC" }}
      {...props}
      title=" "
    >
      <CustomizableDatagrid
        rowStyle={rowStyle(selectedRow)}
        className={classes.customDatagrid}
      >
        <FunctionField
          label={`${translate("resources.notes.fields.title")}`}
          className={classes.compoundColumn}
          sortBy={"title"}
          render={(record) => {
            return (
              <>
                <TextField className={classes.title} source="title" />
                <div>
                  <div>
                    {translate("components.created_by")}&nbsp;
                    <ReferenceField source="creator_id" reference="journalists">
                      <TextField source="username" />
                    </ReferenceField>
                  </div>
                  {record && record.status !== "scheduled" ? (
                    <div>
                      {translate("components.last_update")}&nbsp;
                      <DateField
                        source="updated_at"
                        showTime
                        locales={translate("components.locale_language")}
                        options={{
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <AccessAlarmIcon style={{ fontSize: "1rem" }} />
                      &nbsp;{translate("resources.notes.status.scheduled")}
                      &nbsp;
                      <DateField
                        source="date_publish"
                        showTime
                        locales={translate("components.locale_language")}
                        options={{
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                        }}
                      />
                    </div>
                  )}
                </div>
              </>
            );
          }}
        />
        <DateField
          label="resources.notes.fields.date_publish"
          source="date_publish"
          options={{ timeZone: "UTC" }}
        />
        {showResource("paper") ? (
          <DateField
            label="resources.notes.fields.date_publish_paper_short"
            source="date_publish_paper"
            options={{ timeZone: "UTC" }}
          />
        ) : null}
        <StatusField source="status" label="resources.notes.fields.status" />
        <ReferenceField
          label="resources.notes.fields.category_id"
          source="category_id"
          reference="categories"
        >
          <TextField source="name" />
        </ReferenceField>
        {showResource("paper") ? (
          <TextField
            source="page_paper"
            label="resources.notes.fields.page_paper"
          />
        ) : null}
        {showResource("paper") ? (
          <TextField
            source="section_paper"
            label="resources.notes.fields.section_paper"
          />
        ) : null}
        {showResource("paper") ? (
          <TypeContentField
            sortable={false}
            source="type_content"
            label={translate("resources.notes.fields.type_content")}
          />
        ) : null}
        <NotesListActionToolbar>
          <ShowNote record={props.record} permissions={permissions} />
          {permissions &&
            permissions.some(
              (permission) =>
                permission.name === "*" || permission.name === "share-notes"
            ) && <ShareButton />}
          {permissions &&
            showResource("paper") &&
            permissions.some(
              (permission) =>
                permission.name === "*" ||
                permission.name === "download-edition-paper"
            ) && (
              <CopyToPaper record={props.record} permissions={permissions} />
            )}
          {permissions &&
            permissions.some(
              (permission) =>
                permission.name === "*" || permission.name === "edit-notes"
            ) && (
              <EditButtonOrLocked
                label=""
                identity={identity}
                permissions={permissions}
              />
            )}
        </NotesListActionToolbar>
      </CustomizableDatagrid>
    </List>
  );
};
