import React, { useState } from "react";
import {
  Edit,
  FormWithRedirect,
  TextInput,
  required,
  SaveButton,
  ImageField,
  DateInput,
  ReferenceInput,
  SelectInput,
  DeleteButton,
  useDataProvider,
  LoadingPage,
} from "react-admin";
import classnames from "classnames";
import { FormDataConsumer, useTranslate } from "ra-core";
import { Box, Toolbar, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CreateCategory } from "./CreateCategoryDialog";
import { EditCategory } from "./EditCategoryDialog";
import EditIcon from "@material-ui/icons/Edit";
import { useFormState } from "react-final-form";

export const openPdf = (e, setLoading) => {
  e.preventDefault();
  const fileRef = e.target.href;
  const token = localStorage.getItem("token");
  setLoading(true);
  fetch(fileRef, {
    method: "GET",
    headers: new Headers({
      Authorization: "Bearer " + token,
    }),
  })
    .then((response) => response.blob())
    .then((blob) => {
      setLoading(false);
      const file = new Blob([blob], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      // Open new windows and show PDF
      window.open(fileURL);
    })
    .catch(function (e) {
      console.log(e);
      setLoading(false);
    });
};

const FileNewspaperField = ({ source_file, source_title }) => {
  const [loading, setLoading] = React.useState(false);
  return (
    <div>
      <a
        href={source_file}
        title={source_title}
        onClick={(e) => {
          openPdf(e, setLoading);
        }}
      >
        {source_title}
      </a>
      {loading && <p>Cargado...</p>}
    </div>
  );
};

const useStyles = makeStyles(
  (theme) => ({
    toolbar: {
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[900],
    },
    desktopToolbar: {
      marginTop: theme.spacing(2),
    },
    mobileToolbar: {
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      padding: "16px",
      width: "100%",
      boxSizing: "border-box",
      flexShrink: 0,
      zIndex: 2,
    },
    defaultToolbar: {
      flex: 1,
      display: "flex",
      justifyContent: "space-between",
    },
    spacer: {
      [theme.breakpoints.down("xs")]: {
        height: "5em",
      },
    },
    imgPreview: {
      width: "initial",
      minWidth: "initial",
      maxWidth: "42rem",
      maxHeight: "15rem",
      border: "1px solid",
    },
  }),
  { name: "RaToolbar" }
);

const BtnEditCategory = ({ categories }) => {
  const { values } = useFormState();
  const [open, setOpen] = useState(false);
  const translate = useTranslate();
  if (!(values?.category_id) || !categories) return null;
  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<EditIcon />}
        onClick={() => setOpen(true)}
      >
        {translate("ra.action.edit")+" "+translate("components.category")}
      </Button>
      <EditCategory
        open={open}
        setOpen={setOpen}
        data={categories?.find((i) => i.id === values?.category_id)}
      />
    </>
  );
};

export const NewspapersEdit = (props) => {
  const classes = useStyles(props);
  const dataProvider = useDataProvider();
  const [categories, setCategories] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const translate = useTranslate();

  const getCategories = () => {
    dataProvider
      .getList("newspapers_categories", {
        pagination: { page: 1, perPage: 10000 },
        sort: { field: "name", order: "ASC" },
        filter: {},
      })
      .then((data) => {
        setCategories(data.data);
        setLoading(false);
      })
      .catch((error) => {
        setCategories([]);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <LoadingPage />;
  return (
    <Edit title=" " {...props}>
      <FormWithRedirect
        {...props}
        render={(formProps) => (
          <form>
            <Box display="flex" m="1em">
              <Box flex={1}>
                <DateInput
                  source="date_publish"
                  label="resources.newspapers.fields.date_publish"
                  validate={required()}
                />
                <TextInput
                  source="title"
                  label="resources.newspapers.fields.title"
                  fullWidth
                />
                <Box display="flex" alignItems="center" style={{ gap: "1em" }}>
                  <ReferenceInput
                    source="category_id"
                    reference="newspapers_categories"
                    label="resources.newspapers.fields.category"
                  >
                    <SelectInput
                      create={<CreateCategory />}
                      choices={categories}
                      styles={{ width: "300px" }}
                      validate={required()}
                    />
                  </ReferenceInput>
                  <Box mb="8px">
                    <BtnEditCategory categories={categories} />
                  </Box>
                </Box>
              </Box>
              <Box flex={1} ml="1em">
                <FormDataConsumer>
                  {({ formData }) => (
                    <React.Fragment>
                      {formData.thumbnail_url && (
                        <>
                          <Typography
                            variant="h6"
                            style={{
                              color: "rgba(0, 0, 0, 0.54)",
                              fontSize: "12px",
                            }}
                          >
                            {translate("components.preview")}
                          </Typography>
                          <ImageField source="thumbnail_url" title="title" />
                        </>
                      )}
                      {formData.file_url && (
                        <>
                          <Typography
                            variant="h6"
                            style={{
                              color: "rgba(0, 0, 0, 0.54)",
                              fontSize: "12px",
                            }}
                          >
                            Link
                          </Typography>
                          <FileNewspaperField
                            source_file={formData.file_url}
                            source_title={formData.date_publish}
                          />
                        </>
                      )}
                    </React.Fragment>
                  )}
                </FormDataConsumer>
              </Box>
            </Box>
            <Toolbar className={classnames(classes.toolbar)} role="toolbar">
              <Box display="flex" justifyContent="space-between" width="100%">
                <SaveButton
                  saving={formProps.saving}
                  disabled={formProps.pristine}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                />
                <DeleteButton record={formProps.record} />
              </Box>
            </Toolbar>
          </form>
        )}
      />
    </Edit>
  );
};
