import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import ImageIcon from "@material-ui/icons/Image";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import GridListMedia from "../fields/GridListMedia";
import { NonPermission } from "../../NonPermission";
import { TabbedShowLayout, Tab } from "react-admin";
import { MediaCreate } from "../../views/media/create";
import { SaveContextProvider, useTranslate } from "ra-core";
import { checkPermissions } from "../../utils/validations";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  gridContainer: {
    height: "100%",
  },
  list: {
    width: "100%",
  },
  itemContainer: {
    padding: "20px",
    overflowY: "auto",
    height: "100%",
  },
  tabs: {
    "& .MuiTab-wrapper": {
      flexDirection: "row",
      "& svg": {
        marginRight: "8px",
      },
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ImagePicker(props) {
  const translate = useTranslate();
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState();
  const [modeMultiSelect, setModeMultiSelect] = useState(false);
  const [currentEditor, setCurrentEditor] = useState("");
  const [openImagePicker, setOpenImagePicker] = useState(false);
  const [max, setMax] = useState(null);

  const handleClose = () => {
    setOpenImagePicker(false);
  };

  const handleSave = (uploadedValue = null) => {
    let currentData;
    if (uploadedValue.title) {
      currentData = uploadedValue;
    } else {
      currentData = selectedValue;
    }
    let returned = null;

    if (currentData) {
      if (Array.isArray(currentData) && currentData.length > 0) {
        if (modeMultiSelect) {
          if (!max || max >= currentData.length) {
            returned = currentData.map((image) => ({
              id: image.id,
              alt_text: image.alt_text ? image.alt_text : image.title,
              caption: image.caption,
              imageUrl: image.preview_url,
              photographer: image.photographer,
            }));
          }
        } else {
          returned = {
            id: currentData[0].id,
            alt_text: currentData[0].alt_text
              ? currentData[0].alt_text
              : currentData[0].title,
            caption: currentData[0].caption,
            imageUrl: currentData[0].preview_url,
            photographer: currentData[0].photographer,
          };
        }
      } else {
        returned = {
          id: currentData.id,
          alt_text: currentData.alt_text
            ? currentData.alt_text
            : currentData.title,
          caption: currentData.caption,
          imageUrl: currentData.preview_url,
          photographer: currentData.photographer,
        };
      }
      if (returned) {
        const customEvent = new CustomEvent("selected-image-imagepicker", {
          detail: {
            editorId: currentEditor,
            image: returned,
          },
          bubbles: false,
          cancelable: true,
          composed: false,
        });
        // Disparar event.
        window.dispatchEvent(customEvent);
      }
    }
    handleClose();
  };

  // Callback para asignar imagen subida desde Notes
  const handleCallbackSave = (value) => {
    const formatedValue = {
      ...value,
      preview_url: value.preview_url,
    };
    handleSave(formatedValue);
  };

  const handleListItemDoubleClick = (value) => {
    if (value) {
      let image = null;
      if (Array.isArray(value) && value.length > 0) {
        if (modeMultiSelect) {
          if (!max || max >= selectedValue.length) {
            image = value.map((image) => ({
              id: image.id,
              alt_text: image.alt_text ? image.alt_text : image.title,
              caption: image.caption,
              imageUrl: image.preview_url,
              photographer: image.photographer,
            }));
          }
        } else {
          image = {
            id: value[0].id,
            alt_text: value[0].alt_text ? value[0].alt_text : value[0].title,
            caption: value[0].caption,
            imageUrl: value[0].preview_url,
            photographer: value[0].photographer,
          };
        }
      } else {
        image = {
          id: value.id,
          alt_text: value.alt_text ? value.alt_text : value.title,
          caption: value.caption,
          imageUrl: value.preview_url,
          photographer: value.photographer,
        };
      }
      if (image) {
        const customEvent = new CustomEvent("selected-image-imagepicker", {
          detail: {
            editorId: currentEditor,
            image,
          },
          bubbles: false,
          cancelable: true,
          composed: false,
        });
        // Disparar event.
        window.dispatchEvent(customEvent);
      }
    }
    handleClose();
  };

  const handleListItemClick = (value) => {
    if (!max || max >= value?.length) {
      setSelectedValue(value);
    }
  };

  useEffect(() => {
    const handleWindowClick = (event) => {
      const editorId = event.detail.editor;
      const modemultiselect = event.detail.modemultiselect;
      setModeMultiSelect(!!modemultiselect);
      setMax(event.detail?.max);
      setCurrentEditor(editorId);
      setOpenImagePicker(true);
    };
    window.addEventListener("open-imagepicker", handleWindowClick);
    return () => {
      window.removeEventListener("open-imagepicker", handleWindowClick);
    };
  }, [currentEditor]);

  // Chequear permisos
  const canCreate = checkPermissions(props.permissions, "create-media");

  return (
    <Dialog
      fullScreen
      open={openImagePicker}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {translate("components.select_image")}
          </Typography>
        </Toolbar>
      </AppBar>
      <TabbedShowLayout className={classes.tabs} syncWithLocation={false}>
        <Tab
          icon={<ImageIcon />}
          label={translate("resources.media.selectExisting")}
        >
          <Grid container className={classes.gridContainer}>
            <Grid item xs={12} className={classes.itemContainer}>
              <GridListMedia
                media={"image"}
                modeMultiSelect={modeMultiSelect}
                handleListItemDoubleClick={handleListItemDoubleClick}
                handleListItemClick={handleListItemClick}
                max={max}
              />
            </Grid>
          </Grid>
        </Tab>
        <Tab icon={<AddIcon />} label={translate("ra.action.add")}>
          {canCreate ? (
            <SaveContextProvider>
              <MediaCreate
                basePath={"/media"}
                resource={"media"}
                innote={true}
                successcallback={handleCallbackSave}
              />
            </SaveContextProvider>
          ) : (
            <NonPermission />
          )}
        </Tab>
      </TabbedShowLayout>
      <Divider />
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          {translate("ra.action.cancel")}
        </Button>
        <Button variant="outlined" color="primary" onClick={handleSave}>
          {translate("ra.action.accept")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
