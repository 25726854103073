import { useState } from "react";
import { Confirm } from "react-admin";
import DeleteIcon from "@material-ui/icons/Delete";
import { IconButton } from "@material-ui/core";
import { useTranslate } from "ra-core";

const DeleteComponent = ({ deleteComponent }) => {
  const [open, setOpen] = useState(false);
  const translate = useTranslate();

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    deleteComponent();
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleClick} aria-label='Borrar'>
        <DeleteIcon />
      </IconButton>
      <Confirm
        isOpen={open}
        title={translate("components.delete_components")}
        content={translate("ra.message.continue")}
        cancel={translate("ra.message.no")}
        confirm={translate("ra.message.yes")}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

export default DeleteComponent;
