import React, { useEffect, useState } from "react";
import NotePreview from "./NotePreview";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { useTranslate} from "ra-core";

const useStyles = makeStyles((theme) => ({
  SuggestedContainer: {
    maxWidth: "600px",
    margin: "50px auto 40px",
    padding: "0 16px",
  },
  title: {
    fontWeight: "bold",
    marginBottom: "8px",
    fontSize: "20px",
    marginTop: 0,
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  },
  MaxNotes: {
    padding: "8px",
    fontSize: "16px",
    borderRadius: "4px",
    backgroundColor: "rgba(0, 0, 0, 0.03)",
    color: "#3f51b5",
    width: "fit-content",
    marginLeft: "auto",
  },
  suggestedNotes: {
    padding: 0,
    "& li": {
      listStyleType: "none",
      flexBasis: "100%",
      maxWidth: "100%",
      paddingBottom: "8px",
      marginBottom: "16px",

      "& article": {
        display: "flex",

        "& div.content": {
          display: "flex",
          flexDirection: "column",
          marginLeft: "16px",
          "& h2": {
            marginTop: 0,
          },
        },
      },
    },
    "& li:not(:last-child)": {
      borderBottom: "0.5px solid #e5e5e5",
    },
    "& figure": {
      display: "inline-block",
      margin: "0 20px 20px 0",
    },
    "& img": {
      width: "160px",
      height: "100px",
      maxHeight: "400px",
      objectFit: "cover",
    },
  },
}));

const NoteList = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  let suggestedRender = null;

  const clearItems = props.clearAction
    ? (id) => {
        const newNotes = [...props.data];
        const findNote = props.data.filter((item) => item.id === id);
        const noteId =
          findNote.length > 0 ? props.data.indexOf(findNote[0]) : null;

        if (noteId !== null) {
          newNotes.splice(noteId, 1);
          props.clearAction(newNotes);
        }
      }
    : null;

  if (props.ids && props.ids.length > 0) {
    // Llama al servidor para traer los datos segun el ID
    suggestedRender = props.ids.map((id, index) => (
      <NotePreview key={"suggested-" + id + "-" + index} id={id} />
    ));
  } else if (props.data && props.data.length > 0) {
    // Renderiza directamente el componente con el objeto enviado
    suggestedRender = props.data.map((item, index) => {
      return item ? (
        <NotePreview
          clear={() => clearItems(item.id)}
          key={"suggested-" + item.id + "-" + index}
          data={item}
        />
      ) : null;
    });
  }

  // ========================================================================
  // Limitar selección
  // ========================================================================
  const [count, setCount] = useState(0);
  const [countStyles, setCountStyles] = useState({});

  const updateCount = (n) => {
    setCount(n);
    setCountStyles({
      backgroundColor:
        n === props.maxNotes ? "rgba(0, 0, 0, 0.1)" : "rgba(0, 0, 0, 0.03)",
      color: n === props.maxNotes ? "#888" : "#3f51b5",
    });
  };

  useEffect(() => {
    if (props.ids && props.ids.length > 0) {
      updateCount(props.ids.length);
    } else if (props.data && props.data.length > 0) {
      updateCount(props.data.length);
    }
  }, [props.ids, props.data]); // eslint-disable-line react-hooks/exhaustive-deps

  // ========================================================================
  // Component Body
  // ========================================================================
  return (
    <div className={classes.SuggestedContainer}>
        {props.maxNotes ? (
          <>
            <TextField
              label={translate("components.section_title")}
              margin="dense"
              variant="filled"
              value={props.title}
              fullWidth
              onChange={(e) => props.setTitle(e.target.value)}
            />
            <div className={classes.MaxNotes} style={{ ...countStyles }}>
              {count} / {props.maxNotes}
            </div>
          </>
        ) : (
          <h3 className={classes.title}>
          {props.title || `${translate("components.interested")}`}
          </h3>
        )}
      <ul className={classes.suggestedNotes}>{suggestedRender}</ul>
    </div>
  );
};

export default NoteList;
