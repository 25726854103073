import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';
import { Field } from 'react-final-form';
import Avatar from '@material-ui/core/Avatar';
import CameraEnhanceIcon from '@material-ui/icons/CameraEnhance';
import { useInput } from 'ra-core';

const useStyles = makeStyles({
    container: {
        marginBottom: '16px',
        marginTop: '10px',
        '& button:hover': {
            background: 'rgba(255, 255, 255, 1)!important',
            boxShadow: 'rgb(0 0 0 / 17%) 3px 2px 3px 2px !important',
        }
    },
    flex: {
        display: 'flex',
        alignItems: 'center'
    },
    hidden: {
        display: 'none'
    },
});

export const AvatarInput = ({ record, ...props }) => {
    const classes = useStyles();
    const imageInputRef = useRef();
    const [featuredImage, setFeaturedImage] = useState('');

    // turn a browser dropped file structure into expected structure
    const transformFile = file => {
        if (!(file instanceof File)) {
            return file;
        }

        const preview = URL.createObjectURL(file);
        setFeaturedImage(preview);
        const transformedFile = {
            rawFile: file,
        };

        return transformedFile;
    };

    const {
        input: { onChange },
    } = useInput({
        format: transformFile,
        parse: transformFile,
        type: 'file',
        ...props,
    });

    useEffect(() => {
        if (record?.avatar) {
            setFeaturedImage(`${record.avatar}?${Math.random()}`);
        }
    }, [record]);

    const handleClick = () => {
        imageInputRef.current.click();
    };

    return (
        <>
            <div className={classes.container} style={{ position: 'relative' }}>
                <Button onClick={handleClick} label={""}
                    style={{ position: 'absolute', left: '70px', bottom: '5px', minWidth: '40px', height: '40px', borderRadius: '50%', zIndex: 2, color: 'rgb(31, 58, 79)', background: 'rgba(255, 255, 255, 0.8)', boxShadow: '1px 1px 3px 1px #0000002b' }}>
                    <CameraEnhanceIcon />
                </Button>
                <Avatar
                    onClick={handleClick}
                    src={featuredImage ? `${featuredImage}` : null}
                    style={{ width: 100, height: 100, cursor: 'pointer', marginRight: "12px" }}
                />
            </div>
            <div className={classes.hidden}>
                <Field ref={imageInputRef}
                    name="avatar_input_file"
                    component="input"
                    type="file"
                    accept="image/*"
                    onChange={e => onChange(e.target.files[0])}
                />
            </div>
        </>
    );
};

export default AvatarInput;