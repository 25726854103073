import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  Box,
  FormControl,
  InputLabel,
  FormControlLabel,
  Switch,
  FormHelperText,
} from "@material-ui/core";
import { useRecordContext, useTranslate } from "ra-core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useField } from "react-final-form";
import { RelationType } from "./RelationType";
import { makeStyles } from "@material-ui/styles";
import { List } from "./ListOptions";
import { ListObject } from "./ListObjects";
import { ObjectCreator } from "./ObjectCreator";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  attributes: {
    "& .MuiButton-outlinedSizeSmall": {
      marginTop: "17px",
      marginBottom: "15px",
    },
  },
}));

const CustomArrayInput = ({ label, onChange, choices }) => {
  const record = useRecordContext();
  const classes = useStyles();
  const t = useTranslate();
  const [items, setItems] = useState(record?.attributes || []);
  const { input: input_options } = useField("attributes");

  useEffect(() => {
    if (record?.attributes) {
      setItems(record?.attributes);
    }
  }, [record]);

  useEffect(() => {
    input_options.onChange(items);
  }, [items]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAddItem = () => {
    setItems([...items, { name: "", format: "", options: null }]);
  };

  const handleItemChange = (index, fieldName, fieldValue) => {
    const updatedItems = [...items];
    updatedItems[index][fieldName] = fieldValue;
    setItems(updatedItems);
    onChange(updatedItems);
  };

  const handleRemoveItem = (index) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
    onChange(updatedItems);
  };

  return (
    <div>
      <Typography variant="h6">{label}</Typography>
      {items?.map((item, index) => (
        <div
          key={index}
          style={{ marginBottom: "10px", display: "flex", gap: "16px" }}
          className={classes.attributes}
        >
          <TextField
            label={`${t("resources.types.fields.name")} *`}
            value={item.name}
            disabled={item?.id ? true : false}
            variant="filled"
            size="small"
            helperText={
              item?.name === ""
                ? `${t("ra.validation.field_required")}`
                : ""
            }
            error={item?.name === ""}
            onChange={(e) => handleItemChange(index, "name", e.target.value)}
          />
          <FormControl size="small">
            <InputLabel
              id="select-format"
              style={{ paddingLeft: "10px", paddingTop: "4px" }}
              size="small"
              error={item?.format === ""}
            >
              {t("resources.types.fields.format") + " *"}
            </InputLabel>
            <Select
              labelId="select-format"
              id="format"
              disabled={item?.id ? true : false}
              value={item?.format || ""}
              error={item?.format === ""}
              variant="filled"
              size="small"
              style={{ minWidth: "180px" }}
              onChange={(e) =>
                handleItemChange(index, "format", e.target.value)
              }
            >
              {choices?.map((i, key) => (
                <MenuItem key={key} value={i?.id}>
                  {i?.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={item?.format === ""}>
              {item?.format === ""
                ? `${t("ra.validation.field_required")}`
                : ""}
            </FormHelperText>
          </FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={item?.show}
                onChange={(e) =>
                  handleItemChange(index, "show", e.target.checked)
                }
              />
            }
            label={`${t("components.reduced_view")}`}
          />
          <FormControlLabel
            control={
              <Switch
                checked={item?.required}
                onChange={(e) =>
                  handleItemChange(index, "required", e.target.checked)
                }
              />
            }
            label={t("ra.validation.required")}
          />
          {item?.format === "options" ? (
            <List
              record={item?.options}
              onSave={(values) => handleItemChange(index, "options", values)}
            />
          ) : null}
          {item.format === "object" ? (
            <ObjectCreator
              record={item?.options}
              onSave={(values) => handleItemChange(index, "options", values)}
            />
          ) : null}
          {item?.format === "relation" ? (
            <RelationType
              source={`attributes[${index}].relation_type_id`}
              record={item}
              disabled={item?.id ? true : false}
              onChange={(value) =>
                handleItemChange(index, "relation_type_id", value)
              }
            />
          ) : null}
          {!item?.id ? (
            <Box display="flex" alignItems="center">
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                onClick={() => handleRemoveItem(index)}
                startIcon={<DeleteIcon />}
              >
                {t("ra.action.delete")}
              </Button>
            </Box>
          ) : null}
          {item?.format === "list" ? (
            <ListObject
              record={item?.options}
              onSave={(values) => handleItemChange(index, "options", values)}
              title={t("components.object")}
              label={t("components.attribute")}
              labelButton={t("components.define_object")}
            />
          ) : null}
        </div>
      ))}
      <Button variant="outlined" color="primary" onClick={handleAddItem}>
        {t("components.add")}
      </Button>
    </div>
  );
};

export default CustomArrayInput;
