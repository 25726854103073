import * as React from 'react';
import { ListBase } from 'react-admin';
import MyFormSettings from '../../components/myFormSettings/myFormSettings'
import {useListController } from 'react-admin';

const MyListSettings = (props) => {

    const data = useListController(props).data;

    return(    
            <ListBase {...props}>
                <MyFormSettings data={data}/>
            </ListBase>
    );
}
export default MyListSettings;