import * as React from "react";
import {
    Edit,
    TextInput,
    Toolbar,
    FormWithRedirect,
    required,
} from 'react-admin';
import Aside from './aside';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import { AvatarInput } from './AvatarInput';
import { useTranslate } from "ra-core";


export const AuthorEdit = props => {
    return(
    <Edit
        title=" "
        aside={<Aside />}
        {...props}
        component="div"
        >
        <AuthorForm />
    </Edit>
    )
};

const AuthorForm = (props) => {
    const translate = useTranslate();
    return (
        <FormWithRedirect
            {...props}
            render={(formProps) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <Typography variant="h6" gutterBottom>
                                        {translate("components.edit_author")} ({formProps.record && formProps.record.id})
                                    </Typography>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box flex={1} style={{alignSelf: "center"}}>
                                            <AvatarInput resource="authors" source="avatar_file" fullWidth record={formProps.record} />
                                        </Box>
                                    </Box>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box flex={1}>
                                            <TextInput
                                                source="first_name"
                                                resource="authors"
                                                validate={[required()]}
                                                fullWidth
                                            />
                                        </Box>
                                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }} >
                                            <TextInput
                                                source="last_name"
                                                resource="authors"
                                                validate={[required()]}
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                    <TextInput
                                        source="description"
                                        resource="authors"
                                        multiline
                                        rows={3}
                                        fullWidth
                                    />
                                </Box>
                                <Box flex={1} ml={{ xs: 0, lg: '1em' }} mt={{ xs: '1em', lg: 0 }}>
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            pristine={formProps.pristine}
                            undoable={true}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="authors"
                        />
                    </form>
                </Card>
            )}
        />
    );
};