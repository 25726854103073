import React, { Fragment, useEffect, useState } from "react";
import { useField } from "react-final-form";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import { CustomAddButton } from "../../components/ArrayInputButton/CustomAddButton";
import { CustomDeleteButton } from "../../components/ArrayInputButton/CustomDeleteButton";
import { OptionRender } from "./OptionRender";
import { OptionRenderMultiple } from "./OptionRenderMultiple";
import { TextInput, BooleanInput, required } from "react-admin";
import {
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useTranslate } from "ra-core";
import { ArrayInput, SimpleFormIterator, FormDataConsumer } from "react-admin";
import {
  Typography,
  TextField as MuiField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from "@material-ui/core";
import { Field } from "react-final-form";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  arrayInput: {
    marginTop: 0,
    marginBottom: 0,
  },
  select: {},
  label: {
    "&.MuiFormLabel-root": {
      marginTop: "4px!important",
      marginLeft: "10px!important",
    },
    "&.MuiInputLabel-shrink": {
      marginTop: "12px!important",
      marginLeft: "10px!important",
    },
  },
  labelError: {
    color: "#f44336!important",
  },
  selectError: {
    color: "#f44336",
    "&.MuiFilledInput-underline:after": {
      borderColor: "#f44336!important",
    },
  },
}));

const errorStyles = makeStyles({
  error: {
    color: "#f44336",
    marginLeft: "14px",
    marginRight: "14px",
    marginTop: "4px",
    fontSize: "0.75rem",
    textAlign: "left",
    fontWeight: 400,
    lineHeight: "1.66",
    letterSpacing: "0.03333em",
  },
});

const getOptionRender = (TYPES, type) => {
  if (type) {
    return TYPES.find((i) => i?.id === type);
  }
  return null;
};

const Error = ({ label }) => {
  const classes = errorStyles();
  return <span className={classes.error}>{label}</span>;
};

const SelectType = ({ source, scopedFormData, optionSource, TYPES }) => {
  const classes = useStyles();
  const { input: type } = useField(source);
  const { input: options } = useField(optionSource);
  const [current, setCurrent] = useState();
  const translate = useTranslate();



  useEffect(() => {
    setCurrent(scopedFormData?.type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (value) => {
    if (current != value) {
      setCurrent(value);
      options.onChange({});
    }
    type.onChange(value);
  };

  return (
    <Field name={source} initialvalue={type?.value} validate={required()}>
      {({ input, meta: { error } }) => (
        <FormControl size='small'>
          <InputLabel
            id={`${source}-label`}
            className={`${classes.label} ${error ? classes.labelError : ""}`}
          >
            {translate("resources.combos.fields.steps.type")} *
          </InputLabel>
          <Fragment>
            <Select
              name={input?.name}
              labelId={`${source}-label`}
              id={`${source}`}
              value={type?.value}
              onChange={(e) => handleChange(e.target.value)}
              className={error ? classes.selectError : ""}
              style={{ marginRight: "1em", width: "250px", marginTop: "8px" }}
              variant='filled'
            >
              {TYPES.map((item, key) => (
                <MenuItem key={key} value={item.id}>
                  {" "}
                  <Tooltip title={item?.helperText || ""} placement='right-end'>
                    <div style={{ width: "100%" }}>{item.name}</div>
                  </Tooltip>
                </MenuItem>
              ))}
            </Select>
            {error && <Error label={translate(error)} />}
          </Fragment>
        </FormControl>
      )}
    </Field>
  );
};

const SimpleAccordion = ({ index, source, scoped }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { input: name_input } = useField(`steps[${index}].name`);

  const handleChange = (value) => {
    name_input.onChange(value);
  };

  const TYPES = [
    {
      id: "texto-corto",
      name: `${translate("resources.combos.types.short_text_name")}`,
      helperText: `${translate("resources.combos.types.short_text_help")}`,
      options: [],
      optionsMultiple: [],
    },
    {
      id: "texto-largo",
      name: `${translate("resources.combos.types.paragrahp_name")}`,
      helperText: `${translate("resources.combos.types.paragrahp_help")}`,
      options: [{
        type: "number",
        name: "max",
        label: `${translate("resources.combos.types.paragrahp_label")}`,
        options: [],
      }],
      optionsMultiple: [],
    },
    {
      id: "email",
      name: `${translate("resources.combos.types.email_name")}`,
      helperText: `${translate("resources.combos.types.email_help")}`,
      options: [],
      optionsMultiple: [],
    },
    {
      id: "fecha",
      name: `${translate("resources.combos.types.date_name")}`,
      helperText: `${translate("resources.combos.types.date_help")}`,
      options: [
        {
          type: "radio",
          name: "date_type",
          label: `${translate("resources.combos.types.date_type")}`,
          options: [
            { id: "multiple", name: `${translate("resources.combos.types.multiple")}`, checked: true },
            { id: "rango", name: `${translate("resources.combos.types.range")}` },
            { id: "simple", name: `${translate("resources.combos.types.simple")}` },
          ],
        },
        {
          type: "radio",
          name: "date_range",
          label: `${translate("resources.combos.types.date_range")}`,
          options: [
            { id: "libre", name: `${translate("resources.combos.types.free")}`, checked: true },
            { id: "desde", name: `${translate("resources.combos.types.from_now")}` },
            { id: "hasta", name: `${translate("resources.combos.types.to_now")}` },
          ],
        },
        {
          type: "check",
          name: "disabled_days",
          label: `${translate("resources.combos.types.disabled_days")}`,
          options: [
            { id: "0", name: `${translate("resources.combos.types.days.sunday")}` },
            { id: "1", name: `${translate("resources.combos.types.days.monday")}` },
            { id: "2", name: `${translate("resources.combos.types.days.tuesday")}` },
            { id: "3", name: `${translate("resources.combos.types.days.wednesday")}` },
            { id: "4", name: `${translate("resources.combos.types.days.thursday")}` },
            { id: "5", name: `${translate("resources.combos.types.days.friday")}` },
            { id: "6", name: `${translate("resources.combos.types.days.saturday")}` },
          ],
          settings: { icon: "cross", text: "line-through" },
        },
        {
          type: "number",
          name: "quantity_days",
          label:  `${translate("resources.combos.types.quantity_days")}`,
          helperText: `${translate("resources.combos.types.quantity_days_help")}`
        }
      ],
      optionsMultiple: [],
    },
    {
      id: "number",
      name: `${translate("resources.combos.types.number")}`,
      helperText: `${translate("resources.combos.types.number_help")}`,
      options: [],
      optionsMultiple: [],
    },
    {
      id: "check",
      name: `${translate("resources.combos.types.check")}`,
      helperText: `${translate("resources.combos.types.check_help")}`,
      options: [],
      optionsMultiple: [{ type: "option-check" }],
    },
    {
      id: "radio",
      name: `${translate("resources.combos.types.radio")}`,
      helperText: `${translate("resources.combos.types.radio_help")}`,
      options: [],
      optionsMultiple: [{ type: "option-check" }],
    },
    {
      id: "card",
      name:`${translate("resources.combos.types.card")}`,
      helperText:`${translate("resources.combos.types.card_help")}` ,
      options: [],
      optionsMultiple: [
        { type: "text", name: "title", label: `${translate("components.title")}` },
        { type: "text", name: "description", label: `${translate("components.description")}` },
      ],
    },
    {
      id: "image-card",
      name: `${translate("resources.combos.types.image_card")}`,
      helperText: `${translate("resources.combos.types.image_card_help")}`,
      options: [],
      optionsMultiple: [
        { type: "text", name: "title", label: `${translate("components.title")}` },
        { type: "image", name: "image", label: `${translate("components.image")}` },
        { type: "text", name: "description", label: `${translate("components.description")}`},
      ],
    },
    {
      id: "file",
      name: `${translate("resources.combos.types.file")}`,
      helperText: `${translate("resources.combos.types.file_help")}`,
      options: [
        {
          type: "radio",
          name: "file_type",
          label: `${translate("resources.combos.types.file_type")}`,
          options: [
            { id: "image", name: `${translate("components.image")}`, checked: true },
            { id: "video", name: `${translate("components.video")}` },
            { id: "audio", name: `${translate("components.audio")}` },
          ],
        },
      ],
      optionsMultiple: [],
    },
  ];

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel-content'
          id='panel-header'
        >
          <Box
            display='flex'
            alignItems='center'
            justifyContent={"center"}
            width='100%'
          >
            <Box display='flex' alignItems='end' height='100%'>
              <Typography className={classes.heading}>
                {translate("resources.combos.fields.steps.step")} {index + 1}
              </Typography>
            </Box>
            <Box
              ml='16px'
              display='flex'
              justifyContent='flex-start'
              flexGrow={1}
              flexShrink={1}
            >
              <MuiField
                value={scoped?.name || ""}
                variant='standard'
                label={translate("components.title")}
                style={{ minWidth: "320px" }}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => handleChange(e.target.value)}
              />
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <ArrayInput source={source} label=' ' className={classes.arrayInput}>
            <SimpleFormIterator
              addButton={
                <CustomAddButton
                  label={translate("resources.combos.fields.steps.add_field")}
                />
              }
              removeButton={<CustomDeleteButton className={classes.array} />}
              className={classes.iterator}
              getItemLabel={(index) => ""}
              TransitionProps={{ enter: false, exit: false }}
            >
              <FormDataConsumer>
                {({ getSource, scopedFormData }) => {
                  const optionrender = getOptionRender(TYPES, scopedFormData?.type);
                  return (
                    <Fragment>
                      <Box
                        display='flex'
                        flexDirection='column'
                        alignItems={"flex-start"}
                      >
                        <Box display='flex'>
                          <SelectType
                            source={getSource("type")}
                            scopedFormData={scopedFormData}
                            optionSource={getSource("options")}
                            TYPES={TYPES}
                          />
                          <Tooltip
                            title={translate(
                              "resources.combos.tooltips.question"
                            )}
                          >
                            <span>
                              <TextInput
                                source={getSource("question")}
                                label={translate(
                                  "resources.combos.fields.steps.question"
                                )}
                                validate={required()}
                                autoComplete='off'
                                style={{ marginRight: "1em", marginBottom: 0 }}
                              />
                            </span>
                          </Tooltip>
                          <Tooltip
                            title={translate(
                              "resources.combos.tooltips.checkout_text"
                            )}
                          >
                            <span>
                              <TextInput
                                source={getSource("checkout_text")}
                                label={translate(
                                  "resources.combos.fields.steps.checkout_text"
                                )}
                                autoComplete='off'
                                validate={required()}
                                style={{ marginRight: "1em", marginBottom: 0 }}
                              />
                            </span>
                          </Tooltip>
                        </Box>
                        <Box display='flex'>
                          <TextInput
                            source={getSource("helper_text")}
                            label={translate(
                              "resources.combos.fields.steps.helper_text"
                            )}
                            style={{ marginRight: "1em", marginBottom: 0 }}
                          />
                          <BooleanInput
                            source={getSource("required")}
                            label={translate(
                              "resources.combos.fields.steps.required"
                            )}
                            style={{ alignSelf: "center" }}
                            defaultValue={true}
                          />
                        </Box>
                      </Box>
                      {optionrender?.options?.length > 0 && (
                        <OptionRender
                          source={getSource("options")}
                          options={optionrender?.options}
                          defaultValue={false}
                        />
                      )}
                      {optionrender?.optionsMultiple?.length > 0 && (
                        <OptionRenderMultiple
                          source={getSource("options")}
                          options={optionrender?.optionsMultiple}
                          booleanSource={getSource("multiple")}
                        />
                      )}
                    </Fragment>
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
export default React.memo(
  SimpleAccordion,
  (prev, next) => prev.index === next.index && prev.scoped === next.scoped
);
