import { Fragment } from "react";
import {
  Create,
  NumberInput,
  SimpleForm,
  TextInput,
  ArrayInput,
  BooleanInput,
  AutocompleteInput,
  ReferenceInput,
  required,
  maxValue,
} from "react-admin";
import { Typography, InputAdornment, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import CustomIterator from "../plans/CustomIterator";
import ImagePicker from "../../components/pickers/ImagePicker";
import Steps from "./Steps";
import { useFormState } from "react-final-form";
import { ActionsBack } from "../../components/Toolbar/ActionsBack";
import { useTranslate } from "ra-core";

const useStyles = makeStyles({
  input: {
    maxWidth: "220px",
  },
  arrayInput: {
    marginTop: 0,
    marginBottom: 0,
  },
});

export const ComboFields = ({ mode = "create" , ...props }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { values } = useFormState();
  return (
    <Fragment>
      <Typography variant="h5" style={{ marginBottom: "16px" }}>
        {mode == "create" ? translate("resources.combos.new") : translate("resources.combos.edit")}
      </Typography>
      <Box display="flex" flexDirection="column">
        <TextInput
          source="name"
          label={translate("resources.combos.fields.name")}
          className={classes.input}
          validate={required()}
        />
        <Box display="flex">
          <NumberInput
            source="price"
            label={translate("resources.combos.fields.price")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{translate("components.currency_sign")}</InputAdornment>
              ),
            }}
            className={classes.input}
            validate={required()}
          />
          <NumberInput
            style={{ marginLeft: "1em" }}
            source="price_discount"
            label={translate("resources.combos.fields.price_discount")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{translate("components.currency_sign")}</InputAdornment>
              ),
            }}
            validate={maxValue(values?.price)}
            className={classes.input}
          />
        </Box>
        <ReferenceInput
          source="admedia_id"
          reference="amedia"
          label={translate("resources.combos.fields.admedia_id")}
          validate={required()}
          className={classes.input}
        >
          <AutocompleteInput source="id" label="Medio" resettable />
        </ReferenceInput>
        <ArrayInput
          label=""
          source="lines_description"
          className={classes.arrayInput}
          style={{ marginTop: "2em" }}
        >
          <CustomIterator
            label={translate("resources.combos.fields.details")}
            source="lines_description"
            tooltip="resources.combos.tooltips.lines_description"
            maxLengthRichText={90}
            maxLines={5}
          />
        </ArrayInput>

        <Box className={classes.arrayInput} style={{ marginTop: "2em" }}>
          <Steps
            label={translate("resources.combos.fields.config")}
            source="steps"
          />
        </Box>

        <BooleanInput
          source="recommend"
          label={translate("resources.combos.fields.recommend")}
          style={{ marginTop: "2em" }}
        />
        <BooleanInput
          source="active"
          label={translate("resources.combos.fields.active")}
        />
      </Box>
      <ImagePicker {...props} />
    </Fragment>
  );
};

export const ComboCreate = ({ ...props }) => {
  return (
    <Create {...props} title=" " actions={<ActionsBack />}>
      <SimpleForm redirect="edit">
        <ComboFields />
      </SimpleForm>
    </Create>
  );
};
