import * as React from 'react';
import { Fragment, useState } from 'react';
import {
    Button,
    Confirm,
    useUpdateMany,
    useRefresh,
    useUnselectAll,
    useTranslate,
    showNotification,
} from 'react-admin';
import { useDispatch } from "react-redux";
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';

const ResetNotesButton = ({ selectedIds, filterValues }) => {
    const [open, setOpen] = useState(false);
    const refresh = useRefresh();
    const dispatch = useDispatch();
    const unselectAll = useUnselectAll();
    const translate = useTranslate();

    const [updateMany, { loading }] = useUpdateMany(
        'notes/restore',
        selectedIds,
        { deleted_at: null },
        {
            onSuccess: () => {
                refresh();
                unselectAll('notes');
                dispatch(
                    showNotification("resources.notes.notification.success", "success"),
                    unselectAll('club_cards')
                );   
            },
            onFailure: error => dispatch(showNotification(error.message || error, "error")),
        }
    );

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        updateMany();
        setOpen(false);
    };

    return (
        filterValues.deleted_at ?
            <Fragment>
                <Button label={translate('resources.notes.bulk_actions.restore_label')} onClick={handleClick}>
                    <RestoreFromTrashIcon />
                </Button>
                <Confirm
                    isOpen={open}
                    loading={loading}
                    title={translate('resources.notes.bulk_actions.restore_confirm_title')}
                    content={translate('resources.notes.bulk_actions.restore_confirm_content')}
                    onConfirm={handleConfirm}
                    onClose={handleDialogClose}
                />
            </Fragment>
            : null
    );
}

export default ResetNotesButton;