import * as React from "react";
import { Layout } from "react-admin";
import CustomAppBar from "./CustomAppBar";
import Menu from "./Menu";

const customtheme = {
  overrides: {
    RaMenuItemLink: {
      root: {
        borderLeft: "3px solid #fff",
      },
      active: {
        borderLeft: "3px solid #14A9D4",
      },
    },
    MuiDrawer: {
      paper: {
        "& div:nth-child(1)": {
          position: "relative",
        },
      },
    },
    RaLayout: {
      root: {
        paddingBottom: "8px!important",
      },
      appFrame:{
        marginTop: "0px!important"
      }
    },
  },
};

const CustomLayout = (props) => {
  return (
    <Layout {...props} appBar={CustomAppBar} menu={Menu} theme={customtheme} />
  );
};

export default CustomLayout;
