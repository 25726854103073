import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { makeStyles } from "@material-ui/styles";
import { useTranslate} from "ra-core";

const useStyles = makeStyles({
  container: {
    border: "1px solid lightgrey",
    padding: "8px",
    marginBottom: "8px",
    background: 'white',
    borderRadius: '5px',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontWeight: 700,
    fontSize: '1.2em',
    marginBottom: '.5em'
  }
});

export const List = ({ combo, index }) => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <Draggable draggableId={`${combo?.id}`} index={index}>
      {(provided, snapshot) => (
        <div
          className={classes.container}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        // isDragging={snapshot.isDragging}
        >
          <div className={classes.card}>
            <span className={classes.title}>{combo?.name}</span>
            <span>{translate("components.currency_sign")}{combo?.price}</span>
          </div>
        </div>
      )}
    </Draggable>
  );
};
