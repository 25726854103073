import React, { useEffect } from "react";
import { TextInput } from "react-admin";
import { Typography } from "@material-ui/core";
import { useField } from "react-final-form";
import { useTranslate} from "ra-core";

export const VideoNoteField = ({ record }) => {
  const { input: input_meta_note } = useField("video_note");
  const translate = useTranslate();
  useEffect(() => {
    if (record && record.format === "video" && !input_meta_note.value) {
      input_meta_note.onChange(record.meta_note);
    }
  }, [record]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="d-flex">
        <Typography variant="h6" gutterBottom>
          {translate("components.video")}
        </Typography>
      </div>
      <div>
        <TextInput
          id="video_note"
          source="video_note"
          label={translate("components.multimedia_url")}
          helperText={translate("components.multimedia_url_helper")}
          fullWidth
        />
      </div>
    </>
  );
};
