import { useCallback, useState } from "react";
import { Button, Dialog, Box, DialogTitle, DialogContent, makeStyles } from "@material-ui/core";
import {
    useTranslate,
    useRefresh,
    SimpleForm,
    useUpdateMany,
    useUnselectAll,
    showNotification,
    SaveButton,
    Toolbar,
    SelectInput,
    required
} from "react-admin";
import IconCancel from '@material-ui/icons/Cancel';
import Edit from '@material-ui/icons/Edit';
import { STATUS } from "../../utils/cardStatus";
import { useDispatch } from "react-redux";

const useStyles = makeStyles({
    button: {
        margin: '10px 24px',
        position: 'relative',
    },
    iconPaddingStyle: {
        paddingRight: '0.5em',
    },
});

const StatusEditToolbar = ({ onCancel, ...props }) => {
    const classes = useStyles();
    const translate = useTranslate();
    return (
        <Toolbar {...props} style={{ justifyContent: 'space-between' }}>
            <Button className={classes.button} onClick={onCancel}>
                <IconCancel className={classes.iconPaddingStyle} />
                {translate('ra.action.cancel')}
            </Button>
            <SaveButton />
        </Toolbar>
    )
};

export default function StatusButton({ selectedIds, ...props }) {
    const translate = useTranslate();
    const refresh = useRefresh();
    const unselectAll = useUnselectAll();
    const dispatch = useDispatch();
    const [showDialog, setShowDialog] = useState(false);
    const [updateMany, { loading }] = useUpdateMany();

    const handleOpen = useCallback((event) => {
        event.stopPropagation();
        event.preventDefault();
        setShowDialog(true);
    }, [setShowDialog]);

    const handleClose = useCallback(() => {
        setShowDialog(false);
    }, [setShowDialog]);

    const handleSave = (values) => {
        updateMany(
            'club_cards',
            selectedIds,
            {
                state: values.state,
            },
            {
                onSuccess: () => {
                    refresh();
                    setShowDialog(false);
                    dispatch(
                        showNotification("resources.club_cards.notification.success", "success"),
                        unselectAll('club_cards')
                    );
                },
                onFailure: (error) => {
                    dispatch(showNotification("resources.club_cards.notification.error", "error"));
                },
            }
        );
    };

    return (
        <Box display='flex'>
            <Button style={{ marginRight: '.5em' }} size="small" color="primary" onClick={handleOpen} startIcon={<Edit />}>
                {translate('resources.club_cards.bulk_actions.status_label')}
            </Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleClose}
                onClick={(e) => e.stopPropagation()}
                aria-label="crear"
            >
                <DialogTitle>{translate('resources.club_cards.bulk_actions.status_confirm_title')}</DialogTitle>
                <DialogContent style={{ padding: 0 }}>
                    <SimpleForm
                        save={handleSave}
                        saving={loading}
                        redirect={false}
                        toolbar={<StatusEditToolbar onCancel={handleClose} />}
                        {...props}
                    >
                        <SelectInput source="state" resource="club_cards" choices={STATUS} validate={required()} />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </Box>
    );
};