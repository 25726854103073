import * as React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    main: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: -8,
        marginBottom: -8,
    },
    chip: { margin: 4 },
});

const RolField = ({ record }) => {
    const classes = useStyles();
    
    if (!record) return null;

    return record.roles && Array.isArray(record.roles) ? (
        <span className={classes.main}>
            { record.roles.map((rol, index) => (
                <Chip
                    size="small"
                    key={index}
                    className={classes.chip}
                    label={rol.name}
                />
            )
            )}
        </span>
    ) : null;
};

export default RolField;
