import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { catalogList } from '../../utils/catalogComponents';
import { useTranslate } from "ra-core";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  dialogContent: {
    padding: "0 24px 14px 24px"
  }
}));

export default function DialogSelect({ zoneIndex, callback }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [componente, setComponente] = useState();
  const translate = useTranslate();
  const handleChange = (event) => {
    setComponente(event.target.value || "");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    setOpen(false);
    if (componente) {
      callback(zoneIndex, Object.assign({}, componente));
      setComponente("");
    }
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>+ {translate("components.component")}</Button>
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle>{translate("components.select_component")}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <form className={classes.container}>
            <FormControl className={classes.formControl}>
              <InputLabel>{translate("components.component")}</InputLabel>
              <Select
                value={componente ? componente : ""}
                onChange={handleChange}
                input={<Input />}
              >
                {catalogList.map((elem) => (
                  <MenuItem key={elem.key} value={elem}>
                    {translate(`components.catalog_list.${elem.key}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {translate("ra.action.cancel")}
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            {translate("ra.action.select")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}