import React, { useState } from "react";
import { FieldArray } from "react-final-form-arrays";
import { useField } from "react-final-form";
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";
import { Draggable, DragDropContext, Droppable } from "react-beautiful-dnd";
import { Confirm } from "react-admin";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Tooltip,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import SimpleAccordion from "./SimpleAccordion";
import { useTranslate } from "ra-core";

const useStyles = makeStyles((theme) => ({
  btnDelete: {
    color: "red",
    padding: "5px",
    border: "1px solid red",
    minWidth: "auto",
    "&:hover": {
      cursor: "pointer",
      background: "red",
      color: "white",
    },
  },
}));

const Steps = ({ source, label }) => {
  const { input } = useField(source);
  const translate = useTranslate();
  const [open, setOpen] = useState();
  const classes = useStyles();

  const onDragEnd = (result, provided) => {
    const { source, destination } = result;
    if (destination) {
      // Get the item
      const item = input.value[source.index];

      // Remove item from array
      const newArray = input.value.filter(
        (el, index) => index !== source.index
      );

      // Insert item at destination
      newArray.splice(destination.index, 0, item);
      input.onChange(newArray);
    }
  };

  return (
    <>
      <Typography
        variant="h6"
        style={{
          marginTop: "1em",
          marginBottom: 0,
          display: "flex",
          alignItems: "center",
        }}
        gutterBottom
      >
        {label}
        <Tooltip title={translate("resources.combos.tooltips.steps")}>
          <InfoIcon style={{ marginLeft: ".5em" }} color="primary" />
        </Tooltip>
      </Typography>
      <FieldArray name="steps">
        {(fieldProps) => (
          <DragDropContext onDragEnd={onDragEnd}>
            <TableContainer style={{ marginTop: "1em" }}>
              <Table aria-label="steps">
                <Droppable droppableId="droppable-steps">
                  {(provided, snapshot) => (
                    <TableBody
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {fieldProps.fields.map((description, index) => (
                        <Draggable
                          key={`d${index}`}
                          draggableId={`d${index}`}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <TableRow
                              hover
                              tabIndex={-1}
                              key={index}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <TableCell
                                align="left"
                                width="100%"
                                {...provided.dragHandleProps}
                              >
                                <SimpleAccordion
                                  index={index}
                                  scoped={input.value[index]}
                                  source={`steps[${index}].settings`}
                                />
                              </TableCell>
                              <TableCell align="right">
                                <Button
                                  className={classes.btnDelete}
                                  type="button"
                                  onClick={() => setOpen(index)}
                                >
                                  <Delete />
                                </Button>
                                <Confirm
                                  isOpen={open === index}
                                  title={translate("resources.combos.confirm_remove_step.title")}
                                  content={translate("resources.combos.confirm_remove_step.message")}
                                  onConfirm={() => {
                                    fieldProps.fields.remove(index);
                                    setOpen(null);
                                  }}
                                  onClose={() => setOpen(null)}
                                />
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </Table>
              <Button
                type="button"
                onClick={() => fieldProps.fields.push({name:"" ,steps: []})}
                color="primary"
                variant="outlined"
                style={{ marginTop: "1em" }}
                startIcon={<Add />}
              >
                {translate("resources.combos.fields.steps.add_step")}
              </Button>
            </TableContainer>
          </DragDropContext>
        )}
      </FieldArray>
    </>
  );
};

export default Steps;
