import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
} from "@material-ui/core";
import { useDataProvider } from "react-admin";

const Tapa = ({ component, settings, index, handleChange }) => {
  const [state, setState] = useState({});
  const [options, setOptions] = useState([]);
  const dataProvider = useDataProvider();

  const getOptions = useCallback(async () => {
    const { data: values } = await dataProvider.getList(
      "newspapers_categories",
      {
        filter: {},
        sort: { field: "id", order: "DESC" },
        pagination: { page: 1, perPage: 10000 },
      }
    );
    setOptions(values);
  }, [dataProvider]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getOptions();
    setState(settings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        (state?.title !== settings?.title ||
          state?.category !== settings?.category) &&
        handleChangeField(),
      1000
    );
    return () => clearTimeout(timeOutId);
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeField = () => {
    if (Object.values(state)?.length > 0) {
      const copyComponent = { ...component };
      copyComponent.settings = state;
      handleChange(copyComponent, index);
    }
  };
  if (options.length === 0) return null;
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      maxWidth="350px"
      padding="1em"
      border="1px solid gray"
      boxSizing="border-box"
      borderRadius="5px"
      style={{ gap: "8px" }}
    >
      <Typography variant="h6" style={{ marginBottom: "4px" }}>
        {component?.name}
      </Typography>
      <TextField
        value={state.title}
        label="Titulo"
        onChange={(e) => setState({ ...state, title: e.target.value })}
      />
      <FormControl>
        <InputLabel id="InputLabel-position-hero">Categoría</InputLabel>
        <Select
          value={state.category}
          onChange={(e) => setState({ ...state, category: e.target.value })}
        >
          {options.map((item, key) => (
            <MenuItem key={key} value={item.slug}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default React.memo(
  Tapa,
  (prev, next) =>
    prev.settings === next.settings && prev.index === next.index
);
