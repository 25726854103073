import * as React from "react";
import { useState, useEffect } from "react";
import { httpClient } from "../../utils/utilsFetch";
import { apiUrl } from "../../utils/utilsFetch";
import { Labeled, useNotify, fetchStart, fetchEnd } from "react-admin";
import {
  Button,
  Box,
  Card,
  Switch,
  TextField,
  CardContent,
  makeStyles,
  CircularProgress,
  FormHelperText,
  Tooltip
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useTranslate } from "ra-core";

const useStyles = makeStyles({
  card: {
    marginTop: "1em",
    maxWidth: '900px'
  },
  label: {
    "& .MuiFormLabel-root": {
      fontWeight: "bold",
      fontSize: "1.2em",
      margin: "8px 0",
    },
  },
  input: {
    width: "254px",
  },
  labelOrizontal: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& .MuiFormLabel-root": {
      fontWeight: "bold",
      fontSize: "1.2em",
      margin: "8px 0",
      minWidth: "170px",
      "& span": {
        width: "100%",
      },
    },
  },
  button: {
    width: "124px",
    margin: "8px 0",
  },
  li: {
    display: "flex",
    flexDirection: "column",
    margin: "26px 0",
  },
  containerCheck: {
    display: "flex",
    flexDirection: "row",
    maxWidth: "500px",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  containerInput: {
    display: "flex",
    alignItems: "end",
    maxWidth: "500px",
    justifyContent: "space-between",
    width: "100%",
  },
});
const MyFormSettings = (props) => {
  const classes = useStyles();
  const [states, setStates] = useState([]);
  const notify = useNotify();
  const [loading, setLoading] = useState(-1);
  const dispatch = useDispatch();
  const translate = useTranslate();

  const handleChange = (value, id) => {
    const newStates = [...states];
    const objIndex = newStates.findIndex((element) => element.id === Number(id));
    if (objIndex >= 0) {
      newStates[objIndex].val = value;
      setStates(newStates);
    }
  };

  useEffect(() => {
    const dataItems = props.data && Object.values(props.data);
    setStates(dataItems);
  }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSave = (id) => {
    const elem = states.find((element) => element.id === Number(id));
    if (elem) {
      setLoading(id);
      dispatch(fetchStart()); // start the global loading indicator
      const body = { name: elem.name, val: elem.val };
      httpClient(`${apiUrl}/settings/${id}`, {
        method: "PUT",
        body: JSON.stringify(body),
      })
        .then((res) => {
          if (res.status === 200) {
            notify("ra.notification.settings_save");
          } else {
            notify("ra.notification.http_error", { type: "warning" });
          }
        })
        .catch((e) => {
          notify("ra.notification.http_error", { type: "warning" });
        })
        .finally(() => {
          setLoading(-1);
          dispatch(fetchEnd()); // stop the global loading indicator
        });
    }
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <ul>
          {states &&
            states.map((item, i) => (
              <li key={i} className={classes.li}>
                {item.type === "checkbox" ? (
                  <Box className={classes.containerCheck}>
                    <Box display="flex" flexDirection="column">
                      <Labeled
                        label={item.name}
                        className={classes.labelOrizontal}
                      >
                        <Tooltip title={`${Number(item.val) > 0 ? `${translate("components.enabled")}` : `${translate("components.disabled")}`}`} aria-label="">
                          <Switch
                            name={item.name}
                            type={item.type}
                            checked={Number(item.val)}
                            onChange={(e) =>
                              handleChange(e.target.checked, item.id)
                            }
                            color="primary"
                          />
                        </Tooltip>
                      </Labeled>
                      <FormHelperText>{item.helper_text || ""}</FormHelperText>
                    </Box>
                    <Box>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() => handleSave(item.id)}
                        disabled={loading === Number(item.id)}
                      >
                        {loading === Number(item.id) && (
                          <CircularProgress
                            size={18}
                            thickness={2}
                          />
                        )}
                        {translate("ra.action.save")}
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  <Box className={classes.containerInput}>
                    <Labeled label={item.name} className={classes.label}>
                      <TextField
                        className={classes.input}
                        name={item.name}
                        value={item.val}
                        type={item.type}
                        multiline
                        color="primary"
                        onChange={(e) => handleChange(e.target.value, item.id)}
                        variant="standard"
                        helperText={item.helper_text || ""}
                      />
                    </Labeled>
                    <Box>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() => handleSave(item.id)}
                        disabled={loading === Number(item.id)}
                      >
                        {loading === Number(item.id) && (
                          <CircularProgress
                            size={18}
                            thickness={2}
                          />
                        )}
                        {translate("ra.action.save")}
                      </Button>
                    </Box>
                  </Box>
                )}
              </li>
            ))}
        </ul>
      </CardContent>
    </Card>
  );
};

export default MyFormSettings;
