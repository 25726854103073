import React, { useState, useEffect } from "react";
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ListDnd from "../dynamicComponentForm/ListDnd";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useTranslate } from "ra-core";

const useStyles = makeStyles((theme) =>
    createStyles({
        heading: {
            fontSize: theme.typography.pxToRem(16),
            fontWeight: theme.typography.fontWeightBold,
        },
        selectCategory: {
            width: 'auto',
            minWidth: "250px",
            marginRight: "30px"
        },
        textField: {
            width: 'auto',
            minWidth: "250px",
            marginRight: "30px"
        },
        selectNotes: {
            flex: 1,
        },
    }),
);

const NoticiaField = ({ handleCallback, zoneIndex, notes }) => {
    const classes = useStyles();
    const [listNotes, setListNotes] = useState();
    const translate = useTranslate();
    const handleChangeListNote = (list) => {
        if (list && list.length > 0) {
            handleCallback(zoneIndex, list);
        }
    };

    useEffect(() => {
        const list = [];
        if (notes && notes.length > 0) {
            notes.forEach((elem) => {
                list.push(elem ? elem : {})
            })
        }
        setListNotes(list);
    }, [notes]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        notes && (
            <Box display="flex" justifyContent="space-between">
                <div className={classes.selectNotes}>
                    <Typography className={classes.heading}>{translate("components.notes")}</Typography>
                    {listNotes && <ListDnd listNotes={listNotes} handleChangeListNote={handleChangeListNote} />}
                </div>
            </Box>
        ));
}

export default NoticiaField;