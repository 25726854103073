import * as React from "react";
import Button from '@material-ui/core/Button';
import { useFormState } from 'react-final-form';
import { useNotify } from 'react-admin';
import { useTranslate } from "ra-core";

export default function DownloadWord(props) {
    const { values } = useFormState();
    const notify = useNotify();
    const translate = useTranslate();
    //TODO reemplazar textos export
    const getFormData = () => {
        return `Sección: ${values.section_paper} \nPágina: ${values.page_paper} \nPrioridad: ${values.priority_paper} \nEs nota de tapa: ${values.is_cover_note_paper ? 'Sí' : 'No'} \nVolanta: ${values.volanta_paper} \nTítulo: ${values.title_paper} \nCopete: ${values.copete_paper} \nEpígrafe/s: ${values.epigrafe_paper} \n\nContenido:\n ${JSON.stringify(values.content_paper).replace(/(&nbsp;|<([^>]+)>)/ig, '')} \n`;
    }

    const ClipBoard = () => {
        const textField = document.createElement('textarea')
        const newline = String.fromCharCode(13, 10);
        let text = getFormData();
        textField.innerHTML = text.replaceAll('\\n', newline);
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove();
        notify('ra.notification.copy_elements', 'success', {}, false, 3000);
    }

    /* const handleClickOpen = () => {
        const fakeLink = document.createElement('a');
        fakeLink.style.display = 'none';
        document.body.appendChild(fakeLink);
        const data = getFormData();
        const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // Manage IE11+ & Edge
            window.navigator.msSaveOrOpenBlob(blob, `${values.title_paper}.doc`);
        } else {
            fakeLink.setAttribute('href', URL.createObjectURL(blob));
            fakeLink.setAttribute('download', `${values.title_paper}.doc`);
            fakeLink.click();
        }
    }; */

    return (
        <Button style={{ textAlign: 'end', marginBottom: '16px' }} variant="outlined" color="primary" onClick={ClipBoard} >
            {translate("components.copy_to_assemble")}
        </Button>
    )
}