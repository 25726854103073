import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextInput, Button } from 'react-admin';
import { Typography } from '@material-ui/core';
import { useField } from 'react-final-form';
import IconContentAdd from '@material-ui/icons/Add';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import ImageDefault from "../../assets/img/note-default.png";
import { useTranslate } from "ra-core";

const useStyles = makeStyles({
    container: {
        '& img': {
            width: '390px',
            height: '190px',
            objectFit: 'cover',
        },
        '& button:hover + img': {
            filter: 'blur(3px) brightness(.9)',
        }
    },
    flex: {
        display: 'flex',
        alignItems: 'center'
    },
    hidden: {
        display: 'none'
    },
});

export const ImageField = ({ record }) => {
    const [imageQuestion, setImageQuestion] = useState('');
    const classes = useStyles();
    const [customEvent, setCustomEvent] = useState();
    const { input: input_image_question } = useField('image_question');
    const { input: input_image_id } = useField('media_id');
    const translate = useTranslate();
    useEffect(() => {
        if (record) {
            setImageQuestion(record.image_question);
        }
    }, [record]);

    const handleCallback = (event) => {
        const { editorId, image } = event.detail;
        if (editorId === 'featuredImagePolls') {
            setImageQuestion(image.imageUrl);
            input_image_question.onChange(image.imageUrl);
            input_image_id.onChange(image.id);
        }
    };

    const handleClick = () => {
        window.addEventListener('selected-image-imagepicker', handleCallback);
        window.dispatchEvent(customEvent);
    };

    useEffect(() => {
        setCustomEvent(new CustomEvent('open-imagepicker', {
            detail: {
                editor: 'featuredImagePolls'
            },
            bubbles: false,
            cancelable: true,
            composed: false,
        }));
        return () => {
            setCustomEvent(null);
        }
    }, [])

    return (
        <>
            <div className="d-flex">
                <Typography variant="h6" gutterBottom>
                    {translate("components.image")}
                </Typography>
            </div>
            <div className={classes.container} style={{ position: 'relative' }}>
                {imageQuestion && imageQuestion !== '' ?
                    <Button onClick={handleClick} label={translate("ra.action.update")}
                        style={{ position: 'absolute', left: '12px', top: '12px', zIndex: 2, color: 'rgb(31, 58, 79)', background: 'rgba(255, 255, 255, 0.8)', boxShadow: '1px 1px 3px 1px #0000002b' }}>
                        <AutorenewIcon />
                    </Button>
                    :
                    <Button onClick={handleClick} label={translate("components.add")}
                        style={{ position: 'absolute', left: '12px', top: '12px', zIndex: 2, color: 'rgb(31, 58, 79)', background: 'rgba(255, 255, 255, 0.8)', boxShadow: '1px 1px 3px 1px #0000002b' }}>
                        <IconContentAdd />
                    </Button>
                }
                <img src={imageQuestion ? imageQuestion : ImageDefault} alt="" className={classes.img} />
            </div>
            <div className={classes.hidden}>
                <TextInput
                    id="image_question"
                    source="image_question"
                    label="Imagen"
                    value={imageQuestion}
                    fullWidth
                />
            </div>

        </>
    );
};