import * as React from 'react';
import {
    DateInput,
    Edit,
    TextInput,
    PasswordInput,
    Toolbar,
    FormWithRedirect,
    required,
    email,
    minLength,
    TextField,
} from 'react-admin';
import { Box, Card, CardContent, Typography, Button } from '@material-ui/core';
import { validatePasswords } from './create';
import { AvatarInput } from './AvatarInput';
import Aside from './aside';
import { useTranslate } from "ra-core";

export const MemberEdit = props => (
    <Edit
        title=" "
        aside={<Aside />}
        {...props}
        resource="subscribers"
        component="div">
        <SubscriberForm {...props} />
    </Edit>
);

const SubscriberForm = ({ permissions, ...props }) => {
    const [showPasswordFields, setShowPasswordFields] = React.useState(false);

    const translate = useTranslate();
    return (
        <FormWithRedirect
            {...props}
            validate={validatePasswords}
            render={(formProps) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <Typography variant="h6" gutterBottom>
                                        {translate("components.edit_user")} ({formProps.record && formProps.record.id})
                                    </Typography>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box flex={1} style={{ alignSelf: "center" }}>
                                            <AvatarInput resource="subscribers" fullWidth record={formProps.record} />
                                        </Box>
                                    </Box>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="first_name"
                                                resource="subscribers"
                                                validate={requiredValidate}
                                                fullWidth
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="last_name"
                                                resource="subscribers"
                                                validate={requiredValidate}
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box flex={1}>
                                            <TextInput
                                                type="email"
                                                source="email"
                                                resource="subscribers"
                                                validate={[email(), required()]}
                                                fullWidth
                                            />
                                        </Box>
                                        <Box flex={1}></Box>
                                    </Box>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <DateInput
                                            source="birthday"
                                            resource="subscribers"
                                            style={{ minWidth: '200px' }}
                                        />
                                    </Box>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <TextInput
                                            source="dni"
                                            resource="subscribers"
                                        />
                                    </Box>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                            <TextInput
                                                source="phone_feature"
                                                resource="subscribers"
                                            />
                                        </Box>
                                        <Box flex={3}>
                                            <TextInput
                                                source="phone"
                                                resource="subscribers"
                                            />
                                        </Box>
                                    </Box>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <TextInput
                                            source="dealer"
                                            resource="subscribers"
                                        />
                                    </Box>
                                    <Box mt="1em" />

                                    <Typography variant="h6" gutterBottom>
                                        {translate("components.address")}
                                    </Typography>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="country"
                                                resource="subscribers"
                                                fullWidth
                                                helperText={false}
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="province"
                                                resource="subscribers"
                                                fullWidth
                                                helperText={false}
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                        >
                                            <TextInput
                                                source="city"
                                                resource="subscribers"
                                                fullWidth
                                                helperText={false}
                                            />
                                        </Box>
                                    </Box>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={2}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="address"
                                                resource="subscribers"
                                                fullWidth
                                                helperText={false}
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="addr_floor"
                                                resource="subscribers"
                                                fullWidth
                                                helperText={false}
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                        >
                                            <TextInput
                                                source="addr_door"
                                                resource="subscribers"
                                                fullWidth
                                                helperText={false}
                                            />
                                        </Box>
                                    </Box>

                                    <Box mt="1em" />

                                    <Box display="flex" alignItems="center" mb={2} gridGap={10}>
                                        <Typography variant="h6" >
                                            {translate("ra.auth.password")}
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            onClick={() => setShowPasswordFields(prevState => !prevState)}
                                        >
                                            {translate("ra.auth.change")}
                                        </Button>
                                    </Box>
                                    {showPasswordFields && (
                                        <Box display={{ xs: 'block', sm: 'flex' }} mt={2}>
                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                <PasswordInput
                                                    source="password"
                                                    resource="journalists"
                                                    validate={[minLength(6)]}
                                                    fullWidth
                                                />
                                            </Box>
                                            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                                <PasswordInput
                                                    source="password_confirmation"
                                                    resource="journalists"
                                                    validate={[minLength(6)]}
                                                    fullWidth
                                                />
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                                <Box flex={1} ml={{ xs: 0, lg: '1em' }} mt={{ xs: '1em', lg: 0 }}>
                                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                        <Typography variant="h6" gutterBottom>{translate("components.profile")}</Typography>
                                        <TextField source="roles.title" fullWidth />
                                    </Box>
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            undoable={true}
                            pristine={formProps.pristine}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="subscribers"
                        />
                    </form>
                </Card>
            )}
        />
    );
};

const requiredValidate = [required()];