import { Box, Button, TextField } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/styles";
import React, { Fragment } from "react";
import { CustomDialog } from "./Dialog";
import { RenderExceptions } from "./RenderExceptions";
import { useTranslate } from "ra-core";

const useStyles = makeStyles((theme) => ({
  attributes: {
    "& .MuiButton-outlinedSizeSmall": {
      marginTop: "17px",
      marginBottom: "15px",
    },
  },
}));

export const ListObjects = ({
  title,
  value,
  onChange,
  new_object,
  isRequired,
  exceptions,
  relations_options,
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const handleAddItem = () => {
    let index = value?.length ? value?.length + 1 : 1;
    const copy = value
      ? [...value, { id: index, ...new_object }]
      : [{ id: index, ...new_object }];
    onChange(copy);
  };

  const handleItemChange = (index, fieldName, fieldValue) => {
    const updatedItems = [...value];
    updatedItems[index][fieldName] = fieldValue;
    onChange(updatedItems);
  };

  const handleRemoveItem = (index) => {
    const updatedItems = [...value];
    updatedItems.splice(index, 1);
    onChange(updatedItems);
  };

  const removeUnused = () => {
    const fixed = value.filter((obj) => {
      const copy = JSON.parse(JSON.stringify(obj));
      delete copy.id;
      if (JSON.stringify(copy) !== JSON.stringify(new_object)) return obj;
      else return;
    });
    onChange(fixed);
  };
  return (
    <CustomDialog
      labelButton="Editar"
      title={`${title} ${isRequired ? " *" : ""}`}
      onSave={removeUnused}
    >
      <div>
        {value?.map((item, index) => (
          <div
            key={index}
            style={{ marginBottom: "10px", display: "flex", gap: "16px" }}
            className={classes.attributes}
          >
            {item != null &&
            item !== undefined &&
            Object.entries(item)?.length > 0
              ? Object.entries(item).map(([key, value], ind) =>
                  key !== "id" ? (
                    <Fragment key={`${ind}-${key}`}>
                      {exceptions?.includes(key) ? (
                        <RenderExceptions
                          label={key}
                          value={value || ""}
                          index={index}
                          onChange={handleItemChange}
                          relations_options={relations_options}
                        />
                      ) : (
                        <TextField
                          label={key}
                          value={value || ""}
                          variant="filled"
                          size="small"
                          onChange={(e) =>
                            handleItemChange(index, key, e.target.value)
                          }
                        />
                      )}
                    </Fragment>
                  ) : null
                )
              : null}
            {item != null && item !== undefined ? (
              <Box display="flex" alignItems="center">
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => handleRemoveItem(index)}
                  startIcon={<DeleteIcon />}
                >
                    {translate("ra.action.delete")}
                </Button>
              </Box>
            ) : null}
          </div>
        ))}
        <Button variant="outlined" color="primary" onClick={handleAddItem}>
          {translate("components.add")}
        </Button>
      </div>
    </CustomDialog>
  );
};
