import { Box, TextField, makeStyles } from "@material-ui/core";
import IconContentAdd from "@material-ui/icons/Add";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useEffect, useState } from "react";
import { Button } from "react-admin";
import ImageDefault from "../../assets/img/note-default.png";
import { useTranslate} from "ra-core";

const useStyles = makeStyles({
  container: {
    "& img": {
      objectFit: "cover",
    },
    "& button:hover + img": {
      filter: "blur(3px) brightness(.9)",
    },
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  hidden: {
    display: "none",
  },
  deleteButton: {
    lineHeight: 1,
    color: "rgb(31, 58, 79)",
    background: "rgba(255, 255, 255, 0.8)",
    boxShadow: "1px 1px 3px 1px #0000002b",
    borderRadius: "50px",
    fontSize: "12px",
    minWidth: "28px",
    width: "28px",
    height: "28px",
    "& .MuiSvgIcon-root": {
      width: "18px",
      height: "18px",
    },
    "&:hover": {
      background: "rgba(255, 255, 255, 0.8)",
    },
  },
});

export const ImageSelect = ({
  imgSize = { imgWidth: null, imgHeight: null },
  zoneIndex,
  componentData,
  indexComponent,
  handleSelected,
  indexImage,
  hiddeLabel = false,
  field = "image",
}) => {
  const { imgWidth, imgHeight } = imgSize;
  const translate = useTranslate();


  const [imageSelected, setImageSelected] = useState(
    componentData
      ? indexImage !== undefined
        ? componentData[indexImage][field]
        : componentData[field]
      : null
  );
  const classes = useStyles();
  const [customEvent, setCustomEvent] = useState();

  const handleCallback = (event) => {
    const { editorId, image } = event.detail;
    if (
      editorId ===
      `selectedImage-${field}-${zoneIndex}${
        indexComponent != null ? `-ic${indexComponent}` : ""
      }${indexImage != null ? `-ii${indexImage}` : ""}`
    ) {
      setImageSelected(image.imageUrl);
      handleSelected(image.imageUrl);
    }
  };

  const handleClick = () => {
    window.addEventListener("selected-image-imagepicker", handleCallback);
    window.dispatchEvent(customEvent);
  };

  const handleDelete = () => {
    setImageSelected(null);
    handleSelected(null);
  };

  useEffect(() => {
    setCustomEvent(
      new CustomEvent("open-imagepicker", {
        detail: {
          editor: `selectedImage-${field}-${zoneIndex}${
            indexComponent != null ? `-ic${indexComponent}` : ""
          }${indexImage != null ? `-ii${indexImage}` : ""}`,
        },
        bubbles: false,
        cancelable: true,
        composed: false,
      })
    );
    return () => {
      setCustomEvent(null);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className={classes.container} style={{ position: "relative" }}>
        {imageSelected && imageSelected !== "" ? (
          <Box
            style={{
              position: "absolute",
              top: "12px",
              padding: "0 12px",
              boxSizing: "border-box",
              zIndex: 2,
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button
              onClick={handleClick}
              label={!hiddeLabel ? translate("components.update") : ""}
              style={{
                color: "rgb(31, 58, 79)",
                background: "rgba(255, 255, 255, 0.8)",
                boxShadow: "1px 1px 3px 1px #0000002b",
              }}
            >
              <AutorenewIcon />
            </Button>
            <Button onClick={handleDelete} className={classes.deleteButton}>
              <DeleteIcon />
            </Button>
          </Box>
        ) : (
          <Button
            onClick={handleClick}
            label={!hiddeLabel ? translate("components.add") : ""}
            style={{
              position: "absolute",
              left: "12px",
              top: "12px",
              zIndex: 2,
              color: "rgb(31, 58, 79)",
              background: "rgba(255, 255, 255, 0.8)",
              boxShadow: "1px 1px 3px 1px #0000002b",
            }}
          >
            <IconContentAdd />
          </Button>
        )}
        <img
          style={{ width: imgWidth ?? "390px", height: imgHeight ?? "190px" }}
          src={imageSelected ? imageSelected : ImageDefault}
          alt=""
          className={classes.img}
        />
      </div>
      <div className={classes.hidden}>
        <TextField
          id={"TextField-image-" + zoneIndex}
          label={translate("components.image")}
          value={imageSelected || undefined}
        />
      </div>
    </>
  );
};
