import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import {
  DeleteWithConfirmButton,
  Edit,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  maxLength,
  required,
  useRecordContext,
  useTranslate,
} from "react-admin";

const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const EditToolbar = (props) => {
  const record = useRecordContext();
  const translate = useTranslate();
  return (
    <Toolbar classes={useStyles()} {...props}>
      <SaveButton disabled={props.pristine} />
      <DeleteWithConfirmButton
        confirmTitle={`${translate("resources.tags.confirm_title", {
          name: record.name,
        })}`}
        confirmContent={`${translate("resources.tags.confirm_content")}`}
      />
    </Toolbar>
  );
};

export const TagsEdit = (props) => (
  <Edit title=" " {...props}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput disabled source="id" />
      <TextInput source="name" validate={[required()]} />
      <TextInput
        validate={[maxLength(1000)]}
        source="description"
        multiline
        style={{ width: "600px" }}
      />
    </SimpleForm>
  </Edit>
);
