import * as React from "react";
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
        imgPreview: {
            width: 'initial',
            minWidth: 'initial',
            maxWidth: '42rem',
            maxHeight: '15rem',
            border: '1px solid'
        }
    })
);

const PreviewUrlField = ({ record }) => {
    const classes = useStyles();

    if (!record) return "";
    if (record.media_type === 'image') {
        return (
            <img
                src={record.preview_url}
                alt="Preview"
                className={classes.imgPreview}
            />
        );
    } else {
        return (
            <audio controls>
                <source src={record.preview_url} type="audio/ogg" />
            </audio>
        );
    }
};

export const MediaShow = (props) => (
    <Show {...props} title=" ">
        <SimpleShowLayout>
            <TextField disabled source="id" fullWidth />
            <TextField source="title" fullWidth />
            <TextField source="description" fullWidth />
            <TextField source="alt_text" fullWidth />
            <TextField source="caption" fullWidth />
            <TextField source="source_url" fullWidth />
            <br />
            <PreviewUrlField source="preview_url" title="file" />
        </SimpleShowLayout>
    </Show>
);