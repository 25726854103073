import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  maxLength,
  required,
} from "react-admin";

export const CategoriesCreate = (props) => (
  <Create title=" " {...props}>
    <SimpleForm>
      <TextInput source="name" validate={[required()]} />
      <TextInput
        validate={[maxLength(1000)]}
        source="description"
        multiline
        style={{ width: "600px" }}
      />
    </SimpleForm>
  </Create>
);
