import * as React from 'react';
import {
    useEditController,
    EditContextProvider,
    useTranslate,
    SimpleForm,
    DateField,
    ReferenceField,
    TextField
} from 'react-admin';
import { IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { CustomerReferenceField } from './CommentsListDesktop';
import CommentsEditToolbar from './CommentsEditToolbar';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 40,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1em',
        [theme.breakpoints.up('xs')]: {
            marginTop: 30,
        },
    },
    form: {
        [theme.breakpoints.up('xs')]: {
            width: 400,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            marginTop: -30,
        },
    },
    inlineField: {
        display: 'inline-block',
        width: '50%',
    },
    lastChild: {
        marginBottom: 30
    }
}));

const CommentsEdit = ({ onCancel, ...props }) => {
    const classes = useStyles();
    const controllerProps = useEditController(props);
    const translate = useTranslate();
    if (!controllerProps.record) {
        return null;
    }
    return (
        <div className={classes.root}>
            <div className={classes.title}>
                <Typography variant="h6">
                    {translate('resources.comments.detail')}
                </Typography>
                <IconButton onClick={onCancel}>
                    <CloseIcon />
                </IconButton>
            </div>
            <EditContextProvider value={controllerProps}>
                <SimpleForm
                    className={classes.form}
                    basePath={controllerProps.basePath}
                    record={controllerProps.record}
                    save={controllerProps.save}
                    version={controllerProps.version}
                    redirect="list"
                    resource="comments"
                    toolbar={<CommentsEditToolbar />}
                >
                    <DateField
                        source="created_at"
                        formClassName={classes.inlineField}
                    />
                    <CustomerReferenceField
                        source="user_id"
                        record={controllerProps.record}
                        formClassName={classes.inlineField}
                        {...props}
                    />
                    <ReferenceField
                        label="resources.notes.name"
                        source="note_id"
                        record={controllerProps.record}
                        reference="notes"
                        {...props}
                    >
                        <TextField source="title" />
                    </ReferenceField>
                    <TextField
                        source="comment"
                        formClassName={classes.lastChild}
                        fullWidth
                    />
                    {controllerProps.record && controllerProps.record.parent_id &&
                        <ReferenceField
                            source="parent_id"
                            record={controllerProps.record}
                            reference="comments"
                            {...props}
                        >
                            <TextField source="comment" />
                        </ReferenceField>
                    }
                </SimpleForm>
            </EditContextProvider>
        </div>
    );
};

export default CommentsEdit;
