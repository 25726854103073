import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/styles";
import { useTranslate } from "ra-core";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles((_theme) => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  selectInput: {
    width: "auto",
    minWidth: "250px",
    marginRight: "30px",
    marginBottom: "24px",
    background: "rgba(0, 0, 0, 0.03)",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.05)",
    },
  },
}));

const SelectCategories = ({ value, handleChangeField, listCategories }) => {
  const classes = useStyles();
  const [state, setState] = useState(
    value.categories
      ? value
      : { categories: [value === "default" ? "" : value], exclude: [] }
  );
  const translate = useTranslate();

  useEffect(() => {
    if (state !== value) {
      handleChangeField(state, "category");
    }
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event) => {
    if (Array.isArray(event.target.value)) {
      if (event.target.value.includes("") && !state.categories.includes("")) {
        setState({ categories: [""], exclude: state.exclude });
      } else {
        setState({
          categories: event.target.value.filter((word) => word !== ""),
          exclude: [],
        });
      }
    }
  };

  const handleChangeExclude = (event) => {
    setState({ categories: state.categories, exclude: event.target.value });
  };

  if (listCategories.length === 0) return null;
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        gap: "8px",
        marginRight: { xs: "2rem" },
        width: { xs: "160px", md: "250px" },
      }}
    >
      <FormControl variant="filled">
        <InputLabel id="component-categories">{translate("resources.categories.name")}</InputLabel>
        <Select
          className={classes.selectInput}
          multiple
          value={state.categories ?? []}
          onChange={handleChange}
          style={{ minWidth: "280px" }}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {Array.isArray(selected) ? (
                selected.map((value) => (
                  <Chip
                    key={value}
                    label={value === "" ? `${translate("components.all")}`: value}
                    className={classes.chip}
                  />
                ))
              ) : (
                <Chip label={value.name} className={classes.chip} />
              )}
            </div>
          )}
        >
          <MenuItem value="">
            <em>{translate("components.all")}</em>
          </MenuItem>
          {listCategories.map((item, index) => (
            <MenuItem value={item.slug} key={index}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {state.categories && state.categories.includes("") ? (
        <FormControl variant="filled">
          <InputLabel>{translate("components.exclude_categories")}</InputLabel>
          <Select
            className={classes.selectInput}
            multiple
            value={state.exclude ?? []}
            onChange={handleChangeExclude}
            style={{ minWidth: "280px" }}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {Array.isArray(selected) ? (
                  selected.map((value) => (
                    <Chip key={value} label={value} className={classes.chip} />
                  ))
                ) : (
                  <Chip label={value} className={classes.chip} />
                )}
              </div>
            )}
          >
            {listCategories.map((item, index) => (
              <MenuItem value={item.slug} key={index}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : null}
    </Box>
  );
};

export default SelectCategories;
