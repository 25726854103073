import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  IconButton,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Divider from "@material-ui/core/Divider";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ClearIcon from "@material-ui/icons/Clear";
import Tooltip from "@material-ui/core/Tooltip";
import { ImageComponent } from "./ImageComponent";
import { useTranslate } from "ra-core";

const useStyles = makeStyles((theme) =>
  createStyles({
    img: {
      width: "75px",
      marginRight: "15px",
    },
    numberOrder: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightBold,
      marginRight: "8px",
    },
    items: { marginRight: "12px" },
    select: {
      minWidth: "250px",
    },
  })
);

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    background: "rgb(235,235,235)",
  }),
});

const getListStyle = (isDraggingOver) => ({
  //background: isDraggingOver ? 'lightblue' : 'lightgrey',
});

const ListImgs = ({ list, handleChangeListImgs, componentData }) => {
  const classes = useStyles();
  const [items, setItems] = useState();
  const translate = useTranslate();

  useEffect(() => {
    if (Array.isArray(list)) {
      setItems(list);
    }
  }, [list]);

  const handleSelectedImgs = (value, index) => {
    const newListItems = [...items];
    newListItems[index] = value;
    setItems(newListItems);
    handleChangeListImgs(newListItems);
  };

  const handleDelete = (index) => {
    const newListItems = [...items];
    newListItems[index] = {};
    setItems(newListItems);
    handleChangeListImgs(newListItems);
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newListItems = reorder(
      [...items],
      result.source.index,
      result.destination.index
    );
    setItems(newListItems);
    handleChangeListImgs(newListItems);
  };

  return items ? (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable'>
        {(provided, snapshot) => (
          <List
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {items.map((item, index) => (
              <Draggable
                key={item.id ? item.id : "d" + index}
                draggableId={item.id ? `${item.id}-${index}` : `d${index}`}
                index={index}
              >
                {(provided, snapshot) => (
                  <>
                    <ListItem
                      ContainerComponent='li'
                      ContainerProps={{ ref: provided.innerRef }}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <Typography className={classes.numberOrder}>{`${
                        index + 1
                      }.`}</Typography>

                      <ImageComponent
                        handleSelectedImgs={handleSelectedImgs}
                        index={index}
                        componentData={componentData}
                      />
                      <ListItemSecondaryAction>
                        {item.id ? (
                          <Tooltip title={translate('ra.action.delete')}>
                            <IconButton
                              edge='start'
                              onClick={() => handleDelete(index)}
                              aria-label={translate('ra.action.delete')}
                            >
                              <ClearIcon />
                            </IconButton>
                          </Tooltip>
                        ) : null}
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider variant='middle' />
                  </>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  ) : (
    <></>
  );
};

export default ListImgs;
