import React, { useEffect, useState } from "react";
import { useTranslate } from "react-admin";
import { FormControl, TextField, Box } from "@material-ui/core";

const DescriptionSection = ({ value, zoneIndex, handleChangeField, helperTextDescription }) => {
  const translate = useTranslate();
  const [state, setState] = useState(value);

  useEffect(() => {
    const timeOutId = setTimeout(
      () => state !== value && handleChangeField(state, "description", zoneIndex),
      1000
    );
    return () => clearTimeout(timeOutId);
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box marginRight="2em">
      <FormControl fullWidth>
        <TextField
          id={"TextField-description-" + zoneIndex}
          label={translate("resources.pages.fields.description")}
          margin="dense"
          variant="filled"
          value={state}
          multiline
          minRows={2}
          style={{ minWidth: "400px", marginBottom: 0 }}
          onChange={(e) => setState(e.target.value)}
          helperText={helperTextDescription}
        />
      </FormControl>
    </Box>
  );
};

export default React.memo(
  DescriptionSection,
  (prev, next) => prev.value === next.value && prev.zoneIndex === next.zoneIndex
);
