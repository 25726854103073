import * as React from "react";
import {
    Create,
    FormWithRedirect,
    TextInput,
    ImageInput,
    ImageField,
    FileField,
    FileInput,
    required,
    SaveButton,
    ReferenceInput,
    SelectInput,
    RadioButtonGroupInput,
    DeleteButton,
    useNotify,
    useRedirect,
    BooleanInput
} from 'react-admin';
import { FormDataConsumer, useTranslate } from 'ra-core';
import { Box, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import {  bytesToSize } from "../../utils/validations";

const maxSizePhoto = 2097152
const maxSizeAudio = 20971520


const validateAudio = (value, allValues) => {
    if (allValues) {
        allValues?.picture?.forEach(img => {
            let size = img?.rawFile?.size || 0;
            if (size > 0 && size > maxSizeAudio) {
                return {
                    message: 'resources.media.audio_maxFilesize',
                    messageArgs: { maxSize: bytesToSize(maxSizePhoto) }
                };
            }
        });
        return undefined;
    }
    return undefined;
}

const validatePhoto = (value, allValues) => {
    if (allValues) {
        allValues?.picture?.forEach(img => {
            let size = img?.rawFile?.size || 0;
            if (size > 0 && size > maxSizePhoto) {
                return {
                    message: 'resources.media.image_maxFilesize',
                    messageArgs: { maxSize: bytesToSize(maxSizePhoto) }
                };
            }
        });
        return undefined;
    }
    return undefined;
}

const useStyles = makeStyles(
    theme => ({
        toolbar: {
            backgroundColor:
                theme.palette.type === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
        },
        desktopToolbar: {
            marginTop: theme.spacing(2),
        },
        mobileToolbar: {
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            padding: '16px',
            width: '100%',
            boxSizing: 'border-box',
            flexShrink: 0,
            zIndex: 2,
        },
        defaultToolbar: {
            flex: 1,
            display: 'flex',
            justifyContent: 'space-between',
        },
        spacer: {
            [theme.breakpoints.down('xs')]: {
                height: '5em',
            },
        },
        mediaType: {
            display: "none"
        }
    }),
    { name: 'RaToolbar' }
);

const FileComponent = () => {
    const translate = useTranslate();
    const notification = useNotify();

    const onDrop = (newFiles, rejectedFiles, event) => {
        if (rejectedFiles?.length > 0) {
            notification('resources.media.audio_maxFilesize', { type: 'error', messageArgs: { maxSize: bytesToSize(maxSizeAudio) } });
        }
    };

    return (
        <FileInput
            source="file"
            label="Archivo de audio"
            accept="audio/*"
            maxSize={maxSizeAudio}
            validate={[validateAudio, required()]}
            options={{ onDrop }}
            helperText={translate('resources.media.audio_maxFilesize', { maxSize: bytesToSize(maxSizeAudio) })}
        >
            <FileField source="src" title="Upload Preview" target="_blank" />
        </FileInput>
    )
}

const ImageComponent = () => {
    const translate = useTranslate();
    const notification = useNotify();

    const onDrop = (newFiles, rejectedFiles, event) => {
        if (rejectedFiles?.length > 0) {
            notification('resources.media.image_maxFilesize', { type: 'error', messageArgs: { maxSize: bytesToSize(maxSizePhoto) } });
        }
    };

    return (
        <ImageInput
            source="picture"
            label="Imagen"
            accept="image/*"
            maxSize={maxSizePhoto}
            validate={[validatePhoto, required()]}
            options={{ onDrop }}
            multiple
            helperText={translate('resources.media.image_maxFilesize', { maxSize: bytesToSize(maxSizePhoto) })}
        >
            <ImageField source="picture_prev" title="Upload Preview" />
        </ImageInput>
    )
}

export const MediaCreate = props => {

    const classes = useStyles(props);

    const notify = useNotify();
    const redirect = useRedirect();

    // ========================================================================
    // Prevenimos el callback automatico de RA para poder elegir que hacer
    // ========================================================================
    const successOverride = ({ data }) => {

        const response = data.response;

        // Comportamiento default del OnSuccess
        if (!props.innote) {
            redirect('list', '/media');
            notify('ra.notification.created', 'info');
        } else {
            if (response) {
                props.successcallback(response);
                notify('ra.notification.created', 'info');
            } else {
                // Error general
                notify('ra.message.error', 'error');
            }
        }
    }

    const failureOverride = (error) => {
        if (error) {
            if (error.message === 'ra.notification.data_provider_error') {
                // Considera el ra.notification.data_provider_error como un error de falla de multiples archivos
                notify('resources.media.maxFilesizeMultiple', 'error')
            } else {
                notify(error.message, 'error');
            }
        } else {
            // Error general
            notify('ra.message.error', 'error');
        }
    }


    // ========================================================================
    // Component Body
    // ========================================================================
    return <Create onFailure={failureOverride} onSuccess={successOverride} mutationMode="pessimistic" undoable={false} title=" " {...props}>
        <FormWithRedirect
            {...props}
            render={formProps => (
                <form>
                    <Box display="flex" m="1em">
                        <Box flex={1} mr="1em">
                            <TextInput source="title" label="resources.media.fields.title" validate={required()} fullWidth />
                            <TextInput source="description" label="resources.media.fields.description" fullWidth />
                            <TextInput source="alt_text" label="resources.media.fields.alt_text" fullWidth />
                            <TextInput source="caption" label="resources.media.fields.caption" fullWidth />
                            <FormDataConsumer>
                                {({ formData }) => (
                                    formData.media_type === 'image' && (
                                        <ReferenceInput source="photographer_id" label="resources.media.fields.photographer" reference="photographers" allowEmpty >
                                            <SelectInput source="name" fullWidth />
                                        </ReferenceInput>
                                    )
                                )}
                            </FormDataConsumer>
                        </Box>
                        <Box flex={1} ml="1em">
                            <div className={props.innote && classnames(
                                classes.mediaType
                            )}>
                                <RadioButtonGroupInput label="resources.media.fields.media_type" source="media_type" fullWidth
                                    defaultValue={props.isaudio ? "file" : "image"}
                                    choices={[
                                        {
                                            id: 'image',
                                            name: 'Imagen',
                                        },
                                        {
                                            id: 'file',
                                            name: 'Audio',
                                        },
                                    ]}
                                />
                            </div>
                            <FormDataConsumer>
                                {({ formData }) => formData.media_type === 'image' ?
                                    <>
                                        <ImageComponent />
                                        <BooleanInput source="enable_watermark" label="resources.media.fields.watermark" defaultValue={false} fullWidth />
                                    </>
                                    :
                                    <FileComponent />
                                }
                            </FormDataConsumer>
                        </Box>
                    </Box>
                    <Toolbar className={classnames(
                        classes.toolbar
                    )}
                        role="toolbar">
                        <Box display="flex" justifyContent="flex-end" width="100%">
                            <SaveButton
                                saving={formProps.saving}
                                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                            />
                            <DeleteButton record={formProps.record} />
                        </Box>
                    </Toolbar>
                </form>
            )}
        />
    </Create>;

}