import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogContent,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import HistoryIcon from "@material-ui/icons/History";
import { makeStyles } from "@material-ui/styles";
import { useNotify, useTranslate } from "ra-core";
import { useEffect, useState } from "react";
import { useForm, useFormState } from "react-final-form";
import { apiUrl, httpClient } from "../../utils/utilsFetch";
const useStyles = makeStyles({
  suggest: {
    padding: "6px 16px",
  },
  history: {
    minWidth: "40px",
    "& span.MuiButton-startIcon": {
      marginLeft: 0,
      marginRight: 0,
    },
  },
});

export const SuggestedIA = () => {
  const { values } = useFormState();
  const [state, setSate] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { change } = useForm();
  const notification = useNotify();
  const classes = useStyles();
  const translate = useTranslate();

  useEffect(() => {
    let local = localStorage.getItem(`suggested_${values?.id || "created"}`);
    if (local) {
      let response = JSON.parse(local);
      setSate(response);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state?.length > 0) {
      localStorage.setItem(
        `suggested_${values?.id || "created"}`,
        JSON.stringify(state.slice(-3))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const handleClick = async () => {
    setLoading(true);
    let text = values?.content ?? values?.content_premium ?? null;
    if (text) {
      if (!values?.keywords || values?.keywords?.length === 0) {
        notification(
          "Escriba las palabras clave antes de solicitar la sugerencia",
          {
            type: "error",
          }
        );
        setLoading(false);
        return;
      }
      try {
        let response = await httpClient(apiUrl + "/artic-ia/predict", {
          method: "POST",
          body: JSON.stringify({ content: text, keywords: values.keywords }),
        });
        if (response.status !== 200 && response.status !== 204) {
          setLoading(false);
          notification(
            "Hubo un error con obtener la sugerencia, intente mas tarde",
            {
              type: "error",
            }
          );
        }
        const json = response?.json;
        if (json?.response) {
          setSate((prev) => [...prev, json.response].slice(-3));
          setOpen(true);
          setLoading(false);
        } else {
          setLoading(false);
          notification(
            "Hubo un error con obtener la sugerencia, intente mas tarde",
            {
              type: "error",
            }
          );
        }
      } catch (error) {
        console.log("catch", error);
        setLoading(false);
        notification(
          "Hubo un error con obtener la sugerencia, intente mas tarde",
          {
            type: "error",
          }
        );
      }
    } else {
      notification("Escriba un texto antes de solicitar la sugerencia", {
        type: "error",
      });
      setLoading(false);
      return;
    }
  };

  const handleAcceptTitle = (title) => {
    change("title", title);
    setOpen(false);
  };
  const handleAcceptDescription = (description) => {
    change("meta_description", description);
    setOpen(false);
  };
  const handleAcceptBoth = (item) => {
    change("meta_description", item?.meta_description);
    change("title", item?.title);
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        style={{
          marginTop: "10px",
          border: "1px solid #3f51b5",
          borderRadius: "4px",

          width: "160px",
        }}
      >
        <Button
          color="primary"
          onClick={() => handleClick()}
          disabled={loading}
          startIcon={
            loading ? (
              <CircularProgress
                size={16}
                thickness={5}
                style={{ marginBottom: "4px", marginRight: "4px" }}
              />
            ) : (
              <EmojiObjectsIcon style={{ marginBottom: "4px" }} />
            )
          }
          className={classes.suggest}
        >
          {translate("components.suggest")}
        </Button>
        <Button
          startIcon={<HistoryIcon />}
          onClick={() => setOpen(true)}
          disabled={state?.length === 0}
          color="primary"
          style={{
            width: "36px",
            padding: 0,
            height: "36px",
            borderLeft: "1px solid #3f51b5",
            borderRadius: 0,
          }}
          className={classes.history}
        ></Button>
      </Box>
      <Dialog open={open} maxWidth="md">
        <DialogContent
          style={{
            paddingBottom: "20px",
            backgroundColor: "rgba(0, 0, 0, 0.09)",
          }}
        >
          <Box
            style={{
              marginBottom: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6">Sugerencias</Typography>
            <Button onClick={() => handleCancel()}>
              <CloseIcon />
            </Button>
          </Box>
          <Box display="flex" flexDirection="column" style={{ gap: "10px" }}>
            {state?.reverse()?.map((item, index) => {
              return (
                <Card
                  key={index}
                  elevation={index === 0 ? 4 : 1}
                  variant="elevation"
                  style={{
                    display: "flex",
                    padding: "16px",
                    boxSizing: "border-box",
                    gap: "16px",
                    alignItems: "center",
                    background: index === 0 ? "white" : "rgba(255,255,255,1)",
                  }}
                >
                  <Box style={{ width: "100%" }}>
                    <Typography variant="subtitle1">
                      <strong>Titulo:</strong> {item?.title}
                    </Typography>
                    <Button
                      onClick={() => handleAcceptTitle(item?.title)}
                      variant="contained"
                      color="primary"
                      style={{
                        marginBottom: "22px",
                        marginTop: "8px",
                        backgroundColor: "white",
                        color: "black",
                        border: "1px solid #3f51b5",
                      }}
                    >
                      Aplicar
                    </Button>
                    <Typography variant="subtitle1">
                      <strong>Meta Descripción:</strong>{" "}
                      {item?.meta_description}
                    </Typography>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        onClick={() =>
                          handleAcceptDescription(item?.meta_description)
                        }
                        variant="contained"
                        color="primary"
                        style={{
                          marginBottom: "22px",
                          marginTop: "8px",
                          backgroundColor: "white",
                          color: "black",
                          border: "1px solid #3f51b5",
                        }}
                      >
                        Aplicar
                      </Button>
                      <Button
                        onClick={() => handleAcceptBoth(item)}
                        variant="contained"
                        color="primary"
                        style={{
                          alignSelf: "flex-end",
                        }}
                      >
                        Aplicar ambos
                      </Button>
                    </Box>
                  </Box>
                </Card>
              );
            })}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
