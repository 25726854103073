import * as React from "react";
import {Show, SimpleShowLayout, TextField} from 'react-admin';

export const CategoriesShow = (props) => (
    <Show {...props} title=" ">
        <SimpleShowLayout>
            <TextField source="name"/>
            <TextField source="description"/>
        </SimpleShowLayout>
    </Show>
);