import React, { useState, forwardRef } from "react";
import {
  Button,
  Typography,
  Box,
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslate} from "ra-core";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

export const CustomDialog = ({
  labelButton,
  title,
  children,
  onSave,
  disableSave,
}) => {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const translate = useTranslate();
  const handleClick = () => {
    setShow(true);
  };

  const handleClose = () => {
    if (onSave) {
      onSave();
    }
    setShow(false);
  };

  const handleSave = () => {
    if (onSave) {
      onSave();
    }
    setShow(false);
  };

  return (
    <Box display={"flex"} style={{ alignItems: "center" }}>
      <Button onClick={() => handleClick()} variant="outlined" size="small">
        {labelButton}
      </Button>
      <Dialog
        open={show}
        onClose={() => handleClose()}
        fullScreen
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {title ? title.charAt(0).toUpperCase() + title.slice(1) : ""}
            </Typography>
            {!disableSave ? (
              <Button autoFocus variant="contained" onClick={handleSave}>
                {translate("ra.action.save")}
              </Button>
            ) : null}
          </Toolbar>
        </AppBar>
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </Box>
  );
};
