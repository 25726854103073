import jsonExport from 'jsonexport/dist';
import { downloadCSV } from 'react-admin';
import medio from "Static/data/medio.json";
const default_language = medio?.default_language ? `${medio?.default_language}` : "es";


const exporter = notes => {
    const notesForExport = notes.map(note => {
        const { title_paper, volanta_paper, content_paper } = note;
        const noteForExport = default_language === "es" ?
            {
                'Título Papel': title_paper,
                'Volanta Papel': volanta_paper,
                'Contenido Papel': JSON.stringify(content_paper).replace(/(&nbsp;|<([^>]+)>)/ig, '')
            }
            : {
                'Paper title': title_paper,
                'Paper volanta': volanta_paper,
                'Paper content': JSON.stringify(content_paper).replace(/(&nbsp;|<([^>]+)>)/ig, '')
            };
        return noteForExport;
    });
    jsonExport(notesForExport, {
        headers: default_language === "es" ? ['Título Papel', 'Volanta Papel', 'Contenido Papel'] : ['Paper title', 'Paper volanta', 'Paper content'], // order fields in the export
        rowDelimiter: ';'
    }, (err, csv) => {
        downloadCSV(csv, 'Notas' + (new Date()).getTime()); // download as 'posts.csv` file
    });
};

export default exporter;