import { useState, useEffect } from 'react';
import FormNotesInternet from "../../components/formNotes/FormNotesInternet";
import FormNotesPaper from "../../components/formNotes/FormNotesPaper";
import { Create, TabbedForm, FormTab, SaveButton, Toolbar, useNotify, useRedirect, useTranslate } from 'react-admin';
import ImagePicker from "../../components/pickers/ImagePicker";
import AudioPicker from "../../components/pickers/AudioPicker";
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Prompt } from 'react-router-dom';
import { useFormState, useForm } from 'react-final-form';
import useAutosave, { initAutosave, AutosaveAlert } from "../../utils/useAutosave";
import { showResource } from '../../providers/configProvider';


const getEntities = (data) => {
    let mergedEntities = [];
    for (let key in data) {
        if (key.startsWith('entities_')) {
            let values = data[key];
            if (Array.isArray(values)) {
                mergedEntities = mergedEntities.concat(values.filter( i => i != null));
            }
        }
    }
    return mergedEntities;
}


const removeEntities = (data) =>{
    for (let key in data) {
        if (key.startsWith('entities_')) {
            delete data[key];
        }
    }
    return data;
}

export const transform = data => ({
    entities: getEntities(data),
    ...removeEntities(data),
    meta_note:
        data.format === "video" ? (data.video_note ? `${data.video_note}` : null)
            : data.format === "audio" ? (data.audio_note ? `${data.audio_note}` : null)
                : data.format === "gallery" ? (data.gallery_note ? `${data.gallery_note}` : null)
                    : null
});

const useStyles = makeStyles((theme) =>
    createStyles({
        container: {
            '& .MuiTabs-root': {
                minHeight: '56px'
            }
        },
        saveButton: {
            [theme.breakpoints.up('sm')]: {
                marginLeft: "auto",
            }
        },
    })
);

const NotesCreateToolbar = ({ setFormElement, showAlert, ...props }) => {
    const classes = useStyles();
    const translate = useTranslate();

    // ========================================================================
    // Autosave
    // ========================================================================
    const formState = useFormState();
    const formElement = useForm();
    const [currentNote, setCurrentNote] = useState({});

    useAutosave(formState, currentNote, setCurrentNote, showAlert);

    useEffect(() => {
        setFormElement(formElement);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const showMessage = () => {
        return !props.pristine && !formState.submitSucceeded;
    }

    // ========================================================================
    //  Component Body
    // ========================================================================
    return (
        <>
            <Prompt
                when={showMessage()}
                message={() => translate('ra.message.unsaved_changes')}
            />
            <Toolbar {...props}>
                <SaveButton id="publish-notes" label={translate("ra.action.publish")} className={classes.saveButton} disabled={props.pristine} submitOnEnter={false} />
            </Toolbar>
        </>
    );
}

export const NotesCreate = props => {
    const translate = useTranslate();
    const classes = useStyles();
    // ========================================================================
    // Autosave - Recovery
    // ========================================================================
    const [showAlert, setShowAlert] = useState(false);
    const [autosaveNote, setAutosaveNote] = useState(null);
    const [formElement, setFormElement] = useState(null);

    const notify = useNotify();
    const redirect = useRedirect();

    useEffect(() => {
        initAutosave(setAutosaveNote);
    }, [])

    useEffect(() => {
        if (autosaveNote && autosaveNote.updated_at) {
            setShowAlert(true);
        }
    }, [autosaveNote]);

    const successOverride = ({ data }) => {
        // Borramos los datos locales del post huerfano
        localStorage.removeItem('autosave_data_null');

        // Comportamiento default del OnSuccess
        notify('resources.notes.successMessage', 'info', { smart_count: 1 });
        redirect('edit', '/notes', data.id, data);
    }

    // ========================================================================
    // Component Body
    // ========================================================================
    return (
        <>
            <ImagePicker permissions={props.permissions} />
            <AudioPicker permissions={props.permissions} />
            <AutosaveAlert
                showAlert={showAlert} setShowAlert={setShowAlert}
                form={formElement} autosaveNote={autosaveNote}
            >
                {translate('resources.notes.alert_autosave')}
            </AutosaveAlert>
            <Create onSuccess={successOverride} title=" " transform={transform} {...props}>
                <TabbedForm className={classes.container} toolbar={<NotesCreateToolbar setFormElement={setFormElement} showAlert={showAlert} />} syncWithLocation={false}>
                    <FormTab
                        label={translate("components.internet")}>
                        <FormNotesInternet mode="create" {...props} />
                    </FormTab>
                    {showResource("paper") && <FormTab
                        label={translate("components.paper")}>
                        <FormNotesPaper {...props} />
                    </FormTab>}
                </TabbedForm>
            </Create>
        </>
    );
}