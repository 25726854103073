import * as React from "react";
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { useFormState, useField } from 'react-final-form';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useNotify } from 'react-admin';
import { useTranslate } from "ra-core";
const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    gridContainer: {
        height: '100%',
    },
    list: {
        width: '100%',
    },
    itemContainer: {
        padding: '20px',
        overflowY: 'auto',
        height: '100%',
    },
    button: {
        display: 'flex',
        marginLeft: 'auto',
        marginBottom: '10px',
    },
}));

const CustomDialog = ({ onClose, onSave, open, copyFrom, ...props }) => {
    const classes = useStyles();
    const [checked, setChecked] = React.useState([]);
    const translate = useTranslate();
    const fields = [
        { name: 'free', title: `${translate("components.free")}` },
        { name: 'premium', title: `${translate("components.premium")}` },
        { name: 'paper', title: `${translate("components.paper")}` }
    ];

    const handleClose = () => {
        onClose();
    };

    const handleAgree = () => {
        onSave(checked);
    };

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={handleClose}
            aria-label={translate("components.select_to_copy")}>
            <DialogTitle>{translate("components.copy_to")}</DialogTitle>
            <DialogContent>
                <List className={classes.root}>
                    {fields.map((value, index) => {
                        if (value.name !== copyFrom){
                            const labelId = `checkbox-list-label-${value.name}`;
                            return (
                                <ListItem key={value.name} role={undefined} dense button onClick={handleToggle(value.name)}>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={checked.indexOf(value.name) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={`${value.title}`} />
                                </ListItem>
                            );
                        } else{
                            return null;
                        } 
                    })}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleAgree} color="primary" autoFocus>
                    {translate("ra.action.accept")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default function CopyEditorToAnotherMedia(props) {
    const classes = useStyles();
    const notify = useNotify();
    const { title, copyFrom } = props;
    const [open, setOpen] = useState(false);

    const { input: input_content } = useField('content');
    const { input: input_content_premium } = useField('content_premium');
    const { input: input_content_paper } = useField('content_paper');

    const { values } = useFormState();

    const handleSelectedFields = (checked) => {
        if (copyFrom === "free"){
            if (checked.includes("premium")) {
                input_content_premium.onChange(values.content);
            }
            if (checked.includes("paper")) {
                input_content_paper.onChange(values.content);
            }
        }else if(copyFrom === "premium"){
            if (checked.includes("free")) {
                input_content.onChange(values.content_premium);
            }
            if (checked.includes("paper")) {
                input_content_paper.onChange(values.content_premium);
            }
        } else {
            if (checked.includes("free")) {
                input_content.onChange(values.content_paper);
            }
            if (checked.includes("premium")) {
                input_content_premium.onChange(values.content_paper);
            }
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleSave = (value) => {
        setOpen(false);
        handleSelectedFields(value);
        notify('ra.notification.copy_elements', 'success', {}, false, 3000);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button startIcon={<FileCopyIcon />} variant="outlined" color="primary" onClick={handleClickOpen} className={classes.button}>
                {title}
            </Button>
            <CustomDialog  {...props} copyFrom={copyFrom} open={open} onSave={handleSave} onClose={handleClose} />
        </>
    )
}
