import { useQueryWithStore, SelectInput, required } from "react-admin";
import { CategoriesPicker } from "./CategoriesPicker";
import { LinearProgress } from '@material-ui/core';
import { useTranslate} from "ra-core";

export const CategoryPicker = () => {
  const translate = useTranslate();
  const { loaded, data: choices } = useQueryWithStore({
    type: 'getList',
    resource: 'categories',
    payload: {
      filter: {
      },
      sort: { field: 'name', order: 'ASC' },
      pagination: { page: 1, perPage: 1000 },
    },
  });

  if (!loaded || !choices) return <div style={{ marginBottom: "24px", marginTop: "16px" }}>{translate("components.loading_categories")}<LinearProgress /></div>;

  return (
    <div style={{ marginBottom: "24px", marginTop: "16px" }}>
      <SelectInput
        label="components.main_category"
        source="category_id"
        optionText="name"
        validate={required()}
        choices={choices}
        fullWidth
      />
      <CategoriesPicker source="categories" label={translate("components.other_categories")} choices={choices} />
    </div>
  );
};
