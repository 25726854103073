import React, { useState, useEffect } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { TextField, Box, Tooltip } from "@material-ui/core";
import { useTranslate } from "ra-core";

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      width: "auto",
      minWidth: "380px",
      maxWidth: "500px",
      marginRight: "30px",
      marginBottom: "24px",
      "&.full": {
        width: "100%",
      },
    },
    numberField: {
      width: "auto",
      minWidth: "250px",
      marginRight: "30px",
      marginBottom: "24px",

      /* Chrome, Safari, Edge, Opera */
      "& input::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: "0",
      },
      "& input::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: "0",
      },

      /* Firefox */
      "& input[type=number]": {
        "-moz-appearance": "textfield",
      },
    },
  })
);

const IframeFullWidth = ({ handleCallback, componentData }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [state, setState] = useState(componentData);

  const handleChange = () => {
    componentData = state;
    handleCallback(componentData);
  };

  useEffect(() => {
    const timeOutId = setTimeout(
      () => state !== componentData.phoneNumber && handleChange(),
      1000
    );
    return () => clearTimeout(timeOutId);
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box display='flex' flexDirection='row' flexWrap='wrap'>
      <TextField
        id={"TextField-src-iframe"}
        label={"Source"}
        margin='dense'
        variant='filled'
        autoComplete='off'
        value={state?.src || ""}
        className={classes.textField}
        onChange={(e) => setState((prev) => ({ ...prev, src: e.target.value }))}
      />
      <Tooltip title={translate("components.iframe_full_width.tooltip")}>
        <TextField
          id={"TextField-height-iframe"}
          type='number'
          label={translate('components.iframe_full_width.size')}
          margin='dense'
          variant='filled'
          autoComplete='off'
          className={classes.numberField}
          value={state?.height || ""}
          onChange={(e) =>
            setState((prev) => ({ ...prev, height: e.target.value }))
          }
        />
      </Tooltip>
    </Box>
  );
};

export default IframeFullWidth;
