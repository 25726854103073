import { Fragment } from "react";
import { AppBar, Link } from "react-admin";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "../../Logo";
import SvgIcon from "@material-ui/core/SvgIcon";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import medio from "Static/data/medio.json";
import { useTranslate } from "ra-core";

const ContactSupportIcon = () => (
  <SvgIcon>
    <path
      d="M21.4 7.0002H19.9C19.4 3.2002 16 0.200195 12 0.200195C8 0.200195 4.6 3.2002 4.1 7.0002H2.5C1.4 7.0002 0.5 7.9002 0.5 9.0002V15.0002C0.5 16.1002 1.4 17.0002 2.5 17.0002H6.5V7.0002H5.6C6.1 4.0002 8.8 1.8002 12 1.8002C15.2 1.8002 17.9 4.0002 18.4 7.0002H17.4V17.0002H19.2C19.2 19.0002 17.8 20.7002 15.9 21.1002C15.6 20.2002 14.8 19.5002 13.7 19.5002H11.2C10 19.5002 9 20.5002 9 21.7002C9 22.9002 10 23.9002 11.2 23.9002H13.7C14.6 23.9002 15.4 23.4002 15.7 22.6002C18.5 22.2002 20.7 19.8002 20.7 16.9002H21.4C22.5 16.9002 23.4 16.0002 23.4 14.9002V9.0002C23.4 7.9002 22.5 7.0002 21.4 7.0002ZM5 15.5002H2.5C2.2 15.5002 2 15.3002 2 15.0002V9.0002C2 8.7002 2.2 8.5002 2.5 8.5002H5V15.5002ZM13.8 22.5002H11.3C10.9 22.5002 10.5 22.2002 10.5 21.7002C10.5 21.2002 10.8 20.9002 11.3 20.9002H13.8C14.2 20.9002 14.6 21.2002 14.6 21.7002C14.6 22.2002 14.2 22.5002 13.8 22.5002ZM21.9 15.0002C21.9 15.3002 21.7 15.5002 21.4 15.5002H18.9V8.5002H21.4C21.7 8.5002 21.9 8.7002 21.9 9.0002V15.0002Z"
      fill="#737373"
    />
  </SvgIcon>
);

const useStyles = makeStyles({
  supportIcon: {
    height: 24,
    color: "#737373",
  },
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
  appbar: {
    color: "#737373",
    backgroundColor: "#ffffff",
    display: "block",
    position: "relative",
  },
});

const CustomAppBar = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <AppBar className={classes.appbar} {...props} container={Fragment}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <Logo height={medio?.height_icon || "32px"} />
      <span className={classes.spacer} />
      <Link to={"/contact_form/create"}>
        <Tooltip title={translate("components.technical_support")}>
          <IconButton aria-label={translate("components.technical_support")} color="inherit">
            <ContactSupportIcon />
          </IconButton>
        </Tooltip>
      </Link>
    </AppBar>
  );
};

export default CustomAppBar;