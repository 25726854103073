import React, { useState, useEffect } from 'react';
import { httpClient, apiUrl } from "../../utils/utilsFetch";
import SearchIcon from '@material-ui/icons/Search';
import { useTranslate } from 'ra-core';
import { DateInput, SimpleForm, SaveButton, useNotify, Loading } from 'react-admin';
import { subscriptionMetrics } from '../../utils/utilsMetrics';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    loading: {
        height: "100%",
        marginTop: "4em",
    },
});

// ========================================================================
// Formulario para envio de opciones
// ========================================================================
const MetricForm = ({ formdata, setformdata, setData, data, setserverdata, serverdata, jss, endpoint }) => {
    const translate = useTranslate();
    const notify = useNotify();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const compareDates = (from, to) => {
        return from < to
    }

    // Validación de fecha
    const dateValidation = (value) => {
        if (!value) {
            return 'required';
        }

        if (!compareDates(formdata.date_from, value)) {
            return translate("resources.metrics.validation.date_order");
        }
        return undefined;
    };

    // Obtener datos del servidor
    const handleSave = () => {
        if (compareDates(formdata.date_from, formdata.date_to)) {
            setLoading(true);
            httpClient(`${apiUrl}/metrics/${endpoint}`, {
                method: "POST",
                body: JSON.stringify(formdata)
            }).then(({ json }) => {
                setLoading(false);
                setserverdata(json);
                const result = subscriptionMetrics(json)

                if (result) {
                    setData(result);
                } else {
                    notify(
                        'resources.metrics.validation.not_found',
                        'warning'
                    );
                }
            }).catch(error => {
                console.log("ERROR: " + error);
                setLoading(false);
            });
        } else {
            notify(
                "resources.metrics.validation.invalid",
                'warning'
            )
        }
    }

    // Valores iniciales
    const dateToday = new Date();
    const dateLastMonth = new Date(new Date().setDate(dateToday.getDate() - 30));

    useEffect(() => {
        setformdata({
            date_from: dateLastMonth.toISOString().substring(0, 10),
            date_to: dateToday.toISOString().substring(0, 10)
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if(loading) {
        return (
        <Loading 
            className={classes.loading}
            loadingPrimary={!data && 'ra.page.loading'}
            loadingSecondary={!data && 'ra.message.loading'}
        /> 
    )}
    return <SimpleForm className={jss} toolbar={false} >
        <DateInput label={translate("resources.metrics.input.from")} defaultValue={formdata.date_from} style={{ marginTop: "0" }} name='date_from' value={formdata.date_from} onChange={(e) => setformdata({ ...formdata, date_from: e.target.value })} />
        <DateInput label={translate("resources.metrics.input.to")} defaultValue={formdata.date_to} style={{ marginTop: "0" }} validate={dateValidation} name='date_to' value={formdata.date_to} onChange={(e) => setformdata({ ...formdata, date_to: e.target.value })} />
        <SaveButton style={{ height: "48px" }} handleSubmitWithRedirect={handleSave} icon={<SearchIcon />} label={translate("ra.action.search")} />
        
    </SimpleForm>
}

export default MetricForm;