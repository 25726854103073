import { Fragment } from "react";
import {
  Edit,
  SelectInput,
  DateField,
  useRecordContext,
  SelectField,
  Labeled,
  ReferenceField,
  FunctionField,
  SaveButton,
  Toolbar,
  FormWithRedirect,
} from "react-admin";
import { makeStyles } from "@material-ui/styles";
import { ActionsBack } from "../../components/Toolbar/ActionsBack";
import {
  Card,
  CardContent,
  Box,
  Grid,
  Typography,
  Link,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { useTranslate } from "ra-core";

const useStyles = makeStyles({
  input: {
    maxWidth: "220px",
  },
  files: {
    "& .boxFile:not(:first-child)": {
      margin: "1em 0",
    },
  },
  span: {
    color: "#0000008a",
  },
});

const AdvertiserDetails = ({ record, resource = 'subscribers' }) =>
  record &&
  record.id && (
    <Box display="flex" flexDirection="column">
      <Typography
        component={RouterLink}
        color="primary"
        to={`/${resource}/${record.id}`}
        style={{ textDecoration: "none" }}
      >
        {record.first_name} {record.last_name}
      </Typography>
      <Typography
        component={Link}
        color="primary"
        href={`mailto:${record.email}`}
        style={{ textDecoration: "none" }}
      >
        {record.email}
      </Typography>
    </Box>
  );

const ComboDetails = ({ record }) => {
  return (
    <Box display="flex" flexDirection="column">
      <Typography
        component={RouterLink}
        color="primary"
        to={`/combos/${record?.id}`}
        style={{ textDecoration: "none" }}
      >
        {record?.name}
      </Typography>
    </Box>
  );
};

const Spacer = () => <Box m={1}>&nbsp;</Box>;

const RenderCard = ({ options }) => {
  const item = options?.options?.find((i) => i.title === options.value[0]);
  if (!item) return null;
  return (
    <Box maxWidth={"250px"}>
      <Card>
        <CardContent>
          <Typography>{item.title}</Typography>
          {item?.image && (
            <img
              src={item.image}
              style={{ width: "200px", height: "200px" }}
              alt=""
            />
          )}
          <Typography>{item.description}</Typography>
        </CardContent>
      </Card>
    </Box>
  );
};
// mymetype
const isMime = (mimetypes, type = "image") => {
  let res = mimetypes.split("/");
  if (res && res[0] === type) {
    return true;
  }
  return false;
};

const RenderFile = ({ item }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const files = item?.files_urls;
  if (!item?.files_urls || item?.files_urls?.length === 0)
    return <span>--{translate("components.no_answer")}--</span>;
  return (
    <div className={classes.files}>
      {files?.map((item, key) => {
        return (
          <Box
            key={key}
            display="flex"
            flexDirection="column"
            className="boxFile"
          >
            {isMime(item?.mimetypes) && (
              <img
                src={item?.filepath}
                alt={item?.filename ?? ""}
                style={{ maxWidth: "250px", height: "auto" }}
              />
            )}
            {isMime(item?.mimetypes, "audio") && (
              <audio controls>
                <source src={item?.filepath} type={item?.mimetypes} />
                {translate("components.no_audio_comp")}
              </audio>
            )}
            {isMime(item?.mimetypes, "video") && (
              <video controls style={{ maxWidth: "250px", height: "auto" }}>
                <source src={item?.filepath} type={item?.mimetypes} />
                {translate("components.no_video_comp")}
              </video>
            )}
            <a
              target="_blank"
              href={item?.filepath}
              style={{
                margin: ".5em 0",
                textDecoration: "none",
                color: "#3f51b5",
              }}
              rel="noreferrer"
            >
              Link: {item.filepath}
            </a>
          </Box>
        );
      })}
    </div>
  );
};

const RenderDynamic = ({ options }) => {
  if (!options || options?.length === 0) return null;

  return (
    <Fragment>
      {options.map((item, index) => (
        <Box key={index} marginBottom="16px">
          <Typography style={{ fontSize: "1.2em", fontWeight: 600 }}>
            {item?.checkout_text ? item?.checkout_text : item?.question}
          </Typography>
          {item.type === "card" || item.type === "card-image" ? (
            <Fragment>
              <RenderCard options={item} />
            </Fragment>
          ) : item.type === "fecha" ? (
            Array.isArray(item.value) ? (
              item.value.map((date, index2) => (
                <Typography
                  key={index2}
                  style={{ fontWeight: "400", fontSize: "16px" }}
                >
                  {date}
                </Typography>
              ))
            ) : (
              <Typography style={{ fontWeight: "400", fontSize: "16px" }}>
                {item.value}
              </Typography>
            )
          ) : item.type === "file" ? (
            <RenderFile item={item} />
          ) : (
            <Typography style={{ fontWeight: "400", fontSize: "16px" }}>
              {item.value}
            </Typography>
          )}
        </Box>
      ))}
    </Fragment>
  );
};

const RenderSteps = () => {
  const record = useRecordContext();
  const options = record?.options;
  if (!options || options?.length === 0) return null;
  return (
    <Fragment>
      {options.map((item, index) => (
        <Box key={index} borderTop="1px solid lightgray" padding="1em 0">
          <Typography style={{ fontSize: "1.4em", fontWeight: 700 }}>
            {item?.name}
          </Typography>
          <Box m="8px">
            <Typography
              style={{
                fontWeight: "600",
                fontSize: "18px",
                textTransform: "uppercase",
              }}
            >
              {item.question}
            </Typography>
            <RenderDynamic options={item?.step} />
          </Box>
        </Box>
      ))}
    </Fragment>
  );
};

const BillingData = () => {
  const classes = useStyles();
  const record = useRecordContext();
  const translate = useTranslate();
  const { billing } = record;
  if (!billing) return null;
  return (
    <Box display="flex" flexDirection="column">
      <Typography>
        <span className={classes.span}>{translate("components.name")}:</span> {billing?.first_name}{" "}
        {billing?.last_name}
      </Typography>
      {billing?.business_name && (
        <Typography>
          <span className={classes.span}>{translate("components.business_name")}:</span>{" "}
          {billing.business_name}
        </Typography>
      )}
      <Typography>
        <span className={classes.span}>{translate("components.unique_tax")}:</span> {billing?.cuit_cuil}
      </Typography>
      <Typography>
        <span className={classes.span}>{translate("components.tax_attribute")}:</span> {billing?.condition_iva ?? 'N/A'}
      </Typography>
    </Box>
  );
};

const Phone = () => {
  const classes = useStyles();
  const record = useRecordContext();
  const translate = useTranslate();
  const { billing } = record;
  if (!billing) return null;
  return (
    billing?.phone && (
      <Typography>
        <span className={classes.span}>{translate("components.phone")}:</span> {billing.phone}
      </Typography>
    )
  );
};

const OrderForm = (props) => {
  const classes = useStyles();
  const translate = useTranslate();

  const ORDER_STATUS = [
    { id: 'pending', name: `${translate("resources.orders.order_status.pending")}`},
    { id: 'in_review', name:  `${translate("resources.orders.order_status.in_review")}`},
    { id: 'ready', name:  `${translate("resources.orders.order_status.ready")}`},
    { id: 'publish', name: `${translate("resources.orders.order_status.publish")}` },
  ];

  const PAYMENT_STATUS = [
    { id: 'pending_payment', name:`${translate("resources.orders.payment_status.pending_payment")}`},
    { id: 'processing', name: `${translate("resources.orders.payment_status.processing")}` },
    { id: 'complete', name:  `${translate("resources.orders.payment_status.complete")}`},
    { id: 'failed', name:  `${translate("resources.orders.payment_status.failed")}`},
    { id: 'nocomplete', name:  `${translate("resources.orders.payment_status.nocomplete")}`},
  ];

  const PAYMENT_METHOD = [
    { id: "1", name: `${translate("resources.orders.payment_method.mp")}` },
    { id: "2", name: `${translate("resources.orders.payment_method.pa")}` },
    { id: "3", name: `${translate("resources.orders.payment_method.ma")}`},
  ];

  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Box maxWidth="70em">
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    {translate("resources.orders.edit")}{" "}
                    {"#" + String(formProps.record.id).padStart(5, "0")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Grid item xs={12}>
                    <Box>
                      <Typography variant="h6" gutterBottom>
                        {translate("resources.orders.space")}
                      </Typography>
                      <ReferenceField
                        reference="combos"
                        basePath="/combos"
                        source="combo_id"
                        link={false}
                        record={formProps.record}
                      >
                        <ComboDetails />
                      </ReferenceField>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Labeled source="created_at" resource="orders">
                      <DateField
                        source="created_at"
                        resource="orders"
                        record={formProps.record}
                      />
                    </Labeled>
                  </Grid>
                  <Grid item xs={12}>
                    <SelectInput
                      resource="orders"
                      source="status"
                      choices={ORDER_STATUS}
                      className={classes.input}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                  <Grid item xs={12}>
                    <Labeled source="payment_method" resource="orders">
                      <SelectField
                        source="payment_method"
                        resource="orders"
                        choices={PAYMENT_METHOD}
                        className={classes.input}
                      />
                    </Labeled>
                  </Grid>
                  <Grid item xs={12}>
                    <Labeled source="cost" resource="orders">
                      <FunctionField
                        source="cost"
                        resource="orders"
                        render={(v) =>
                          `${Number(v.cost ?? 0).toLocaleString(translate("components.locale_language"), {
                            style: "currency",
                            currency: translate("components.currency"),
                            minimumFractionDigits: 2,
                          })}`
                        }
                      />
                    </Labeled>
                  </Grid>
                  <Grid item xs={12}>
                    <SelectInput
                      resource="orders"
                      source="payment_status"
                      choices={PAYMENT_STATUS}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                  <Box mb="1em">
                    <Typography variant="h6" gutterBottom>
                      {translate("components.advertiser")}
                    </Typography>
                    {formProps.record.advertiser_id ? (
                      <AdvertiserDetails
                        record={formProps.record.advertiser.user}
                        resource={formProps.record.advertiser.user && formProps.record.advertiser.user.type === "journalist" ? "journalists" : "subscribers"}
                      />
                    ) : (
                      <Box display="flex" flexDirection="column">
                        <Typography style={{ textDecoration: "none" }}>
                          {translate("components.guest")}
                        </Typography>
                        <Typography
                          component={Link}
                          color="primary"
                          href={`mailto:${formProps.record.billing.email}`}
                          style={{ textDecoration: "none" }}
                        >
                          {formProps.record.billing.email}
                        </Typography>
                      </Box>
                    )}
                    <Phone />
                  </Box>
                  <Typography variant="h6" gutterBottom>
                    {translate("components.fact_data")}
                  </Typography>
                  <BillingData />
                </Grid>
                <Spacer />
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    {translate("components.order_detail")}
                  </Typography>
                  <Box>
                    <RenderSteps />
                  </Box>
                  <Spacer />
                </Grid>
              </Grid>
            </CardContent>
            <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              undoable={true}
              pristine={formProps.pristine}
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              resource="orders"
            >
              <SaveButton
                disabled={formProps.pristine}
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              />
            </Toolbar>
          </Card>
        </Box>
      )}
    />
  );
};

const useEditStyles = makeStyles({
  root: { alignItems: "flex-start" },
});
export const AdOrderEdit = (props) => {
  const classes = useEditStyles();
  return (
    <Edit
      {...props}
      classes={classes}
      title=" "
      actions={<ActionsBack />}
      component="div"
    >
      <OrderForm />
    </Edit>
  );
};
