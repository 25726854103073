import React, { useState, useEffect } from "react";
import {
  Datagrid,
  EmailField,
  Filter,
  List,
  TextField,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
  EditButton,
  useTranslate,
  useNotify,
  downloadCSV,
  BooleanInput,
} from "react-admin";
import PlanField from "./PlanField";
import FullNameField from "./FullNameField";
import { Chip } from "@material-ui/core";
import { ClubCardPanel } from "./ClubCardPanel";
import { makeStyles } from "@material-ui/core/styles";
import { ActionsCreate } from "../../components/Toolbar/ActionsCreate";
import jsonExport from "jsonexport/dist";
import { ExportNumber } from "../../components/ExportNumber";
import { checkPermissions } from "../../utils/validations";
import { RestoreButton } from "../../components/RestoreButton";

const useStyles = makeStyles({
  select: {
    minWidth: " 160px",
    maxWidth: "300px",
    marginTop: "40px",
  },
});

const SubscriberFilter = (props) => {
  const classes = useStyles();
  return (
    <Filter {...props}>
      <TextInput source="search" label="ra.action.search" alwaysOn />

      <ReferenceArrayInput
        label="resources.plans.name"
        source="plans_ids"
        reference="plans"
        sort={{ field: "name", order: "ASC" }}
        perPage={1000}
        allowEmpty
      >
        <SelectArrayInput
          optionText="name"
          variant="outlined"
          className={classes.select}
        />
      </ReferenceArrayInput>
      <BooleanInput source="deleted_at" label="components.deleted" />
    </Filter>
  );
};

const CardCountField = ({ record }) => {
  if (!record) return null;
  return (
    <Chip
      label={record.clubcards_count}
      color={record.clubcards_count > 0 ? "primary" : ""}
    />
  );
};

const Actions = ({ record }) => {
  if (record?.deleted_at !== null) return null;
  return <EditButton record={record} />
}

export const SubscriberList = ({ permissions, ...props }) => {
  const [havePermissionMoreInfo, setHavePermissionMoreInfo] = useState(false);
  const translate = useTranslate();
  const notify = useNotify();
  const [numberExport, SetNumberExport] = useState(10000);

  useEffect(() => {
    if (checkPermissions(permissions, "subscribers-more-info")) {
      setHavePermissionMoreInfo(true);
    } else {
      setHavePermissionMoreInfo(false);
    }
  }, [permissions]); // eslint-disable-line react-hooks/exhaustive-deps



  const exporter = (values) => {
    let resp = [];
    if (values && values.length > 0) {
      values.forEach((item) => {
        let obj = {};
        const status = item.subscriptions[0]?.status;
        obj.id = item.id;
        obj[translate("resources.subscribers.export.name")] = item.username;
        obj.Email = item.email;
        obj.DNI = item.dni;
        obj[translate("resources.subscribers.export.phone_feature")] = item.phone_feature;
        obj[translate("resources.subscribers.export.phone")] = item.phone;
        obj[translate("resources.subscribers.export.birthday")] =
          item?.birthday != null
            ? new Date(item.birthday).toLocaleDateString()
            : "";
        obj[translate("resources.subscribers.export.printed")] = item?.printed === false ? translate("resources.subscribers.export.no") : translate("resources.subscribers.export.yes");
        obj[translate("resources.subscribers.export.direction")] =
          item?.province != null
            ? `${item.province} , ${item.city} , ${item.address} , ${item.addr_floor}, ${item.addr_door}`
            : "";

        obj[translate("resources.subscribers.export.subscription")] =
          item.subscriptions?.length > 0 ? item.subscriptions[0].plan.name : "";
        obj[translate("resources.subscribers.export.status_subscription")] =
          status === "active"
            ? `${translate("resources.subscribers.export.status.active")}`
            : status === "expired"
              ? `${translate("resources.subscribers.export.status.expired")}`
              : status === "cancelled"
                ? `${translate("resources.subscribers.export.status.cancelled")}`
                : `${translate("resources.subscribers.export.status.unsubscribed")}`;
        resp.push(obj);
      });
    }
    if (resp && resp?.length > 0) {
      let respErr = "";
      let respCsv;
      jsonExport(resp, function (err, csv) {
        if (err) respErr = err;
        respCsv = csv;
      });
      if (respCsv && respErr === "") {
        downloadCSV(
          respCsv,
          `registered_users_${new Date().toLocaleDateString()}`
        );
      } else {
        notify("resources.subsribers.errors.export", "warning");
      }
    }
  };

  return (
    <List
      filters={<SubscriberFilter />}
      actions={
        <ActionsCreate
          label={`${translate("resources.subscribers.new")}`}
          exportButton={true}
          exporter={exporter}
          maxResults={numberExport}
        />
      }
      {...props}
      sort={{ field: "first_name", order: "ASC" }}
      bulkActionButtons={false}
      title=" "
    >
      {
        <Datagrid
          expand={
            havePermissionMoreInfo ? (
              <ClubCardPanel permissions={permissions} />
            ) : null
          }
          rowClick={
            havePermissionMoreInfo
              ? "expand"
              :
              undefined
          }
        >
          <ExportNumber
            numberExport={numberExport}
            SetNumberExport={SetNumberExport}
          />
          <TextField source="id" />
          <FullNameField
            sortBy="first_name"
            source="username"
            label={translate("resources.subscribers.fields.username")}
          />
          <EmailField source="email" />
          <PlanField
            label={translate("resources.subscribers.fields.roles")}
            source="subscription.plan.name"
            sortable={false}
          />
          <CardCountField label={translate("resources.subscribers.fields.cards_asoc")} record={props.record} />
          {checkPermissions(permissions, "edit-subscribers") &&
            <Actions {...props} />}
          {checkPermissions(permissions, "edit-subscribers") ?
            <RestoreButton  {...props} /> : null}
        </Datagrid>
      }
    </List>
  );
};
