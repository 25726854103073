import * as React from "react";
import { Datagrid, EditButton, Filter, List, TextField, TextInput, useTranslate } from 'react-admin';
import { ActionsCreate } from "../../components/Toolbar/ActionsCreate";

const PhotographersFilter = (props) => (
    <Filter {...props}>
        <TextInput source="search" label="ra.action.search" alwaysOn />
    </Filter>
);

export const PhotographersList = ({ permissions, ...props }) => {
    const translate = useTranslate();
    return (
        <List filters={<PhotographersFilter />} actions={<ActionsCreate label={`${translate("resources.photographers.new")}`}/>} {...props} sort={{ field: 'name', order: 'ASC' }} title=" ">
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="description" />
                {permissions && permissions.some(permission => permission.name === '*' || permission.name === 'edit-photographers') && <EditButton />}
            </Datagrid>
        </List>

    )
};