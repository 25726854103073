import { useState, Fragment } from "react";
import styles from "./SeoChecker.module.css";
import {
  Box,
  Button,
  Drawer,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import CloseIcon from "@material-ui/icons/Close";
import {
  validateTitleHome,
  validateImage,
  validateImageEpigrafe,
  validateVolanta,
  validateCopete,
  validateCopeteExtension,
  validateContentExtension,
  validateContentStrong,
  validateContentLink,
  validateTags,
  validateAuthor,
  validateKeywords,
  validateMetaDescription,
  validateTitleIncludesKeywords,
  validateMetaDescriptionIncludesKeywords,
} from "./validations";
import { useFormState } from "react-final-form";
import { useEffect } from "react";
import { CircularProgressPercentage } from "../CircularProgressPercentage/CircularProgressPercentage";
import { useTranslate } from "ra-core";
const ListValidations = ({ values }) => {
  return (
    <List>
      {values.map((item, key) => {
        return (
          <ListItem key={key}>
            <ListItemIcon>
              {item.isValid ? (
                <CheckCircleIcon className={styles.check} />
              ) : (
                <CancelIcon className={styles.fail} />
              )}
            </ListItemIcon>
            <ListItemText
              primary={item.name}
              className={styles.listText}
              secondaryTypographyProps={{
                className: styles.secondary,
              }}
              secondary={
                !item.isValid && item?.helperText ? item.helperText : ""
              }
            />
          </ListItem>
        );
      })}
    </List>
  );
};

export const Checker = ({ open, onClose }) => {
  const { values } = useFormState();
  const [scrollDir, setScrollDir] = useState("up");
  const [percentage, setPercentage] = useState(0);
  const [validations, setValidations] = useState();
  const [valid, setValid] = useState();
  const translate = useTranslate();

  const Criterias = [
    {
      name: `${translate("seo.title_extension")}`,
      helperText: `${translate("seo.title_extension_helper")}`,
      validate: (values) => validateTitleHome(values),
      percentage: 10,
    },
    {
      name: `${translate("seo.has_image")}`,
      validate: (values) => validateImage(values),
      percentage: 10,
    },
    {
      name: `${translate("seo.image_include_epigraph")}`,
      validate: (values) => validateImageEpigrafe(values),
      percentage: 3,
    },
    {
      name: `${translate("seo.include_volanta")}`,
      validate: (values) => validateVolanta(values),
      percentage: 2,
    },
    {
      name: `${translate("seo.include_pompadour")}`,
      validate: (values) => validateCopete(values),
      percentage: 5,
    },
    {
      name: `${translate("seo.pompadour_extension")}`,
      helperText: `${translate("seo.pompadour_extension_helper")}`,
      validate: (values) => validateCopeteExtension(values),
      percentage: 5,
    },
    {
      name: `${translate("seo.content_extension")}`,
      helperText: `${translate("seo.content_extension_helper")}`,
      validate: (values) => validateContentExtension(values),
      percentage: 10,
    },
    {
      name: `${translate("seo.use_bold")}`,
      validate: (values) => validateContentStrong(values),
      percentage: 2,
    },
    {
      name: `${translate("seo.include_links")}`,
      helperText: `${translate("seo.include_links_helper")}`,
      validate: (values) => validateContentLink(values),
      percentage: 5,
    },
    {
      name: `${translate("seo.has_tag")}`,
      validate: (values) => validateTags(values),
      percentage: 3,
    },
    {
      name: `${translate("seo.has_author")}`,
      validate: (values) => validateAuthor(values),
      percentage: 5,
    },
    {
      name: `${translate("seo.keywords")}`,
      validate: (values) => validateKeywords(values),
      percentage: 5,
    },
    {
      name: `${translate("seo.meta_description")}`,
      validate: (values) => validateMetaDescription(values),
      helperText: `${translate("seo.meta_description_helper")}`,
      percentage: 15,
    },
    {
      name: `${translate("seo.meta_description_include_keywords")}`,
      validate: (values) => validateMetaDescriptionIncludesKeywords(values),
      percentage: 10,
    },
    {
      name: `${translate("seo.title_include_keywords")}`,
      validate: (values) => validateTitleIncludesKeywords(values),
      percentage: 10,
    },
  ];

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;
      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollDir(scrollY > lastScrollY ? "down" : "up");
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollDir]);

  const checkValidations = () => {
    let res = [];
    let val = [];
    let percent = 0;
    Criterias.forEach((item) => {
      const isValid = item.validate(values);
      let aux = { ...item };
      aux.isValid = isValid;
      if (isValid) {
        percent += item.percentage;
        val.push(aux);
      } else {
        res.push(aux);
      }
    });
    setValidations(res);
    setValid(val);
    setPercentage(percent);
  };

  useEffect(() => {
    checkValidations();
  }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Drawer
      variant="persistent"
      open={open}
      anchor="right"
      classes={{
        paper: styles.drawerPaper,
      }}
    >
      <Box
        className={`${styles.container} ${
          scrollDir === "up" ? styles.marginTop : styles.marginTopDown
        }`}
      >
        <Box display="flex" alignItems="center">
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={styles.title}>
            {translate("seo.assistant")}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" margin=".5em 0">
          <CircularProgressPercentage
            percentage={percentage}
            variant={
              percentage < 40 ? "red" : percentage < 70 ? "orange" : "green"
            }
          />
        </Box>

        {validations && <ListValidations values={validations} />}

        {valid?.length > 0 && (
          <>
            <Box display="flex" alignItems="center" marginLeft={"16px"}>
              <Typography style={{ fontSize: "18px", fontWeight: 400 }}>
                {translate("seo.approved_validations")}
              </Typography>
            </Box>
            <ListValidations values={valid} />
          </>
        )}
      </Box>
    </Drawer>
  );
};

export const SeoCheck = () => {
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <Box>
        <Button
          variant="outlined"
          onClick={() => setOpen(!open)}
          color="primary"
        >
          SEO Checker
        </Button>
        {open && <Checker open={open} onClose={() => setOpen(false)} />}
      </Box>
    </Fragment>
  );
};
