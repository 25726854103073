import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { colors } from "Static/blocks/ColorOption";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { useTranslate } from "ra-core";
import React, { useEffect, useState } from "react";
import ImageDefault from "../../assets/img/note-default.png";
import NoticiaPicker from "../pickers/NoticiaPicker";
import ColorField from "../../utils/ColorField";

const useStyles = makeStyles((theme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightBold,
    },
    textField: {
      width: "auto",
      minWidth: "250px",
      marginRight: "30px",
    },
    selectNotes: {
      flex: 1,
      marginBottom: "10px",
    },
    img: {
      width: "75px",
      marginRight: "15px",
    },
    numberOrder: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightBold,
      marginRight: "8px",
    },
    ul: {
      listStyleType: "none !important",
    },
    selectInput: {
      width: "auto",
      minWidth: "250px",
      marginRight: "30px",
      marginBottom: "24px",
    },
    inputLabel: {
      width: "auto",
      minWidth: "250px",
      marginRight: "30px",
    },
    chip: {
      marginLeft: "10px",
      width: "20px",
      height: "20px",
      borderRadius: "50%",
    }
  })
);

const HeroBannerForm = ({ handleCallback, componentData }) => {
  const classes = useStyles();
  const [selectedNote, setSelectedNote] = useState({});
  const [localComponent, setLocalComponent] = useState({ ...componentData });
  const translate = useTranslate();
  const FONTCOLOR = [
    { id: "black", name: `${translate("components.black")}` },
    { id: "white", name: `${translate("components.white")}` },
  ];
  const handleSelectedNote = (value) => {
    const newComponent = { ...localComponent, notes: [value] };
    setLocalComponent(newComponent);
    handleCallback(newComponent);
  };

  const handleDelete = () => {
    const newComponent = { ...localComponent, notes: [{}] };
    setLocalComponent(newComponent);
    setSelectedNote({});
    handleCallback(newComponent);
  };

  const handleChangeTitle = (event) => {
    const newComponent = { ...localComponent, title: event.target.value };
    setLocalComponent(newComponent);
    handleCallback(newComponent);
  };
  const handleChangeField = (value, field) => {
    const newComponent = { ...localComponent, [field]: value };
    setLocalComponent(newComponent);
    handleCallback(newComponent);
  };
  useEffect(() => {
    if (componentData.notes) {
      setSelectedNote(componentData.notes[0] || {});
    }
  }, [componentData.notes]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex" flexDirection="column">
        <TextField
          id="component-title"
          label={translate("components.title_section")}
          margin="dense"
          variant="filled"
          className={classes.textField}
          value={componentData?.title || ""}
          onChange={handleChangeTitle}
        />
        {
          componentData?.background !== null && componentData?.background !== undefined ? (
            <FormControl variant='filled'>
              <InputLabel className={classes.inputLabel}>
                {translate("components.background")}
              </InputLabel>
              <Select
                onChange={(e) => {
                  handleChangeField(e.target.value, "background");
                }}
                fullWidth
                className={classes.selectInput}
                value={localComponent.background || ""}
              >
                <MenuItem value=''>Default</MenuItem>
                {colors.map((color, index) => (
                  <MenuItem key={index} value={color.name}>
                    <ColorField classes={classes.chip} value={color.value} name={color.title} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null
        }
        {
          componentData?.fontcolor !== null && componentData?.fontcolor !== undefined ?
            (
              <FormControl variant='filled'>
                <InputLabel className={classes.inputLabel}>
                  {translate("components.font_color")}
                </InputLabel>
                <Select
                  onChange={(e) => {
                    handleChangeField(e.target.value, "fontcolor");
                  }}
                  fullWidth
                  className={classes.selectInput}
                  value={localComponent.fontcolor || ""}
                >
                  <MenuItem value=''>Default</MenuItem>
                  {FONTCOLOR.map((color, index) => (
                    <MenuItem key={index} value={color.id}>
                      {translate(`components.${color.id}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null
        }
        {
          componentData?.show_copete !== null && componentData?.show_copete !== undefined ?
            (
              <Box display='flex' justifyContent='space-between'>
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        componentData.show_copete === undefined
                          ? false
                          : !!componentData.show_copete || false
                      }
                      onChange={(e) =>
                        handleChangeField(e.target.checked, "show_copete")
                      }
                      color='primary'
                    />
                  }
                  label={translate("resources.pages.fields.show_section_copete")}
                  labelPlacement='start'
                />
              </Box>
            ) : null
        }
      </Box>

      <div className={classes.selectNotes}>
        <Typography className={classes.heading}>
          {translate("components.note")}
        </Typography>
        <ListItem className={classes.ul} ContainerComponent="div">
          <Typography className={classes.numberOrder}>1.</Typography>
          <img
            className={classes.img}
            src={
              selectedNote.featured_image
                ? selectedNote.featured_image
                : ImageDefault
            }
            alt=""
          />
          <ListItemText
            primary={
              selectedNote.title_home ? selectedNote.title_home : "Default"
            }
            secondary={selectedNote.copete ? selectedNote.copete : ""}
          />
          <ListItemSecondaryAction>
            {selectedNote.id ? (
              <Tooltip title={translate("ra.action.delete")}>
                <IconButton
                  edge="start"
                  onClick={() => handleDelete()}
                  aria-label={translate("ra.action.delete")}
                >
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <NoticiaPicker
                viewIcon={true}
                handleSelectedValue={handleSelectedNote}
              />
            )}
          </ListItemSecondaryAction>
        </ListItem>
        <Divider variant="middle" />
      </div>
    </Box>
  );
};

export default HeroBannerForm;
