import { Box, Typography } from "@material-ui/core";
import { useTranslate } from "ra-core";
import * as React from "react";
import {
  Create,
  PasswordInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  email,
  minLength,
  required,
} from "react-admin";
import { AvatarInput } from "./AvatarInput";

export const validatePasswords = ({ password, password_confirmation }) => {
  const errors = {};

  if (password && password !== password_confirmation) {
    errors.password_confirmation = [
      "resources.journalists.errors.password_mismatch",
    ];
  }

  return errors;
};

export const JournalistCreate = ({ permissions, ...props }) => {
  const translate = useTranslate();
  return (
    <Create {...props} title=" ">
      <SimpleForm validate={validatePasswords}>
        <Box width={{ xs: "100%!important", md: "60%!important" }}>
          <Box display={{ md: "block", lg: "flex" }}>
            <Box flex={3} mr={{ md: 0, lg: "1em" }}>
              <Typography variant="h6" gutterBottom>
                {translate("components.add_user")}
              </Typography>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} style={{ alignSelf: "center" }}>
                  <AvatarInput resource="journalists" fullWidth />
                </Box>
              </Box>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    source="first_name"
                    resource="journalists"
                    validate={requiredValidate}
                    fullWidth
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    source="last_name"
                    resource="journalists"
                    validate={requiredValidate}
                    fullWidth
                  />
                </Box>
              </Box>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1}>
                  <TextInput
                    type="email"
                    source="email"
                    resource="journalists"
                    validate={[email(), required()]}
                    fullWidth
                  />
                </Box>
                <Box flex={1}></Box>
              </Box>
              <Box display={{ xs: "block", sm: "flex" }}>
                <TextInput source="dni" resource="journalists" />
              </Box>
              {permissions &&
                permissions.some(
                  (permission) =>
                    permission.name === "*" ||
                    permission.name === "change-role-to-journalist"
                ) && (
                  <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                    <ReferenceInput
                      label={translate("components.profile")}
                      source="roles"
                      reference="roles"
                    >
                      <SelectInput
                        optionText="name"
                        validate={requiredValidate}
                      />
                    </ReferenceInput>
                  </Box>
                )}

              <Box mt="1em" />

              <Typography variant="h6" gutterBottom>
                {translate("ra.auth.password")}
              </Typography>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <PasswordInput
                    source="password"
                    resource="journalists"
                    validate={[required(), minLength(6)]}
                    fullWidth
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <PasswordInput
                    source="password_confirmation"
                    resource="journalists"
                    validate={[required(), minLength(6)]}
                    fullWidth
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </SimpleForm>
    </Create>
  );
};

const requiredValidate = [required()];
