import { Fragment } from "react";
import {
  TextInput,
  ArrayInput,
  SelectInput,
  BooleanInput,
  FormDataConsumer,
  SimpleFormIterator,
  required,
  CheckboxGroupInput,
} from "react-admin";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { CustomAddButton } from "../../components/ArrayInputButton/CustomAddButton";
import { CustomDeleteButton } from "../../components/ArrayInputButton/CustomDeleteButton";
import { ImageSelect } from "../../components/pickers/ImageSelectFromSource";
import { useTranslate } from "ra-core";
import { CustomRadio } from "./CustomRadio";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles({
  array: {
    alignItems: "center",
  },
  arrayInput: {
    marginTop: 0,
    marginBottom: 0,
  },
  iterator: {
    marginTop: 0,
    "& .RaSimpleFormIterator-action": {
      "& > not('.MuiButton-root')": {
        paddingTop: "0!important",
      },
    },
    "& .RaSimpleFormIterator-line:not(:last-child) .RaSimpleFormIterator-action":
      {
        marginLeft: "1em",
      },
    "& .RaSimpleFormIterator-line": {
      border: "none!important",
      padding: "0",
    },
    "& .RaSimpleFormIterator-action:last-child": {
      paddingTop: 0,
    },
  },
  witoutorder: {
    "& > li > div:first-child": {
      display: "none",
    },
    "& .MuiFormControl-marginDense": {
      marginBottom: 0,
    },
  },
  tachado: {
    "& .Mui-checked + span": {
      textDecoration: "line-through!important",
    },
  },
});

//*** Opción multiple a renderear dinamicamente  */
export const OptionRenderMultiple = ({
  source,
  options = [],
  booleanSource,
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const insert = options?.find((i) => i?.type === "option-check");
  return (
    <Box mb="1em">
      <ArrayInput source={source} label=" " className={classes.arrayInput}>
        <SimpleFormIterator
          disableReordering={true}
          getItemLabel={(index) => ""}
          addButton={
            <CustomAddButton
              label={translate("resources.combos.fields.steps.add_option")}
            />
          }
          removeButton={<CustomDeleteButton className={classes.array} />}
          className={`${classes.iterator} ${classes.witoutorder}`}
          TransitionProps={{ enter: false, exit: false }}
        >
          <FormDataConsumer>
            {({ getSource, scopedFormData }) => {
              return (
                <Box>
                  {options?.map((item, index) => (
                    <Fragment key={index}>
                      {item?.type === "image" && (
                        <ImageSelect
                          source={getSource(item?.name)}
                          label={item?.label}
                          scopedFormData={scopedFormData}
                          indexStep={Number(
                            getSource(item?.name)
                              ?.split(".")[0]
                              ?.split("[")[1]
                              ?.split("]")[0] || 0
                          )}
                          indexComponent={Number(
                            getSource(item?.name)
                              ?.split(".")[1]
                              ?.split("[")[1]
                              ?.split("]")[0] || 0
                          )}
                          index={Number(
                            getSource(item?.name)
                              ?.split(".")[2]
                              ?.split("[")[1]
                              ?.split("]")[0] || 0
                          )}
                          style={{ marginRight: "1em" }}
                        />
                      )}
                      {item?.type === "text" && (
                        <TextInput
                          source={getSource(item?.name)}
                          label={item?.label}
                          style={{ marginRight: "1em" }}
                        />
                      )}
                      {item?.type === "select" && (
                        <SelectInput
                          source={getSource(item?.name)}
                          label={item?.label}
                          choices={item?.options}
                        />
                      )}
                      {item?.type === "radio" && (
                        <CustomRadio
                          source={getSource(item?.name)}
                          label={item?.label}
                          choices={item?.options}
                        />
                      )}
                      {item?.type === "option-check" && (
                        <Fragment>
                          <TextInput
                            source={getSource("name")}
                            label={translate("components.name")}
                            style={{ marginRight: "1em" }}
                            validate={required()}
                          />
                        </Fragment>
                      )}
                      {item.type === "check" && (
                        <CheckboxGroupInput
                          source={getSource(item.name)}
                          label={item?.label}
                          options={{
                            checkedIcon:
                              item.settings?.icon == "cross" ? (
                                <CloseIcon
                                  style={{
                                    border: "2px solid #3f51b5",
                                    borderRadius: "1px",
                                    width: "14px",
                                    height: "14px",
                                    margin: "0 3px",
                                  }}
                                />
                              ) : undefined,
                          }}
                          choices={item?.options}
                          className={
                            item.settings?.text == "line-through"
                              ? classes.tachado
                              : ""
                          }
                        />
                      )}
                    </Fragment>
                  ))}
                </Box>
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
      {!insert && (
        <BooleanInput
          source={booleanSource}
          label={translate("resources.combos.fields.multiple")}
          style={{ marginRight: "1em", marginLeft: "1em" }}
          defaultValue={false}
        />
      )}
    </Box>
  );
};
