import { cloneElement } from "react";
import { TopToolbar, CreateButton, ExportButton } from "react-admin";

export const ActionsCreate = ({ label = "Nuevo", resource, filter, exporter, exportButton = false, maxResults = 10000, ...props }) => (
  <TopToolbar>
    {props?.filters && cloneElement(props?.filters, { context: "button" })}
    {exportButton && <ExportButton resource={resource} filter={filter} exporter={exporter} maxResults={maxResults}/>}
    <CreateButton
      variant="contained"
      size="medium"
      style={{ marginLeft: '8px' }}
      label={label}
      translate={label}
    />
  </TopToolbar>
);
