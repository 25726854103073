import * as React from "react";
import {
  DeleteWithConfirmButton,
  Toolbar,
  Edit,
  SimpleForm,
  SaveButton,
  useRecordContext,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import { useTranslate } from "ra-core";
import Tooltip from "@material-ui/core/Tooltip";
import { Form } from "./create";

const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  }
});

const EditToolbar = (props) => {
  const record = useRecordContext();
  const translate = useTranslate();
  return (
    <Toolbar classes={useStyles()} {...props}>
      <SaveButton />
      {record && record.notes_count === 0 ? (
        <DeleteWithConfirmButton
        confirmTitle={`${translate("resources.entities.confirm_title",{ name: record.name })}`}
        confirmContent={`${translate("resources.entities.confirm_content")}`}
        />
      ) : (
        <Tooltip title={`${translate("resources.categories.disable_delete")}`}>
          <span>
            <Button
              disabled
              startIcon={<DeleteIcon />}
            >
              {translate("ra.action.delete")}
            </Button>
          </span>
        </Tooltip>
      )}
    </Toolbar>
  );
};

export const RelationEdit = (props) => (
  <Edit title=" " {...props}>
    <SimpleForm toolbar={<EditToolbar />}>
      <Form />
    </SimpleForm>
  </Edit>
);
