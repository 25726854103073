import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useGetIdentity, useTranslate } from "ra-core";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Edit,
  Loading,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useDataProvider,
  useNotify,
} from "react-admin";
import { useField } from "react-final-form";
import { Locked } from "../../components/Locked/Locked";
import CatalogPicker from "../../components/fields/CatalogPicker";
import { ImageSelect } from "../../components/fields/ImageSelect";
import NoticiaField from "../../components/fields/NoticiaField";
import ImagePicker from "../../components/pickers/ImagePicker";
import { generateUUID } from "../../utils/uuidGenerator";
import DescriptionSection from "./DescriptionSection";
import DisableItem from "./DisableItem";
import { DraggableList } from "./DraggableList/DraggableList";
import HeroPosition from "./HeroPosition";
import { SectionGroupComponents } from "./SectionGroupComponents/SectionGroup";
import SelectBackGroundColor from "./SelectBackGroundColor";
import SelectCategory from "./SelectCategory";
import SelectColor from "./SelectColor";
import SelectCount from "./SelectCount";
import SelectFontColor from "./SelectFontColor";
import SelectGeneral from "./SelectGeneral";
import SelectLink from "./SelectLink";
import ShowTags from "./ShowTags";
import TitleSection from "./TitleSection";
export const PAGES_LOCK_LEAVE = 120; //segundos
export const PAGES_LOCK_ENTER = 1000 * 30; //milisegundos

const useStyles = makeStyles((theme) =>
  createStyles({
    heading: {
      fontSize: "20px",
      fontWeight: "500",
      textTransform: "uppercase",
      letterSpacing: ".15em",
      position: "relative",
      "&.edit::before": {
        content: '""',
        display: "block",
        width: "8px",
        height: "24px",
        background: "#00aad7",
        position: "absolute",
        left: "-15px",
      },
      "&.noedit::before": {
        content: '""',
        display: "block",
        width: "8px",
        height: "24px",
        background: "#152633",
        position: "absolute",
        left: "-15px",
      },
    },
    subtitle: {
      fontSize: "1.5rem",
      fontWeight: theme.typography.fontWeightBold,
      flex: "1 1 100%",
    },
    boxZone: {
      borderRadius: "4px",
      padding: "20px",
      margin: "20px 0",
      border: "1px solid #152633",
    },
    boxComponent: {
      backgroundColor: "#fafafa",
      border: "1px solid #e6e6e6",
      borderRadius: "4px",
      padding: "20px",
      margin: "20px 0",
    },
    boxSettings: {
      backgroundColor: "#fafafa",
      border: "1px solid #e6e6e6",
      borderRadius: "4px",
      padding: "12px 20px",
      margin: "20px 0",
      display: "flex",
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: "#fafafa",
      padding: 0,
      minHeight: "auto",
      marginBottom: "15px",
    },
    hidden: { display: "none" },
    preview: { display: "flex", justifyContent: "flex-end" },
    floating: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "flex",
        right: "35px",
        bottom: "32px",
        padding: "12px",
        zIndex: 2,
        position: "fixed",
        borderRadius: "50px",
        width: "50px",
        minWidth: "50px",
        height: "50px",
        "&:disabled": {
          backgroundColor: "#e0e0e0",
          boxShadow:
            "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        },
        "& svg": { marginRight: 0 },
      },
    },
  })
);

const PanelZoneDynamic = ({ record, ...props }) => {
  const classes = useStyles();
  const [panelZone, setPanelZone] = useState([]);
  const [listCategories, setListCategories] = useState([]);
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const { input } = useField("config");

  const listPositions = {
    name: `${translate("components.position")}`,
    list: [
      {
        id: "1",
        name: `${translate("components.list_positions.center")}`,
        value: "center",
      },
      {
        id: "2",
        name: `${translate("components.list_positions.top_left")}`,
        value: "top-left",
      },
      {
        id: "3",
        name: `${translate("components.list_positions.top_right")}`,
        value: "top-right",
      },
      {
        id: "4",
        name: `${translate("components.list_positions.bottom_left")}`,
        value: "bottom-left",
      },
      {
        id: "5",
        name: `${translate("components.list_positions.bottom_right")}`,
        value: "bottom-right",
      },
    ],
  };
  const listHeight = {
    name: `${translate("components.height")}`,
    list: [
      {
        id: "1",
        name: `${translate("components.size_scale.medium")}`,
        value: "medium",
      },
      {
        id: "2",
        name: `${translate("components.size_scale.small")}`,
        value: "small",
      },
    ],
  };

  const listSizes = {
    name: `${translate("components.size")}`,
    list: [
      {
        id: "2",
        name: `${translate("components.size_scale.medium")} (300 x 150)`,
        value: "medium",
      },
      {
        id: "3",
        name: `${translate("components.size_scale.big")} (400 x 200)`,
        value: "large",
      },
    ],
  };

  useEffect(() => {
    getListCategory();
    if (record) {
      setPanelZone(JSON.parse(record.config));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getListCategory = () => {
    dataProvider
      .getList("categories", {
        pagination: { page: 1, perPage: 10000 },
        sort: { field: "name", order: "ASC" },
        filter: {},
      })
      .then((data) => {
        setListCategories(data.data);
      })
      .catch((error) => {
        setListCategories([]);
      });
  };

  const handleCallbackCatalogPicker = (zoneIndex, itemSelected) => {
    const newPanel = { ...panelZone };
    const newItemSelected = { ...itemSelected, uuid: generateUUID() };
    newPanel[zoneIndex].components.push(newItemSelected);
    setPanelZone(newPanel);
    input.onChange(JSON.stringify(newPanel));
  };

  const handleCallbackUpdateComponent = (
    zoneIndex,
    indexComponent,
    itemSelected
  ) => {
    const newPanel = { ...panelZone };
    newPanel[zoneIndex].components[indexComponent].settings = itemSelected;
    setPanelZone(newPanel);
    input.onChange(JSON.stringify(newPanel));
  };

  const handleCallbackUpdateSettings = (zoneIndex, list) => {
    const newPanel = { ...panelZone };
    const notesUpdate = [];
    list.forEach((elem) => {
      notesUpdate.push(elem.id ? elem : "");
    });
    newPanel[zoneIndex].settings.notes = notesUpdate;
    setPanelZone(newPanel);
    input.onChange(JSON.stringify(newPanel));
  };

  const deleteComponent = (zoneIndex, indexComponent) => {
    const newPanel = { ...panelZone };
    newPanel[zoneIndex].components.splice(indexComponent, 1);
    setPanelZone(newPanel);
    input.onChange(JSON.stringify(newPanel));
  };

  const handleChangeField = useCallback(
    (value, field, index) => {
      const newPanel = { ...panelZone };
      newPanel[index].settings[field] = value;
      setPanelZone(newPanel);
      input.onChange(JSON.stringify(newPanel));
    },
    [panelZone] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleSelectedImage = (value, zoneIndex, field = "image") => {
    handleChangeField(value, field, zoneIndex);
  };

  const handleSaveDynamicZone = useCallback(
    (value, index) => {
      const newPanel = { ...panelZone };
      newPanel[index].components = value;
      setPanelZone(newPanel);
      input.onChange(JSON.stringify(newPanel));
    },
    [panelZone] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    panelZone &&
    Object.keys(panelZone).map((zoneIndex) => (
      <Box
        key={zoneIndex}
        className={`${classes.boxZone} ${
          panelZone[zoneIndex].edit ? "edit" : "noedit"
        }`}
      >
        {panelZone[zoneIndex].edit ? (
          <>
            <Box display="flex" justifyContent="space-between">
              <Typography className={`${classes.heading} edit`} variant="h2">
                {panelZone[zoneIndex].name}
              </Typography>
              <CatalogPicker
                zoneIndex={zoneIndex}
                callback={handleCallbackCatalogPicker}
              />
            </Box>
            {panelZone[zoneIndex].components?.length > 0 && (
              <DraggableList
                list={panelZone[zoneIndex].components}
                zoneIndex={zoneIndex}
                zone={panelZone[zoneIndex]}
                handleCallbackUpdateComponent={(
                  componentIndex,
                  componentData
                ) =>
                  handleCallbackUpdateComponent(
                    zoneIndex,
                    componentIndex,
                    componentData
                  )
                }
                listCategories={listCategories}
                handleSave={(items, index) =>
                  handleSaveDynamicZone(items, index)
                }
                deleteComponent={(componentIndex) =>
                  deleteComponent(zoneIndex, componentIndex)
                }
              />
            )}
          </>
        ) : (
          <>
            <Box display="flex" justifyContent="space-between">
              <Typography className={`${classes.heading} noedit`} variant="h2">
                {panelZone[zoneIndex].name}
              </Typography>
              <DisableItem
                value={panelZone[zoneIndex].settings.show_section}
                zoneIndex={zoneIndex}
                handleChangeField={handleChangeField}
              />
            </Box>
            <Box display="flex" alignItems="end">
              {panelZone[zoneIndex].settings?.image !== undefined && (
                <Box marginRight="1em">
                  <ImageSelect
                    imgSize={{ imgWidth: "220px", imgHeight: "100px" }}
                    zoneIndex={zoneIndex}
                    handleSelected={(value) =>
                      handleSelectedImage(value, zoneIndex)
                    }
                    componentData={panelZone[zoneIndex].settings}
                  />
                </Box>
              )}
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                style={{ gap: "1em 1.2em" }}
              >
                <Box display="flex" flexDirection="column">
                  {panelZone[zoneIndex].settings?.title !== undefined && (
                    <TitleSection
                      value={panelZone[zoneIndex].settings?.title || ""}
                      zoneIndex={zoneIndex}
                      handleChangeField={handleChangeField}
                      helperTextTitle={
                        panelZone[zoneIndex].settings.helperTextTitle || ""
                      }
                    />
                  )}
                  {panelZone[zoneIndex].settings?.description !== undefined && (
                    <DescriptionSection
                      value={panelZone[zoneIndex].settings?.description || ""}
                      zoneIndex={zoneIndex}
                      handleChangeField={handleChangeField}
                      helperTextDescription={
                        panelZone[zoneIndex].settings.helperTextDescription ||
                        ""
                      }
                    />
                  )}
                </Box>
                {panelZone[zoneIndex].settings?.title_image !== undefined && (
                  <Box
                    mr="1em"
                    display="flex"
                    flexDirection="column"
                    style={{
                      border: "1px solid #e6e6e6",
                      display: "flex",
                      padding: "12px 20px",
                      borderRadius: "4px",
                      backgroundColor: "#fafafa",
                    }}
                  >
                    <Typography variant="caption" style={{ fontWeight: 600 }}>
                      {translate("resources.pages.fields.sponsor")}
                    </Typography>
                    <Box mr="1em" display="flex" flexDirection="column">
                      <ImageSelect
                        imgSize={{ imgWidth: "180px", imgHeight: "60px" }}
                        zoneIndex={zoneIndex}
                        handleSelected={(value) =>
                          handleSelectedImage(value, zoneIndex, "title_image")
                        }
                        field={"title_image"}
                        componentData={panelZone[zoneIndex].settings}
                      />
                      <Typography variant="caption">
                        {translate("resources.pages.fields.helper_title_image")}
                      </Typography>
                    </Box>
                    <SelectLink
                      value={panelZone[zoneIndex].settings.title_image_link}
                      zoneIndex={zoneIndex}
                      handleChangeField={handleChangeField}
                      label={"link_sponsor"}
                      field="title_image_link"
                    />
                  </Box>
                )}
                {panelZone[zoneIndex].settings?.category !== undefined && (
                  <SelectCategory
                    value={panelZone[zoneIndex].settings.category}
                    zoneIndex={zoneIndex}
                    handleChangeField={handleChangeField}
                    listCategories={listCategories}
                  />
                )}

                {panelZone[zoneIndex].settings?.link !== undefined && (
                  <SelectLink
                    value={panelZone[zoneIndex].settings.link}
                    zoneIndex={zoneIndex}
                    handleChangeField={handleChangeField}
                    label={"link_section"}
                    field="link"
                  />
                )}
                {panelZone[zoneIndex].settings?.link_iframe !== undefined && (
                  <SelectLink
                    value={panelZone[zoneIndex].settings.link_iframe || ""}
                    zoneIndex={zoneIndex}
                    handleChangeField={handleChangeField}
                    label={"link_iframe"}
                    field="link_iframe"
                  />
                )}
                {panelZone[zoneIndex].settings?.color !== undefined && (
                  <SelectColor
                    value={panelZone[zoneIndex].settings.color}
                    zoneIndex={zoneIndex}
                    handleChangeField={handleChangeField}
                  />
                )}
                {panelZone[zoneIndex].settings?.fontcolor !== undefined && (
                  <SelectFontColor
                    value={panelZone[zoneIndex].settings.fontcolor}
                    zoneIndex={zoneIndex}
                    handleChangeField={handleChangeField}
                  />
                )}
                {panelZone[zoneIndex].settings?.background !== undefined && (
                  <SelectBackGroundColor
                    value={panelZone[zoneIndex].settings.background}
                    zoneIndex={zoneIndex}
                    handleChangeField={handleChangeField}
                  />
                )}
                {panelZone[zoneIndex].settings?.count !== undefined && (
                  <SelectCount
                    value={panelZone[zoneIndex].settings.count}
                    zoneIndex={zoneIndex}
                    handleChangeField={handleChangeField}
                  />
                )}
                {panelZone[zoneIndex].settings?.position !== undefined && (
                  <SelectGeneral
                    value={panelZone[zoneIndex].settings.position}
                    zoneIndex={zoneIndex}
                    handleChangeField={handleChangeField}
                    list={listPositions}
                    field={"position"}
                  />
                )}
                {/** Hero height */}
                {panelZone[zoneIndex].settings?.height !== undefined && (
                  <SelectGeneral
                    value={panelZone[zoneIndex].settings.height}
                    zoneIndex={zoneIndex}
                    handleChangeField={handleChangeField}
                    list={listHeight}
                    field={"height"}
                  />
                )}
                {panelZone[zoneIndex].settings?.size !== undefined && (
                  <SelectGeneral
                    value={panelZone[zoneIndex].settings.size}
                    zoneIndex={zoneIndex}
                    handleChangeField={handleChangeField}
                    list={listSizes}
                    field={"size"}
                  />
                )}
              </Box>
            </Box>
            {panelZone[zoneIndex].settings?.notes && (
              <div className={classes.boxSettings}>
                {panelZone[zoneIndex].settings?.position_hero && (
                  <HeroPosition
                    value={panelZone[zoneIndex].settings.position_hero}
                    zoneIndex={zoneIndex}
                    handleChangeField={handleChangeField}
                  />
                )}
                <Box flex={1}>
                  <NoticiaField
                    handleCallback={handleCallbackUpdateSettings}
                    zoneIndex={zoneIndex}
                    notes={panelZone[zoneIndex].settings?.notes}
                  />
                </Box>
              </div>
            )}
            {panelZone[zoneIndex].settings?.show_tags !== undefined && (
              <ShowTags
                value={panelZone[zoneIndex].settings.show_tags || false}
                handleChangeField={(value, field) =>
                  handleChangeField(value, field, zoneIndex)
                }
              />
            )}
          </>
        )}
        {panelZone[zoneIndex].settings?.components && (
          <SectionGroupComponents
            zoneIndex={zoneIndex}
            handleChangeField={(e) =>
              handleChangeField(e, "components", zoneIndex)
            }
            value={panelZone[zoneIndex]?.settings}
            listCategories={listCategories}
          />
        )}
      </Box>
    ))
  );
};

const PagesEditToolbar = ({
  hasCreate,
  hasShow,
  hasEdit,
  hasList,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Toolbar {...props}>
      <SaveButton disabled={props.pristine} />
      <SaveButton
        label=""
        className={classes.floating}
        disabled={props.pristine}
      />
    </Toolbar>
  );
};

const CheckIsLocked = (props) => {
  const {
    showAlert,
    setShowAlert,
    setFormElement,
    formElement,
    localTimeout,
    setLocalTimeout,
    record,
    resource,
    ...rest
  } = props;
  const classes = useStyles();
  const { identity, loading: identityLoading } = useGetIdentity();
  const dataProvider = useDataProvider();
  const intervalRef = useRef(null);

  useEffect(() => {
    if (!identityLoading && identity) {
      setLockResource();
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [identity]); // eslint-disable-line react-hooks/exhaustive-deps

  const setLockResource = () => {
    if (
      identity.email !== "info@tuxdi.com" &&
      (record.locked_at === null ||
        Math.abs((new Date() - new Date(record.locked_at)) / 1000) >
          PAGES_LOCK_LEAVE)
    ) {
      locked(resource, record.id);
    }
  };

  const locked = (resource, recordId) => {
    dataProvider.locked(resource, recordId);
    intervalRef.current = setInterval(() => {
      dataProvider.locked(resource, recordId);
    }, PAGES_LOCK_ENTER);
  };

  // ========================================================================
  // Component body
  // ========================================================================

  if (identityLoading || !record || !rest.permissions) {
    return <Loading />;
  }

  if (
    record.locked_by &&
    record.locked_at &&
    Math.abs((new Date() - new Date(record.locked_at)) / 1000) <
      PAGES_LOCK_LEAVE &&
    record.locked_by !== identity.id &&
    identity.email !== "info@tuxdi.com"
  ) {
    return <Locked record={record} {...rest} />;
  }
  return (
    <Fragment>
      <Box display="flex" justifyContent="space-between">
        <TextInput disabled source="name" />
      </Box>
      <TextInput className={classes.hidden} source="config" />
      <PanelZoneDynamic {...props} />
    </Fragment>
  );
};

export const PagesEdit = (props) => {
  const notify = useNotify();

  const onSuccess = () => {
    notify("resources.pages.successMessage", { type: "info" });
  };

  return (
    <>
      <ImagePicker permissions={props.permissions} />
      <Edit onSuccess={onSuccess} title=" " undoable={false} {...props}>
        <SimpleForm
          toolbar={<PagesEditToolbar {...props} />}
          submitOnEnter={false}
        >
          <CheckIsLocked permissions={props.permissions} />
        </SimpleForm>
      </Edit>
    </>
  );
};
