import React, { useState, useEffect } from "react";
import Divider from "@material-ui/core/Divider";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";
import NoticiaPicker from "../pickers/NoticiaPicker";
import ImageDefault from "../../assets/img/note-default.png";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import ClearIcon from "@material-ui/icons/Clear";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { useTranslate } from "ra-core";

const useStyles = makeStyles((theme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightBold,
    },
    selectInput: {
      width: "auto",
      minWidth: "250px",
      marginRight: "30px",
    },
    inputLabel: {
      width: "auto",
      minWidth: "250px",
      marginRight: "30px",
    },
    selectNotes: {
      flex: 1,
      marginBottom: "10px",
    },
    img: {
      width: "75px",
      marginRight: "15px",
    },
    numberOrder: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightBold,
      marginRight: "8px",
    },
    ul: {
      listStyleType: "none !important",
    },
    second: {
      marginTop: "1em",
    },
  })
);

const HeroBannerForm = ({ handleCallback, componentData }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const [selectedNote, setSelectedNote] = useState();

  const handleSelectedNote = (value) => {
    componentData.notes = [];
    componentData.notes.push(value);
    handleCallback(componentData);
  };

  const handleDelete = () => {
    componentData.notes[0] = {};
    setSelectedNote({});
    handleCallback(componentData);
  };

  const handleChangeHeight = (event) => {
    componentData.height = event.target.value;
    handleCallback(componentData);
  };

  const handleChangePosition = (event) => {
    componentData.position_hero = event.target.value;
    handleCallback(componentData);
  };

  useEffect(() => {
    if (componentData.notes) {
      setSelectedNote(componentData.notes[0] || {});
    }
  }, [componentData.notes]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box display='flex' justifyContent='space-between'>
      <Box display='flex' flexDirection='column'>
        <FormControl>
          <InputLabel className={classes.inputLabel} id='component-height-hero'>
            {translate("resources.pages.fields.type_hero")}
          </InputLabel>
          <Select
            labelId='component-height-hero'
            label={translate('components.hero_form_title')}
            fullWidth
            input={<Input />}
            onChange={handleChangeHeight}
            className={classes.selectInput}
            value={componentData.height}
          >
            <MenuItem value={"small"}>
              {translate("resources.pages.fields.type_hero_small")}
            </MenuItem>
            <MenuItem value={"medium"}>
              {translate("resources.pages.fields.type_hero_medium")}
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.second}>
          <InputLabel
            className={classes.inputLabel}
            id='component-position-hero'
          >
            {translate("resources.pages.fields.position")}
          </InputLabel>
          <Select
            labelId='component-position-hero'
            label={translate('components.image_position')}
            fullWidth
            input={<Input />}
            onChange={handleChangePosition}
            className={classes.selectInput}
            value={componentData.position_hero}
          >
            <MenuItem value={"center"}>
              {translate("resources.pages.fields.position_center")}
            </MenuItem>
            <MenuItem value={"top"}>
              {translate("resources.pages.fields.position_top")}
            </MenuItem>
            <MenuItem value={"bottom"}>
              {translate("resources.pages.fields.position_bottom")}
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
      {selectedNote && (
        <div className={classes.selectNotes}>
          <Typography className={classes.heading}>{translate("components.note")}</Typography>
          <ListItem className={classes.ul} ContainerComponent='div'>
            <Typography className={classes.numberOrder}>1.</Typography>
            <img
              className={classes.img}
              src={
                selectedNote.featured_image
                  ? selectedNote.featured_image
                  : ImageDefault
              }
              alt=''
            />
            <ListItemText
              primary={
                selectedNote.title_home ? selectedNote.title_home : "Default"
              }
              secondary={selectedNote.copete ? selectedNote.copete : ""}
            />
            <ListItemSecondaryAction>
              {selectedNote.id ? (
                <Tooltip title={translate('ra.action.delete')}>
                  <IconButton
                    edge='start'
                    onClick={() => handleDelete()}
                    aria-label={translate('ra.action.delete')}
                  >
                    <ClearIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <NoticiaPicker
                  viewIcon={true}
                  handleSelectedValue={handleSelectedNote}
                />
              )}
            </ListItemSecondaryAction>
          </ListItem>
          <Divider variant='middle' />
        </div>
      )}
    </Box>
  );
};

export default HeroBannerForm;
