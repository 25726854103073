export const STATUS = [
    { id: 'none', name: 'Ninguna' }, //none
    { id: 'request', name: 'Pedida' }, //request
    { id: 'processing', name: 'Procesando en plan A' }, //processing in plan a
    { id: 'delivered', name: 'Entregado en el medio' }, //delivered to media
    { id: 'delivered_subscriber', name: 'Entregada al cliente' }, //delivered to subscriber
    { id: 'back', name: 'Devuelta' }, //give back
    { id: 'pending_return', name: 'Pendiente de devolver' } //pending to return
];
//TODO use inside of a component for translate
export const TYPE = [
    {id:'extension', name:'Extensión'},
    {id:'titular', name:'Titular' },
];


export const STATE_TRANSLATIONS = {
    request: { name: 'Pedida', chip: { padding: '0 4px', backgroundColor: '#5ca445', color: 'white' } }, //request
    processing: { name: 'Procesando en plan A', chip: { padding: '0 4px', backgroundColor: '#f0c278', color: 'white' } }, //processing in plan a
    delivered: { name: 'Entregado en el medio', chip: { padding: '0 4px', backgroundColor: '#9ec999', color: 'white' } }, //delivered to media
    delivered_subscriber: { name: 'Entregada al cliente', chip: { padding: '0 4px', backgroundColor: '#CAE6C7', color: 'white' } }, //delivered to subscriber
    back: { name: 'Devuelta', chip: { padding: '0 4px' } }, //give back
    pending_return: { name: 'Pendiente de devolver', chip: { padding: '0 4px', backgroundColor: '#D11616', color: 'white' } }, //pending to return
    none: { name: 'Ninguna', chip: { padding: '0 4px' } } //none
};