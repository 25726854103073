import React, { useState } from "react";
import { httpClient, apiUrl } from "../../utils/utilsFetch";
import { useInput, useTranslate } from "react-admin";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from '@material-ui/icons/Done';
import InputAdornment from "@material-ui/core/InputAdornment";

const SlugNoteField = (props) => {
  const translate = useTranslate();
  const [customError, setCustomError] = useState("");
  const [edit, setEdit] = useState(false);

  const {
    input: { name, onChange, onBlur, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props);

  const checkSlugUnique = (e) => {
    const currentSlug = e.target.value;
    const recordId = props.record?.id;

    if (currentSlug && recordId) {
      httpClient(`${apiUrl}/notes/check/${recordId}/${currentSlug}`, {
        method: "GET",
      }).then(({ json }) => {
        if (!json.available) {
          setCustomError(translate("ra.notification.link_used"));
        }
      });
    }
    onBlur(e);
    return;
  };

  const handleClickEdit = () => {
    setEdit(!edit);
  };

  const handleMouseDownEdit = (event) => {
    event.preventDefault();
  };

  return (
    <TextField
      fullWidth
      label={translate("resources.notes.fields.slug")}
      InputProps={{
        startAdornment: <InputAdornment position="start">{`/`}</InputAdornment>,
        endAdornment:
          <IconButton
            aria-label={translate("ra.action.edit")}
            onClick={handleClickEdit}
            onMouseDown={handleMouseDownEdit}
            edge="end"
          >
            {edit ? <DoneIcon />: <EditIcon /> }
          </IconButton>
      }}
      name={"slug"}
      disabled={!edit}
      variant="outlined"
      onChange={(e) => {
        setCustomError("");
        onChange(e);
      }}
      error={customError ? true : !!(touched && error)}
      helperText={
        customError ||
        (touched && error && translate(error)) ||
        (edit && translate("resources.notes.slug_helper_text")) || null
      }
      required={isRequired}
      onBlur={(e) => checkSlugUnique(e)}
      margin="normal"
      {...rest}
    />
  );
};

export default SlugNoteField;
