import React, { useEffect, useState } from "react";
import { httpClient, apiUrl } from './utilsFetch';
import { Alert } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import { useLoading,useTranslate } from 'ra-core';

const useAutosave = (formState, currentAutosave, setCurrentAutosave, showAlert, id_parent = null) => {

    // ========================================================================
    // Params
    // ========================================================================
    const localTimeoutAmount = 10000; // 10 segundos
    const serverCooldownAmount = (300000); //5 minutos;

    // ========================================================================
    // Function body
    // ========================================================================
    const autosaveLocalName = 'autosave_data_' + id_parent || 'null';
    const loading = useLoading();

    useEffect(() => {
        if (localStorage.getItem(autosaveLocalName) && !currentAutosave.updated_at) {
            setCurrentAutosave(JSON.parse(localStorage.getItem(autosaveLocalName)));
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // Previene el guardado cuando el formulario no presento cambios
        if (!formState.pristine && !loading && !showAlert) {
            changedAction();
        }
    }, [formState.values]); // eslint-disable-line react-hooks/exhaustive-deps

    // Helpers
    const autosaveExcludedFields = ['enable_comments', 'id'];
    const getFormData = () => {
        const autosaveData = { ...formState.values, id_parent: id_parent }
        autosaveExcludedFields.forEach(item => {
            delete autosaveData[item];
        });
        return autosaveData;
    }

    const resetTimeout = (id, newID) => {
        clearTimeout(id)
        return newID
    }

    const [localTimeout, setLocalTimeout] = useState(null);
    const changedAction = () => {
        setLocalTimeout(
            resetTimeout(localTimeout, setTimeout(autosaveLocal, localTimeoutAmount))
        )
    }

    // Local y server, en caso de no estar en cooldown
    const [serverCooldown, setServerCooldown] = useState(false);
    const [saveAgain, setSaveAgain] = useState(false);

    const autosaveLocal = () => {
        const autosaveData = getFormData();
        const newData = { ...currentAutosave, ...autosaveData, updated_at: Date.now() }; // Para mantener el ID en caso de que este seteado

        setCurrentAutosave(newData);
        localStorage.setItem(autosaveLocalName, JSON.stringify(newData));

        // Dispara guardado en el servidor, si no esta en "cooldown"
        if (!serverCooldown) {
            autosaveServer();
        } else {
            // Si esta en cooldown, guardamos la peticion de guardado para cuando termine
            setSaveAgain(true);
        }
    }

    // Server
    const autosaveServer = () => {
        setServerCooldown(true);

        const autosaveData = getFormData();

        // currentAutosave.id es el valor que usamos para verificar si la nota actual existe en el servidor (PUT) o no (POST)
        const method = (currentAutosave.id)
            ? 'PUT'
            : 'POST';
        const url = (currentAutosave.id)
            ? `${apiUrl}/note_autosaves/${currentAutosave.id}`
            : `${apiUrl}/note_autosaves`;

        httpClient(url, {
            method: method,
            body: JSON.stringify(autosaveData),
        }).then(({ json }) => {
            // Primer guardado de la sesión
            if (!currentAutosave.id) setCurrentAutosave({ ...currentAutosave, id: json.id })
            return { data: json }
        })

        setTimeout(() => {
            setServerCooldown(false);
        }, serverCooldownAmount);

    };

    // Chequeamos si hubo algún cambio luego del último guardado, y re-triggereamos el proceso.
    useEffect(() => {
        if (!serverCooldown && saveAgain) {
            setSaveAgain(false);
            autosaveServer();
        }
    }, [serverCooldown]) // eslint-disable-line react-hooks/exhaustive-deps

}

// ========================================================================
// UTILS - No se exportan
// ========================================================================
const updatedDate = (obj) => {
    if (Date.parse(obj.updated_at)) {
        return new Date(Date.parse(obj.updated_at))
    } else {
        return new Date(obj.updated_at);
    }
}

const getLocalAutosave = (id_parent = null) => {
    const autosaveLocalName = 'autosave_data_' + (id_parent ? id_parent : 'null');
    return JSON.parse(localStorage.getItem(autosaveLocalName));
}

const getLastAutosave = (server, id_parent = null) => {

    const local = getLocalAutosave(id_parent) || {};

    if (!server.id) return local;
    if (!local.updated_at) return server;

    if (updatedDate(server) > updatedDate(local)) {
        return server;
    } else {
        return local;
    }
}

// ========================================================================
// Inicialización al crear nuevas notas
// ========================================================================
const initAutosave = (setData, parentNote = null) => {
    // Server
    httpClient(apiUrl + '/note_autosaves/' + ((parentNote) ? parentNote.id : ''), {
        method: 'GET'
    }).then(({ json }) => {
        if (parentNote) {
            const autosaveData = getLastAutosave(json, parentNote.id);

            // Compara la fecha del autosave para saber si es más reciente que los datos existentes
            if (autosaveData.updated_at && updatedDate(autosaveData) > updatedDate(parentNote)) {
                setData({ ...autosaveData, id: parentNote.id });
            } else {
                setData({ id: parentNote.id }); // Fuerza el id, en caso de estar actualizando una nota existente, para que no vaya al slot NULL
            }
        } else {
            // Nueva nota
            setData(getLastAutosave(json));
        }
    });
}

// ========================================================================
// Alerta de copia existente
// ========================================================================
const AutosaveAlert = ({ showAlert, setShowAlert, form, autosaveNote, children }) => {
    const translate = useTranslate();
    const handleCloseAlert = () => {
        setShowAlert(false);
    }

    const loading = useLoading();

    // Carga programatica de nuevos datos en el formulario
    const loadAutosave = () => {
        handleCloseAlert();
        Object.keys(autosaveNote).forEach(key => {
            form.change(key, autosaveNote[key]);
        });
    }

    return <Snackbar open={showAlert && !loading} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert variant="filled" severity="info" style={{ alignItems: 'center' }} onClose={handleCloseAlert}>
            {children}
            <Button onClick={loadAutosave} color="inherit" size="small" style={{ marginLeft: '8px' }}>{translate("components.restore_note")}</Button>
            <Button onClick={handleCloseAlert} color="inherit" size="small" style={{ marginLeft: '8px' }}>{translate("ra.action.discard")}</Button>
        </Alert>
    </Snackbar>;
}

export { initAutosave, AutosaveAlert };
export default useAutosave;