import React, { useState } from "react";
import CardWithIcon from "../../components/CardWithIcon/CardWithIcon";
import LineChart from "../../components/LineChart/LineChart";
import BarChart from "@material-ui/icons/BarChart";
import { useTranslate } from "ra-core";
import PieChartCard from "../../components/PieChartCard/PieChartCard";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MetricForm from "./UserMetricForm";
// import { Alert } from '@material-ui/lab';
// import mockData from './mock.json';

const styles = {
  container: { display: "flex", padding: "0 0 0 16px" },
  flex: { display: "flex", marginBottom: "1em" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftSpacer: { flex: 1 },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  toolbar: {
    display: "flex",
    alignItems: "flex-start",
    gap: "1em",
    minWidth: "100%",
  },
  centered: { display: "flex", justifyContent: "center" },
};

const useStyles = makeStyles({
  formEmpty: {
    "& div": {
      display: "flex",
      marginRight: "8px",
    },
  },
  formTop: {
    "& div": {
      display: "flex",
      marginRight: "4px",
      maxWidth: "180px",
    },
  },
});

const Spacer = () => <span style={{ width: "1em" }} />;

// ========================================================================
// Componente principal
// ========================================================================
const ViewUsers = (props) => {
  const translate = useTranslate();
  const [data, setData] = useState(null);
  const [formData, setFormData] = useState({});
  const [serverData, setServerData] = useState([]);
  const classes = useStyles();

  // Debug
  // useEffect(() => {
  //     setData(userMetrics(mockData));
  // }, []);

  // ========================================================================
  // Component body
  // ========================================================================
  return (
    <>
      {data ? (
        <>
          <div style={styles.header}>
            <MetricForm
              data={data}
              jss={classes.form + " " + classes.formTop}
              setData={setData}
              serverdata={serverData}
              setserverdata={setServerData}
              formdata={formData}
              setformdata={setFormData}
            />
            <div></div>
          </div>
          <div style={styles.container}>
            <div style={styles.leftCol}>
              {data.notesPerDay && (
                <LineChart
                  title={translate("resources.metrics.titles.content_per_day")}
                  data={data.notesPerDay}
                />
              )}
              {data.notesPerUser && (
                <div style={styles.singleCol}>
                  <PieChartCard
                    title={translate(
                      "resources.metrics.titles.content_per_user"
                    )}
                    data={data.notesPerUser}
                  />
                </div>
              )}
            </div>
            <div style={styles.rightCol}>
              <div style={styles.flex}>
                <CardWithIcon
                  icon={BarChart}
                  title={translate("resources.metrics.titles.content_total")}
                  subtitle={data.notesTotal}
                />
                <Spacer />
                <CardWithIcon
                  icon={BarChart}
                  title={translate("resources.metrics.titles.content_paper")}
                  subtitle={data.notesPaper}
                ></CardWithIcon>
              </div>
              <div style={styles.flex}>
                <CardWithIcon
                  icon={BarChart}
                  title={translate("resources.metrics.titles.characters_free")}
                  subtitle={data.charAvFree}
                ></CardWithIcon>
                <Spacer />
                <CardWithIcon
                  icon={BarChart}
                  title={translate(
                    "resources.metrics.titles.content_free_premium"
                  )}
                  subtitle={data.notesFreePremium}
                ></CardWithIcon>
              </div>
              <div style={styles.flex}>
                <CardWithIcon
                  icon={BarChart}
                  title={translate(
                    "resources.metrics.titles.characters_premium"
                  )}
                  subtitle={data.charAvPremium}
                ></CardWithIcon>
                <Spacer />
                <CardWithIcon
                  icon={BarChart}
                  title={translate(
                    "resources.metrics.titles.content_only_free"
                  )}
                  subtitle={data.notesFree}
                ></CardWithIcon>
              </div>
              <div style={styles.flex}>
                <CardWithIcon
                  icon={BarChart}
                  title={translate("resources.metrics.titles.characters_paper")}
                  subtitle={data.charAvPaper}
                ></CardWithIcon>
                <Spacer />
                <CardWithIcon
                  icon={BarChart}
                  title={translate(
                    "resources.metrics.titles.content_only_premium"
                  )}
                  subtitle={data.notesPremium}
                ></CardWithIcon>
              </div>
              {data.notesUnknown && data.notesUnknown > 0 ? (
                <div style={styles.flex}>
                  <div style={styles.leftSpacer} />
                  <Spacer />
                  <CardWithIcon
                    icon={BarChart}
                    title={translate("resources.metrics.titles.other_content")}
                    subtitle={data.notesUnknown}
                  ></CardWithIcon>
                </div>
              ) : ""}
            </div>
          </div>
        </>
      ) : (
        <Box textAlign="center" m={1} maxWidth={1200}>
          <BarChart style={{ fontSize: "150px" }} color="disabled" />
          <Typography variant="h6">
            {translate("resources.metrics.validation.empty_message")}
          </Typography>
          <Box style={styles.centered} m={1}>
            <MetricForm
              serverdata={serverData}
              data={data}
              setserverdata={setServerData}
              jss={classes.form + " " + classes.formEmpty}
              setData={setData}
              formdata={formData}
              setformdata={setFormData}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default ViewUsers;
