import * as React from "react";
import {
    CreateButton,
    Filter,
    ListBase,
    Pagination,
    SearchInput,
    TopToolbar,
} from 'react-admin';
import { Box, useMediaQuery } from '@material-ui/core';
import GridList from './GridList';

export const MediaList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <ListBase
            perPage={20}
            sort={{ field: 'created_at', order: 'DESC' }}
            {...props}
        >
            <MediaListView isSmall={isSmall} {...props} />
        </ListBase>
    );
};

const MediaListView = ({ permissions, isSmall, ...props }) => {
    return (
        <>
            <ListActions permissions={permissions} />
            <Box display="flex">
                <Box width={isSmall ? 'auto' : '100%'} margin="8px">
                    <GridList />
                    <Pagination rowsPerPageOptions={[20, 40, 60]} />
                </Box>
            </Box>
        </>
    );
};

const MediaFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="search" alwaysOn />
    </Filter>
);

const ListActions = ({ permissions }) => (
    <TopToolbar>
        <Box display="flex" justifyContent="space-between" width="100%">
            <Box m={1}>
                <MediaFilter context="form" />
            </Box>
            {permissions && permissions.some(permission => permission.name === '*' || permission.name === 'create-media') &&
                <Box display="flex" m={1}>
                    <CreateButton
                        variant="contained"
                        style={{ padding: "4px 20px 4px 10px", marginBottom:"10px", height: 'fit-content', alignSelf: 'flex-end' }}
                        basePath="/media"
                    />
                </Box>
            }
        </Box>
    </TopToolbar>
);