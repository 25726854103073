import * as React from 'react';
import { Link } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
}));

const FullNameField = ({ record }) => {
    const classes = useStyles();
    return record ? (
        <Link to={`/authors/${record.id}`}>
            <div className={classes.root}>
                <Avatar
                    src={record.avatar ? `${record.avatar}?${Math.random()}&size=${25}x${25}` : null}
                    style={{ width: 25, height: 25, marginRight: "12px" }}
                />
                {record.first_name} {record.last_name}
            </div>
        </Link>

    ) : null;
};

export default React.memo(FullNameField);
