import * as React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import { DashboardMenuItem, MenuItemLink, getResources } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import DefaultIcon from "@material-ui/icons/ViewList";
import classnames from "classnames";
import SubMenu from "./SubMenu";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import StoreIcon from "@material-ui/icons/Store";
import MoneyIcon from "@material-ui/icons/AttachMoney";
import BarChart from "@material-ui/icons/BarChart";
import HistoryIcon from "@material-ui/icons/History";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import ListAltIcon from "@material-ui/icons/ListAlt";
import ViewQuiltIcon from "@material-ui/icons/ViewQuilt";
import { MembersIcon } from "../Icons/MembersIcon/MembersIcon";
import { BenefitsIcon } from "../Icons/BenefitsIcon/BenefitsIcon";
import { PubliclyIcon } from "../Icons/Plublicy/Publicy";
import AssignmentIconDef from '@material-ui/icons/Assignment';
import ClassIcon from '@material-ui/icons/Class';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';

const useStyles = makeStyles(
  (theme) => ({
    main: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      marginTop: "0.5em",
      marginBottom: "2em",
      [theme.breakpoints.only("xs")]: {
        marginTop: 0,
      },
      [theme.breakpoints.up("md")]: {
        marginTop: "1.5em",
      },
    },
  }),
  { name: "RaMenu" }
);

const capitalize = (text) => text.charAt(0).toUpperCase() + text.slice(1);

const EXCLUDE_RESOURCES = [
  "club_cards",
  "benefits",
  "metrics-users",
  "metrics-subscriptions",
  "visited-url",
  "combos",
  "amedia",
  "orders",
  "plans",
  "members",
  "shop",
  "metric_benefits",
  "entities",
  "types",
  "relations",
  "relation_types"

];
const Menu = (props) => {
  const {
    classes: classesOverride,
    className,
    dense,
    hasDashboard,
    onMenuClick,
    logout,
    ...rest
  } = props;

  const pageUrlActive = useSelector((state) => state.router.location.pathname);
  const [state, setState] = React.useState({
    clubMenu:
      pageUrlActive &&
      (pageUrlActive.includes("club_cards") ||
        pageUrlActive.includes("benefits")),
    metricsMenu:
      pageUrlActive &&
      (pageUrlActive.includes("visited-url") ||
        pageUrlActive.includes("metrics-users")),
    advertisements:
      pageUrlActive &&
      (pageUrlActive.includes("orders") ||
        pageUrlActive.includes("amedia") ||
        pageUrlActive.includes("combos")),
    membership:
      pageUrlActive &&
      (pageUrlActive.includes("plans") ||
        pageUrlActive.includes("members") ||
        pageUrlActive.includes("metrics-subscriptions")),
    entitiesSub:
      pageUrlActive &&
      (pageUrlActive.includes("entities")
        || pageUrlActive.includes("type")
      )
  });

  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const classes = useStyles(props);
  const resources = useSelector(getResources);

  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <div className={classnames(classes.main, className)} {...rest}>
      {hasDashboard && (
        <DashboardMenuItem
          onClick={onMenuClick}
          dense={dense}
          sidebarIsOpen={open}
        />
      )}
      {resources.map((resource) =>
        resource.hasList && !EXCLUDE_RESOURCES.includes(resource.name) ? (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={
              (resource.options && resource.options.label) ||
              capitalize(resource.name)
            }
            leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        ) : (
          <React.Fragment key={resource.name}>
            {resource.name === "submenu_club" ? (
              <SubMenu
                handleToggle={() => handleToggle("clubMenu")}
                isOpen={state.clubMenu}
                sidebarIsOpen={open}
                name={
                  (resource.options && resource.options.label) ||
                  capitalize(resource.name)
                }
                icon={<BenefitsIcon width="24px" height="24px" />}
              >
                {resource.options.labelClub ? (
                  <MenuItemLink
                    to={"/club_cards"}
                    primaryText={resource.options && resource.options.labelClub}
                    leftIcon={<CreditCardIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                  />
                ) : null}
                {resource.options.labelBenefit ? (
                  <MenuItemLink
                    to={"/benefits"}
                    primaryText={
                      resource.options && resource.options.labelBenefit
                    }
                    leftIcon={<CardGiftcardIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                  />
                ) : null}
                {resource.options.labelShop ? (
                  <MenuItemLink
                    to={"/shop"}
                    primaryText={resource.options && resource.options.labelShop}
                    leftIcon={<StoreIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                  />
                ) : null}
                {resource.options.labelMetric ? (
                  <MenuItemLink
                    to={"/metric_benefits"}
                    primaryText={resource.options && resource.options.labelMetric}
                    leftIcon={<BarChart />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                  />
                ) : null}
              </SubMenu>
            ) : resource.name === "submenu_metrics" ? (
              <SubMenu
                handleToggle={() => handleToggle("metricsMenu")}
                isOpen={state.metricsMenu}
                sidebarIsOpen={open}
                name={
                  (resource.options && resource.options.label) ||
                  capitalize(resource.name)
                }
                icon={<BarChart />}
              >
                {resources.findIndex((e) => e.name === "metrics-users") !==
                  -1 && (
                    <MenuItemLink
                      to={"/metrics-users"}
                      primaryText={
                        resource.options && resource.options.labelUsers
                      }
                      leftIcon={<BarChart />}
                      onClick={onMenuClick}
                      sidebarIsOpen={open}
                    />
                  )}

                <MenuItemLink
                  to={"/visited-url"}
                  primaryText={
                    resource.options && resource.options.labelHistory
                  }
                  leftIcon={<HistoryIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                />
              </SubMenu>
            ) : resource.name === "submenu_membership" ? (
              <SubMenu
                handleToggle={() => handleToggle("membership")}
                isOpen={state.membership}
                sidebarIsOpen={open}
                name={
                  (resource.options && resource.options.label) ||
                  capitalize(resource.name)
                }
                //icon={<DvrIcon />}
                icon={<MembersIcon />}
              >
                <MenuItemLink
                  to={"/plans"}
                  primaryText={resource.options && resource.options.labelPlans}
                  leftIcon={<ViewQuiltIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                />
                <MenuItemLink
                  to={"/members"}
                  primaryText={
                    resource.options && resource.options.labelSubscriptions
                  }
                  leftIcon={<ListAltIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                />
                <MenuItemLink
                  to={"/metrics-subscriptions"}
                  primaryText={
                    resource.options &&
                    resource.options.labelMetricSubscriptions
                  }
                  leftIcon={<MoneyIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                />
              </SubMenu>
            ) : resource.name === "submenu_advertisements" ? (
              <SubMenu
                handleToggle={() => handleToggle("advertisements")}
                isOpen={state.advertisements}
                sidebarIsOpen={open}
                name={
                  (resource.options && resource.options.label) ||
                  capitalize(resource.name)
                }
                //icon={<DvrIcon />}
                icon={<PubliclyIcon />}
              >
                <MenuItemLink
                  to={"/orders"}
                  primaryText={
                    resource.options && resource.options.labelAdOrders
                  }
                  leftIcon={<ViewQuiltIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                />
                <MenuItemLink
                  to={"/amedia"}
                  primaryText={
                    resource.options && resource.options.labelAdmedia
                  }
                  leftIcon={<ListAltIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                />
                <MenuItemLink
                  to={"/combos"}
                  primaryText={
                    resource.options && resource.options.labelAdCombos
                  }
                  leftIcon={<AllInboxIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                />
              </SubMenu>
            ) :
              resource.name == "entitiesSub" ? (<SubMenu
                handleToggle={() => handleToggle("entitiesSub")}
                isOpen={state.entitiesSub}
                sidebarIsOpen={open}
                name={
                  (resource.options && resource.options.label) ||
                  capitalize(resource.name)
                }
                icon={<AssignmentIconDef />}>
                <MenuItemLink
                  to={"/entities"}
                  primaryText={
                    resource.options && resource.options.labelEntity
                  }
                  leftIcon={<AssignmentIconDef />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                />
                <MenuItemLink
                  to={"/types"}
                  primaryText={
                    resource.options && resource.options.labelType
                  }
                  leftIcon={<ClassIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                />
              </SubMenu>) : null
            }
          </React.Fragment>
        )
      )}
      {isXSmall && logout}
    </div>
  );
};

export default Menu;
