import {
  Box,
  Button,
  Typography,
  MenuItem,
  Select,
  Tooltip,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import SaveIcon from "@material-ui/icons/Save";
import medioData from "Static/data/medio.json";
import { useTranslate } from "ra-core";
import { useEffect, useMemo } from "react";
import {
  BooleanInput,
  DateTimeInput,
  FormDataConsumer,
  RadioButtonGroupInput,
  ReferenceInput,
  SelectInput,
  required,
} from "react-admin";
import { useField, useFormState } from "react-final-form";
import MultiNoticiaPicker from "../../components/pickers/MultiNoticiaPicker";
import { showResource } from "../../providers/configProvider";
import { validateDatePublish } from "../../utils/validations";
import { SeoCheck } from "../SeoChecker/SeoChecker";
import { AudioNoteField } from "../fields/AudioNoteField";
import { CategoryPicker } from "../fields/CategoryPicker";
import CopyEditorToAnotherMedia from "../fields/CopyEditorToAnotherMedia";
import CopyFieldsToAnotherMedia from "../fields/CopyFieldsToAnotherMedia";
import CustomTextField from "../fields/CustomTextField";
import EntitySelects from "../fields/EntitySelects";
import { GalleryNoteField } from "../fields/GalleryNoteField";
import HtmlEditor from "../fields/HtmlEditor";
import { ImageFeatured } from "../fields/ImageFeatured";
import { CorrectedField } from "../fields/InputCorrectedField";
import { SeoFields } from "../fields/SeoFields";
import SlugNoteField from "../fields/SlugNoteField";
import TagsField from "../fields/TagsField";
import TitleHomeField from "../fields/TitleHomeField";
import { VideoNoteField } from "../fields/VideoNoteField";
import { PreviewButton } from "./PreviewButton";

const SaveWithNoteButton = (props) => {
  const classes = useStyles();
  const formState = useFormState();
  const translate = useTranslate();
  return (
    <Button
      variant="contained"
      color="primary"
      size="medium"
      className={classes.saveButton}
      startIcon={<SaveIcon />}
      disabled={formState.pristine}
      onClick={() => {
        document.querySelector("#publish-notes")?.click();
      }}
    >
      {props.record && props.record.id
        ? `${translate("ra.action.save")}`
        : `${translate("ra.action.publish")}`}
    </Button>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    saveButton: {
      display: "none",
      [theme.breakpoints.up("lg")]: {
        display: "flex",
      },
    },
  })
);

const FormNotesInternet = (props) => {
  const formState = useFormState();
  const { input: date_publish } = useField("date_publish");
  const translate = useTranslate();

  useEffect(() => {
    if (formState.values.status) {
      if (formState.values.status === "scheduled") {
        const minutes = new Date(date_publish.value).getMinutes();
        if (minutes !== 0 && minutes !== 30) {
          date_publish.onChange(undefined);
        }
      } else if (formState.values.status === "publish" && props?.record) {
        if (props.record.status !== formState.values.status) {
          date_publish.onChange(new Date());
        } else if (props.record.date_publish !== date_publish.value) {
          date_publish.onChange(new Date(props.record.date_publish));
        }
      }
    }
  }, [formState.values.status]); // eslint-disable-line react-hooks/exhaustive-deps

  const dateDefaultValue = useMemo(() => new Date(), []);

  const getChoicesStatus = () => {
    if (props.record && props.record.id) {
      return [
        {
          id: "draft",
          name: `${translate("resources.notes.status.draft")}`,
        },
        {
          id: "publish",
          name: `${translate("resources.notes.status.published")}`,
        },
        {
          id: "scheduled",
          name: `${translate("resources.notes.status.scheduled")}`,
        },
      ];
    }
    return [
      {
        id: "draft",
        name: `${translate("resources.notes.status.draft")}`,
      },
      {
        id: "publish",
        name: `${translate("resources.notes.status.publish")}`,
      },
      {
        id: "scheduled",
        name: `${translate("resources.notes.status.schedule")}`,
      },
    ];
  };

  const userInterest = [
    {
      name: translate("resources.notes.user_interest.inform_name"),
      tip: translate("resources.notes.user_interest.inform_tip"),
    },
    {
      name: translate("resources.notes.user_interest.educate_name"),
      tip: translate("resources.notes.user_interest.educate_tip"),
    },
    {
      name: translate("resources.notes.user_interest.distract_name"),
      tip: translate("resources.notes.user_interest.distract_tip"),
    },
    {
      name: translate("resources.notes.user_interest.inspire_name"),
      tip: translate("resources.notes.user_interest.inspire_tip"),
    },
  ];
  return (
    <>
      <Box
        display="flex"
        style={{
          position: "relative",
          width: "100%",
          justifyContent: "flex-end",
          gap: "8px",
          flexWrap: "wrap",
          marginTop: "16px",
        }}
      >
        <SeoCheck />
        {showResource("paper") && (
          <Box>
            <CopyFieldsToAnotherMedia
              title={translate("components.copy_paper")}
              copyTo={"papel"}
              {...props}
            />
          </Box>
        )}
        <PreviewButton resource={"note"} />
        <SaveWithNoteButton {...props} />
      </Box>
      <Box p="1em">
        <MultiNoticiaPicker
          permissions={props.permissions}
          currentNote={props.record?.id ? [props.record.id] : null}
          selectedNotes={[]}
        />
        <Box display={{ md: "block", lg: "flex" }}>
          <Box
            flex={2}
            mr="1em"
            position="relative"
            top={{ sm: "0px", lg: "-80px" }}
          >
            {props.record && props.record.id ? (
              <SlugNoteField
                source="slug"
                record={props.record}
                resource="notes"
                validate={required()}
                isRequired={true}
              />
            ) : null}

            {!medioData?.hidden_volanta ? (
              <CustomTextField
                label={translate("resources.notes.fields.volanta")}
                source="volanta"
                record={props.record}
                resource="notes"
                isRequired={!medioData.volanta_norequired}
              />
            ) : null}

            <CustomTextField
              label={`${translate("resources.notes.fields.title")}`}
              source="title"
              record={props.record}
              resource="notes"
              isRequired={true}
            />
            <TitleHomeField
              label={translate("resources.notes.fields.title_home")}
              source="title_home"
              record={props.record}
              resource="notes"
            />
            <CustomTextField
              label={translate("resources.notes.fields.copete")}
              source="copete"
              record={props.record}
              resource="notes"
            />
            <Box mt="1em" />
            {showResource("premium") && (
              <Box display="flex" mr="0.5em">
                <Box flex={1} mr="0.5em">
                  <Typography variant="h6" gutterBottom>
                    {translate("components.free_editor")}
                  </Typography>
                </Box>
                <Box flex={1} ml="0.5em">
                  <CopyEditorToAnotherMedia
                    title={translate("components.copy")}
                    copyFrom={"free"}
                    {...props}
                  />
                </Box>
              </Box>
            )}
            <Box flex={1} mr="0.5em">
              <HtmlEditor source="content" />
            </Box>
            <Box mt="1em" />
            {showResource("premium") && (
              <>
                <Box display="flex" mr="0.5em">
                  <Box flex={1} mr="0.5em">
                    <Typography variant="h6" gutterBottom>
                      {translate("components.premium_editor")}
                    </Typography>
                  </Box>
                  <Box flex={1} ml="0.5em">
                    <CopyEditorToAnotherMedia
                      title={translate("components.copy")}
                      copyFrom={"premium"}
                      {...props}
                    />
                  </Box>
                </Box>
                <Box flex={1} mr="0.5em">
                  <HtmlEditor source="content_premium" />
                </Box>
              </>
            )}
          </Box>
          <Box flex={1} ml={{ lg: "1em" }} position="relative">
            <SelectInput
              label={"resources.notes.fields.status"}
              source="status"
              defaultValue="draft"
              fullWidth
              choices={getChoicesStatus()}
            />
            <DateTimeInput
              source="date_publish"
              label="resources.notes.fields.date_publish"
              fullWidth
              defaultValue={dateDefaultValue}
              validate={[validateDatePublish(), required()]}
            />
            <CorrectedField source={"corrected"} />
            <ImageFeatured resource="notes" record={props.record} fullWidth />
            <CustomTextField
              label={translate("resources.notes.fields.epigrafe")}
              source="epigrafe"
              record={props.record}
              resource="notes"
            />
            <CategoryPicker />
            <TagsField
              record={props.record}
              source="tags"
              name="tags"
              validate={!medioData.tags_norequired ? required() : null}
              isRequired={!medioData.tags_norequired}
              fullWidth
            />
            {medioData?.has_entities ? (
              <EntitySelects
                record={props.record}
                source="entities"
                name="entities"
                fullWidth
              />
            ) : null}
            {
              medioData?.show_interests ? (
                <RadioButtonGroupInput
                  source="interests"
                  label="resources.notes.fields.interest_user_category"
                  choices={userInterest}
                  fullWidth
                  optionValue="name"
                  optionText={(choice) => (
                    <Tooltip title={choice.tip || ''} >
                      <span>{choice.name}</span>
                    </Tooltip>
                  )}
                />
              ) : null
            }
            <RadioButtonGroupInput
              label="resources.notes.fields.format"
              source="format"
              fullWidth
              defaultValue="standard"
              choices={[
                {
                  id: "standard",
                  name: `${translate("resources.notes.format.standard")}`,
                },
                {
                  id: "audio",
                  name: `${translate("resources.notes.format.audio")}`,
                },
                {
                  id: "gallery",
                  name: `${translate("resources.notes.format.gallery")}`,
                },
                {
                  id: "video",
                  name: `${translate("resources.notes.format.video")}`,
                },
              ]}
            />
            <FormDataConsumer>
              {({ formData }) =>
                formData.format === "audio" ? (
                  <AudioNoteField
                    resource="notes"
                    record={props.record}
                    fullWidth
                  />
                ) : formData.format === "video" ? (
                  <VideoNoteField
                    resource="notes"
                    record={props.record}
                    fullWidth
                  />
                ) : formData.format === "gallery" ? (
                  <GalleryNoteField
                    resource="notes"
                    record={props.record}
                    fullWidth
                  />
                ) : null
              }
            </FormDataConsumer>
            <ReferenceInput
              label={translate("resources.notes.fields.author")}
              source="author_id"
              reference="authors"
              perPage={1000}
              sort={{ field: "first_name", order: "ASC" }}
              allowEmpty
              fullWidth
            >
              <SelectInput optionText="display_name" />
            </ReferenceInput>

            <SeoFields />
            <BooleanInput
              label={translate("resources.notes.fields.enable_comments")}
              source="enable_comments"
              defaultValue={
                props.record
                  ? props.record.enable_comments === false
                    ? 0
                    : 1
                  : 1
              }
              fullWidth
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default FormNotesInternet;
