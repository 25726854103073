import { lighten } from "../../../utils/sassFunction";

export const colors = [
    { name: "primary", title:"Primary", value: '#1f3a4f'},
    { name: "primaryHover", title:"Alternative primary", value: '#14a9d4'},
    { name: "secondary",title:"Secondary" ,value: '#6dac97'},
    { name: "clubPrimary",title:"Primary Club" ,value: '#2090b5'},
    { name: "clubSecondary",title:"Secondary Club" , value: '#6dac97'},
    { name: "success", title:"Green", value: '#98bf17'},
    { name: "warning", title:"Yellow",value: '#fbbc04'},
    { name: "danger", title:"Red",value: '#b9000b'},
    { name: "info", title:"Info",value: '#14a9d4'},
    { name: "white", title:"White",value: '#ffffff'},
    { name: "light", title:"Clear", value: '#dfdfdf'},
    { name: "grey", title:"Grey",value: '#6f6f6f'},
    { name: "dark", title:"Dark",value: '#404040'},
    { name: "black", title:"Black", value: '#1b1b1b'},
    { name: "bronce", title:"Bronze",value: '#e4d7b6'},
    { name: "vibrant", title:"Alive",value: '#ff7a00'},
    { name: "clubVibrant", title:"Alive Club", value: '#c85529'},
    { name: "radio", title:"Radio", value: '#ff7a00'},
];

export const colorsalert = [
    { name: "light", title:"Default", value: `${lighten("#6f6f6f",48)}`}, 
    { name: "success",title:"Event", value: '#98bf17'},
    { name: "warning", title:"Alert", value: '#fbbc04'},
    { name: "danger",title:"Danger", value: '#b9000b'},
];