import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  required,
  ReferenceInput,
  SelectInput,
  ArrayInput,
  BooleanInput,
  minValue,
  number,
  useTranslate,
  FormDataConsumer,
  CheckboxGroupInput,
} from "react-admin";
import { InputAdornment, Box } from "@material-ui/core";
import CustomIterator from "./CustomIterator";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  hidden: {
    "& p": {
      display: "none",
    },
    "& div label": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
  },
  boxCard: {
    backgroundColor: "#fff",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
    transition: "all 250ms ease-in-out",
  },
  switcher: {
    "& p": {
      display: "none",
    },
  },
});

export const PlansCreate = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  return (
    <Create title=' ' {...props}>
      <SimpleForm>
        <TextInput source='name' validate={[required()]} />
        <SelectInput
          source='type'
          defaultValue='monthly'
          choices={[
            {
              id: "yearly",
              name: "Anual",
            },
            {
              id: "monthly",
              name: "Mensual",
            },
          ]}
        />

        <Box
          border={"1px solid #e5e5e5"}
          borderRadius={2}
          padding={2}
          marginBottom={2}
          style={{ width: "100%" }}
          maxWidth={450}
          className={classes.boxCard}
        >
          <CheckboxGroupInput
            source='external'
            label="Plataforma de cobro"
            choices={[{ id: "mercadopago", name: "Mercado Pago" }]}
            style={{ fontSize: "18px", color: "#000", marginBottom: 0 }}
            className={classes.hidden}
            defaultValue={["mercadopago"]} //
          />

          <Box
            display={{ xs: "block", sm: "flex" }}
            maxWidth={450}
            style={{
              flexDirection: "column",
              width: "100%",
            }}
          >
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return (
                  formData.external &&
                  formData.external[0] === "mercadopago" && (
                    <Box>
                      <BooleanInput
                        source='has_free_trial'
                        resource='plans'
                        initialValue={false}
                        className={classes.switcher}
                      />
                      {formData?.has_free_trial && (
                        <Box
                          display={{ xs: "block", sm: "flex" }}
                          flexDirection={{ sm: "row" }}
                          style={{
                            gap: "12px",
                          }}
                        >
                          <SelectInput
                            label='Prueba gratis'
                            source='free_trial_type'
                            defaultValue={"days"}
                            choices={[
                              {
                                id: "days",
                                name: "Días",
                              },
                              {
                                id: "months",
                                name: "Meses",
                              },
                            ]}
                          />
                          <Box mr={{ xs: 0, md: "0.5em" }}>
                            <NumberInput
                              resource='plans'
                              label='Duración de prueba'
                              source={"free_trial_duration"}
                              validate={[required(), number(), minValue(1)]}
                            />
                          </Box>
                        </Box>
                      )}
                    </Box>
                  )
                );
              }}
            </FormDataConsumer>
          </Box>
        </Box>

        <Box
          display={{ xs: "block", md: "flex" }}
          style={{ width: "100%", alignItems: "center" }}
        >
          <Box mr={{ xs: 0, md: "0.5em" }}>
            <NumberInput
              resource={"plans"}
              source={"price"}
              validate={[required(), number(), minValue(0)]}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>$</InputAdornment>
                ),
              }}
            />
          </Box>
          <Box flex={1} mr={{ xs: 0, md: "0.5em" }}>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.type === "yearly" &&
                formData.price > 0 &&
                !formData.price_discount && (
                  <Alert
                    style={{ marginBottom: "1em", display: "inline-flex" }}
                    severity='info'
                  >
                    {translate("resources.plans.helper.price", {
                      price: formData.price * 12,
                    })}
                  </Alert>
                )
              }
            </FormDataConsumer>
          </Box>
        </Box>

        <Box
          display={{ xs: "block", md: "flex" }}
          style={{ width: "100%", alignItems: "center" }}
        >
          <Box mr={{ xs: 0, md: "0.5em" }}>
            <NumberInput
              resource={"plans"}
              source={"price_discount"}
              helperText={translate("resources.plans.helper.price_discount")}
              validate={[number(), minValue(0)]}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>$</InputAdornment>
                ),
              }}
            />
          </Box>
          <Box flex={1} mr={{ xs: 0, md: "0.5em" }}>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.type === "yearly" &&
                formData.price > 0 &&
                formData.price_discount && (
                  <Alert
                    style={{ marginBottom: "1em", display: "inline-flex" }}
                    severity='info'
                  >
                    {translate("resources.plans.helper.price", {
                      price: formData.price_discount * 12,
                    })}
                  </Alert>
                )
              }
            </FormDataConsumer>
          </Box>
        </Box>

        <ReferenceInput
          source='role_id'
          reference='roles'
          sort={{ field: "title", order: "ASC" }}
          perPage={1000}
          filterToQuery={() => ({
            type: "subscriber",
          })}
        >
          <SelectInput optionText='title' validate={required()} />
        </ReferenceInput>

        <BooleanInput
          source='recommend'
          resource='plans'
          initialValue={false}
        />

        <BooleanInput
          fullWidth
          source='locally'
          resource='plans'
          initialValue={false}
        />

        <ArrayInput label='' source='lines_description'>
          <CustomIterator
            label='Detalles del plan'
            source='lines_description'
          />
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};
