import * as React from 'react';
import Close from '@material-ui/icons/Close';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
    button: {
        color: '#FF0000',
        borderColor: '#FF0000',
        '& .MuiButton-startIcon': {
            margin: 0
        },
        "& svg":{
            width:'24px',
            height:'24px',
        },
        "&:hover":{
            cursor:'pointer',
        },
        padding: '5px',
        minWidth: '48px',
        minHeight: '48px'
    }
})

export const CustomDeleteButton = (props) => {
    const classes = useStyles();
    return (
        <Button  {...props} startIcon={<Close />} className={classes.button} >
        </Button>
    );
};