import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import ImageIcon from '@material-ui/icons/Image';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import GridListMedia from '../fields/GridListMedia';
import { MediaCreate } from '../../views/media/create';
import { SaveContextProvider, useTranslate } from "ra-core";
import { TabbedShowLayout, Tab } from 'react-admin'
import { NonPermission } from '../../NonPermission';
import { checkPermissions } from "../../utils/validations";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    gridContainer: {
        height: '100%',
    },
    list: {
        width: '100%',
    },
    itemContainer: {
        padding: '20px',
        overflowY: 'auto',
        height: '100%',
    },
    tabs: {
        "& .MuiTab-wrapper": {
            flexDirection: "row",
            '& svg': {
                marginRight: '8px'
            }
        }
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AudioPicker(props) {
    const translate = useTranslate();
    const classes = useStyles();
    const [selectedValue, setSelectedValue] = useState();
    const [currentEditor, setCurrentEditor] = useState('');
    const [openAudioPicker, setOpenAudioPicker] = useState(false);

    const handleClose = () => {
        setOpenAudioPicker(false);
    };

    const handleSave = (uploadedValue = null) => {
        const currentData = selectedValue || uploadedValue;
        if (currentData) {
            let audio = null;
            if (Array.isArray(currentData) && currentData.length > 0) {
                audio = {
                    id: currentData[0].id,
                    audioUrl: currentData[0].preview_url,
                };
            } else {
                audio = {
                    id: currentData.id,
                    audioUrl: currentData.preview_url,
                };
            }
            const customEvent = new CustomEvent('selected-audio-audiopicker', {
                detail: {
                    editorId: currentEditor,
                    audio
                },
                bubbles: false,
                cancelable: true,
                composed: false,
            });
            // Disparar event.
            window.dispatchEvent(customEvent);
        }
        handleClose();
    };

    // Callback para asignar imagen subida desde Notes
    const handleCallbackSave = (value) => {
        const formatedValue = {
            ...value,
            audioUrl: value.preview_url
        }
        handleSave(formatedValue);
    }

    const handleListItemDoubleClick = (value) => {
        if (value) {
            let audio = null;
            if (Array.isArray(value) && value.length > 0) {
                audio = {
                    id: value[0].id,
                    audioUrl: value[0].preview_url,
                };
            } else {
                audio = {
                    id: value.id,
                    audioUrl: value.preview_url,
                };
            }
            const customEvent = new CustomEvent('selected-audio-audiopicker', {
                detail: {
                    editorId: currentEditor,
                    audio
                },
                bubbles: false,
                cancelable: true,
                composed: false,
            });
            // Disparar event.
            window.dispatchEvent(customEvent);
        }
        handleClose();
    };

    const handleListItemClick = (value) => {
        setSelectedValue(value);
    };

    useEffect(() => {
        const handleWindowClick = (event) => {
            const editorId = event.detail.editor;
            setCurrentEditor(editorId);
            setOpenAudioPicker(true);
        }
        window.addEventListener('open-audiopicker', handleWindowClick);
        return () => {
            window.removeEventListener('open-audiopicker', handleWindowClick)
        }
    }, [currentEditor]);

    // Chequear permisos
    const canCreate = checkPermissions(props.permissions, 'create-media');

    return (
        <Dialog fullScreen open={openAudioPicker} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {translate("components.select_audio")}
                    </Typography>
                </Toolbar>
            </AppBar>
            <TabbedShowLayout className={classes.tabs} syncWithLocation={false}>
                <Tab
                    icon={<ImageIcon />}
                    label={translate('resources.media.selectExisting')}>
                    <Grid container className={classes.gridContainer}>
                        <Grid item xs={12} className={classes.itemContainer}>
                            <GridListMedia media={"file"} handleListItemDoubleClick={handleListItemDoubleClick} handleListItemClick={handleListItemClick} />
                        </Grid>
                    </Grid>
                </Tab>
                <Tab
                    icon={<AddIcon />}
                    label={translate('ra.action.add')}>
                    {canCreate
                        ? <SaveContextProvider>
                            <MediaCreate basePath={'/media'} resource={'media'} innote={true} isaudio={true} successcallback={handleCallbackSave} />
                        </SaveContextProvider>
                        : <NonPermission />
                    }
                </Tab>
            </TabbedShowLayout>
            <Divider />
            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    {translate("ra.action.cancel")}
                </Button>
                <Button variant="outlined" color="primary" onClick={handleSave}>
                    {translate("ra.action.accept")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}