import {
  EditButton,
  List,
  ReferenceField,
  SelectField,
  TextField,
  Filter,
  TextInput,
  SelectInput,
  AutocompleteInput,
  ReferenceInput,
  DateField,
  DateInput,
  FunctionField,
  Link
} from "react-admin";
import { Actions } from "../../components/Toolbar/Actions";
import StatusField from "./StatusField";
import CustomizableDatagrid from "../../components/CustomizableDataGrid/CustomizableDatagrid";
import { makeStyles } from "@material-ui/styles";
import {  useTranslate } from "ra-core";

const useListStyles = makeStyles({
  customDatagrid: {
    position: "relative",
    "& .MuiTableCell-sizeSmall": {
      padding: "6px 12px",
    },
    "& .MuiTableCell-head": {
      padding: "6px 12px",
      height: "48px",
    },
    "& .MuiButton-root": {
      minWidth: "40px",
    },
  },
});

const CustomFilters = (props) => {
  const translate = useTranslate();

  const ORDER_STATUS = [
    { id: 'pending', name: `${translate("resources.orders.order_status.pending")}` },
    { id: 'in_review', name: `${translate("resources.orders.order_status.in_review")}` },
    { id: 'ready', name: `${translate("resources.orders.order_status.ready")}` },
    { id: 'publish', name: `${translate("resources.orders.order_status.publish")}` },
  ];

  const PAYMENT_STATUS = [
    { id: 'pending_payment', name: `${translate("resources.orders.payment_status.pending_payment")}` },
    { id: 'processing', name: `${translate("resources.orders.payment_status.processing")}` },
    { id: 'complete', name: `${translate("resources.orders.payment_status.complete")}` },
    { id: 'failed', name: `${translate("resources.orders.payment_status.failed")}` },
    { id: 'nocomplete', name: `${translate("resources.orders.payment_status.nocomplete")}` },
  ];

  const PAYMENT_METHOD = [
    { id: "1", name: `${translate("resources.orders.payment_method.mp")}` },
    { id: "2", name: `${translate("resources.orders.payment_method.pa")}` },
    { id: "3", name: `${translate("resources.orders.payment_method.ma")}` },
  ];
  return (
    <Filter {...props}>
      <TextInput source="search" label="ra.action.search" alwaysOn />
      <SelectInput source="payment_status" label="resources.orders.fields.payment_status"
        choices={PAYMENT_STATUS} />
      <SelectInput
        source="payment_method"
        choices={PAYMENT_METHOD}
        label="resources.orders.fields.payment_method"
      />
      <SelectInput source="status" label="resources.orders.fields.status" choices={ORDER_STATUS} />
      <ReferenceInput
        source="user_id"
        label={translate("components.advertiser")}
        reference="users"
        filterToQuery={(searchText) => ({
          search: searchText,
        })}
      >
        <AutocompleteInput resettable optionText={"username"} />
      </ReferenceInput>
      <ReferenceInput
        source="admedia_id"
        label={translate("components.medio")}
        reference="amedia"
        filterToQuery={(searchText) => ({
          search: searchText,
        })}
      >
        <AutocompleteInput resettable optionText={"name"} />
      </ReferenceInput>
      <ReferenceInput
        label={translate("resources.combos.label")}
        source="combo_id"
        reference="combos"
        filterToQuery={(searchText) => ({
          search: searchText,
        })}
      >
        <AutocompleteInput resettable optionText={"name"} />
      </ReferenceInput>

      <DateInput source="date" label="resources.orders.fields.created_at" />
    </Filter>
  )
}

const ReferenceTextField = ({ record }) => {
  if (record) {
    return <span>{"#" + String(record.id).padStart(5, "0")}</span>;
  }
  return null;
};

const ReferenceAdvertiserField = ({ record, source, ...props }) => {
  const translate = useTranslate();
  if (!record[source] || !record[source].user) {
    return `${translate("components.guest")}`;
  }
  const reference = record[source].user.type === "journalist" ? "journalists" : "subscribers"
  return (
    <Link to={`/${reference}/${record[source].user_id}`}>
      {record[source].user.username}
    </Link>
  );
};

const ButtonEditPermission = ({ record, permissions, basePath }) => {
  if (record && permissions && permissions.some((permission) => permission.name === "*" || permission.name === "edit-adorder")) {
    return <EditButton record={record} basePath={basePath} />
  }
  return <div style={{ width: "50px" }} />;
};

export const AdOrderList = (props) => {
  const classes = useListStyles();
  const translate = useTranslate();

  const PAYMENT_STATUS = [
    { id: 'pending_payment', name: `${translate("resources.orders.payment_status.pending_payment")}` },
    { id: 'processing', name: `${translate("resources.orders.payment_status.processing")}` },
    { id: 'complete', name: `${translate("resources.orders.payment_status.complete")}` },
    { id: 'failed', name: `${translate("resources.orders.payment_status.failed")}` },
    { id: 'nocomplete', name: `${translate("resources.orders.payment_status.nocomplete")}` },
  ];

  const PAYMENT_METHOD = [
    { id: "1", name: `${translate("resources.orders.payment_method.mp")}` },
    { id: "2", name: `${translate("resources.orders.payment_method.pa")}` },
    { id: "3", name: `${translate("resources.orders.payment_method.ma")}` },
  ];
  return (
    <List
      {...props}
      title=" "
      bulkActionButtons={false}
      filters={<CustomFilters />}
      actions={<Actions />}
      sort={{ field: "id", order: "DESC" }}
    >
      <CustomizableDatagrid
        className={classes.customDatagrid}
      >
        <ReferenceTextField
          sortBy="id"
          source="reference"
          label={translate("resources.orders.fields.reference")}
          record={(record) => record}
        />
        <DateField source="created_at" label="resources.orders.fields.created_at" />
        <ReferenceAdvertiserField
          label={translate("components.advertiser")}
          source="advertiser"
          sortable={false}
        />
        <ReferenceField
          reference="amedia"
          source="combo_relation.admedia_id"
          label={translate("components.medio")}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          reference="combos"
          source="combo_id"
          label={translate("resources.orders.fields.combo_id")}
        >
          <TextField source="name" />
        </ReferenceField>
        <FunctionField
          source="cost"
          label={translate("resources.orders.fields.cost")}
          render={(v) =>
            `${Number(v.cost ?? 0).toLocaleString(translate("components.locale_language"), {
              style: "currency",
              currency: translate("components.currency"),
              minimumFractionDigits: 2,
            })}`
          }
        />
        <SelectField
          source="payment_method"
          label="resources.orders.fields.payment_method"
          choices={PAYMENT_METHOD}
        />
        <SelectField
          source="payment_status"
          label="resources.orders.fields.payment_status"
          choices={PAYMENT_STATUS}
        />
        <StatusField source="status"  />
        <ButtonEditPermission {...props} />
      </CustomizableDatagrid>
    </List>
  );
};
