import React, { useState, useEffect, Fragment } from "react";
import { Box } from "@material-ui/core";
import Tapa from "./components/Tapa";
import Opinion from "./components/Opinion";
import GrillaForm from "../../../components/dynamicComponentForm/GrillaForm";

const listComponents = {
  tapa: Tapa,
  opinion: Opinion,
  GrillaX2: GrillaForm,
};
const renderDynamicComponents = (
  components,
  handleChange,
  handleChangeGrilla,
  listCategories,
  zoneIndex
) => {
  return components?.map((component, indexComponent) => {
    return (
      <Fragment key={indexComponent}>
        {typeof listComponents[component.component] !== "undefined" ? (
          <Fragment>
            {component.component === "opinion"
              ? React.createElement(
                  listComponents[component.component],
                  {
                    component,
                    settings: component.settings,
                    index: indexComponent,
                    handleChange,
                    listCategories,
                  },
                  null
                )
              : component.component === "GrillaX2"
              ? React.createElement(
                  listComponents[component.component],
                  {
                    handleCallback: handleChangeGrilla,
                    zoneIndex: zoneIndex,
                    indexComponent: indexComponent,
                    componentData: { ...component.settings },
                    listCategories: listCategories,
                  },
                  null
                )
              : React.createElement(
                  listComponents[component.component],
                  {
                    component,
                    settings: component.settings,
                    index: indexComponent,
                    handleChange,
                  },
                  null
                )}
          </Fragment>
        ) : null}
      </Fragment>
    );
  });
};

export const SectionGroupComponents = ({
  zoneIndex,
  value,
  handleChangeField,
  listCategories,
}) => {
  const [state, setState] = useState([]);

  useEffect(() => {
    setState(value?.components || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (state?.length !== 0) {
        handleChangeField(state);
      }
    }, 1000);
    return () => clearTimeout(timeOutId);
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeGrilla = (componentData) => {
    let copy = JSON.parse(JSON.stringify(state));
    if (!copy || !Array.isArray(copy)) return;
    const gridPos = copy?.findIndex((item) => item.name === "GrillaX2");
    copy[gridPos].settings = componentData;
    setState(copy);
  };

  const handleChange = (newvalues, index) => {
    let copy = JSON.parse(JSON.stringify(state));
    copy[index] = newvalues;
    setState(copy);
  };

  return (
    <Box display='flex' style={{ gap: "16px" }}>
      {renderDynamicComponents(
        state,
        handleChange,
        handleChangeGrilla,
        listCategories,
        zoneIndex
      )}
    </Box>
  );
};
