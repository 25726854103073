import { Box, Typography } from "@material-ui/core";
import { useTranslate } from "ra-core";
import * as React from "react";
import {
  BooleanInput,
  DateInput,
  minValue,
  number,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextInput,
} from "react-admin";
import medioData from "Static/data/medio.json";
import CopyEditorToAnotherMedia from "../fields/CopyEditorToAnotherMedia";
import CopyFieldsToAnotherMedia from "../fields/CopyFieldsToAnotherMedia";
import CustomTextField from "../fields/CustomTextField";
import DownloadWord from "../fields/DownloadWord";
import HtmlEditor from "../fields/HtmlEditor";

const FormNotesPaper = (props) => {
  const translate = useTranslate();
  return (
    <Box p="1em">
      <Box display={{ md: "block", lg: "flex" }}>
        <Box flex={2} mr="1em">
          <CustomTextField
            label={translate("resources.notes.fields.volanta")}
            source="volanta_paper"
            record={props.record}
            resource="notes"
          />
          <CustomTextField
            label={translate("resources.notes.fields.title")}
            source="title_paper"
            record={props.record}
            resource="notes"
          />
          <CustomTextField
            label={translate("resources.notes.fields.copete")}
            source="copete_paper"
            record={props.record}
            resource="notes"
          />
          <Box mt="1em" />
          <Box display="flex" mr="0.5em">
            <Box flex={1} mr="0.5em">
              <Typography variant="h6" gutterBottom>
                {translate("components.paper_editor")}
              </Typography>
            </Box>
            <Box flex={1} ml="0.5em">
              <CopyEditorToAnotherMedia
                title={translate("components.copy")}
                copyFrom={"paper"}
                {...props}
              />
            </Box>
          </Box>
          <Box flex={1} mr="0.5em">
            <HtmlEditor source="content_paper" />
          </Box>
          <Box mt="1em" />
          <TextInput
            label={translate("resources.notes.fields.observation")}
            source="observation"
            multiline
            minRows={3}
            helperText={translate("resources.notes.observation_helper")}
            fullWidth
          />
        </Box>
        <Box flex={1} ml={{ lg: "1em" }} position="relative">
          <CopyFieldsToAnotherMedia
            title={translate("components.copy_internet")}
            copyTo={"internet"}
            {...props}
          />
          {props.record &&
            props.record.id &&
            props.permissions &&
            props.permissions.some(
              (permission) =>
                permission.name === "*" ||
                permission.name === "download-edition-paper"
            ) && (
              <Box flex={1} ml="0.5em" textAlign="right">
                <DownloadWord {...props} />
              </Box>
            )}
          <CustomTextField
            label={"resources.notes.fields.epigrafe"}
            source="epigrafe_paper"
            record={props.record}
            resource="notes"
          />
          <DateInput
            source="date_publish_paper"
            label={translate("resources.notes.fields.date_publish")}
            fullWidth
          />
          {/* TODO move choices to medio  */}
          <SelectInput
            label={translate("resources.notes.fields.section_paper")}
            source="section_paper"
            fullWidth
            choices={medioData?.paper_categories || []}
          />
          <NumberInput
            label={translate("resources.notes.fields.page")}
            source="page_paper"
            min={0}
            step={1}
            validate={[number(), minValue(0)]}
            fullWidth
          />
          <SelectInput
            label={translate("resources.notes.fields.priority_paper")}
            source="priority_paper"
            fullWidth
            choices={[
              {
                id: "A",
                name: "A",
              },
              {
                id: "B",
                name: "B",
              },
              {
                id: "C",
                name: "C",
              },
              {
                id: "D",
                name: "D",
              },
              {
                id: "E",
                name: "E",
              },
            ]}
          />
          <ReferenceInput
            label={translate("resources.notes.fields.author")}
            source="author_id"
            reference="authors"
            perPage={1000}
            sort={{ field: "first_name", order: "ASC" }}
            fullWidth
          >
            <SelectInput optionText="display_name" />
          </ReferenceInput>
          <BooleanInput
            label={translate("resources.notes.fields.is_cover_note_paper")}
            source="is_cover_note_paper"
            fullWidth
          />
          <BooleanInput
            label={translate("resources.notes.fields.fixed")}
            source="fixed"
            fullWidth
          />
          <BooleanInput
            label={translate("resources.notes.fields.built")}
            source="built"
            fullWidth
          />
          <BooleanInput
            label={translate("resources.notes.fields.revised")}
            source="revised"
            fullWidth
          />
        </Box>
      </Box>
    </Box>
  );
};
export default FormNotesPaper;
