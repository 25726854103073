export const apiUrl = process.env.REACT_APP_API_HOST_ADMIN;
export const apiUrlPlanA = process.env.REACT_APP_API_HOST_PLAN_A;
export const apiCODPlanA = process.env.REACT_APP_API_COD_CLUB_PLAN_A || "CLE";
const tokenPassword =
  process.env.REACT_APP_PASSWORD_PLAN_A || "testclient:t1e2stpass";



const fecther = async (url, options) => {
  return fetch(url, {
    method: options.method ? options.method : "GET",
    headers: options.headers,
    mode: options.mode || "cors",
    body: options?.body || null,
  })
    .then((response) =>
      response.text().then((text) => ({
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        body: text,
      }))
    )
    .then(({ status, statusText, headers, body }) => {
      let json;
      try {
        json = JSON.parse(body);
      } catch (e) {
        // not json, no big deal
      }
      if (status < 200 || status >= 300) {
        return Promise.reject(
          new Error((json && json.message) || statusText, status, json)
        );
      }
      return Promise.resolve({ status, headers, body, json });
    });
};

export const authenticate = () => {
  const now = new Date();
  let tokenPlanA = JSON.parse(localStorage.getItem("tokenPlanA"));
  if (tokenPlanA && now.getTime() >= tokenPlanA?.expiry) {
    localStorage.removeItem("tokenPlanA");
    tokenPlanA = null;
  }
  if (!tokenPlanA) {
    return fetch(apiUrlPlanA + "/token.php", {
      method: "POST",
      headers: {
        Authorization: "Basic " + btoa(tokenPassword),
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({ grant_type: "client_credentials" }),
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (json.access_token) {
          tokenPlanA = json.access_token;
          localStorage.setItem(
            "tokenPlanA",
            JSON.stringify({
              expiry: now.getTime() + json.expires_in * 1000,
              value: json.access_token,
            })
          );
          return json.access_token;
        } else {
          throw new Error("ERROR: AUTHENTICATION FAIL");
        }
      })
      .catch((e) => {
        console.log(e);
        throw new Error("ERROR: AUTHENTICATION FAIL");
      });
  } else {
    return tokenPlanA.value;
  }
};

const fetcherBuildOptions = (token, url, options) => {
  const newHeaders = {
    accept: "application/json",
    "content-type": "application/json",
  };
  if (token) {
    newHeaders.authorization = `Bearer ${token}`;
  }
  const newOptions = { ...options };
  newOptions.headers = newHeaders;
  newOptions.mode = "cors";
  return fecther(url, newOptions);
};

export const httpClientPlanA = async (url, options = {}) => {
  if (options.notauth == true) {
    delete options.noauth;
    return fetcherBuildOptions(null, url, options);
  } else {
    let token = await authenticate();
    if (token) {
      return fetcherBuildOptions(token, url, options);
    }
  }
};
export const sendAsFormData = async (method, resource, params) => {
  let formData = new FormData();
  for (const name in params.data) {
    formData.append(name, params.data[name] ? params.data[name] : "");
  }
  if (resource === "createAdd" || resource === "updateAdd") {
    if (params.data.file) {
      formData.set("image", params.data.file.rawFile);
      formData.delete("file");
    } else {
      formData.delete("image");
    }
  }
  let token = await authenticate();
  const newHeaders = {
    accept: "application/json",
    authorization: `Bearer ${token}`,
  };
  const newOptions = {};
  newOptions.headers = newHeaders;
  newOptions.method = "POST";
  newOptions.mode = "cors";
  newOptions.body = formData;
  delete newOptions["data"];
  return fetch(
    `${apiUrlPlanA}/${resource}?${params?.id ? `id=${params.id}&` : ""
    }club=${apiCODPlanA}`,
    newOptions
  )
    .then(async (res) => {
      if (res.status >= 200 && res.status < 300) {
        return res.json();
      }
      throw await res.json();
    })
    .then((json) => {
      return {
        data: { ...params.data, id: json.id, response: json },
      };
    })
    .catch((error) => {
      let message = "";
      if (error.error && typeof error.error === "object") {
        for (const [key, value] of Object.entries(error.error)) {
          message += ` ${value}`;
        }
      }
      throw new Error(message ? `Error: ${message}` : "ra.message.error");
    });
};
