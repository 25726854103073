import React, { useState, useEffect } from "react";
import { Datagrid } from "react-admin";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import get from "lodash/get";
import ColumnIcon from "@material-ui/icons/ViewColumn";
import Button from "@material-ui/core/Button";
import SelectionDialog from "./SelectionDialog";
import LocalStorage from "./LocalStorage";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "ra-core";
const useListStyles = makeStyles({
  "btn-filter-column": {
    position: "absolute",
    right: "12px",
    zIndex: "5",
    marginBottom: "12px",
    marginTop: "12px",
  },
  RaList: {
    bulkActionsDisplayed: {
      "btn-filter-column": {
        top: "76px",
      },
    },
  },
});

export default function CustomizableDatagrid({
  children,
  defaultColumns,
  resource,
  classBtn,
  ...rest
}) {
  const classes = useListStyles();
  const [modalOpened, setModalOpened] = useState(false);
  const [selection, setSelection] = useState({});
  const translate = useTranslate();

  useEffect(() => {
    // use Effect to get initial selection
    const previousSelection = LocalStorage.get(resource);
    // if we have a previously stored value, let's return it
    if (!isEmpty(previousSelection)) {
      setSelection(previousSelection);
    }
    // if defaultColumns are set let's return them
    else if (!isEmpty(defaultColumns)) {
      setSelection(arrayToSelection(defaultColumns));
    }
    // otherwise we fallback on the default behaviour : display all columns
    else {
      const select = arrayToSelection(
        React.Children.map(children, (field) => get(field, ["props", "source"]))
      );
      setSelection(select);
    }
  }, [children, defaultColumns, resource]);

  const arrayToSelection = (values) =>
    values.reduce((selection, columnName) => {
      selection[columnName] = true;
      return selection;
    }, {});

  const getColumnLabels = () => {
    return filter(
      React.Children.map(
        children,
        (field) =>
          field &&
          {
            source: get(field, ["props", "source"]),
            label: get(field, ["props", "label"]),
          }
      ),
      (item) => item && item.source
    );
  };

  const toggleColumn = (columnName) => {
    const previousSelection = selection;
    const currentSelection = {
      ...previousSelection,
      [columnName]: !previousSelection[columnName],
    };

    setSelection(currentSelection);

    // updates storage
    LocalStorage.set(resource, currentSelection);
  };

  const handleOpen = () => setModalOpened(true);
  const handleClose = () => setModalOpened(false);

  const renderChild = (child) => {

    if (child != null) {
      const source = get(child, ["props", "source"]);

      // Show children without source, or children explicitly visible
      if (!source || selection[source]) {
        return React.cloneElement(child, {});
      }
    }

    return null;
  };

  return (
    <div>
      <div className={classBtn ? classBtn : classes["btn-filter-column"]}>
        <Button variant="outlined" aria-label={translate("ra.action.add")} onClick={handleOpen}>
          <ColumnIcon color="primary" />
        </Button>
      </div>
      {modalOpened && (
        <SelectionDialog
          selection={selection}
          columns={getColumnLabels()}
          onClose={handleClose}
          onColumnClicked={toggleColumn}
        />
      )}
      <Datagrid {...rest}>{React.Children.map(children, renderChild)}</Datagrid>
    </div>
  );
}
