import { useCallback, useState, useEffect } from "react";
import {
  Button,
  IconButton,
  Dialog,
  Box,
  DialogTitle,
  DialogContent,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import {
  useRefresh,
  SimpleForm,
  useCreate,
  showNotification,
  SaveButton,
  Toolbar,
  SelectInput,
  DateTimeInput,
  required,
  FormDataConsumer,
} from "react-admin";
import IconCancel from "@material-ui/icons/Cancel";
import Share from "@material-ui/icons/Share";
import { STATUS, NETWORKS } from "Static/blocks/ShareOptions";
import { useDispatch } from "react-redux";
import { httpClient, apiUrl } from "../../utils/utilsFetch";
import { Alert } from "@material-ui/lab";
import CustomTextField from "../../components/fields/CustomTextField";
import { useTranslate } from "ra-core";

const useStyles = makeStyles({
  button: {
    margin: "10px 24px",
    position: "relative",
  },
  iconPaddingStyle: {
    paddingRight: "0.5em",
  },
});

const ShareToolbar = ({ onCancel, ...props }) => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <Toolbar {...props} style={{ justifyContent: "space-between" }}>
      <Button className={classes.button} onClick={onCancel}>
        <IconCancel className={classes.iconPaddingStyle} />
        {translate("ra.action.cancel")}
      </Button>
      <SaveButton label={translate("ra.action.publish")} />
    </Toolbar>
  );
};

export default function ShareButton({ note = null, ...props }) {
  const noteData = props.record || note;

  const translate = useTranslate();
  const refresh = useRefresh();
  const dispatch = useDispatch();
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate();
  const [shareRecords, setShareRecords] = useState([]);
  const [inputNetwork, setInputNetwork] = useState(null);
  const [alertData, setAlertData] = useState(false);

  const handleOpen = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      setShowDialog(true);
    },
    [setShowDialog]
  );

  const handleClose = useCallback(() => {
    setShowDialog(false);
    setAlertData(false);
    setInputNetwork(null);
  }, [setShowDialog]);

  const handleSave = (values) => {
    create(
      "shares",
      {
        network: values.network,
        status: values.status_share,
        note_id: noteData.id,
        message: values.message,
        date_publish:
          values.status_share === "scheduled" ? values.date_publish : null,
      },
      {
        onSuccess: () => {
          refresh();
          setShowDialog(false);
          setAlertData(false);
          setInputNetwork(null);
          dispatch(
            showNotification("resources.share.notification.success", "success")
          );
        },
        onFailure: (error) => {
          dispatch(
            showNotification("resources.share.notification.error", "error")
          );
        },
      }
    );
  };

  // Llamada a la api para obtener los shares del último mes
  useEffect(() => {
    if (showDialog) {
      httpClient(`${apiUrl}/shares/check/${noteData.id}`, {
        method: "GET",
      }).then(({ json }) => {
        setShareRecords(json);
      });
    }
  }, [showDialog]); // eslint-disable-line react-hooks/exhaustive-deps

  // Handle para el Alert
  useEffect(() => {
    if (inputNetwork) {
      setAlertData(shareRecords.some((item) => item.network === inputNetwork));
    }
  }, [inputNetwork]); // eslint-disable-line react-hooks/exhaustive-deps

  const showAlert = alertData ? (
    <Alert
      fullWidth
      variant='filled'
      severity='info'
      style={{ alignItems: "center" }}
    >
      {translate("components.shared_content")}{" "}
      <span style={{ textTransform: "capitalize" }}>{inputNetwork}</span>
    </Alert>
  ) : null;

  if (Array.isArray(NETWORKS) && NETWORKS.length <= 0) {
    return null;
  }

  return (
    <Box display='flex'>
      <Tooltip title={translate("components.share")}>
        <IconButton
          style={{ marginRight: ".5em", borderRadius: "5px" }}
          size='small'
          color='primary'
          onClick={handleOpen}
        >
          <Share />
        </IconButton>
      </Tooltip>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
        aria-label='crear'
      >
        <DialogTitle>
          {translate("resources.share.status_confirm_title")}
        </DialogTitle>
        <DialogContent style={{ padding: 0 }}>
          <SimpleForm
            save={handleSave}
            saving={loading}
            redirect={false}
            toolbar={<ShareToolbar onCancel={handleClose} />}
            {...props}
          >
            {showAlert}
            <SelectInput
              label={translate("resources.share.network")}
              onChange={(e) => setInputNetwork(e.target.value)}
              fullWidth
              source='network'
              resource='share'
              choices={NETWORKS}
              validate={required()}
            />
            <CustomTextField
              label={translate("resources.share.message")}
              multiline
              fullWidth
              source='message'
              resource='share'
              inputProps={{ maxLength: 280 }}
            />
            {noteData && (
              <Box
                style={{
                  width: "75%",
                  margin: "0 auto",
                  backgroundColor: "#EFEFEF",
                  padding: "8px",
                  marginBottom: "8px",
                }}
              >
                <img
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                  src={noteData.featured_image}
                  alt=''
                />
                <p style={{ fontWeight: "bold", margin: 0 }}>
                  {noteData.title}
                </p>
              </Box>
            )}
            <SelectInput
              label={translate("resources.share.status")}
              fullWidth
              source='status_share'
              resource='share'
              defaultValue={STATUS[0]?.id}
              choices={STATUS}
              validate={required()}
            />
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.status_share === "scheduled" && (
                  <DateTimeInput
                    source='date_publish'
                    label='Fecha de publicación'
                    initialvalue={new Date().toJSON()}
                    validate={required()}
                    fullWidth
                  />
                )
              }
            </FormDataConsumer>
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
