import React from "react";
import { useState, useEffect } from "react";
import {
  useQueryWithStore,
  AutocompleteArrayInput,
  LinearProgress,
  ReferenceArrayInput,
} from "react-admin";
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
} from "@material-ui/core";
import { useForm } from "react-final-form";
import { httpClient, apiUrl } from "../../utils/utilsFetch";
import { useFormState } from "react-final-form";
import { useTranslate } from "ra-core";

const EntitySelects = ({ record = {} }) => {
  const [types, setTypes] = useState();
  const { change } = useForm();
  const [listEntities, SetListEntities] = useState();
  const { values } = useFormState();
  const [show, setShow] = useState(false);
  const [toUpdate, setToUpdate] = useState(null);
  const [name, setName] = useState();
  const translate = useTranslate();

  const { data: listTypes } = useQueryWithStore({
    type: "getList",
    resource: "types",
    payload: {
      filter: { show: true },
      sort: {},
      pagination: { page: 1, perPage: 100 },
    },
  });

  useEffect(() => {
    if (listTypes || !types) {
      const all = listTypes?.map(i => ("entities_" + (i?.slug?.replace("-", "_"))));
      if (all) {
        setTypes(all);
      }
    }
  }, [listTypes]) // eslint-disable-line react-hooks/exhaustive-deps

  const getMunicipiosOfSection = (sections_ids) => {
    let municipios = [];
    if (sections_ids?.length > 0) {
      municipios = listEntities?.municipies || [];
      let idsEncontrados = [];
      municipios.forEach((objeto) => {
        const tieneNombreEnRelations = objeto?.relations?.some((relacion) =>
          sections_ids.includes(relacion?.id)
        );
        if (tieneNombreEnRelations) {
          idsEncontrados.push(objeto);
        }
      });
      municipios = idsEncontrados.map((i) => i.id);
    }
    return municipios;
  };

  const handleAccept = () => {
    change("entities_municipio", combine(values?.entities_municipio, toUpdate));
    setShow(false);
    setToUpdate(null);
  };

  const handleCancel = () => {
    setShow(false);
    setToUpdate(null);
  };

  function combine(arr1 = [], arr2 = []) {
    const merged = new Set([...arr1, ...arr2]);
    return Array.from(merged);
  }
  const getListEntities = async () => {
    const municipies = await httpClient(apiUrl + "/entities/all/municipio");
    const sections = await httpClient(apiUrl + "/entities/all/seccion-electoral");
    const municipiesData = municipies.json;
    const sectionsData = sections.json;
    if (Array.isArray(municipiesData) && Array.isArray(sectionsData)) {
      SetListEntities({
        municipies: municipiesData,
        sections: sectionsData
      });
    }
  };

  function containsEvery(arreglo1, arreglo2) {
    return arreglo2?.every((elemento) => arreglo1.includes(elemento));
  }


  useEffect(() => {
    getListEntities();
  }, []);

  const handleChangeEntity = (values, slug) => {
    if (typeof values?.inputValue == "number") {
      if (slug === "seccion_electoral") {
        handleChangeSeccionElectoral(values.inputValue);
      } else {
        if (slug === "municipio") {
          handleChangeMunicipio(values.inputValue);
        }
      }
    }
  };

  const handleChangeSeccionElectoral = (value) => {
    const municipios = getMunicipiosOfSection([value]);
    const section_name = listEntities?.sections?.find((i) => i?.id === value)?.name;
    if (
      (values?.entities_municipio &&
        !containsEvery(values?.entities_municipio, municipios)) ||
      !values?.entities_municipio
    ) {
      setToUpdate(municipios);
      setShow(true);
      setName(section_name);
    }
  };

  const handleChangeMunicipio = (value) => {
    const municipio = listEntities?.municipies?.find((i) => i?.id === value);
    const sections_all = listEntities?.sections || [];
    const relation = municipio.relations.find((relation) =>
      sections_all.some((j) => j?.id === relation?.id)
    );
    if (
      relation &&
      !values?.entities_seccion_electoral?.some((s) => s === relation?.id)
    ) {
      const new_array = combine(values?.entities_seccion_electoral, [
        relation?.id,
      ]);
      change("entities_seccion_electoral", new_array);
    }
  };

  if (!types || !listEntities)
    return (
      <>
        <Typography variant="h6" style={{ marginBottom: "10px" }}>
          {translate("resources.entities.plural_name")}
        </Typography>
        <LinearProgress />
      </>
    );
  function upperCase(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <>
      <Typography variant="h6" style={{ marginBottom: "10px" }}>
        {translate("resources.entities.plural_name")}
      </Typography>
      <ul style={{ listStyle: "none", paddingLeft: 0 }}>
        {types?.map((item, key) => (
          <li key={key} style={{ width: "100%" }}>
            {item != "entities_municipio" && item != "entities_seccion_electoral" ?
              <ReferenceArrayInput reference="entities"
                source={item}
                filterToQuery={(searchText) => ({
                  search: searchText,
                })}
                perPage={20}
                filter={{ type_id: listTypes?.find(i => i?.slug?.replace("-", "_") == item?.replace("entities_", ""))?.id || null }} >
                <AutocompleteArrayInput
                  source={item}
                  label={upperCase(item?.replace("entities_", "")?.replace("_", " "))}
                  optionText="name"
                  onStateChange={(values) =>
                    handleChangeEntity(values, item?.replace("entities_", ""))
                  }
                  optionValue="id"
                  fullWidth
                />
              </ReferenceArrayInput>
              :
              <AutocompleteArrayInput
                source={item}
                label={upperCase(item?.replace("entities_", "")?.replace("_", " "))}
                optionText="name"
                choices={item == "entities_municipio" ? listEntities.municipies : listEntities.sections}
                onStateChange={(values) =>
                  handleChangeEntity(values, item?.replace("entities_", ""))
                }
                optionValue="id"
                fullWidth
              />
            }
          </li>
        ))}
      </ul>
      <Dialog open={show}>
        <DialogContent>
          <Typography variant="h6" style={{ marginBottom: "10px" }}>
            Quiere agregar los municipios de la {name}? {/* TODO replace entity selects for each medio with entities */}
          </Typography>
          <DialogActions style={{ marginTop: "20px" }}>
            <Button
              onClick={() => handleCancel()}
              variant="outlined"
              color="secondary"
            >
              {translate("ra.action.cancel")}
            </Button>
            <Button
              onClick={() => handleAccept()}
              variant="contained"
              color="primary"
            >
              {translate("ra.action.accept")}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EntitySelects;
