import React, { Fragment, useState } from "react";
import {
  Datagrid,
  EmailField,
  DateField,
  Filter,
  List,
  TextField,
  TextInput,
  BooleanField,
  BooleanInput,
  useListContext,
  SelectInput,
  EditButton,
  useTranslate,
  CreateButton,
  downloadCSV,
  useNotify,
} from "react-admin";
import FullNameField from "./FullNameField";
import StateField from "./StateField";
import { makeStyles } from "@material-ui/core/styles";
import { STATE_TRANSLATIONS, STATUS, TYPE } from "../../utils/cardStatus";
import PrintedButton from "./PrintedButton";
import StatusButton from "./StatusButton";
import { Box, Typography } from "@material-ui/core";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import { ActionsCreate } from "../../components/Toolbar/ActionsCreate";
import jsonExport from "jsonexport/dist";
import { ExportNumber } from "../../components/ExportNumber";

const useStyles = makeStyles({
  select: {
    minWidth: " 160px",
    maxWidth: "300px",
    marginTop: "40px",
  },
});

const SubscriberFilter = (props) => {
  const classes = useStyles();
  return (
    <Filter {...props}>
      <TextInput source='search' label='ra.action.search' alwaysOn />
      <TextInput source='dni' />
      <SelectInput
        className={classes.select}
        source='state'
        resource='club_cards'
        choices={STATUS}
        defaultValue={"request"}
      />
      <SelectInput
        className={classes.select}
        source='type'
        resource='club_cards'
        choices={TYPE}
      />
      <BooleanInput label='Impresas' source='printed' initialvalue={true} />
      <BooleanInput label='En Vigencia' source='active' />
    </Filter>
  );
};

const AddressField = ({ record }) => {
  if (!record) return null;
  return (
    <span style={{ textTransform: "capitalize" }}>
      {record.address} {record.addr_floor} {record.addr_door} - {record.city},{" "}
      {record.province}
    </span>
  );
};

const BulkActionButtons = (props) => (
  <Fragment>
    <StatusButton {...props} />
    <PrintedButton {...props} />
  </Fragment>
);

const Empty = () => {
  const translate = useTranslate();
  const { basePath } = useListContext();
  return (
    <Box textAlign='center' m={1}>
      <CreditCardIcon style={{ fontSize: "150px" }} color='disabled' />
      <Typography variant='h4'>
        {translate("resources.club_cards.empty_title")}
      </Typography>
      <Typography variant='h6' paragraph>
        {translate("resources.club_cards.empty_body")}
      </Typography>
      <CreateButton
        variant='contained'
        style={{
          padding: "4px 20px 4px 10px",
          height: "fit-content",
          alignSelf: "flex-end",
        }}
        basePath={basePath}
      />
    </Box>
  );
};

export const ClubCardList = ({ permissions, ...props }) => {
  const translate = useTranslate();
  const notify = useNotify();
  const [numberExport, SetNumberExport] = useState(10000);

  const exporter = (values) => {
    let resp = [];
    if (values && values.length > 0) {
      values.forEach((item) => {
        let obj = {};
        obj.id = item.id;
        obj.Nombre = item.username;
        obj.Email = item.email;
        obj.DNI = item.dni;
        obj["Telefono"] = item.phone;
        obj["Fecha nacimiento"] =
          item?.birthday != null
            ? new Date(item.birthday).toLocaleDateString()
            : "";
        obj.Tipo = TYPE.find((i) => i.id === item.type)?.name || "";
        obj.Estado = STATE_TRANSLATIONS[item.status]?.name;
        obj.Impresa = item?.printed === false ? "No" : "Si";
        obj["Direccion"] =
          item?.province != null
            ? `${item.province} , ${item.city} , ${item.address} , ${item.addr_floor}, ${item.addr_door}`
            : "";
        obj["Fecha entrega"] =
          item?.delivery_date != null
            ? new Date(item.delivery_date).toLocaleDateString()
            : "";
        obj.Activo = item?.active === false ? "No" : "Si";
        resp.push(obj);
      });
    }
    if (resp && resp?.length > 0) {
      let respErr = "";
      let respCsv;
      jsonExport(resp, function (err, csv) {
        if (err) respErr = err;
        respCsv = csv;
      });
      if (respCsv && respErr === "") {
        downloadCSV(respCsv, `tarjetas_${new Date().toLocaleDateString()}`);
      } else {
        notify("Error al convertir a csv", "warning");
      }
    }
  };

  return (
    <List
      empty={<Empty />}
      filters={<SubscriberFilter />}
      {...props}
      sort={{ field: "username", order: "ASC" }}
      bulkActionButtons={<BulkActionButtons />}
      actions={
        <ActionsCreate
          label={`${translate("resources.club_cards.new")}`}
          exportButton={true}
          exporter={exporter}
          maxResults={numberExport}
        />
      }
      title=' '
    >
      <Datagrid rowClick={"show"}>
        <ExportNumber
          numberExport={numberExport}
          SetNumberExport={SetNumberExport}
        />
        <FullNameField source='username' />
        <TextField source='dni' />
        <AddressField
          sortBy='address'
          source='address'
          record={(record) => record}
        />
        <EmailField source='email' />
        <TextField source='phone' />
        <DateField source='birthday' options={{ timeZone: "UTC" }} />
        <BooleanField source='active' sortable={false} />
        <StateField source='state' />
        <BooleanField source='printed' />
        {permissions &&
          permissions.some(
            (permission) =>
              permission.name === "*" || permission.name === "edit-club-cards"
          ) && <EditButton />}
      </Datagrid>
    </List>
  );
};
