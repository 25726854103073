import * as React from "react";
import { useField } from "react-final-form";
import { TextInput, required } from "react-admin";
import { useTranslate } from "ra-core";
const CustomTextField = ({
  label,
  source,
  isRequired = false,
  inputProps = {},
}) => {
  const { input } = useField(source);
  const translate = useTranslate();

  const handleChangeTextArea = (e) => {
    input.onChange(e.target.value);
  };

  function getCharsWithoutBlanck() {
    if (input?.value) {
      let count = 0;
      for (let i = 0; i < input?.value.length; i++) {
        if (input?.value[i] !== " ") {
          count++;
        }
      }
      return count;
    }
    return 0;
  }

  return (
    <TextInput
      source={source}
      label={label}
      inputProps={{ ...inputProps }}
      multiline
      initialvalue={input?.value ? input?.value : undefined}
      onChange={(e) => handleChangeTextArea(e)}
      helperText={`${translate("components.chars_with_blank")}: ${input?.value ? input?.value.length : 0
        } - ${inputProps?.maxLength ? `Max: ${inputProps.maxLength}` : `${translate("components.chars_without_blank")}:${getCharsWithoutBlanck()}`}`}
      fullWidth
      validate={isRequired ? required() : null}
    />
  );
};

export default CustomTextField;
