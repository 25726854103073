import SvgIcon from "@material-ui/core/SvgIcon";

export const MembersIcon = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.9051 23.4681H20.4317L20.6377 24.0005H24L18.1127 9.4308L22.7901 9.43768V8.9053L17.1877 8.89844L23.075 23.4681H21.624H20.9051Z"
          fill="#000000"
        />
        <path
          d="M3.09288 20.5287H3.56602L3.36012 21.061H0L5.88349 6.49136L1.2135 6.49822L1.20912 5.96586L6.81223 5.95898L0.92436 20.5287H2.37442H3.09288Z"
          fill="#000000"
        />
        <path
          d="M15.8756 7.83644C15.6134 7.83644 15.3467 7.80874 15.0844 7.75335C13.4364 7.40714 12.4528 6.06386 12.89 4.75866C13.3271 3.45346 15.0232 2.67449 16.6713 3.0207C18.3193 3.3669 19.3029 4.71018 18.8657 6.01538C18.4985 7.1094 17.2439 7.83644 15.8756 7.83644ZM15.8756 3.47423C15.456 3.47423 15.0407 3.56078 14.6691 3.73388C14.1096 3.99008 13.7118 4.40553 13.5457 4.89714C13.3796 5.39222 13.467 5.90807 13.7905 6.34775C14.114 6.7909 14.6385 7.10594 15.2593 7.23749C15.8844 7.36905 16.5357 7.29981 17.0909 7.04362C17.6505 6.78743 18.0483 6.37198 18.2144 5.88037C18.3805 5.38529 18.2931 4.86944 17.9696 4.42976C17.6461 3.98662 17.1215 3.67157 16.5008 3.54001C16.2953 3.495 16.0855 3.47423 15.8756 3.47423V3.47423Z"
          fill="#000000"
        />
        <path
          d="M8.53923 4.81633C8.27694 4.81633 8.01028 4.78908 7.74799 4.73461C6.09995 4.39418 5.11638 3.07329 5.55352 1.78984C5.99067 0.506393 7.68679 -0.259586 9.33483 0.0808508C10.9829 0.421288 11.9664 1.74218 11.5293 3.02563C11.1621 4.10141 9.90749 4.81633 8.53923 4.81633ZM8.53923 0.526822C7.47259 0.526822 6.48901 1.08513 6.20487 1.92601C6.03875 2.41284 6.12618 2.92009 6.44967 3.35244C6.77316 3.7848 7.29336 4.098 7.91848 4.22737C9.20805 4.49291 10.5326 3.89373 10.8736 2.89285C11.2146 1.89197 10.4452 0.857046 9.15997 0.591506C8.95451 0.547249 8.74468 0.526822 8.53923 0.526822Z"
          fill="#000000"
        />
        <path
          d="M12.4761 12.6876L12.6613 12.1414L12.7804 11.791L12.4761 12.6842V12.6876Z"
          fill="#000000"
        />
        <path
          d="M15.4177 9.42699V9.43386L15.2149 8.90484H15.2105L15.2149 8.89797H14.69L14.4828 8.89453L14.4872 8.89797H13.7551L12.948 11.2923V11.2957L12.7804 11.7904L12.6613 12.1408L12.4761 12.687V12.6836L12.1321 13.6867L12.1365 13.697L12.4717 14.7L12.8113 13.6798L14.2314 9.43042H14.7033L20.6175 23.9992H21.3407L15.4177 9.42699Z"
          fill="#000000"
        />
        <path
          d="M10.1958 5.94043H8.47137L2.61011 21.0416H3.33339L8.96973 6.47289H9.71948L11.5938 12.0723L11.9643 11.1757L10.1958 5.94043Z"
          fill="#000000"
        />
      </svg>
    </SvgIcon>
  );
};
