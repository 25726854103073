import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ImageDefault from "../../assets/img/note-default.png";
import TypeContentField from "../../views/notes/TypeContentField";
import NoticiaPicker from "../pickers/NoticiaPicker";
import { useTranslate } from "ra-core";

const useStyles = makeStyles((theme) =>
  createStyles({
    img: {
      width: "75px",
      marginRight: "15px",
    },
    numberOrder: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightBold,
      marginRight: "8px",
    },
    items: { marginRight: "12px" },
  })
);

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    background: "rgb(235,235,235)",
  }),
});

const getListStyle = (isDraggingOver) => ({
  //background: isDraggingOver ? 'lightblue' : 'lightgrey',
});

const ListDnd = ({ listNotes, handleChangeListNote, showImg = true }) => {
  const classes = useStyles();
  const [items, setItems] = useState();
  const translate = useTranslate();
  useEffect(() => {
    if (Array.isArray(listNotes)) {
      setItems(listNotes);
    }
  }, [listNotes]);

  const handleSelectedNote = (value, index) => {
    const newListItems = [...items];
    newListItems[index] = value;
    setItems(newListItems);
    handleChangeListNote(newListItems);
  };

  const handleDelete = (index) => {
    const newListItems = [...items];
    newListItems[index] = {};
    setItems(newListItems);
    handleChangeListNote(newListItems);
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newListItems = reorder(
      [...items],
      result.source.index,
      result.destination.index
    );
    setItems(newListItems);
    handleChangeListNote(newListItems);
  };

  const getPrimaryContent = (item) => {
    if (item.title_home) {
      return (
        <div style={{ display: "flex", gap: " 4px", alignItems: "end" }}>
          <span>{item.title_home}</span>
          <span> </span>
          <TypeContentField record={item} />
        </div>
      );
    }
    return "Default";
  };

  return items ? (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <List
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {items.map((item, index) => (
              <Draggable
                key={item.id ? item.id : "d" + index}
                draggableId={item.id ? `${item.id}-${index}` : `d${index}`}
                index={index}
              >
                {(provided, snapshot) => (
                  <>
                    <ListItem
                      ContainerComponent="li"
                      ContainerProps={{ ref: provided.innerRef }}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <Typography className={classes.numberOrder}>{`${
                        index + 1
                      }.`}</Typography>

                      {showImg && (
                        <img
                          className={classes.img}
                          src={
                            item.featured_image
                              ? item.featured_image
                              : ImageDefault
                          }
                          alt=""
                        />
                      )}

                      <ListItemText
                        className={classes.items}
                        primary={getPrimaryContent(item)}
                        secondary={item.copete ? item.copete : ""}
                      />

                      <ListItemSecondaryAction>
                        {item.id ? (
                          <Tooltip title={translate("ra.action.delete")}>
                            <IconButton
                              edge="start"
                              onClick={() => handleDelete(index)}
                              aria-label={translate("ra.action.delete")}
                            >
                              <ClearIcon />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <NoticiaPicker
                            id={index}
                            viewIcon={true}
                            handleSelectedValue={handleSelectedNote}
                          />
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider variant="middle" />
                  </>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  ) : (
    <></>
  );
};

export default ListDnd;
