import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { List } from './List';
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  container:{
    margin: '8px',
    border: '1px solid #CACACA',
    backgroundColor: 'white',
    borderRadius: '3px',
    width: '220px',
    display: 'flex',
    flexDirection: 'column',

  },
  title:{
    background: `#14A9D4`,
    color:'white',
    padding: '8px',
  },
  list:{
    padding: '8px',
    backgroundColor: 'lightgrey' ,
    flexGrow: 1,
    minHeight: '100px',
  }
})


const InnerList = ({combos})  =>{
    if (!combos) return null;
    return combos?.map((combo, index) => (
      <List key={combo?.id} combo={combo} index={index} />
    ));
  
}

export const Column =({combos,column, index})=> {
  const classes =useStyles();
    return (
      <Draggable draggableId={column?.id} index={index}>
        {provided => (
          <div className={classes.container} {...provided.draggableProps} ref={provided.innerRef}>
            <div className={classes.title} {...provided.dragHandleProps}>
              {column?.title}
            </div>
            <Droppable droppableId={column?.id} type="combo">
              {(provided, snapshot) => (
                <div className={classes.list}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <InnerList combos={combos} />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        )}
      </Draggable>
    );
}
