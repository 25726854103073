import { useEffect, useState } from "react";
import { AutocompleteArrayInput } from "react-admin";
import { useFormState, useField } from "react-final-form";

export const CategoriesPicker = ({ source, label, choices = [] }) => {
  const { values } = useFormState();
  const category_id = values.category_id;
  const { input: categories } = useField("categories");
  const [filterChoices, setFilterChoices] = useState([]);

  const filter = () => {
    if (choices && choices.length > 0 && category_id) {
      const response = choices.filter(
        (i) => category_id !== i.id
      );
      setFilterChoices(response);
    }
  };

  useEffect(() => {
    filter();
    if (values?.categories) {
      const res = values.categories.filter((i) => i !== category_id);
      if (res.length < values.categories.length) {
        categories.onChange(res);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category_id, choices]);

  if (!category_id) return null;

  return (
    <AutocompleteArrayInput
      source={source}
      label={label}
      optionValue="id"
      choices={filterChoices}
      fullWidth
    />
  );
};
