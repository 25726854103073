import * as React from "react";
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { useFormState, useField } from 'react-final-form';
import { useTranslate } from "ra-core";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    gridContainer: {
        height: '100%',
    },
    list: {
        width: '100%',
    },
    itemContainer: {
        padding: '20px',
        overflowY: 'auto',
        height: '100%',
    },
    button: {
        display: 'block',
        minWidth: '160px',
        [theme.breakpoints.up('lg')]: {
            position: 'absolute',
            top: '-70px',
            right: '0',
        }
    },
}));

const CustomDialog = ({ onClose, onSave, open, ...props }) => {
    const classes = useStyles();
    const [checked, setChecked] = React.useState([]);
    const translate = useTranslate();
    const fields = [
        { name: 'volanta', title: `${translate("resources.notes.fields.volanta")}` },
        { name: 'title', title: `${translate("resources.notes.fields.title")}` },
        { name: 'copete', title: 'Copete' },
        { name: 'epigrafe', title: `${translate("resources.notes.fields.epigrafe")}` }
    ];

    const handleClose = () => {
        onClose();
    };

    const handleAgree = () => {
        onSave(checked);
    };

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={handleClose}
            aria-label={translate("components.select_fields")}>
            <DialogTitle>{translate("components.select_fields_to_copy")}</DialogTitle>
            <DialogContent>
                <List className={classes.root}>
                    {fields.map((value) => {
                        const labelId = `checkbox-list-label-${value.name}`;
                        return (
                            <ListItem key={value.name} role={undefined} dense button onClick={handleToggle(value.name)}>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={checked.indexOf(value.name) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={`${value.title}`} />
                            </ListItem>
                        );
                    })}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleAgree} color="primary" autoFocus>
                    {translate("ra.action.accept")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default function CopyFieldsToAnotherMedia(props) {
    const classes = useStyles();
    const { title, copyTo } = props;
    const [open, setOpen] = useState(false);
    const { input: input_title } = useField('title');
    const { input: input_copete } = useField('copete');
    const { input: input_volanta } = useField('volanta');
    const { input: input_epigrafe } = useField('epigrafe');

    const { input: input_title_paper } = useField('title_paper');
    const { input: input_copete_paper } = useField('copete_paper');
    const { input: input_volanta_paper } = useField('volanta_paper');
    const { input: input_epigrafe_paper } = useField('epigrafe_paper');

    const { values } = useFormState();

    const handleSelectedFields = (checked) => {
        if (copyTo === "internet") {
            if (checked.includes("title")) {
                input_title.onChange(values.title_paper);
            }
            if (checked.includes("copete")) {
                input_copete.onChange(values.copete_paper);
            }
            if (checked.includes("volanta")) {
                input_volanta.onChange(values.volanta_paper);
            }
            if (checked.includes("epigrafe")) {
                input_epigrafe.onChange(values.epigrafe_paper);
            }
        } else {
            if (checked.includes("title")) {
                input_title_paper.onChange(values.title);
            }
            if (checked.includes("copete")) {
                input_copete_paper.onChange(values.copete);
            }
            if (checked.includes("volanta")) {
                input_volanta_paper.onChange(values.volanta);
            }
            if (checked.includes("epigrafe")) {
                input_epigrafe_paper.onChange(values.epigrafe);
            }
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleSave = (value) => {
        setOpen(false);
        handleSelectedFields(value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button variant="outlined" color="primary" onClick={handleClickOpen} className={classes.button}>
                {title}
            </Button>
            <CustomDialog  {...props} open={open} onSave={handleSave} onClose={handleClose} />
        </>
    )
}
