import * as React from 'react';
import {
    AutocompleteInput,
    ReferenceInput,
    SearchInput,
    SelectInput,
} from 'react-admin';

const commentsFilters = [
    <SearchInput source="search" alwaysOn />,
    <SelectInput
        source="status"
        choices={[
            { id: 'publish', name: 'Publicado' },
            { id: 'rejected', name: 'Rechazado' },
        ]}
    />,
    <ReferenceInput filterToQuery={searchText => ({ search: searchText })} source="note_id" reference="notes">
        <AutocompleteInput optionText="title" />
    </ReferenceInput>,
];

export default commentsFilters;
