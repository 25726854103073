import Button from "@material-ui/core/Button";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useTranslate } from "ra-core";
import { useEffect, useRef, useState } from "react";
import {
  DeleteButton,
  Edit,
  FormTab,
  Loading,
  SaveButton,
  TabbedForm,
  Toolbar,
  TopToolbar,
  useDataProvider,
  useGetIdentity,
} from "react-admin";
import { useForm, useFormState } from "react-final-form";
import { Locked } from "../../components/Locked/Locked";
import FormNotesInternet from "../../components/formNotes/FormNotesInternet";
import FormNotesPaper from "../../components/formNotes/FormNotesPaper";
import AudioPicker from "../../components/pickers/AudioPicker";
import ImagePicker from "../../components/pickers/ImagePicker";
import { showResource } from "../../providers/configProvider";
import useAutosave, {
  AutosaveAlert,
  initAutosave,
} from "../../utils/useAutosave";
import ShareButton from "./ShareButton";
import { transform } from "./create";
export const NOTES_LOCK_LEAVE = 120; //segundos
export const NOTES_LOCK_ENTER = 1000 * 30; //milisegundos

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      "& .MuiTabs-root": {
        minHeight: "56px",
      },
    },
    saveButton: {
      [theme.breakpoints.up("sm")]: {
        marginLeft: "auto",
      },
    },
  })
);

const NotesEditToolbar = (props) => {
  const { setShowAlert, showAlert, setFormElement, ...rest } = props;
  const classes = useStyles();
  const translate = useTranslate();

  // ========================================================================
  // Ejecución del autosave al tipear
  // ========================================================================
  const formState = useFormState();
  const formElement = useForm();
  const [currentNote, setCurrentNote] = useState({});

  useAutosave(
    formState,
    currentNote,
    setCurrentNote,
    showAlert,
    rest.record.id
  );

  const removeAutosaveRecords = () => {
    const allRecords = { ...localStorage };
    if (allRecords) {
      const autosaveKeys = Object.keys(allRecords)?.filter((key) =>
        key.startsWith("autosave_data_")
      );
      const validAutosaveKeys = autosaveKeys.filter(
        (key) => !key.includes("autosave_data_null")
      );
      validAutosaveKeys.forEach((key) => {
        localStorage.removeItem(key);
      });
    }
  };

  const cleanLocalStorage = () => {
    const last_clear = localStorage.getItem("last_clear");
    const currentDate = new Date().getTime();
    const last_clearDate = last_clear ? new Date(parseInt(last_clear)) : null;
    if (last_clearDate) {
      const differenceInDays =
        (currentDate - last_clearDate.getTime()) / (1000 * 3600 * 24); //1 dia en milisegundos
      if (differenceInDays >= 7) {
        removeAutosaveRecords();
        localStorage.setItem("last_clear", currentDate.toString());
      }
    } else {
      removeAutosaveRecords();
      localStorage.setItem("last_clear", currentDate.toString());
    }
  };

  useEffect(() => {
    setFormElement(formElement);
    cleanLocalStorage();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // ========================================================================
  // Component body
  // ========================================================================
  return rest.record.id ? (
    <Toolbar {...rest}>
      <DeleteButton record={rest.record} />
      <SaveButton
        id="publish-notes"
        label={translate("ra.action.save")}
        className={classes.saveButton}
        undoable={false}
        redirect={false}
        disabled={formState.pristine}
        submitOnEnter={false}
      />
    </Toolbar>
  ) : null;
};

const CheckIsLocked = (props) => {
  const {
    showAlert,
    setShowAlert,
    setFormElement,
    formElement,
    localTimeout,
    setLocalTimeout,
    record,
    resource,
    ...rest
  } = props;
  const classes = useStyles();
  const { identity, loading: identityLoading } = useGetIdentity();
  const dataProvider = useDataProvider();
  const intervalRef = useRef(null);
  const translate = useTranslate();

  useEffect(() => {
    if (!identityLoading && identity) {
      setLockResource();
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [identity]); // eslint-disable-line react-hooks/exhaustive-deps

  const setLockResource = () => {
    if (
      identity.email !== "info@tuxdi.com" &&
      (record.locked_at === null ||
        Math.abs((new Date() - new Date(record.locked_at)) / 1000) >
          NOTES_LOCK_LEAVE)
    ) {
      locked(resource, record.id);
    }
  };

  const locked = (resource, recordId) => {
    dataProvider.locked(resource, recordId);
    intervalRef.current = setInterval(() => {
      dataProvider.locked(resource, recordId);
    }, NOTES_LOCK_ENTER);
  };

  // ========================================================================
  // Inicialización del Autosave/Carga de datos
  // ========================================================================
  const [autosaveNote, setAutosaveNote] = useState(null);

  useEffect(() => {
    if (record.id && !autosaveNote) {
      initAutosave(setAutosaveNote, record);
    }
  }, [record]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (autosaveNote && formElement) {
      if (autosaveNote.updated_at && !formElement.pristine) setShowAlert(true);
    }
  }, [autosaveNote, formElement]); // eslint-disable-line react-hooks/exhaustive-deps

  // ========================================================================
  // Component body
  // ========================================================================

  if (identityLoading || !record || !rest.permissions) {
    return <Loading />;
  }

  if (
    record.locked_by &&
    record.locked_at &&
    Math.abs((new Date() - new Date(record.locked_at)) / 1000) <
      NOTES_LOCK_LEAVE &&
    record.locked_by !== identity.id &&
    identity.email !== "info@tuxdi.com"
  ) {
    return <Locked record={record} {...rest} />;
  }

  return (
    <>
      <AutosaveAlert
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        form={formElement}
        autosaveNote={autosaveNote}
      >
        {translate("components.previous_note")}
      </AutosaveAlert>
      <TabbedForm
        className={classes.container}
        toolbar={
          <NotesEditToolbar
            setShowAlert={setShowAlert}
            showAlert={showAlert}
            setFormElement={setFormElement}
          />
        }
        {...rest}
      >
        <FormTab label={translate("components.internet")}>
          <FormNotesInternet mode="edit" {...props} />
        </FormTab>
        {showResource("paper") && (
          <FormTab label={translate("components.paper")}>
            <FormNotesPaper {...props} />
          </FormTab>
        )}
      </TabbedForm>
    </>
  );
};

const EditActions = (props) => {
  const url = process.env.REACT_APP_URL_CLIENT + `${props?.data?.link_note}`;
  const translate = useTranslate();
  return (
    <TopToolbar>
      {props.permissions &&
        props.permissions.some(
          (permission) =>
            permission.name === "*" || permission.name === "share-notes"
        ) && <ShareButton note={props.data} />}
      <Button
        variant="outlined"
        color="primary"
        disabled={props?.data?.status !== "publish" || false}
        startIcon={<VisibilityIcon />}
        href={`${url}`}
        target="_blank"
      >
        {translate("ra.action.show")}
      </Button>
    </TopToolbar>
  );
};

export const NotesEdit = (props) => {
  const [showAlert, setShowAlert] = useState(false);
  const [formElement, setFormElement] = useState(null);
  const [localTimeout, setLocalTimeout] = useState(null);

  // ========================================================================
  // Component body
  // ========================================================================
  return (
    <>
      <ImagePicker permissions={props.permissions} />
      <AudioPicker permissions={props.permissions} />
      <Edit
        title=" "
        {...props}
        undoable={false}
        transform={transform}
        actions={<EditActions {...props} />}
      >
        <CheckIsLocked
          permissions={props.permissions}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
          formElement={formElement}
          setFormElement={setFormElement}
          localTimeout={localTimeout}
          setLocalTimeout={setLocalTimeout}
        />
      </Edit>
    </>
  );
};
