import { useEffect } from "react";
import { useListContext } from "react-admin";
export const ExportNumber = ({ numberExport, SetNumberExport }) => {
  const { total } = useListContext();
  useEffect(() => {
    if (total > numberExport) {
      SetNumberExport(total);
    }
  }, [numberExport, total]);// eslint-disable-line react-hooks/exhaustive-deps
  return null;
};
