import { Typography, Card, CardContent, Tooltip } from "@material-ui/core";
import medioData from "Static/data/medio.json";
import CustomTextField from "./CustomTextField";
import { SuggestedIA } from "../IA/SuggestedIA";
import InfoIcon from "@material-ui/icons/Info";
import { CustomAutocompleteArrayInput } from "./CustomAutocompleteArrayInput";
import { useTranslate } from "ra-core";

export const SeoFields = ({ ...props }) => {
    const translate = useTranslate();
    return (
        <>
            <Typography variant="h6" gutterBottom style={{
                display: "flex",
                alignItems: "center",
            }}>
                SEO
                <Tooltip title={translate("resources.notes.seo_helper")}>
                    <InfoIcon style={{ marginLeft: ".5em" }} color="primary" />
                </Tooltip>
            </Typography>
            <Card style={{ marginBottom: "16px" }}>
                <CardContent>
                    <Typography variant="caption" style={{
                        display: "flex",
                        alignItems: "center",
                        color: "rgba(0, 0, 0, 0.54)",
                        marginBottom: "8px"
                    }} >{translate("resources.notes.fields.keywords")}
                        <Tooltip title={translate("resources.notes.keywords_helper")}>
                            <InfoIcon style={{ marginLeft: ".5em", fontSize: "18px" }} color="primary" />
                        </Tooltip>
                    </Typography>
                    <CustomAutocompleteArrayInput source={"keywords"} label=" " />
                    <Typography variant="caption" style={{
                        display: "flex",
                        alignItems: "center",
                        color: "rgba(0, 0, 0, 0.54)",
                        marginTop: "16px"
                    }} >{translate("resources.notes.fields.meta_description")}
                        <Tooltip title={translate("resources.notes.meta_description_helper")}>
                            <InfoIcon style={{ marginLeft: ".5em", fontSize: "18px" }} color="primary" />
                        </Tooltip>
                    </Typography>
                    <CustomTextField
                        label={" "}
                        source="meta_description"
                        record={props.record}
                        isRequired={false}
                        inputProps={{ maxLength: 160 }}
                    />
                    {medioData?.enabled_ia ? <SuggestedIA /> : null}
                </CardContent>
            </Card>
        </>
    )
}