import * as React from "react";
import {
    Edit,
    FormWithRedirect,
    TextInput,
    required,
    SaveButton,
    ReferenceInput,
    SelectInput,
    RadioButtonGroupInput,
    DeleteButton
} from 'react-admin';
import { FormDataConsumer } from 'ra-core';
import classnames from 'classnames';
import { Box, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
    theme => ({
        toolbar: {
            backgroundColor:
                theme.palette.type === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
        },
        desktopToolbar: {
            marginTop: theme.spacing(2),
        },
        mobileToolbar: {
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            padding: '16px',
            width: '100%',
            boxSizing: 'border-box',
            flexShrink: 0,
            zIndex: 2,
        },
        defaultToolbar: {
            flex: 1,
            display: 'flex',
            justifyContent: 'space-between',
        },
        spacer: {
            [theme.breakpoints.down('xs')]: {
                height: '5em',
            },
        },
        imgPreview: {
            width: 'initial',
            minWidth: 'initial',
            maxWidth: '42rem',
            maxHeight: '15rem',
            border: '1px solid'
        }
    }),
    { name: 'RaToolbar' }
);

export const MediaEdit = props => {
    const classes = useStyles(props);

    return (
        <Edit title=" " {...props}>
            <FormWithRedirect
                {...props}
                render={formProps => (
                    <form>
                        <Box display="flex" m="1em">
                            <Box flex={1}>
                                <TextInput source="title" label="resources.media.fields.title" validate={required()} fullWidth />
                                <TextInput source="description" label="resources.media.fields.description" fullWidth />
                                <TextInput source="alt_text" label="resources.media.fields.alt_text" fullWidth />
                                <TextInput source="caption" label="resources.media.fields.caption" fullWidth />
                                <FormDataConsumer>
                                    {({ formData }) => (
                                        formData.media_type === 'image' && (
                                            <ReferenceInput source="photographer_id" label="resources.media.fields.photographer" reference="photographers" allowEmpty >
                                                <SelectInput source="name" fullWidth />
                                            </ReferenceInput>
                                        )
                                    )}
                                </FormDataConsumer>
                            </Box>
                            <Box flex={1} ml="1em">
                                <RadioButtonGroupInput
                                    label="resources.media.fields.media_type"
                                    source="media_type"
                                    defaultValue="image"
                                    fullWidth
                                    disabled
                                    choices={[
                                        {
                                            id: 'image',
                                            name: 'Imagen',
                                        },
                                        {
                                            id: 'file',
                                            name: 'Audio',
                                        },
                                    ]}
                                />
                                <FormDataConsumer>
                                    {({ formData }) => (
                                        formData.preview_url && (
                                            formData.media_type === 'image' ?
                                                <img
                                                    src={formData.preview_url}
                                                    className={classnames(classes.imgPreview)}
                                                    alt="Preview"
                                                />
                                                :
                                                formData.media_type === 'file' ?
                                                    <audio controls>
                                                        <source src={formData.preview_url} type="audio/ogg" title="Upload Preview" />
                                                    </audio>
                                                    : <></>
                                        )
                                    )}
                                </FormDataConsumer>
                            </Box>
                        </Box>
                        <Toolbar className={classnames(
                            classes.toolbar
                        )}
                            role="toolbar">
                            <Box display="flex" justifyContent="space-between" width="100%">
                                <SaveButton
                                    saving={formProps.saving}
                                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                                />
                                <DeleteButton record={formProps.record} />
                            </Box>
                        </Toolbar>
                    </form>
                )}
            />
        </Edit>
    )
}