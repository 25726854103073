import { FunctionField } from "react-admin"
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { useTranslate } from "ra-core";

const ORDER_STATUS_PENDING_PAYMENT = 'pending';
const ORDER_STATUS_IN_REVIEW = 'in_review';
const ORDER_STATUS_READY = 'ready';
const ORDER_STATUS_PUBLISH = 'publish';

const useStyles = makeStyles({
  pending: { color: 'black' },
  in_review: { color: 'orange' },
  ready: { color: 'green' },
  publish: { color: 'black' },
});

const StatusField = ({ record, ...props }) => {
  const classes = useStyles();
  const translate = useTranslate();
  if (!record) return null;

  return (
    <FunctionField
      render={() =>
        record.status === ORDER_STATUS_PENDING_PAYMENT ? `${translate("resources.orders.order_status.pending")}`
          : record.status === ORDER_STATUS_IN_REVIEW ? `${translate("resources.orders.order_status.in_review")}`
            : record.status === ORDER_STATUS_READY ? `${translate("resources.orders.order_status.ready")}`
              : record.status === ORDER_STATUS_PUBLISH ? `${translate("resources.orders.order_status.publish")}`
                : 'Error'
      }
      className={classnames({
        [classes.pending]: record.status === ORDER_STATUS_PENDING_PAYMENT,
        [classes.in_review]: record.status === ORDER_STATUS_IN_REVIEW,
        [classes.ready]: record.status === ORDER_STATUS_READY,
        [classes.publish]: record.status === ORDER_STATUS_PUBLISH,
      })}
      {...props}
    />
  )
}

export default StatusField;