import { useEffect, useState } from "react";
import { TextInput, required } from "react-admin";
import { Typography, Button } from "@material-ui/core";
import { useField } from "react-final-form";
import { makeStyles, Box } from "@material-ui/core";
import IconContentAdd from "@material-ui/icons/Add";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import ImageDefault from "../../assets/img/note-default.png";
import DeleteIcon from "@material-ui/icons/Delete";
import { useTranslate} from "ra-core";
const useStyles = makeStyles({
  container: {
    "& img": {
      width: "80px",
      height: "80px",
      objectFit: "cover",
    }
  },
  gallery: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    flexWrap: "wrap",
    minHeight: "80px",
    background: "#fafafa",
    padding: "6px",
    marginBottom: "18px"
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  photo: {
    position: "relative",
    display: "flex",
    flexDirection: "column"
  },
  number: {
    position: "absolute",
    bottom: 0,
    right: 0,
    padding: "6px",
    background: "rgba(255, 255, 255, 0.7)",
  },
  hidden: {
    display: "none",
  },
  deleteButton: {
    lineHeight: 1,
    color: "rgb(31, 58, 79)",
    background: "rgba(255, 255, 255, 0.8)",
    boxShadow: "1px 1px 3px 1px #0000002b",
    borderRadius: "50px",
    fontSize: "12px",
    minWidth: "28px",
    width: "28px",
    height: "28px",
    "& .MuiSvgIcon-root": {
      width: "18px",
      height: "18px",
    },
    "&:hover": {
      background: "rgba(255, 255, 255, 0.8)",
    },
  },
});

export const GalleryNoteField = ({ record }) => {
  const [galleryImages, setGalleryImages] = useState();
  const [customEvent, setCustomEvent] = useState();
  const { input: input_meta_note } = useField("gallery_note");
  const classes = useStyles();
  const translate = useTranslate();

  useEffect(() => {
    if (record && record.format === "gallery" && !galleryImages) {
      try {
        setGalleryImages(JSON.parse(record.meta_note));
      } catch (e) {
        setGalleryImages([]);
      }
    }
  }, [record]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCallback = (event) => {
    const { editorId, image } = event.detail;
    if (editorId === "galleryImages") {
      const images = image.map((i) => ({ id: i.id, src: i.imageUrl, alt: i.alt_text }))
      input_meta_note.onChange(JSON.stringify(images));
      setGalleryImages(images);
    }
  };

  const handleClick = () => {
    window.addEventListener("selected-image-imagepicker", handleCallback);
    window.dispatchEvent(customEvent);
  };

  const handleDelete = () => {
    setGalleryImages(null);
    input_meta_note.onChange(null);
  };

  useEffect(() => {
    setCustomEvent(
      new CustomEvent("open-imagepicker", {
        detail: {
          editor: "galleryImages",
          modemultiselect: true,
          max: 20
        },
        bubbles: false,
        cancelable: true,
        composed: false,
      })
    );
    return () => {
      setCustomEvent(null);
    };
  }, []);

  return (
    <>
      <div className="d-flex">
        <Typography variant="h6" gutterBottom>
          {translate("components.gallery")}
        </Typography>
      </div>
      <div className={classes.container} style={{ position: "relative" }}>
        {galleryImages && galleryImages !== "" ? (
          <Box
            style={{
              position: "absolute",
              top: "12px",
              padding: "0 12px",
              boxSizing: "border-box",
              zIndex: 2,
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button
              onClick={handleClick}
              style={{
                color: "rgb(31, 58, 79)",
                background: "rgba(255, 255, 255, 0.8)",
                boxShadow: "1px 1px 3px 1px #0000002b",
              }}
              startIcon={<AutorenewIcon />}
            >
              {translate("components.update")}
            </Button>
            <Button
              onClick={handleDelete}
              className={classes.deleteButton}
            ><DeleteIcon /></Button>
          </Box>
        ) : (
          <Button
            onClick={handleClick}
            label={translate("components.add")}
            style={{
              position: "absolute",
              right: "12px",
              top: "12px",
              zIndex: 2,
              color: "rgb(31, 58, 79)",
              background: "rgba(255, 255, 255, 0.8)",
              boxShadow: "1px 1px 3px 1px #0000002b",
            }}
            startIcon={<IconContentAdd />}
          >
           {translate("components.add")}
          </Button>
        )}
        <div className={classes.gallery}>
          {galleryImages && Array.isArray(galleryImages) && galleryImages.length > 0 &&
            galleryImages.map((image, index) =>
              <div className={classes.photo}>
                <img
                  src={image.src ? image.src : ImageDefault}
                  alt=""
                  className={classes.img}
                />
                <figcaption className={classes.number}>
                  <span>{index + 1}</span>
                </figcaption>
              </div>
            )}
        </div>
      </div>
      <div className={classes.hidden}>
        <TextInput
          id="gallery_note"
          source="gallery_note"
          validate={required()}
          fullWidth
        />
      </div>
    </>
  );
};
