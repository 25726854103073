import React from "react";
import LogoHeader from 'Static/images/logo-header.svg';

const Logo = ({ maxWidth, height = "32px" }) => {
    return (
        <img style={{ maxWidth : "100%"}} height={height} src={LogoHeader} alt="logo" />
    );
};

export default Logo;
