import * as React from "react";
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from '@material-ui/core/Tooltip';
import { TableNotes } from '../fields/TableNotes';
import { useTranslate } from "ra-core";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    gridContainer: {
        height: '100%',
    },
    list: {
        width: '100%',
    },
    itemContainer: {
        padding: '20px',
        overflowY: 'auto',
        height: '100%',
        transition: 'all 0.6s ease'
    },
    preview: {
        border: ' 1px solid grey',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '60vh'
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenDialog = ({ onClose, onSave, open, ...props }) => {
    const classes = useStyles();
    const [selectedValue, setSelectedValue] = useState();
    const translate = useTranslate();
    const handleClose = () => {
        setSelectedValue(null);
        onClose();
    };

    const handleSave = () => {
        const post = {
            id: selectedValue.id,
            title_home: selectedValue.title_home,
            copete: selectedValue.copete,
            featured_image: selectedValue.featured_image,
            content: Boolean(selectedValue.content),
            content_premium: Boolean(selectedValue.content_premium),
        };
        onSave(post);
        setSelectedValue(null);
    };

    const handleListItemClick = (value) => {
        setSelectedValue(value);
    };

    return (
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {translate("components.select_news")}

                    </Typography>
                </Toolbar>
            </AppBar>
            <Grid container className={classes.gridContainer}>
                <Grid item xs={12} md={selectedValue ? 6 : 12} className={classes.itemContainer}>
                    <TableNotes handleListItemClick={handleListItemClick} open={open} selectedValue={selectedValue} />
                </Grid>
                {selectedValue &&
                    <Grid item xs={12} md={6} className={classes.itemContainer}>
                        <div>
                            <h2>{selectedValue.title}</h2>
                            <img width="100%" src={selectedValue.featured_image} alt="" />
                            <p dangerouslySetInnerHTML={{ __html: selectedValue.content }}></p>
                        </div>
                    </Grid>
                }
            </Grid>
            <Divider />
            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    {translate("ra.action.cancel")}
                </Button>
                <Button variant="contained" color="primary" onClick={handleSave}>
                    {translate("ra.action.accept")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default function NoticiaPicker(props) {
    const { handleSelectedValue, id, viewIcon } = props;
    const [open, setOpen] = useState(false);
    const translate = useTranslate();
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleSave = (value) => {
        setOpen(false);
        handleSelectedValue(value, id);
    };

    const handleClose = () => {
        setOpen(false);
    };

    if (viewIcon) {
        return (
            <>
                <Tooltip title={translate("components.select_news")}>
                    <IconButton onClick={handleClickOpen} aria-label= {translate("components.select_news")}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <FullScreenDialog  {...props} open={open} onSave={handleSave} onClose={handleClose} />
            </>
        )
    } else {
        return (
            <>
                <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                    {translate("components.select_news")}
                </Button>
                <FullScreenDialog  {...props} open={open} onSave={handleSave} onClose={handleClose} />
            </>
        )
    }
}
