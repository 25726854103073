import * as React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    main: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: -8,
        marginBottom: -8,
    },
    chip: { margin: 4 },
});

const PlanField = ({ record }) => {
    const classes = useStyles();
    if (!record) return null;

    return record?.subscriptions && Array.isArray(record.subscriptions) && record.subscriptions.length > 0 ? (
        <span className={classes.main}>
            {record.subscriptions[0]?.plan ?
            <Chip
                size="small"
                className={classes.chip}
                label={record.subscriptions[0].plan?.name}
            />: null}
        </span>)
     : null;
};

export default PlanField;
