import SvgIcon from "@material-ui/core/SvgIcon";

export const BenefitsIcon = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 188 206"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48.4645 157.022V142.646C90.6789 133.735 91.8091 71.9899 48.4645 62.8404V48.4644H146.546V40H40V70.4318C82.6832 70.4318 82.5507 135.056 40 135.056V165.487H146.546V157.023L48.4645 157.022Z"
          fill="#000000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M147.464 56V69.1984H139V56H147.464ZM147.464 76.2836V89.482H139V76.2836H147.464ZM147.464 96.5671V109.766H139V96.5671H147.464ZM147.464 116.851V130.049H139V116.851H147.464ZM147.464 137.134V150.333H139V137.134H147.464Z"
          fill="#000000"
        />
      </svg>
    </SvgIcon>
  );
};
